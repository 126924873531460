import {PaginatedDataSetModel} from '@matchsource/models/core';
import {Name} from '@matchsource/api-generated/subject';

export type SwabKitOrdersLookupDataSetModel = PaginatedDataSetModel<SwabKitOrderModel>;

export class SwabKitOrderModel {
  bpGuid: string;
  rcpGuid: string;
  rid?: string;
  name?: Name;
  birthDay?: string;
  orderDate: string;
  orderNumber: number;
  orderStatus: string;
  outgoingTrackingNumber: string;
  trackingLink: string;
  quantity: number;
  shippedTo: Name;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  state: string;
  zipPostalCode: string;
  phoneNum: string;
  bulk: boolean;
  email?: string;
  hospitalName?: string;
  shipToPatientInd: boolean;
  inventoryTypeDesc?: string;
}

export const PATIENT_SWAB_KIT_ORDERS_ROUTE_NAME = 'patient-swab-kit-orders';
export const BULK_SWAB_KIT_ORDERS_ROUTE_NAME = 'bulk-swab-kit-orders';
