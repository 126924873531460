/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SearchControllerService } from './services/search-controller.service';
import { SearchSummaryReportControllerService } from './services/search-summary-report-controller.service';
import { CoopSearchControllerService } from './services/coop-search-controller.service';
import { WmdaDonorRequestControllerService } from './services/wmda-donor-request-controller.service';
import { MatchResultControllerService } from './services/match-result-controller.service';
import { HaplotypePairControllerService } from './services/haplotype-pair-controller.service';
import { GenotypeProbabilityControllerService } from './services/genotype-probability-controller.service';
import { ExportControllerService } from './services/export-controller.service';
import { SummaryCountsControllerService } from './services/summary-counts-controller.service';
import { SecurityControllerService } from './services/security-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SearchControllerService,
    SearchSummaryReportControllerService,
    CoopSearchControllerService,
    WmdaDonorRequestControllerService,
    MatchResultControllerService,
    HaplotypePairControllerService,
    GenotypeProbabilityControllerService,
    ExportControllerService,
    SummaryCountsControllerService,
    SecurityControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
