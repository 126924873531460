/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createVaccinationData } from '../fn/vaccination-controller/create-vaccination-data';
import { CreateVaccinationData$Params } from '../fn/vaccination-controller/create-vaccination-data';
import { getCatalog } from '../fn/vaccination-controller/get-catalog';
import { GetCatalog$Params } from '../fn/vaccination-controller/get-catalog';
import { getVaccinationsByGuid } from '../fn/vaccination-controller/get-vaccinations-by-guid';
import { GetVaccinationsByGuid$Params } from '../fn/vaccination-controller/get-vaccinations-by-guid';
import { SupplementsStaticsDto } from '../models/supplements-statics-dto';
import { updateVaccinationData } from '../fn/vaccination-controller/update-vaccination-data';
import { UpdateVaccinationData$Params } from '../fn/vaccination-controller/update-vaccination-data';
import { VaccinationDto } from '../models/vaccination-dto';

@Injectable({ providedIn: 'root' })
export class VaccinationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateVaccinationData()` */
  static readonly UpdateVaccinationDataPath = '/vaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVaccinationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccinationData$Response(params: UpdateVaccinationData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateVaccinationData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVaccinationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccinationData(params: UpdateVaccinationData$Params, context?: HttpContext): Observable<void> {
    return this.updateVaccinationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getVaccinationsByGuid()` */
  static readonly GetVaccinationsByGuidPath = '/vaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationsByGuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVaccinationsByGuid$Response(params: GetVaccinationsByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VaccinationDto>>> {
    return getVaccinationsByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVaccinationsByGuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVaccinationsByGuid(params: GetVaccinationsByGuid$Params, context?: HttpContext): Observable<Array<VaccinationDto>> {
    return this.getVaccinationsByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VaccinationDto>>): Array<VaccinationDto> => r.body)
    );
  }

  /** Path part for operation `createVaccinationData()` */
  static readonly CreateVaccinationDataPath = '/vaccinations/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVaccinationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVaccinationData$Response(params: CreateVaccinationData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createVaccinationData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVaccinationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVaccinationData(params: CreateVaccinationData$Params, context?: HttpContext): Observable<void> {
    return this.createVaccinationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCatalog()` */
  static readonly GetCatalogPath = '/vaccinations/catalog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalog$Response(params?: GetCatalog$Params, context?: HttpContext): Observable<StrictHttpResponse<SupplementsStaticsDto>> {
    return getCatalog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalog(params?: GetCatalog$Params, context?: HttpContext): Observable<SupplementsStaticsDto> {
    return this.getCatalog$Response(params, context).pipe(
      map((r: StrictHttpResponse<SupplementsStaticsDto>): SupplementsStaticsDto => r.body)
    );
  }

}
