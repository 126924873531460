/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRecipientIdentityVerificationInfo } from '../fn/recipient-identity-verification-controller/get-recipient-identity-verification-info';
import { GetRecipientIdentityVerificationInfo$Params } from '../fn/recipient-identity-verification-controller/get-recipient-identity-verification-info';
import { getRecipientIdentityVerifyHistory } from '../fn/recipient-identity-verification-controller/get-recipient-identity-verify-history';
import { GetRecipientIdentityVerifyHistory$Params } from '../fn/recipient-identity-verification-controller/get-recipient-identity-verify-history';
import { RecipientIdentityVerificationInfo } from '../models/recipient-identity-verification-info';
import { RecipientIdentityVerifyHistory } from '../models/recipient-identity-verify-history';

@Injectable({ providedIn: 'root' })
export class RecipientIdentityVerificationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRecipientIdentityVerificationInfo()` */
  static readonly GetRecipientIdentityVerificationInfoPath = '/recipients/{guid}/identity-verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentityVerificationInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerificationInfo$Response(params: GetRecipientIdentityVerificationInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipientIdentityVerificationInfo>> {
    return getRecipientIdentityVerificationInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentityVerificationInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerificationInfo(params: GetRecipientIdentityVerificationInfo$Params, context?: HttpContext): Observable<RecipientIdentityVerificationInfo> {
    return this.getRecipientIdentityVerificationInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipientIdentityVerificationInfo>): RecipientIdentityVerificationInfo => r.body)
    );
  }

  /** Path part for operation `getRecipientIdentityVerifyHistory()` */
  static readonly GetRecipientIdentityVerifyHistoryPath = '/recipients/{guid}/history/identity-verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentityVerifyHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerifyHistory$Response(params: GetRecipientIdentityVerifyHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipientIdentityVerifyHistory>>> {
    return getRecipientIdentityVerifyHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentityVerifyHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentityVerifyHistory(params: GetRecipientIdentityVerifyHistory$Params, context?: HttpContext): Observable<Array<RecipientIdentityVerifyHistory>> {
    return this.getRecipientIdentityVerifyHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipientIdentityVerifyHistory>>): Array<RecipientIdentityVerifyHistory> => r.body)
    );
  }

}
