import {Injectable} from '@angular/core';
import {HlaHistorySerializerService} from './hla-history-serializer.service';
import {Observable} from 'rxjs';
import {HlaHistoryDTO} from './hla-history.interfaces';
import {map} from 'rxjs/operators';
import {HlaHistoryModel} from './hla-history.model';
import {TestResultControllerService} from '@matchsource/api-generated/subject';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';

@Injectable({
  providedIn: 'root',
})
export class HlaHistoryApiService {
  constructor(
    private readonly serializer: HlaHistorySerializerService,
    private readonly testResultControllerService: TestResultControllerService
  ) {}

  getPatientHlaHistory(
    patientId: MsApp.Guid,
    phenotypeId: number = ACTUAL_PHENOTYPE_INDEX
  ): Observable<HlaHistoryModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);

    return this.testResultControllerService
      .getTestResultsBySubjectGuid1({guid: patientId, phenotypeId}, context())
      .pipe(map(hlaHistory => hlaHistory.map(item => this.serializer.fromDTO(item as HlaHistoryDTO))));
  }

  getSourceHlaHistory(sourceId: MsApp.Guid): Observable<HlaHistoryModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);

    return this.testResultControllerService
      .getTestResultsBySubjectGuid({guid: sourceId}, context())
      .pipe(map(hlaHistory => hlaHistory.map(item => this.serializer.fromDTO(item as HlaHistoryDTO))));
  }
}
