/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getReports } from '../fn/looker-controller/get-reports';
import { GetReports$Params } from '../fn/looker-controller/get-reports';
import { getUrl } from '../fn/looker-controller/get-url';
import { GetUrl$Params } from '../fn/looker-controller/get-url';
import { LookerReportsDto } from '../models/looker-reports-dto';
import { LookerUrlDto } from '../models/looker-url-dto';

@Injectable({ providedIn: 'root' })
export class LookerControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUrl()` */
  static readonly GetUrlPath = '/looker/{reportId}/{tcId}';

  /**
   * This method retrieves looker's embed URL by reportId and tcId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrl$Response(params: GetUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<LookerUrlDto>> {
    return getUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves looker's embed URL by reportId and tcId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrl(params: GetUrl$Params, context?: HttpContext): Observable<LookerUrlDto> {
    return this.getUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookerUrlDto>): LookerUrlDto => r.body)
    );
  }

  /** Path part for operation `getReports()` */
  static readonly GetReportsPath = '/looker/reports';

  /**
   * This method retrieves available looker reports' info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Response(params?: GetReports$Params, context?: HttpContext): Observable<StrictHttpResponse<LookerReportsDto>> {
    return getReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves available looker reports' info
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports(params?: GetReports$Params, context?: HttpContext): Observable<LookerReportsDto> {
    return this.getReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookerReportsDto>): LookerReportsDto => r.body)
    );
  }

}
