import {DocumentModel} from '@matchsource/models/documents';
import {NotesModel} from '@matchsource/models/notes';
import {PaginatedDataSetModel} from '@matchsource/models/core';
import {VaccinationModel} from '@matchsource/models/vaccination';

export enum OplSourceType {
  Donor = 'DONOR',

  Cord = 'CORD',

  Mixed = 'MIXED',
}

export interface SsaHistoryRequestModel {
  id: MsApp.Guid;
  requestedDate: MsApp.DateString;
  status: string;
  editedBy: string;
  editedDate: MsApp.DateString;
  cancelDate?: MsApp.DateString;
  oplId: MsApp.Guid | number;
  requestedBy: string;
}

export interface SsaRequestModel {
  id?: MsApp.Guid;
  labReportCode: 'POSITIVE' | 'NEGATIVE' | 'UNKNOWN';
  donorMatchInd: boolean;
  donorMatchCriteria?: string[];
  donorNote?: NotesModel;
  cordMatchInd: boolean;
  singleCordInd?: boolean;
  singleCordTnc?: number;
  singleCordCd34?: number;
  doubleCordInd?: boolean;
  doubleCordTnc?: number;
  doubleCordCd34?: number;
  patientWeight?: number;
  cordNote?: NotesModel;
  requestNote?: NotesModel;
  requestedDate?: MsApp.DateString;
  status?: string;
  cancelDate?: MsApp.DateString;
  oplId?: MsApp.Guid | number;
  attachments: DocumentModel[];
  idsToDelete?: (number | MsApp.Guid)[];
  requestGuid?: MsApp.Guid;
  notifyCaseResourceIds?: number[];
  includeNextBestMatch?: boolean;
}

export interface DonorContactReportModel {
  reportDate: MsApp.DateString;
  fileName: string;
  documentId: number;
}

export type DonorContactReportDataSetModel = PaginatedDataSetModel<DonorContactReportModel>;
export type OplSourceTypes = 'DONOR' | 'CORD';
type MixedOplSourceTypes = 'DONOR' | 'CORD' | 'MIXED';

export interface OplSourceModel {
  guid: MsApp.Guid;
  orderNum: number;
  source: any;
  noteId: number | null;
  isPotentialSource: boolean;
}

export interface OplDonorSourceModel extends OplSourceModel {
  suggestedTypings: string[];
  vaccination?: VaccinationModel;
}

export interface OplCordSourceModel extends OplSourceModel {
  hlaMismatches: string[];
  matchCategoryOption: string;
  singleCordReq: boolean;
}

export interface OplDonorSimpleModel {
  guid: MsApp.Guid;
  note: NotesModel | null;
  suggestedTypings: string[];
}

export interface OplCordSimpleModel {
  guid: MsApp.Guid;
  note: NotesModel | null;
  singleCordReq: boolean;
  matchCategoryOption: string;
  hlaMismatches: string[];
}

export interface NewOplModel {
  option: string;
  name: string;
  private: boolean;
  copyToId: any;
  oplOwnershipType: OplOwnershipType;
}

export interface CopySourcesToOplModel extends NewOplModel {
  patientGuid: MsApp.Guid;
  sourceType: 'CORD' | 'DONOR';
  sources: any[];
}

export enum OplOwnershipType {
  Ssa = 'SSA',
  Tcc = 'TCC',
}

export interface OplModel {
  id?: number;
  sharedInd?: boolean;
  name: string;
  caseResourceId: MsApp.Guid;
  patientId: MsApp.Guid;
  phenotypeId: number;
  sourceType: MixedOplSourceTypes;
  private: boolean;
  owner: boolean;
  donors: OplDonorSourceModel[];
  cords: OplCordSourceModel[];
  noteId: number;
  oplOwnershipType: OplOwnershipType;
  viewOnly: boolean;
  ssaRequestId?: MsApp.Guid;
  disabled?: boolean;
  ssaRequestGuid?: MsApp.Guid;

  getSourcesByType(type: OplSourceType): any[];
}

export interface OplPanelModel {
  id?: number;
  sharedInd?: boolean;
  shareDate?: string;
  name: string;
  caseResourceId: MsApp.Guid;
  patientId: MsApp.Guid;
  phenotypeId: number;
  sourceType: MixedOplSourceTypes;
  private: boolean;
  owner: boolean;
  donors: OplDonorSourceModel[];
  cords: OplCordSourceModel[];
  noteId: number;
  oplOwnershipType: 'TCC' | 'SSA';
  viewOnly: boolean;
  ssaRequestId?: MsApp.Guid;
  ssaRequestGuid?: MsApp.Guid;
  nameNcounter: string;
  isSSA: boolean;
  patientGuid: MsApp.Guid;
  disabled?: boolean;

  getSourcesByType(type: OplSourceType): any[];

  serialize(): any;
}

export interface OplSimpleModel {
  id?: number;
  shareDate?: string;
  name: string;
  patientGuid: MsApp.Guid;
  sourceType: MixedOplSourceTypes;
  private?: boolean;
  oplOwnershipType: 'TCC' | 'SSA';
  note?: NotesModel | null;
}

export interface CreateOplResponse {
  [key: string]: number;
}

export interface OplPanelSimpleModel {
  id?: number;
  name: string;
  patientGuid: MsApp.Guid;
  sourceType: MixedOplSourceTypes;
  private?: boolean;
  oplOwnershipType: 'TCC' | 'SSA';
  noteId?: number;
  phenotypeId?: number;
  sharedInd?: boolean;
  shareDate?: string;
  isSSA: boolean;
}

export interface OplCreateModel {
  name: string;
  oplOwnershipType: 'TCC' | 'SSA';
  patientGuid: MsApp.Guid;
  phenotypeId: number;
  private: boolean;
  sourceType: MixedOplSourceTypes;
  ssaRequestGuid?: MsApp.Guid;
}

export interface OrderPreviewList {
  cords?: OplCordSourceModel[];
  donors?: OplDonorSourceModel[];
  id?: number;
  shareDate?: string;
  name: string;
  noteId?: number;
  oplOwnershipType: 'TCC' | 'SSA';
  owner?: boolean;
  patientGuid: MsApp.Guid;
  private?: boolean;
  sourceType: MixedOplSourceTypes;
  ssaRequestGuid?: string;
  nameNcounter: string;
  viewOnly: boolean;
}

export interface OplEditDataModel {
  oplName: string;
  oplStatus: boolean;
}

export interface OplEditModel {
  oldData: OplEditDataModel;
  newData: OplEditDataModel;
}
