/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { exportSampleInstructions } from '../fn/export-controller/export-sample-instructions';
import { ExportSampleInstructions$Params } from '../fn/export-controller/export-sample-instructions';

@Injectable({ providedIn: 'root' })
export class ExportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportSampleInstructions()` */
  static readonly ExportSampleInstructionsPath = '/exports/sample-instructions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSampleInstructions()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSampleInstructions$Response(params: ExportSampleInstructions$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSampleInstructions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSampleInstructions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSampleInstructions(params: ExportSampleInstructions$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSampleInstructions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
