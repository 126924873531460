import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DiseaseModel} from '@matchsource/models/nomenclature';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {fromDTO} from './diseases.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class DiseasesApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getDiseases(): Observable<DiseaseModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSubjectData);

    return this.nomenclaturesControllerService
      .listAllDisease(null, context())
      .pipe(map(diseases => diseases.map(disease => fromDTO(disease))));
  }
}
