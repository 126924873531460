export const USER_PERMISSIONS = {
  // coop:search:view || coop:source:view
  COOP_ALLOW: 'coop:allow',
  COOP_LINK_SHOW: 'coop:link:show',

  // coop:search:view
  COOP_SEARCHES_SHOW: 'coop:searches:show',

  // coop:search:create
  COOP_ADD_SEARCH_LINK_SHOW: 'coop:add_search_link:show',

  // coop:search:rerun
  COOP_RERUN_SEARCH_LINK_SHOW: 'coop:rerun_search_link:show',

  // coop:search:update
  COOP_ENTER_DATE_LINK_SHOW: 'coop:enter_date_link:show',

  // coop:source:view
  COOP_SOURCE_ALLOW: 'coop:source:allow',
  COOP_SOURCES_SHOW: 'coop:sources:show',

  // coop:source:create
  COOP_ADD_SOURCE_ALLOW: 'coop:add_source:allow',
  COOP_ADD_SOURCE_LINK_SHOW: 'coop:add_source_link:show',

  // coop:source:edit
  COOP_EDIT_SOURCE_ALLOW: 'coop:edit_source:allow',
  COOP_EDIT_SOURCE_LINK_SHOW: 'coop:edit_source_link:show',

  // coop:emdis:source:edit
  DONOR_EDIT_ALLOW: 'donor:edit:allow',
  DONOR_EDIT_LINK_SHOW: 'donor:edit_link:show',
  CORD_EDIT_ALLOW: 'cord:edit:allow',
  CORD_EDIT_LINK_SHOW: 'cord:edit_link:show',

  // cord:view
  CORD_ALLOW: 'cord:allow',
  CORD_LOOKUP_ALLOW: 'cord_lookup:allow',
  CORD_DETAILS_LINK_SHOW: 'cord:details_link:show',
  CORD_DETAILS_LINK_HIDE: 'cord:details_link:hide',
  CORD_LOOKUP_LINK_SHOW: 'cord:lookup_link:show',
  CORD_UNIT_REPORT_ALLOW: 'cord:unit_report:allow',

  // biobank:view
  BIOBANK_CORD_ALLOW: 'bdp:allow',
  BIOBANK_LOOKUP_ALLOW: 'bdp:view',
  BIOBANK_DETAILS_LINK_SHOW: 'biobank:details_link:show',
  BIOBANK_EDIT_LINK_SHOW: 'biobank:edit_link:show',

  // donor:view
  DONOR_ALLOW: 'donor:allow',
  DONOR_LOOKUP_ALLOW: 'donor_lookup:allow',
  DONOR_DETAILS_LINK_SHOW: 'donor:details_link:show',
  DONOR_DETAILS_LINK_HIDE: 'donor:details_link:hide',
  DONOR_LOOKUP_LINK_SHOW: 'donor:lookup_link:show',

  // form_due:view
  FORMS_DUE_ALLOW: 'forms_due:allow',
  FORMS_DUE_LINK_SHOW: 'forms_due:link:show',

  FORM_DUE_RESOLUTION_REQUEST: 'form_due_resolution:request',

  FORMS_DUE_FOLLOW_UP_PROCESS: 'form_due:follow_up_process',

  // order:view
  ORDERS_ALLOW: 'order:allow',
  ORDER_DETAILS_ALLOW: 'order_details:allow',
  ORDERS_LINK_SHOW: 'order:link:show',
  ORDERS_LINK_HIDE: 'order:link:hide',
  ORDER_FULFILLMENT_LINK_SHOW: 'order:fulfillment_link:show',
  ORDER_FULFILLMENT_LINK_HIDE: 'order:fulfillment_link:hide',
  ORDER_CRYOPRESERVED_PRODUCT_SHIPMENT_DATE: 'order:cryopreserved_product_shipment_date:view',

  // order:create
  CART_ALLOW: 'cart:allow',
  ADD_TO_CART_BUTTON_SHOW: 'cart:add_button:show',
  CART_BUTTON_SHOW: 'cart:link_button:show',

  // recipient:view
  PATIENT_ALLOW: 'patient:allow',
  PATIENT_LOOKUP_ALLOW: 'patient_lookup:allow',
  PATIENT_LOOKUP_LINK_SHOW: 'patient:lookup_link:show',
  PATIENT_ADVANCED_LOOKUP_ALLOW: 'patient_advanced_lookup:allow',
  PATIENT_ADVANCED_LOOKUP_LINK_SHOW: 'patient:advanced_lookup_link:show',
  PATIENT_DETAILS_LINK_SHOW: 'patient:details_link:show',
  PATIENT_DETAILS_LINK_HIDE: 'patient:details_link:hide',
  PATIENT_SUB_REF_FIELD_SHOW: 'subref_field:view',
  PATIENT_LAB_REF_CREATE: 'labref:create',
  PATIENT_LAB_REF_VIEW: 'labref:view',

  // recipient:create
  PATIENT_CREATE_ALLOW: 'patient_create:allow',
  PATIENT_CREATE_BUTTON_SHOW: 'core:create_button:show',

  // recipient:edit
  PATIENT_EDIT_ALLOW: 'patient_edit:allow',
  ADD_PHENOTYPE_BUTTON_SHOW: 'patient:add_phenotype_button:show',
  PATIENT_EDIT_BUTTON_SHOW: 'patient:edit-button:show',

  PATIENT_PROCESS_CREATE: 'recipient:patient_process:create',
  PATIENT_PROCESS_EDIT: 'recipient:patient_process:edit',
  PATIENT_PROCESS_VIEW: 'recipient:patient_process:view',
  PATIENT_PROCESSES_SUBMIT_BUTTON: 'recipient:create:submit_button',

  // user:request:recipient:close
  PATIENT_CLOSE_CASE: 'user:request:recipient:close',

  // ssa-request:view
  SSA_REQUEST_CREATE: 'ssa:request:create',
  SSA_REQUEST_VIEW: 'ssa:request:view',
  SSA_REQUEST_REMOVE: 'ssa:request:remove',

  // search:view
  SEARCH_ALLOW: 'search_patient:allow',
  SEARCH_RESULTS_LINK_SHOW: 'search:results_link:show',
  SUMMARY_COUNTS: 'search:summary:show',
  SUMMARY_COUNTS_LINK_SHOW: 'search:summary_link:show',

  // graphmatching:view
  DOTS_SEARCH_RESULTS: 'graphmatching:view',

  // search:create
  PATIENT_IN_PROGRESS_ALLOW: 'patient_in_progress:allow',
  SEARCH_STATUS_ALLOW: 'search_status:allow',
  PATIENT_REACTIVATE_BUTTON_SHOW: 'patient:reactivate_button:show',
  SEARCH_STATUS_BUTTON_SHOW: 'core:search_status_button:show',
  SEARCH_RUN_BUTTON_SHOW: 'search:run_button:show',

  // secondary_code:view
  SECONDARY_CODE_ALLOW: 'secondary_code:allow',
  SECONDARY_CODE_LINK_SHOW: 'secondary_code:link:show',
  SECONDARY_CODE_PAGES_VIEW: 'secondary_code:pages:view',

  // secondary_code:create
  SECONDARY_CODE_ADD_ALLOW: 'secondary_code_add:allow',
  SECONDARY_CODE_ADD_BUTTON_SHOW: 'secondary_code:add_button:show',

  // secondary_code:edit
  SECONDARY_CODE_EDIT_ALLOW: 'secondary_code_edit:allow',
  SECONDARY_CODE_EDIT_BUTTON_SHOW: 'secondary_code:edit_button:show',

  // notification:view
  WORKFLOW_ALLOW: 'workflow:allow',
  WORKFLOW_LINK_SHOW: 'workflow:show',
  WORKFLOW_LINK_HIDE: 'workflow:hide',

  // workup:in_progress:view
  WORKUP_IN_PROGRESS_ALLOW: 'cart_in_progress:allow',
  WORKUP_IN_PROGRESS_TAB_SHOW: 'cart:in_progress_tab:show',

  // opl
  OPL_VIEW: 'opl:view',
  OPL_CREATE: 'opl:create',
  OPL_EDIT: 'opl:edit',
  OPL_REMOVE: 'opl:remove',
  OPL_SSA_CREATE: 'opl_ssa:create',
  OPL_SSA_EDIT: 'opl_ssa:edit',
  OPL_SSA_REMOVE: 'opl_ssa:remove',
  BP_VIEW_CONTRACTED: 'bp:contracted:view',
  BP_VIEW_NON_CONTRACTED: 'bp:noncontracted:view',

  // hla-history
  HLA_HISTORY_TAB: 'hla:history:view',
  // hla-today
  HLA_REPORT_VIEW: 'hla:summary_report:view',
  HLA_REPORT_COUNTS: 'hla:summary_report:counts',
  HLA_REPORT_REQUEST: 'hla:summary_report:request',
  HLA_REPORT_EDIT: 'hla:summary_report:edit',

  CASE_INFO_VIEW: 'case:info:view',

  SETTINGS_EDIT: 'settings:edit',

  SEARCH_EXPORT_SETTINGS_VIEW: 'settings:search_export:view',
  SEARCH_EXPORT_SETTINGS_EDIT: 'settings:search_export:edit',

  NOTIFICATION_SETTINGS_VIEW: 'settings:notification_suppress:view',
  NOTIFICATION_SETTINGS_EDIT: 'settings:notification_suppress:edit',

  FEATURE_TOGGLE_SETTINGS_EDIT: 'feature_toggles:edit:view',

  SSA_OPL_REQUEST_SETTINGS_EDIT: 'settings:ssa_opl_request:edit',

  ANNOUNCEMENT_VIEW: 'announcement:view',
  ANNOUNCEMENT_EDIT: 'announcement:edit',
  ANNOUNCEMENT_CREATE: 'announcement:create',
  ANNOUNCEMENT_DELETE: 'announcement:delete',

  NOTIFICATION_VIEW: 'notification_tile:view',

  ORDER_IMPORTANT_DATES_VIEW: 'order:important_dates:view',

  RECIPIENT_TRANSFER: 'recipient:transfer',
  AUTO_SAVE: 'autosave:create:edit:view:delete',

  DUPLICATES_VIEW: 'duplicates:view',
  REQUEST_WMDA_DONOR: 'user:request:order_wmda:create',
  WORKUP_INTERNATIONAL_FORMS_CREATE: 'workup:international_form_doc:create',

  ORDER_INTERNATIONAL_FORMS_VIEW: 'order:international_form_doc:view',

  IDENTITY_DISABLED: 'recipient:ct_policy:view_without_edit',

  RECIPIENT_CT_POLICY_EDIT: 'recipient:ct_policy:edit',

  IDENTITY_HISTORY_VIEW: 'recipient:ct_policy:history:view',

  SOURCE_ENHANCEMENT_PROGRAMS_VIEW: 'programs:view',
  OMIDUBICEL_SEARCH: 'omidubicel:search:create',
  LOOOKER_REPORTS_VIEW: 'looker_reports:view',

  ACCESS_TO_PROTECTED_AREA: 'access_to_protected_area',

  ANTIBODY_PROFILE_VIEW: 'recipient:antibody_profile:view',
  ANTIBODY_PROFILE_EDIT: 'recipient:antibody_profile:edit',

  EDIT_VACCINATION_DETAILS: 'supplements:vaccinations:edit',

  SAMPLE_INSTRUCTION_VIEW: 'sip:view',
};
