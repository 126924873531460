/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SettingsControllerService } from './services/settings-controller.service';
import { SampleInstructionControllerService } from './services/sample-instruction-controller.service';
import { HistoryControllerService } from './services/history-controller.service';
import { NoteControllerService } from './services/note-controller.service';
import { CaseResourceControllerService } from './services/case-resource-controller.service';
import { AutoSaveControllerService } from './services/auto-save-controller.service';
import { AnnouncementControllerService } from './services/announcement-controller.service';
import { LoggerControllerService } from './services/logger-controller.service';
import { IncidentControllerService } from './services/incident-controller.service';
import { ExportControllerService } from './services/export-controller.service';
import { DisclaimerControllerService } from './services/disclaimer-controller.service';
import { BusinessPartyControllerService } from './services/business-party-controller.service';
import { LoginControllerService } from './services/login-controller.service';
import { AddressValidationControllerService } from './services/address-validation-controller.service';
import { SecurityControllerService } from './services/security-controller.service';
import { LookerControllerService } from './services/looker-controller.service';
import { GeographyControllerService } from './services/geography-controller.service';
import { ProgramControllerService } from './services/program-controller.service';
import { ApplicationParameterControllerService } from './services/application-parameter-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SettingsControllerService,
    SampleInstructionControllerService,
    HistoryControllerService,
    NoteControllerService,
    CaseResourceControllerService,
    AutoSaveControllerService,
    AnnouncementControllerService,
    LoggerControllerService,
    IncidentControllerService,
    ExportControllerService,
    DisclaimerControllerService,
    BusinessPartyControllerService,
    LoginControllerService,
    AddressValidationControllerService,
    SecurityControllerService,
    LookerControllerService,
    GeographyControllerService,
    ProgramControllerService,
    ApplicationParameterControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
