/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWmdaDonorsForPatient } from '../fn/wmda-donor-request-controller/get-wmda-donors-for-patient';
import { GetWmdaDonorsForPatient$Params } from '../fn/wmda-donor-request-controller/get-wmda-donors-for-patient';
import { sendWmdaRequest } from '../fn/wmda-donor-request-controller/send-wmda-request';
import { SendWmdaRequest$Params } from '../fn/wmda-donor-request-controller/send-wmda-request';

@Injectable({ providedIn: 'root' })
export class WmdaDonorRequestControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWmdaDonorsForPatient()` */
  static readonly GetWmdaDonorsForPatientPath = '/wmda-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWmdaDonorsForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWmdaDonorsForPatient$Response(params: GetWmdaDonorsForPatient$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getWmdaDonorsForPatient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWmdaDonorsForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWmdaDonorsForPatient(params: GetWmdaDonorsForPatient$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getWmdaDonorsForPatient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `sendWmdaRequest()` */
  static readonly SendWmdaRequestPath = '/wmda-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendWmdaRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWmdaRequest$Response(params: SendWmdaRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendWmdaRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendWmdaRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWmdaRequest(params: SendWmdaRequest$Params, context?: HttpContext): Observable<void> {
    return this.sendWmdaRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
