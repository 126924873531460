import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  InternationalFormDocumentControllerService,
  InternationalFormDocumentNewWorkupControllerService,
} from '@matchsource/api-generated/subject';
import {DocumentModel} from '@matchsource/models/documents';
import {ApiConfig, ApiConfigService} from '@matchsource/api-config';
import {HttpClient} from '@angular/common/http';
import {internationalFormsSerializer} from './international-forms.serializer';

@Injectable({
  providedIn: 'root',
})
export class InternationalApiService {
  constructor(
    @Inject(ApiConfigService) private readonly config: ApiConfig,
    private readonly http: HttpClient,
    private readonly internationalFormDocumentControllerService: InternationalFormDocumentControllerService,
    private readonly newInternationalFormDocumentControllerService: InternationalFormDocumentNewWorkupControllerService
  ) {}

  getAllInternationalFiles(workupId: number, isLegacyOrder = false): Observable<DocumentModel[]> {
    if (isLegacyOrder) {
      return this.internationalFormDocumentControllerService
        .getIntFormDetails({workupId})
        .pipe(map(data => data.map(input => internationalFormsSerializer.fromDTO(input))));
    }

    return this.newInternationalFormDocumentControllerService
      .getInternationalFormDetails({workupId})
      .pipe(map(data => data.map(input => internationalFormsSerializer.fromDTO(input))));
  }

  add(models: DocumentModel[], workupId: number, isLegacyOrder = false): Observable<unknown> {
    const files = models.filter(model => !!model.data).map(model => model.data);
    const formData = new FormData();
    files.forEach(attachment => formData.append('files', attachment));

    if (isLegacyOrder) {
      return this.http.post(`${this.config.subjectServiceUrl}int-forms-documents?workupId=${workupId}`, formData);
    }

    return this.http.post(
      `${this.config.subjectServiceUrl}int-forms-documents/enhanced?workupId=${workupId}`,
      formData
    );
    // There is no body in requests sent by internationalFormDocumentControllerService.
    // Impossible to send files.
    // return this.internationalFormDocumentControllerService.addDocument({workupId, files});
  }

  reassign(workupInProgressId: number, orderGuid: string, isLegacyOrder = false): Observable<void> {
    const data = {workupInProgressId, orderGuid};

    if (isLegacyOrder) {
      return this.internationalFormDocumentControllerService.reassignWorkupDocuments(data);
    }

    return this.newInternationalFormDocumentControllerService.reassignDocuments(data);
  }

  delete(documentsIds: number[], isLegacyOrder = false): Observable<void> {
    if (isLegacyOrder) {
      return this.internationalFormDocumentControllerService.deleteDocuments({documentsIds});
    }

    return this.newInternationalFormDocumentControllerService.removeDocuments({documentsIds});
  }
}
