import {Injectable} from '@angular/core';
import {combineLatest, firstValueFrom, from, iif, Observable, of} from 'rxjs';
import {
  PatientDemographicsModel,
  PatientModel,
  PatientStatus,
  TransplantTimelineHistoryModel,
} from '@matchsource/models/patient';
import {PatientCoreModel} from '@matchsource/models/patient-extended';
import {PatientState} from './patient.state';
import {Store} from '@ngxs/store';
import {BusinessPartiesService, GetBusinessParties} from '@matchsource/store/business-parties';
import {CountriesService} from '@matchsource/store/countries';
import {UserService} from '@matchsource/core';
import {SearchStatesService} from '@matchsource/store/search-states';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';
import {ClearPatient, GetPatient} from './patient.actions';
import {GetRaces} from '@matchsource/store/races';
import {GetEthnicity} from '@matchsource/store/ethnicity';
import {GetPatientStatusList} from '@matchsource/store/patient-status';
import {distinctUntilChanged, filter, map, skipUntil, switchMap, tap} from 'rxjs/operators';
import {translate} from '@jsverse/transloco';
import {PatientTransplantTimelineHistoryState} from './transplant-timeline-history.state';
import {GetPatientTransplantTimelineHistory} from './transplant-timeline-history.actions';

@Injectable({providedIn: 'root'})
export class PatientService {
  patient$: Observable<PatientModel>;

  patientLegacy$: Observable<PatientCoreModel>;

  demographics$: Observable<PatientDemographicsModel>;

  isCpBp$: Observable<boolean>;

  isDomesticTc$: Observable<boolean>;

  isRestricted$: Observable<boolean>;

  isRestrictedByUserRole$: Observable<boolean>;

  isEligibleForEps$: Observable<boolean>;

  isEligibleForGamida$: Observable<boolean>;

  duplicateTooltipText$: Observable<string | null>;

  transplantTimelineHistory$: Observable<TransplantTimelineHistoryModel[]>;

  loaded$: Observable<boolean>;

  private readonly typeCp = 'CP';

  get patient(): PatientModel {
    return this.store.selectSnapshot(PatientState.patient);
  }

  get patientLegacy(): PatientCoreModel {
    return this.store.selectSnapshot(PatientState.patientLegacy);
  }

  get id(): MsApp.Guid | null {
    return this.patient?.id ?? null;
  }

  get phenotypeIds(): number[] {
    return this.patient?.phenotypeIds ?? [];
  }

  get loaded(): boolean {
    return this.store.selectSnapshot(PatientState.loaded);
  }

  constructor(
    private readonly store: Store,
    private readonly bp: BusinessPartiesService,
    private readonly countriesService: CountriesService,
    private readonly userService: UserService,
    private readonly searchStatesService: SearchStatesService
  ) {
    this.init();
  }

  load(
    patientId: MsApp.Guid,
    index: number = ACTUAL_PHENOTYPE_INDEX,
    suppressErrorStatuses: number[] = null
  ): Observable<any> {
    return this.store
      .dispatch([
        new GetPatient(patientId, index, undefined, suppressErrorStatuses),
        new GetRaces(),
        new GetEthnicity(),
        new GetPatientStatusList(),
        new GetBusinessParties(),
      ])
      .pipe(switchMap(() => from(this.searchStatesService.load(patientId, this.phenotypeIds))));
  }

  loadAsync(
    patientId: MsApp.Guid,
    index: number = ACTUAL_PHENOTYPE_INDEX,
    suppressErrorStatuses: number[] = null
  ): Promise<PatientService> {
    return firstValueFrom(this.load(patientId, index, suppressErrorStatuses)).then(() => this);
  }

  clear(patientId?: MsApp.Guid): void {
    this.store.dispatch(new ClearPatient(patientId));
  }

  private init() {
    this.patient$ = this.store.select<boolean>(PatientState.loaded).pipe(
      distinctUntilChanged(),
      filter(loaded => loaded),
      switchMap(() => this.store.select<PatientModel>(PatientState.patient))
    );

    this.loaded$ = this.store.select(PatientState.loaded);

    this.patientLegacy$ = this.store.select<boolean>(PatientState.loaded).pipe(
      distinctUntilChanged(),
      filter(loaded => loaded),
      switchMap(() => this.store.select<PatientCoreModel>(PatientState.patientLegacy))
    );

    this.demographics$ = this.store.select<boolean>(PatientState.loaded).pipe(
      distinctUntilChanged(),
      filter(loaded => loaded),
      switchMap(() => this.store.select<PatientDemographicsModel>(PatientState.demographics))
    );

    this.isCpBp$ = this.patient$.pipe(
      filter(patient => !!patient),
      switchMap(patient => this.bp.getTcById(patient.tcId)),
      map(tc => tc?.type === this.typeCp)
    );

    this.isDomesticTc$ = this.patient$.pipe(
      filter(patient => !!patient),
      switchMap(patient => this.bp.getTcById(patient.tcId)),
      map(tc => tc.isDomestic && tc.type === 'TC')
    );

    this.isRestricted$ = this.patient$.pipe(
      switchMap(patient =>
        combineLatest([
          this.bp.isPatientRestricted(patient.tcId),
          this.countriesService.isPatientRestricted(patient.countryCode),
        ])
      ),
      map(([isRestrictedBp, isRestrictedCountry]) => isRestrictedBp || isRestrictedCountry)
    );

    this.isRestrictedByUserRole$ = iif(() => this.userService.isTCC(), this.isRestricted$, of(false));

    this.isEligibleForEps$ = this.patient$.pipe(
      switchMap(({status, tcId}) =>
        iif(
          () => status !== PatientStatus.PreliminarySearch && status !== PatientStatus.FormalSearch,
          of(false),
          this.bp.isEligibleForEps(tcId)
        )
      )
    );

    this.isEligibleForGamida$ = this.patient$.pipe(switchMap(({tcId}) => this.bp.isEligibleForGamida(tcId)));

    this.duplicateTooltipText$ = this.patientLegacy$.pipe(
      map(patient => {
        if (patient.isConfirmedNotDuplicate) {
          return null;
        }

        if (patient.isConfirmedDuplicate) {
          return patient.isFrmlClosedDuplicateDetected
            ? translate('PATIENT.DUPLICATE_TOOLTIPS.FRML_CLOSED.CONFIRMED')
            : translate('PATIENT.DUPLICATE_TOOLTIPS.HLA_TODAY.CONFIRMED');
        }

        if (patient.isFrmlClosedDuplicateDetected) {
          return translate('PATIENT.DUPLICATE_TOOLTIPS.FRML_CLOSED.POTENTIAL');
        }

        if (patient.isHlaTodayDuplicateDetected) {
          return translate('PATIENT.DUPLICATE_TOOLTIPS.HLA_TODAY.POTENTIAL');
        }

        return null;
      })
    );

    this.transplantTimelineHistory$ = this.store
      .select<TransplantTimelineHistoryModel[]>(PatientTransplantTimelineHistoryState.list)
      .pipe(
        tap(() => {
          if (!this.store.selectSnapshot<boolean>(PatientTransplantTimelineHistoryState.inProgressOrCompleted)) {
            this.store.dispatch(new GetPatientTransplantTimelineHistory(this.id));
          }
        }),
        skipUntil(this.store.select(PatientTransplantTimelineHistoryState.loaded).pipe(filter(loaded => loaded)))
      );
  }
}
