import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {map} from 'rxjs/operators';
import {mapToNomenclature} from '@matchsource/api-utils';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import flow from 'lodash-es/flow';
import {Nomenclature} from '@matchsource/models/nomenclature';
import {useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class EthnicityApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getEthnicity(silent = false): Observable<Nomenclature[]> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingReferenceData);
    const context = flow(useSpinner(silent), customErrorHandlingContext);
    return this.nomenclaturesControllerService.listAllEthnicities({}, context()).pipe(
      map(items => {
        return items.map(item => mapToNomenclature(item));
      })
    );
  }
}
