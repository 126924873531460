/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { exportCaseProgress$Pdf } from '../fn/export-controller/export-case-progress-pdf';
import { ExportCaseProgress$Pdf$Params } from '../fn/export-controller/export-case-progress-pdf';
import { exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet } from '../fn/export-controller/export-case-progress-vnd-openxmlformats-officedocument-spreadsheetml-sheet';
import { ExportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params } from '../fn/export-controller/export-case-progress-vnd-openxmlformats-officedocument-spreadsheetml-sheet';
import { exportCordShipment } from '../fn/export-controller/export-cord-shipment';
import { ExportCordShipment$Params } from '../fn/export-controller/export-cord-shipment';
import { exportFormDue } from '../fn/export-controller/export-form-due';
import { ExportFormDue$Params } from '../fn/export-controller/export-form-due';
import { exportOpl$Csv } from '../fn/export-controller/export-opl-csv';
import { ExportOpl$Csv$Params } from '../fn/export-controller/export-opl-csv';
import { exportOpl$Pdf } from '../fn/export-controller/export-opl-pdf';
import { ExportOpl$Pdf$Params } from '../fn/export-controller/export-opl-pdf';
import { exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet } from '../fn/export-controller/export-opl-vnd-openxmlformats-officedocument-spreadsheetml-sheet';
import { ExportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params } from '../fn/export-controller/export-opl-vnd-openxmlformats-officedocument-spreadsheetml-sheet';
import { exportPatientDetails } from '../fn/export-controller/export-patient-details';
import { ExportPatientDetails$Params } from '../fn/export-controller/export-patient-details';
import { exportPatients } from '../fn/export-controller/export-patients';
import { ExportPatients$Params } from '../fn/export-controller/export-patients';
import { exportSearchSummaryReport } from '../fn/export-controller/export-search-summary-report';
import { ExportSearchSummaryReport$Params } from '../fn/export-controller/export-search-summary-report';
import { exportSearchSummaryReportWithLabs } from '../fn/export-controller/export-search-summary-report-with-labs';
import { ExportSearchSummaryReportWithLabs$Params } from '../fn/export-controller/export-search-summary-report-with-labs';
import { exportSource } from '../fn/export-controller/export-source';
import { ExportSource$Params } from '../fn/export-controller/export-source';
import { exportSources$Csv } from '../fn/export-controller/export-sources-csv';
import { ExportSources$Csv$Params } from '../fn/export-controller/export-sources-csv';
import { exportSources$Pdf } from '../fn/export-controller/export-sources-pdf';
import { ExportSources$Pdf$Params } from '../fn/export-controller/export-sources-pdf';
import { exportSummaryCounts } from '../fn/export-controller/export-summary-counts';
import { ExportSummaryCounts$Params } from '../fn/export-controller/export-summary-counts';
import { exportWorkup } from '../fn/export-controller/export-workup';
import { ExportWorkup$Params } from '../fn/export-controller/export-workup';

@Injectable({ providedIn: 'root' })
export class ExportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportSources()` */
  static readonly ExportSourcesPath = '/exports/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSources$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Pdf$Response(params: ExportSources$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSources$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSources$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Pdf(params: ExportSources$Pdf$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSources$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSources$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Csv$Response(params: ExportSources$Csv$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return exportSources$Csv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSources$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSources$Csv(params: ExportSources$Csv$Params, context?: HttpContext): Observable<string> {
    return this.exportSources$Csv$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `exportSource()` */
  static readonly ExportSourcePath = '/exports/source';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSource$Response(params: ExportSource$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSource(params: ExportSource$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSource$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportSearchSummaryReport()` */
  static readonly ExportSearchSummaryReportPath = '/exports/search-summary-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSearchSummaryReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSearchSummaryReport$Response(params: ExportSearchSummaryReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSearchSummaryReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSearchSummaryReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSearchSummaryReport(params: ExportSearchSummaryReport$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSearchSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportSearchSummaryReportWithLabs()` */
  static readonly ExportSearchSummaryReportWithLabsPath = '/exports/search-summary-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSearchSummaryReportWithLabs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSearchSummaryReportWithLabs$Response(params: ExportSearchSummaryReportWithLabs$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSearchSummaryReportWithLabs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSearchSummaryReportWithLabs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportSearchSummaryReportWithLabs(params: ExportSearchSummaryReportWithLabs$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSearchSummaryReportWithLabs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportPatients()` */
  static readonly ExportPatientsPath = '/exports/patients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPatients()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportPatients$Response(params: ExportPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return exportPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportPatients$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportPatients(params: ExportPatients$Params, context?: HttpContext): Observable<string> {
    return this.exportPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `exportOpl()` */
  static readonly ExportOplPath = '/exports/opl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Pdf$Response(params: ExportOpl$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportOpl$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportOpl$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Pdf(params: ExportOpl$Pdf$Params, context?: HttpContext): Observable<Blob> {
    return this.exportOpl$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Csv$Response(params: ExportOpl$Csv$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return exportOpl$Csv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportOpl$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$Csv(params: ExportOpl$Csv$Params, context?: HttpContext): Observable<string> {
    return this.exportOpl$Csv$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: ExportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: ExportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params, context?: HttpContext): Observable<Blob> {
    return this.exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportFormDue()` */
  static readonly ExportFormDuePath = '/exports/form-due';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportFormDue$Response(params: ExportFormDue$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportFormDue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportFormDue(params: ExportFormDue$Params, context?: HttpContext): Observable<Blob> {
    return this.exportFormDue$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportCaseProgress()` */
  static readonly ExportCaseProgressPath = '/exports/case-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCaseProgress$Pdf()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$Pdf$Response(params: ExportCaseProgress$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportCaseProgress$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCaseProgress$Pdf$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$Pdf(params: ExportCaseProgress$Pdf$Params, context?: HttpContext): Observable<Blob> {
    return this.exportCaseProgress$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params: ExportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(params: ExportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params, context?: HttpContext): Observable<Blob> {
    return this.exportCaseProgress$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportWorkup()` */
  static readonly ExportWorkupPath = '/exports/workup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportWorkup()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportWorkup$Response(params: ExportWorkup$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportWorkup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportWorkup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportWorkup(params: ExportWorkup$Params, context?: HttpContext): Observable<Blob> {
    return this.exportWorkup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportSummaryCounts()` */
  static readonly ExportSummaryCountsPath = '/exports/summary-counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSummaryCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSummaryCounts$Response(params: ExportSummaryCounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportSummaryCounts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSummaryCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSummaryCounts(params: ExportSummaryCounts$Params, context?: HttpContext): Observable<Blob> {
    return this.exportSummaryCounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportPatientDetails()` */
  static readonly ExportPatientDetailsPath = '/exports/patient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportPatientDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPatientDetails$Response(params: ExportPatientDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportPatientDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportPatientDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportPatientDetails(params: ExportPatientDetails$Params, context?: HttpContext): Observable<Blob> {
    return this.exportPatientDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportCordShipment()` */
  static readonly ExportCordShipmentPath = '/exports/cord-shipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCordShipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCordShipment$Response(params: ExportCordShipment$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportCordShipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCordShipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCordShipment(params: ExportCordShipment$Params, context?: HttpContext): Observable<Blob> {
    return this.exportCordShipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
