/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acknowledgeDisclaimer } from '../fn/disclaimer-controller/acknowledge-disclaimer';
import { AcknowledgeDisclaimer$Params } from '../fn/disclaimer-controller/acknowledge-disclaimer';
import { Disclaimer } from '../models/disclaimer';
import { getDisclaimer } from '../fn/disclaimer-controller/get-disclaimer';
import { GetDisclaimer$Params } from '../fn/disclaimer-controller/get-disclaimer';

@Injectable({ providedIn: 'root' })
export class DisclaimerControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `acknowledgeDisclaimer()` */
  static readonly AcknowledgeDisclaimerPath = '/disclaimers/{type}/acknowledge';

  /**
   * This method acknowledges a disclaimer with a given type for logged user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acknowledgeDisclaimer()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgeDisclaimer$Response(params: AcknowledgeDisclaimer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acknowledgeDisclaimer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method acknowledges a disclaimer with a given type for logged user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acknowledgeDisclaimer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgeDisclaimer(params: AcknowledgeDisclaimer$Params, context?: HttpContext): Observable<void> {
    return this.acknowledgeDisclaimer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDisclaimer()` */
  static readonly GetDisclaimerPath = '/disclaimers/{type}';

  /**
   * This method retrieves a disclaimer by type for logged user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDisclaimer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisclaimer$Response(params: GetDisclaimer$Params, context?: HttpContext): Observable<StrictHttpResponse<Disclaimer>> {
    return getDisclaimer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a disclaimer by type for logged user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDisclaimer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisclaimer(params: GetDisclaimer$Params, context?: HttpContext): Observable<Disclaimer> {
    return this.getDisclaimer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Disclaimer>): Disclaimer => r.body)
    );
  }

}
