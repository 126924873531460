import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CoordinatorModel} from '@matchsource/models/coordinator';
import {CaseResourceControllerService} from '@matchsource/api-generated/common';
import {coordinatorSerializer} from './coordinator.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class CoordinatorsApiService {
  constructor(private readonly caseResourceControllerService: CaseResourceControllerService) {}

  getTcCoordinators(tcId: MsApp.Guid): Observable<CoordinatorModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingTcCoordinatorData);

    return this.caseResourceControllerService
      .getCaseResourceByRoleAndBp({roleCode: 'TCC', bpGuid: tcId}, context())
      .pipe(map(coordinators => coordinators.map(coordinator => coordinatorSerializer.fromDTO(coordinator))));
  }
}
