import {BusinessPartyModel} from '@matchsource/models/business-party';
import {PatientModel} from '@matchsource/models/patient';
import {ButtonTypes} from '@matchsource/nmdp-ui';
import {SearchCriteria} from '@matchsource/models/search';
import {SourceTypes, UppercaseSourceType} from '@matchsource/models/source';
import {PostponementStatuses} from '@matchsource/models/order';
import {Nomenclature} from '@matchsource/models/nomenclature';

export interface ColumnConfig {
  key: string;
  order: number;
  visible: boolean;
}

export const POSTPONEMENT_READABLE_STATUSES = {
  [PostponementStatuses.InProgress]: 'COMMON.STATUSES.IN_PROGRESS',
  [PostponementStatuses.Submit]: 'COMMON.STATUSES.SUBMITTED',
  [PostponementStatuses.Sysgen]: 'COMMON.STATUSES.SYSTEM_GENERATED',
  [PostponementStatuses.Deleted]: 'COMMON.STATUSES.DELETED',
};

export interface NomenclatureOptionsStateModel {
  loading: boolean;
  loaded: boolean;
  options: Nomenclature[];
}

export const SOURCE_TYPE_TO_UPPERCASE_SOURCE_TYPE_MAP: Record<SourceTypes, UppercaseSourceType> = {
  [SourceTypes.Donor]: UppercaseSourceType.Donor,
  [SourceTypes.Cord]: UppercaseSourceType.Cord,
  [SourceTypes.Biobank]: UppercaseSourceType.Biobank,
};

export interface DuplicatesModel {
  businessParty: BusinessPartyModel;
  patient: PatientModel;
  domesticSecondaryTc: boolean;
}

export interface Eligibility {
  eligibilityStatus: string;
  ineligibleReasons: string[];
}

export enum CordThresholdIssue {
  LowValues = 'LowValues',
  UnknownValues = 'UnknownValues',
}

export interface RunSearchParams {
  searchCriteria: SearchCriteria;
  abOnly?: boolean;
}

export interface ConfirmationModalConfig {
  caption?: string;
  cancelText?: string;
  confirmText?: string;
  confirmButtonType?: ButtonTypes;
  confirmMessage?: string;
}

export const CHOICE = {
  YES: 'Yes',
  NO: 'No',
};

export interface RaceTooltipModel {
  id: MsApp.Guid;
  sourceType: SourceTypes.Donor | SourceTypes.Cord;
}

export enum FormControlNames {
  TransplantTimeline = 'transplantTimelineCode',
}
