/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CoopPreliminarySearch } from '../models/coop-preliminary-search';
import { loadCoopPreliminarySearches } from '../fn/coop-search-controller/load-coop-preliminary-searches';
import { LoadCoopPreliminarySearches$Params } from '../fn/coop-search-controller/load-coop-preliminary-searches';
import { rerunCoopPreliminarySearches } from '../fn/coop-search-controller/rerun-coop-preliminary-searches';
import { RerunCoopPreliminarySearches$Params } from '../fn/coop-search-controller/rerun-coop-preliminary-searches';
import { saveCoopPreliminarySearch } from '../fn/coop-search-controller/save-coop-preliminary-search';
import { SaveCoopPreliminarySearch$Params } from '../fn/coop-search-controller/save-coop-preliminary-search';
import { updateCordSearchResultsReceivedDate } from '../fn/coop-search-controller/update-cord-search-results-received-date';
import { UpdateCordSearchResultsReceivedDate$Params } from '../fn/coop-search-controller/update-cord-search-results-received-date';
import { updateDonorSearchResultsReceivedDate } from '../fn/coop-search-controller/update-donor-search-results-received-date';
import { UpdateDonorSearchResultsReceivedDate$Params } from '../fn/coop-search-controller/update-donor-search-results-received-date';

@Injectable({ providedIn: 'root' })
export class CoopSearchControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateDonorSearchResultsReceivedDate()` */
  static readonly UpdateDonorSearchResultsReceivedDatePath = '/recipients/{recipientGuid}/coop-preliminary-searches/{id}/donor-results-received-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDonorSearchResultsReceivedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorSearchResultsReceivedDate$Response(params: UpdateDonorSearchResultsReceivedDate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDonorSearchResultsReceivedDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDonorSearchResultsReceivedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorSearchResultsReceivedDate(params: UpdateDonorSearchResultsReceivedDate$Params, context?: HttpContext): Observable<void> {
    return this.updateDonorSearchResultsReceivedDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCordSearchResultsReceivedDate()` */
  static readonly UpdateCordSearchResultsReceivedDatePath = '/recipients/{recipientGuid}/coop-preliminary-searches/{id}/cord-results-received-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCordSearchResultsReceivedDate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCordSearchResultsReceivedDate$Response(params: UpdateCordSearchResultsReceivedDate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateCordSearchResultsReceivedDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCordSearchResultsReceivedDate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCordSearchResultsReceivedDate(params: UpdateCordSearchResultsReceivedDate$Params, context?: HttpContext): Observable<void> {
    return this.updateCordSearchResultsReceivedDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rerunCoopPreliminarySearches()` */
  static readonly RerunCoopPreliminarySearchesPath = '/recipients/{recipientGuid}/coop-preliminary-searches/rerun';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rerunCoopPreliminarySearches()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunCoopPreliminarySearches$Response(params: RerunCoopPreliminarySearches$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rerunCoopPreliminarySearches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rerunCoopPreliminarySearches$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunCoopPreliminarySearches(params: RerunCoopPreliminarySearches$Params, context?: HttpContext): Observable<void> {
    return this.rerunCoopPreliminarySearches$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadCoopPreliminarySearches()` */
  static readonly LoadCoopPreliminarySearchesPath = '/recipients/{recipientGuid}/coop-preliminary-searches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCoopPreliminarySearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopPreliminarySearches$Response(params: LoadCoopPreliminarySearches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoopPreliminarySearch>>> {
    return loadCoopPreliminarySearches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadCoopPreliminarySearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopPreliminarySearches(params: LoadCoopPreliminarySearches$Params, context?: HttpContext): Observable<Array<CoopPreliminarySearch>> {
    return this.loadCoopPreliminarySearches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoopPreliminarySearch>>): Array<CoopPreliminarySearch> => r.body)
    );
  }

  /** Path part for operation `saveCoopPreliminarySearch()` */
  static readonly SaveCoopPreliminarySearchPath = '/recipients/{recipientGuid}/coop-preliminary-searches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCoopPreliminarySearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCoopPreliminarySearch$Response(params: SaveCoopPreliminarySearch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveCoopPreliminarySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveCoopPreliminarySearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCoopPreliminarySearch(params: SaveCoopPreliminarySearch$Params, context?: HttpContext): Observable<void> {
    return this.saveCoopPreliminarySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
