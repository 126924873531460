import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {CountrySerializerService} from './country-serializer.service';
import {Observable} from 'rxjs';
import {CountryStateSerializerService} from './country-state-serializer.service';
import {GeographyControllerService} from '@matchsource/api-generated/common';
import {HttpContext} from '@angular/common/http';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {CountryModel, CountryStateModel} from '@matchsource/models/country';

@Injectable({
  providedIn: 'root',
})
export class GeographyApiService {
  constructor(
    private readonly geographyControllerService: GeographyControllerService,
    private readonly countrySerializer: CountrySerializerService,
    private readonly countryStateSerializer: CountryStateSerializerService
  ) {}

  private static getBasicCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingGeography);
  }

  getCountries(): Observable<CountryModel[]> {
    const context = GeographyApiService.getBasicCustomErrorHandling();

    return this.geographyControllerService
      .getCountries(null, context())
      .pipe(map(countries => countries.map(country => this.countrySerializer.fromDTO(country))));
  }

  getStates(countryCode: string): Observable<CountryStateModel[]> {
    const context = GeographyApiService.getBasicCustomErrorHandling();

    return this.geographyControllerService
      .getStates({countryCode}, context())
      .pipe(map(states => states.map(state => this.countryStateSerializer.fromDTO(state))));
  }
}
