/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addPostponeCancelRequest } from '../fn/order-controller/add-postpone-cancel-request';
import { AddPostponeCancelRequest$Params } from '../fn/order-controller/add-postpone-cancel-request';
import { AtOrder } from '../models/at-order';
import { BdpOrOrder } from '../models/bdp-or-order';
import { CctOrder } from '../models/cct-order';
import { CreateOrderResult } from '../models/create-order-result';
import { CtOrder } from '../models/ct-order';
import { deletePostponeCancelRequest } from '../fn/order-controller/delete-postpone-cancel-request';
import { DeletePostponeCancelRequest$Params } from '../fn/order-controller/delete-postpone-cancel-request';
import { DrOrder } from '../models/dr-order';
import { EWorkupOrder } from '../models/e-workup-order';
import { findCtCompletedDonors } from '../fn/order-controller/find-ct-completed-donors';
import { FindCtCompletedDonors$Params } from '../fn/order-controller/find-ct-completed-donors';
import { findInProgressRequests } from '../fn/order-controller/find-in-progress-requests';
import { FindInProgressRequests$Params } from '../fn/order-controller/find-in-progress-requests';
import { getDocumentRecipientGuid } from '../fn/order-controller/get-document-recipient-guid';
import { GetDocumentRecipientGuid$Params } from '../fn/order-controller/get-document-recipient-guid';
import { getInventoryOrderDetails } from '../fn/order-controller/get-inventory-order-details';
import { GetInventoryOrderDetails$Params } from '../fn/order-controller/get-inventory-order-details';
import { getInventorySerialNumbers } from '../fn/order-controller/get-inventory-serial-numbers';
import { GetInventorySerialNumbers$Params } from '../fn/order-controller/get-inventory-serial-numbers';
import { getOrderTrackingServiceCodes } from '../fn/order-controller/get-order-tracking-service-codes';
import { GetOrderTrackingServiceCodes$Params } from '../fn/order-controller/get-order-tracking-service-codes';
import { HeOrder } from '../models/he-order';
import { HrOrder } from '../models/hr-order';
import { HwOrder } from '../models/hw-order';
import { InventoryOrderDetails } from '../models/inventory-order-details';
import { InventorySerialNumberDetails } from '../models/inventory-serial-number-details';
import { loadAllOrders } from '../fn/order-controller/load-all-orders';
import { LoadAllOrders$Params } from '../fn/order-controller/load-all-orders';
import { loadAllOrders1 } from '../fn/order-controller/load-all-orders-1';
import { LoadAllOrders1$Params } from '../fn/order-controller/load-all-orders-1';
import { loadInventoryOrders } from '../fn/order-controller/load-inventory-orders';
import { LoadInventoryOrders$Params } from '../fn/order-controller/load-inventory-orders';
import { loadLatestOrder } from '../fn/order-controller/load-latest-order';
import { LoadLatestOrder$Params } from '../fn/order-controller/load-latest-order';
import { loadOrder } from '../fn/order-controller/load-order';
import { LoadOrder$Params } from '../fn/order-controller/load-order';
import { loadOrders } from '../fn/order-controller/load-orders';
import { LoadOrders$Params } from '../fn/order-controller/load-orders';
import { loadOrderTrackings } from '../fn/order-controller/load-order-trackings';
import { LoadOrderTrackings$Params } from '../fn/order-controller/load-order-trackings';
import { loadOrderTrackings1 } from '../fn/order-controller/load-order-trackings-1';
import { LoadOrderTrackings1$Params } from '../fn/order-controller/load-order-trackings-1';
import { loadPostponeCancelRequest } from '../fn/order-controller/load-postpone-cancel-request';
import { LoadPostponeCancelRequest$Params } from '../fn/order-controller/load-postpone-cancel-request';
import { loadPostponeCancelRequests } from '../fn/order-controller/load-postpone-cancel-requests';
import { LoadPostponeCancelRequests$Params } from '../fn/order-controller/load-postpone-cancel-requests';
import { loadRecent } from '../fn/order-controller/load-recent';
import { LoadRecent$Params } from '../fn/order-controller/load-recent';
import { markInventoryOrderAsUsed } from '../fn/order-controller/mark-inventory-order-as-used';
import { MarkInventoryOrderAsUsed$Params } from '../fn/order-controller/mark-inventory-order-as-used';
import { markInventoryOrderRecipientAsTransferred } from '../fn/order-controller/mark-inventory-order-recipient-as-transferred';
import { MarkInventoryOrderRecipientAsTransferred$Params } from '../fn/order-controller/mark-inventory-order-recipient-as-transferred';
import { OrderLineItemServiceCode } from '../models/order-line-item-service-code';
import { OrderRequested } from '../models/order-requested';
import { OrderTracking } from '../models/order-tracking';
import { OrOrder } from '../models/or-order';
import { PageInventoryOrderDetails } from '../models/page-inventory-order-details';
import { PbscOrder } from '../models/pbsc-order';
import { placeOrder } from '../fn/order-controller/place-order';
import { PlaceOrder$Params } from '../fn/order-controller/place-order';
import { PostponeCancelRequest } from '../models/postpone-cancel-request';
import { PostponementCancellationInfo } from '../models/postponement-cancellation-info';
import { requestKitOrder } from '../fn/order-controller/request-kit-order';
import { RequestKitOrder$Params } from '../fn/order-controller/request-kit-order';
import { updateOrder } from '../fn/order-controller/update-order';
import { UpdateOrder$Params } from '../fn/order-controller/update-order';
import { updateOrderTracking } from '../fn/order-controller/update-order-tracking';
import { UpdateOrderTracking$Params } from '../fn/order-controller/update-order-tracking';
import { updatePostponeCancelRequest } from '../fn/order-controller/update-postpone-cancel-request';
import { UpdatePostponeCancelRequest$Params } from '../fn/order-controller/update-postpone-cancel-request';
import { UwOrder } from '../models/uw-order';
import { wasCtRequestedForSourceAndBusinessParty } from '../fn/order-controller/was-ct-requested-for-source-and-business-party';
import { WasCtRequestedForSourceAndBusinessParty$Params } from '../fn/order-controller/was-ct-requested-for-source-and-business-party';
import { WbOrder } from '../models/wb-order';
import { WuOrder } from '../models/wu-order';

@Injectable({ providedIn: 'root' })
export class OrderControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadPostponeCancelRequests()` */
  static readonly LoadPostponeCancelRequestsPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPostponeCancelRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequests$Response(params: LoadPostponeCancelRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostponeCancelRequest>>> {
    return loadPostponeCancelRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadPostponeCancelRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequests(params: LoadPostponeCancelRequests$Params, context?: HttpContext): Observable<Array<PostponeCancelRequest>> {
    return this.loadPostponeCancelRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostponeCancelRequest>>): Array<PostponeCancelRequest> => r.body)
    );
  }

  /** Path part for operation `updatePostponeCancelRequest()` */
  static readonly UpdatePostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePostponeCancelRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostponeCancelRequest$Response(params: UpdatePostponeCancelRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updatePostponeCancelRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePostponeCancelRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostponeCancelRequest(params: UpdatePostponeCancelRequest$Params, context?: HttpContext): Observable<number> {
    return this.updatePostponeCancelRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `addPostponeCancelRequest()` */
  static readonly AddPostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPostponeCancelRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPostponeCancelRequest$Response(params: AddPostponeCancelRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return addPostponeCancelRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPostponeCancelRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPostponeCancelRequest(params: AddPostponeCancelRequest$Params, context?: HttpContext): Observable<number> {
    return this.addPostponeCancelRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `loadOrderTrackings1()` */
  static readonly LoadOrderTrackings1Path = '/orders/{guid}/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrderTrackings1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrderTrackings1$Response(params: LoadOrderTrackings1$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>> {
    return loadOrderTrackings1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadOrderTrackings1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrderTrackings1(params: LoadOrderTrackings1$Params, context?: HttpContext): Observable<{
[key: string]: Array<OrderTracking>;
}> {
    return this.loadOrderTrackings1$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>): {
[key: string]: Array<OrderTracking>;
} => r.body)
    );
  }

  /** Path part for operation `updateOrderTracking()` */
  static readonly UpdateOrderTrackingPath = '/orders/{guid}/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrderTracking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderTracking$Response(params: UpdateOrderTracking$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return updateOrderTracking(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrderTracking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderTracking(params: UpdateOrderTracking$Params, context?: HttpContext): Observable<number> {
    return this.updateOrderTracking$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `updateOrder()` */
  static readonly UpdateOrderPath = '/orders/{guid}/recipient';

  /**
   * This method changes the infusion date of order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder$Response(params: UpdateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method changes the infusion date of order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder(params: UpdateOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.updateOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `markInventoryOrderRecipientAsTransferred()` */
  static readonly MarkInventoryOrderRecipientAsTransferredPath = '/orders/inventory/mark-patient-as-transferred';

  /**
   * This method checks if a patient has inventory orders and if yes, updates transferred index field to Y
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markInventoryOrderRecipientAsTransferred()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderRecipientAsTransferred$Response(params: MarkInventoryOrderRecipientAsTransferred$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markInventoryOrderRecipientAsTransferred(this.http, this.rootUrl, params, context);
  }

  /**
   * This method checks if a patient has inventory orders and if yes, updates transferred index field to Y
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markInventoryOrderRecipientAsTransferred$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderRecipientAsTransferred(params: MarkInventoryOrderRecipientAsTransferred$Params, context?: HttpContext): Observable<void> {
    return this.markInventoryOrderRecipientAsTransferred$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markInventoryOrderAsUsed()` */
  static readonly MarkInventoryOrderAsUsedPath = '/orders/inventory/mark-as-used';

  /**
   * Marks Inventory Order Serial Number as used
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markInventoryOrderAsUsed()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderAsUsed$Response(params: MarkInventoryOrderAsUsed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markInventoryOrderAsUsed(this.http, this.rootUrl, params, context);
  }

  /**
   * Marks Inventory Order Serial Number as used
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markInventoryOrderAsUsed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderAsUsed(params: MarkInventoryOrderAsUsed$Params, context?: HttpContext): Observable<void> {
    return this.markInventoryOrderAsUsed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadAllOrders1()` */
  static readonly LoadAllOrders1Path = '/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadAllOrders1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllOrders1$Response(params?: LoadAllOrders1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {
    return loadAllOrders1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadAllOrders1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllOrders1(params?: LoadAllOrders1$Params, context?: HttpContext): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {
    return this.loadAllOrders1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>): Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> => r.body)
    );
  }

  /** Path part for operation `placeOrder()` */
  static readonly PlaceOrderPath = '/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  placeOrder$Response(params: PlaceOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CreateOrderResult>>> {
    return placeOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `placeOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  placeOrder(params: PlaceOrder$Params, context?: HttpContext): Observable<Array<CreateOrderResult>> {
    return this.placeOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreateOrderResult>>): Array<CreateOrderResult> => r.body)
    );
  }

  /** Path part for operation `loadAllOrders()` */
  static readonly LoadAllOrdersPath = '/orders/{orderStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadAllOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadAllOrders$Response(params: LoadAllOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {
    return loadAllOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadAllOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadAllOrders(params: LoadAllOrders$Params, context?: HttpContext): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {
    return this.loadAllOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>): Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> => r.body)
    );
  }

  /** Path part for operation `loadOrderTrackings()` */
  static readonly LoadOrderTrackingsPath = '/orders/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrderTrackings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrderTrackings$Response(params: LoadOrderTrackings$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}>> {
    return loadOrderTrackings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadOrderTrackings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrderTrackings(params: LoadOrderTrackings$Params, context?: HttpContext): Observable<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}> {
    return this.loadOrderTrackings$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}>): {
[key: string]: {
[key: string]: Array<OrderTracking>;
};
} => r.body)
    );
  }

  /** Path part for operation `loadRecent()` */
  static readonly LoadRecentPath = '/orders/recent/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadRecent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadRecent$Response(params: LoadRecent$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return loadRecent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadRecent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadRecent(params: LoadRecent$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.loadRecent$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `getOrderTrackingServiceCodes()` */
  static readonly GetOrderTrackingServiceCodesPath = '/orders/order-line-items/service-codes';

  /**
   * Getting order line item's service codes by line item ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingServiceCodes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingServiceCodes$Response(params: GetOrderTrackingServiceCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderLineItemServiceCode>>> {
    return getOrderTrackingServiceCodes(this.http, this.rootUrl, params, context);
  }

  /**
   * Getting order line item's service codes by line item ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingServiceCodes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingServiceCodes(params: GetOrderTrackingServiceCodes$Params, context?: HttpContext): Observable<Array<OrderLineItemServiceCode>> {
    return this.getOrderTrackingServiceCodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderLineItemServiceCode>>): Array<OrderLineItemServiceCode> => r.body)
    );
  }

  /** Path part for operation `loadOrders()` */
  static readonly LoadOrdersPath = '/orders/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrders$Response(params: LoadOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {
    return loadOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrders(params: LoadOrders$Params, context?: HttpContext): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {
    return this.loadOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>): Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> => r.body)
    );
  }

  /** Path part for operation `loadInventoryOrders()` */
  static readonly LoadInventoryOrdersPath = '/orders/inventory';

  /**
   * This method loads inventory orders of specific type based on bulk ind and bpGuids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadInventoryOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadInventoryOrders$Response(params: LoadInventoryOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<PageInventoryOrderDetails>> {
    return loadInventoryOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads inventory orders of specific type based on bulk ind and bpGuids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadInventoryOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadInventoryOrders(params: LoadInventoryOrders$Params, context?: HttpContext): Observable<PageInventoryOrderDetails> {
    return this.loadInventoryOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageInventoryOrderDetails>): PageInventoryOrderDetails => r.body)
    );
  }

  /** Path part for operation `requestKitOrder()` */
  static readonly RequestKitOrderPath = '/orders/inventory/swab-kit';

  /**
   * This method sends a request to common module to create a kit order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestKitOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestKitOrder$Response(params: RequestKitOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestKitOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method sends a request to common module to create a kit order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestKitOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestKitOrder(params: RequestKitOrder$Params, context?: HttpContext): Observable<void> {
    return this.requestKitOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadPostponeCancelRequest()` */
  static readonly LoadPostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPostponeCancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequest$Response(params: LoadPostponeCancelRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<PostponeCancelRequest>> {
    return loadPostponeCancelRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadPostponeCancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequest(params: LoadPostponeCancelRequest$Params, context?: HttpContext): Observable<PostponeCancelRequest> {
    return this.loadPostponeCancelRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostponeCancelRequest>): PostponeCancelRequest => r.body)
    );
  }

  /** Path part for operation `deletePostponeCancelRequest()` */
  static readonly DeletePostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePostponeCancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostponeCancelRequest$Response(params: DeletePostponeCancelRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePostponeCancelRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePostponeCancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostponeCancelRequest(params: DeletePostponeCancelRequest$Params, context?: HttpContext): Observable<void> {
    return this.deletePostponeCancelRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadOrder()` */
  static readonly LoadOrderPath = '/orders/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrder$Response(params: LoadOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {
    return loadOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrder(params: LoadOrder$Params, context?: HttpContext): Observable<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> {
    return this.loadOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>): (AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder) => r.body)
    );
  }

  /** Path part for operation `getInventoryOrderDetails()` */
  static readonly GetInventoryOrderDetailsPath = '/orders/related-donor/inventory';

  /**
   * This method loads inventory order for donor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryOrderDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryOrderDetails$Response(params: GetInventoryOrderDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryOrderDetails>> {
    return getInventoryOrderDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads inventory order for donor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryOrderDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryOrderDetails(params: GetInventoryOrderDetails$Params, context?: HttpContext): Observable<InventoryOrderDetails> {
    return this.getInventoryOrderDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryOrderDetails>): InventoryOrderDetails => r.body)
    );
  }

  /** Path part for operation `findInProgressRequests()` */
  static readonly FindInProgressRequestsPath = '/orders/postponed-cancelled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findInProgressRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  findInProgressRequests$Response(params: FindInProgressRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostponementCancellationInfo>>> {
    return findInProgressRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findInProgressRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findInProgressRequests(params: FindInProgressRequests$Params, context?: HttpContext): Observable<Array<PostponementCancellationInfo>> {
    return this.findInProgressRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostponementCancellationInfo>>): Array<PostponementCancellationInfo> => r.body)
    );
  }

  /** Path part for operation `loadLatestOrder()` */
  static readonly LoadLatestOrderPath = '/orders/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadLatestOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLatestOrder$Response(params: LoadLatestOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {
    return loadLatestOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadLatestOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLatestOrder(params: LoadLatestOrder$Params, context?: HttpContext): Observable<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> {
    return this.loadLatestOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>): (AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder) => r.body)
    );
  }

  /** Path part for operation `getInventorySerialNumbers()` */
  static readonly GetInventorySerialNumbersPath = '/orders/inventory/serial-numbers';

  /**
   * This method retrieves inventory serial numbers by inventory order type (mandatory), id, serial number, business party GUID,  bulkInd and usedInd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventorySerialNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventorySerialNumbers$Response(params?: GetInventorySerialNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InventorySerialNumberDetails>>> {
    return getInventorySerialNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves inventory serial numbers by inventory order type (mandatory), id, serial number, business party GUID,  bulkInd and usedInd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventorySerialNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventorySerialNumbers(params?: GetInventorySerialNumbers$Params, context?: HttpContext): Observable<Array<InventorySerialNumberDetails>> {
    return this.getInventorySerialNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InventorySerialNumberDetails>>): Array<InventorySerialNumberDetails> => r.body)
    );
  }

  /** Path part for operation `getDocumentRecipientGuid()` */
  static readonly GetDocumentRecipientGuidPath = '/orders/documents/getbp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentRecipientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentRecipientGuid$Response(params: GetDocumentRecipientGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDocumentRecipientGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentRecipientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentRecipientGuid(params: GetDocumentRecipientGuid$Params, context?: HttpContext): Observable<string> {
    return this.getDocumentRecipientGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `wasCtRequestedForSourceAndBusinessParty()` */
  static readonly WasCtRequestedForSourceAndBusinessPartyPath = '/orders/ct-requested/{recipientGuid}/{sourceGuid}/{bpGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wasCtRequestedForSourceAndBusinessParty()` instead.
   *
   * This method doesn't expect any request body.
   */
  wasCtRequestedForSourceAndBusinessParty$Response(params: WasCtRequestedForSourceAndBusinessParty$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderRequested>> {
    return wasCtRequestedForSourceAndBusinessParty(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wasCtRequestedForSourceAndBusinessParty$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wasCtRequestedForSourceAndBusinessParty(params: WasCtRequestedForSourceAndBusinessParty$Params, context?: HttpContext): Observable<OrderRequested> {
    return this.wasCtRequestedForSourceAndBusinessParty$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderRequested>): OrderRequested => r.body)
    );
  }

  /** Path part for operation `findCtCompletedDonors()` */
  static readonly FindCtCompletedDonorsPath = '/orders/ct-completed-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCtCompletedDonors()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCtCompletedDonors$Response(params?: FindCtCompletedDonors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return findCtCompletedDonors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findCtCompletedDonors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCtCompletedDonors(params?: FindCtCompletedDonors$Params, context?: HttpContext): Observable<Array<string>> {
    return this.findCtCompletedDonors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
