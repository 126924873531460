import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserModel} from '@matchsource/models/user';
import {map} from 'rxjs/operators';
import {CaseResourceControllerService} from '@matchsource/api-generated/common';
import {userSerializer} from './user.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private readonly caseResourceControllerService: CaseResourceControllerService) {}

  get(adId: MsApp.Guid): Observable<UserModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingCaseRelatedInformation);

    return this.caseResourceControllerService
      .getCaseResourceByAdId({adId}, context())
      .pipe(map(user => userSerializer.fromDTO(user)));
  }
}
