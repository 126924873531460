import {SupplierModel} from '@matchsource/models/suppliers';
import {CordListModel} from '@matchsource/models/cord';
import {BusinessParty} from '@matchsource/api-generated/common';
import {DonorListModel} from '@matchsource/models/donor';
import {FormCode} from '@matchsource/models/workup';
import {HashMap} from '@jsverse/transloco';
import {OrderType} from '@matchsource/models/order';
import {SourceTypes} from '@matchsource/models/source';

export type CartDonorListModel = DonorListModel;
export type CartCordListModel = CordListModel & BusinessParty;

export enum PatientCartItemStatus {
  AvailableToSelect = 1,
  AvailableToSubmit = 2,
}

export interface PatientCartItemKey {
  id: MsApp.Guid;
}

export type PatientCartItemModel = PatientCartItemKey & {
  id: MsApp.Guid;
  type: SourceTypes;
  services: any[];
  workupId: null | number;
  status: PatientCartItemStatus;
  orderType: OrderType;
  version?: string;
  isAbonly?: boolean;
  isSelectDisabled?: boolean;
  hasCriticalStatusAnomaly?: boolean;
  hasTemporalStatusAnomaly?: boolean;
  isStatusAnomalyInitialUser?: boolean;
  formCode?: FormCode;
};

export interface CartDonorsGroup {
  sources: DonorListModel[] | CordListModel[];
  supplier: SupplierModel;
}

export enum CartCordsGroupType {
  Supplier = 'supplier',

  WithPrevCT = 'withPrevCT',

  WithoutPrevCT = 'withoutPrevCT',
}

interface CartCordsGroupBase {
  sources: CordListModel[];
  supplier: SupplierModel;
  type: CartCordsGroupType;
}

export interface CartCordsSupplierGroup extends CartCordsGroupBase {
  type: CartCordsGroupType.Supplier;
}

export interface CartCordsWithCTGroup extends CartCordsGroupBase {
  type: CartCordsGroupType.WithPrevCT;
}

export interface CartCordsWithoutCTGroup extends CartCordsGroupBase {
  type: CartCordsGroupType.WithoutPrevCT;
}

export type CartCordsGroup = CartCordsSupplierGroup | CartCordsWithCTGroup | CartCordsWithoutCTGroup;

export interface SubmitOrderModel {
  orderTypeLabel: string;
  orderType: string;
  serviceLabels: string[] | string;
  name: string;
  errorMessage: string;
  workupId: string | number;
  shouldDisplayCtedLabel: boolean;
  services: string[];
  guid: MsApp.Guid;
  isSelectDisabled: boolean;
  hasCriticalStatusAnomaly?: boolean;
  hasTemporalStatusAnomaly?: boolean;
  isStatusAnomalyInitialUser: boolean;
  formCode?: FormCode;
}

export interface DonorListSubmitModel extends CartDonorListModel {
  internationalFormRequired: boolean;
}

export interface SubmitSourceOrderModel extends SubmitOrderModel {
  source: CartDonorListModel | CartCordListModel;
}

export interface SourceMapInProgress {
  [key: string]: CartDonorListModel | CartCordListModel;
}

export interface FailedOrderModalPropsModel {
  failedHeader: string;
  failedMessage: string;
  failedMessageParams: HashMap;
}
