/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCopyOfWorkupFormByPatientGuid } from '../fn/copy-workup-controller/get-copy-of-workup-form-by-patient-guid';
import { GetCopyOfWorkupFormByPatientGuid$Params } from '../fn/copy-workup-controller/get-copy-of-workup-form-by-patient-guid';
import { getCopyOfWorkupFormByPatientGuidAndFormCode } from '../fn/copy-workup-controller/get-copy-of-workup-form-by-patient-guid-and-form-code';
import { GetCopyOfWorkupFormByPatientGuidAndFormCode$Params } from '../fn/copy-workup-controller/get-copy-of-workup-form-by-patient-guid-and-form-code';
import { saveCopyOfWorkupForm } from '../fn/copy-workup-controller/save-copy-of-workup-form';
import { SaveCopyOfWorkupForm$Params } from '../fn/copy-workup-controller/save-copy-of-workup-form';
import { WorkupAnswer } from '../models/workup-answer';

@Injectable({ providedIn: 'root' })
export class CopyWorkupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `saveCopyOfWorkupForm()` */
  static readonly SaveCopyOfWorkupFormPath = '/workup-copy/answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCopyOfWorkupForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCopyOfWorkupForm$Response(params: SaveCopyOfWorkupForm$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveCopyOfWorkupForm(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveCopyOfWorkupForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCopyOfWorkupForm(params: SaveCopyOfWorkupForm$Params, context?: HttpContext): Observable<void> {
    return this.saveCopyOfWorkupForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCopyOfWorkupFormByPatientGuid()` */
  static readonly GetCopyOfWorkupFormByPatientGuidPath = '/workup-copy/answers/{patientGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCopyOfWorkupFormByPatientGuid()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCopyOfWorkupFormByPatientGuid$Response(params: GetCopyOfWorkupFormByPatientGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupAnswer>> {
    return getCopyOfWorkupFormByPatientGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCopyOfWorkupFormByPatientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCopyOfWorkupFormByPatientGuid(params: GetCopyOfWorkupFormByPatientGuid$Params, context?: HttpContext): Observable<WorkupAnswer> {
    return this.getCopyOfWorkupFormByPatientGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>): WorkupAnswer => r.body)
    );
  }

  /** Path part for operation `getCopyOfWorkupFormByPatientGuidAndFormCode()` */
  static readonly GetCopyOfWorkupFormByPatientGuidAndFormCodePath = '/workup-copy/answers/{patientGuid}/{formCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCopyOfWorkupFormByPatientGuidAndFormCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCopyOfWorkupFormByPatientGuidAndFormCode$Response(params: GetCopyOfWorkupFormByPatientGuidAndFormCode$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupAnswer>> {
    return getCopyOfWorkupFormByPatientGuidAndFormCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCopyOfWorkupFormByPatientGuidAndFormCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCopyOfWorkupFormByPatientGuidAndFormCode(params: GetCopyOfWorkupFormByPatientGuidAndFormCode$Params, context?: HttpContext): Observable<WorkupAnswer> {
    return this.getCopyOfWorkupFormByPatientGuidAndFormCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>): WorkupAnswer => r.body)
    );
  }

}
