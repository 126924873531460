export enum BLeaderCode {
  T = 'T',
  M = 'M',
  X = 'X',
  None = '',
}

export enum BLeaderTyping {
  TT = 'TT',
  MM = 'MM',
  TM = 'TM',
  MT = 'MT',
  None = '',
}

export interface BleaderInfoCodeModel {
  code: string;
}

export interface BLeaderDetailedInfoDTO {
  displayBLeader: string;
  familyContainsExceptions: string;
  bleaderDetails: MsApp.Dictionary<string[]>;
}

export type BLeaderDetailedInfoModel = BLeaderDetailedInfoDTO;

export interface BLeaderDetailedModel {
  leader: BLeaderCode;
  typing: BLeaderTyping;
}
