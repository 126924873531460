import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RouterState, Navigate} from 'ngxs-ui-router';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Ng2StateDeclaration, Transition, TransitionOptions, UIRouterModule} from '@uirouter/angular';
import {BULK_SWAB_KIT_ORDERS_ROUTE_NAME, PATIENT_SWAB_KIT_ORDERS_ROUTE_NAME} from '@matchsource/models/swab-kit-order';
import {BULK_SWAB_KIT_ORDER_ROUTE_NAME} from 'app/features/swab-kit-order/declarations';
import {CpLayoutHeaderTestIds} from 'app/features/cp-layout/declarations';
import {ToggleDirective} from '@matchsource/shared/toggle';
import {SearchComponent} from '@matchsource/shared/search';
import {NgClass, NgIf, AsyncPipe, UpperCasePipe, DatePipe} from '@angular/common';
import {TranslocoDirective, TranslocoPipe} from '@jsverse/transloco';
import {OptionsComponent} from '@matchsource/shared/options';
import {MsLogoComponent} from '@matchsource/shared/ms-logo';
import {PatientFormRouteNames} from '@matchsource/shared';
import {MyPatientsRoutes} from 'app/features/my-patients/declarations';
import {WorkflowManagerRoutes} from 'app/features/workflow-manager/declarations';
import {SwitchRolesComponent} from 'app/shared/components/switch-roles/switch-roles.component';
import {SearchParams, SearchTypes} from '@matchsource/models/search';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {UserPermissionsDirective} from '@matchsource/shared/directives';
import {PermissionsService} from '@matchsource/store/permissions';

interface Link {
  label: string;
  value: string;
  values?: string[];
  options?: TransitionOptions;
}

@Component({
  selector: 'ms-cp-layout',
  templateUrl: './cp-layout.component.html',
  styleUrls: ['./cp-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UIRouterModule,
    MsLogoComponent,
    OptionsComponent,
    TranslocoDirective,
    NgClass,
    SearchComponent,
    ToggleDirective,
    NgIf,
    AsyncPipe,
    UpperCasePipe,
    DatePipe,
    TranslocoPipe,
    SwitchRolesComponent,
    UserPermissionsDirective,
  ],
})
export class CpLayoutComponent implements OnInit {
  @Select(RouterState.routeName)
  currentRoute$: Observable<string>;

  @Input() notificationsLink: string;

  @Input() dashboardLink: string;

  @Input() searchTypes: SearchTypes[];

  @Input() searchOptions: SearchParams;

  @Input() userName: string;

  @Input() userFullName: string;

  @Input() lastLogin: string;

  @Input() hasAccessToProtectedArea: any;

  @Input() lastLoginDateFormat: string;

  @Input() userRole: string;

  @Input() userRoles: string[];

  @Input() isMultipleRolesUser: boolean;

  @Input() rolesForSwitching: string[];

  @Input()
  userNameAbbr: string;

  @Output() search = new EventEmitter<any>();

  @Output() logout = new EventEmitter<void>();

  @Output() switchRole = new EventEmitter<string>();

  readonly announcementsEditPermissions = USER_PERMISSIONS.ANNOUNCEMENT_EDIT;
  readonly sampleInstructionPermissions = USER_PERMISSIONS.SAMPLE_INSTRUCTION_VIEW;

  myPatientsOptions: Link[];
  bulkSwabKitsOptions: Link[];
  notificationsLinks: string[];
  isApplicationToolsVisible = false;
  testIds = CpLayoutHeaderTestIds;

  constructor(
    private readonly store: Store,
    private readonly transition: Transition,
    private readonly permissions: PermissionsService
  ) {}

  ngOnInit() {
    this.resetSearchOptions();
    this.transition?.onSuccess({}, transition => {
      this.resetSearchOptions(transition.params());
    });

    this.isApplicationToolsVisible = this.permissions.hasAnyPermission([
      this.announcementsEditPermissions,
      this.sampleInstructionPermissions,
    ]);

    this.notificationsLinks = Object.values(WorkflowManagerRoutes);
    const myPatientsLinks = Object.values(MyPatientsRoutes);
    const patientFormLinks = Object.values(PatientFormRouteNames);

    this.myPatientsOptions = [
      {
        label: 'PATIENT.CREATE_NEW_PATIENT',
        value: PatientFormRouteNames.PatientFormCreate,
        values: patientFormLinks,
        options: {reload: true},
      },
      {
        label: 'MY_PATIENTS_LINKS.VIEW_MY_PATIENTS',
        value: MyPatientsRoutes.MyPatients,
        values: myPatientsLinks,
      },
      {
        label: 'MY_PATIENTS_LINKS.VIEW_PATIENT_SWAB_KIT_ORDERS',
        value: PATIENT_SWAB_KIT_ORDERS_ROUTE_NAME,
        values: [PATIENT_SWAB_KIT_ORDERS_ROUTE_NAME],
      },
    ];

    this.bulkSwabKitsOptions = [
      {
        label: 'BULK_SWAB_KITS_LINKS.ORDER_BULK_KITS',
        value: BULK_SWAB_KIT_ORDER_ROUTE_NAME,
        values: [BULK_SWAB_KIT_ORDER_ROUTE_NAME],
      },
      {
        label: 'BULK_SWAB_KITS_LINKS.VIEW_BULK_KIT_ORDERS',
        value: BULK_SWAB_KIT_ORDERS_ROUTE_NAME,
        values: [BULK_SWAB_KIT_ORDERS_ROUTE_NAME],
      },
    ];
  }

  private resetSearchOptions(params: {search?: string; searchType?: SearchTypes} = {}): void {
    const [searchType = null] = this.searchTypes;

    this.searchOptions = {
      type: params.searchType || searchType,
      searchTerm: params.search || '',
    };
  }

  getLinks(routes: Ng2StateDeclaration[]): string[] {
    return routes.map(route => route.name);
  }

  isNotificationsLinkActive(currentRoute: string): boolean {
    return !!this.notificationsLinks.find(link => link === currentRoute);
  }

  onLinkChanged(link: Link): void {
    this.store.dispatch(new Navigate(link.value, undefined, link.options));
  }

  onSearch(search: any): void {
    this.search.emit(search);
  }

  onLogout(): void {
    this.logout.emit();
  }

  onSwitchRole(role: string): void {
    this.switchRole.emit(role);
  }
}
