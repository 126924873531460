import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NavigationItemModel} from '@matchsource/models/navigation-history';
import {HistoryControllerService} from '@matchsource/api-generated/common';
import {map} from 'rxjs/operators';
import {mapDtoToNavigationItemModel, mapNavigationItemModelToDto} from './utils';

import {silentError} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryApiService {
  constructor(private readonly historyControllerService: HistoryControllerService) {}

  loadHistory(recordsCount: number): Observable<NavigationItemModel[]> {
    return this.historyControllerService
      .get({
        size: recordsCount,
      })
      .pipe(map(({content: history}) => history.map(item => mapDtoToNavigationItemModel(item))));
  }

  saveNavigationItem(navItem: NavigationItemModel): Observable<NavigationItemModel> {
    return this.createOrUpdateNavigationItem(navItem);
  }

  updateNavigationItem(navItem: NavigationItemModel): Observable<NavigationItemModel> {
    return this.createOrUpdateNavigationItem(navItem);
  }

  private createOrUpdateNavigationItem(navItem: NavigationItemModel): Observable<NavigationItemModel> {
    const context = silentError(true)();

    return this.historyControllerService
      .updateOrCreateIfNotExist(
        {
          body: mapNavigationItemModelToDto(navItem),
        },
        context
      )
      .pipe(map(item => mapDtoToNavigationItemModel(item)));
  }
}
