/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LookerReportsDto } from '../../models/looker-reports-dto';

export interface GetReports$Params {
}

export function getReports(http: HttpClient, rootUrl: string, params?: GetReports$Params, context?: HttpContext): Observable<StrictHttpResponse<LookerReportsDto>> {
  const rb = new RequestBuilder(rootUrl, getReports.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LookerReportsDto>;
    })
  );
}

getReports.PATH = '/looker/reports';
