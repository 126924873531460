/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ReassignWorkupDocuments$Params {
  workupInProgressId: number;
  orderGuid: string;
}

export function reassignWorkupDocuments(http: HttpClient, rootUrl: string, params: ReassignWorkupDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, reassignWorkupDocuments.PATH, 'put');
  if (params) {
    rb.query('workupInProgressId', params.workupInProgressId, {});
    rb.query('orderGuid', params.orderGuid, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

reassignWorkupDocuments.PATH = '/int-forms-documents/reassign_documents_workup';
