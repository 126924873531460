/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { loadPermissions } from '../fn/security-controller/load-permissions';
import { LoadPermissions$Params } from '../fn/security-controller/load-permissions';

@Injectable({ providedIn: 'root' })
export class SecurityControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadPermissions()` */
  static readonly LoadPermissionsPath = '/permissions';

  /**
   * This method loads permissions for a given user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPermissions$Response(params?: LoadPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return loadPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads permissions for a given user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPermissions(params?: LoadPermissions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.loadPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
