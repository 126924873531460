import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DocumentModel, DocumentsFeatureType} from '@matchsource/models/documents';
import {documentsSerializer} from './documents.serializer';
import {DocumentControllerService} from '@matchsource/api-generated/documents';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService {
  constructor(private readonly documentApi: DocumentControllerService) {}

  get(id: number, clientErrorCode = ClientErrorCode.DownloadingDocuments): Promise<Blob> {
    const context = setSingleErrorCustomErrorHandlingContext(clientErrorCode);

    return firstValueFrom(this.documentApi.downloadDocument({id}, context()));
  }

  download(owcId: MsApp.Guid, recipientId: MsApp.Guid, docOwnerGuid: MsApp.Guid): Promise<Blob> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ProcessingYourDocuments);


    return firstValueFrom(this.documentApi.downloadByOwcId({owcId, rcpGuid: recipientId, docOwnerGuid}, context()));
  }

  getAll(ids: number[], clientErrorCode = ClientErrorCode.Default): Observable<DocumentModel[]> {
    if (ids.length === 0) {
      return of([]);
    }

    const context = setSingleErrorCustomErrorHandlingContext(clientErrorCode);

    return this.documentApi
      .getDocumentsDetails({body: ids}, context())
      .pipe(map(data => data.map(input => documentsSerializer.fromDTO(input))));
  }

  getDetails(documentId: number): Observable<DocumentModel> {
    return this.documentApi.getDocumentDetails({documentId}).pipe(map(input => documentsSerializer.fromDTO(input)));
  }

  delete(id: number): Observable<void> {
    return this.documentApi.softDelete({id});
  }

  getExtensions(featureType: DocumentsFeatureType): Observable<string[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDocumentsExtensions);

    return this.documentApi.getDocumentFormats({featureType}, context());
  }
}
