/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AtOrder } from '../../models/at-order';
import { BdpOrOrder } from '../../models/bdp-or-order';
import { CctOrder } from '../../models/cct-order';
import { CtOrder } from '../../models/ct-order';
import { DrOrder } from '../../models/dr-order';
import { EWorkupOrder } from '../../models/e-workup-order';
import { HeOrder } from '../../models/he-order';
import { HrOrder } from '../../models/hr-order';
import { HwOrder } from '../../models/hw-order';
import { OrOrder } from '../../models/or-order';
import { PbscOrder } from '../../models/pbsc-order';
import { UwOrder } from '../../models/uw-order';
import { WbOrder } from '../../models/wb-order';
import { WuOrder } from '../../models/wu-order';

export interface LoadAllOrders1$Params {
  recipientGuid?: string;
  sourceGuid?: string;
  orderStatus?: string;
}

export function loadAllOrders1(http: HttpClient, rootUrl: string, params?: LoadAllOrders1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {
  const rb = new RequestBuilder(rootUrl, loadAllOrders1.PATH, 'get');
  if (params) {
    rb.query('recipientGuid', params.recipientGuid, {});
    rb.query('sourceGuid', params.sourceGuid, {});
    rb.query('orderStatus', params.orderStatus, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>;
    })
  );
}

loadAllOrders1.PATH = '/orders';
