import {Inject, Injectable} from '@angular/core';
import {firstValueFrom, Observable, ReplaySubject} from 'rxjs';
import {Ng2StateDeclaration, Transition, UIRouter, UrlRuleHandlerFn, UrlRuleMatchFn} from '@uirouter/angular';
import {FeatureToggleAdapterService} from '../constants';
import {FeatureToggleAdapter, FeatureToggleSettingsModel, FeatureToggleSettingsOptions} from '../declarations';
import {map} from 'rxjs/operators';

export interface FeatureToggleStateDeclaration extends Ng2StateDeclaration {
  data?: {
    [key: string]: any;
    toggle?: {
      feature: string;
      fallbackState: string;
    };
  };
}

type FeatureDictionary = {[key: string]: boolean};

@Injectable()
export class FeatureService {
  private features: string[] = [];

  private overwriteFeatures: FeatureDictionary | null = null;

  private readonly initializeSubject$ = new ReplaySubject<void>(1);

  initialize$: Observable<void>;

  get enabledFeatures(): string[] {
    return this.features;
  }

  constructor(
    @Inject(FeatureToggleAdapterService) private readonly adapter: FeatureToggleAdapter,
    private readonly router: UIRouter
  ) {
    this.initialize$ = this.initializeSubject$.asObservable();
  }

  overwrite(features: FeatureDictionary): void {
    this.overwriteFeatures = features;
  }

  private applyOverwrite(features: string[], overwriteFeatures: FeatureDictionary): string[] {
    return Object.entries(overwriteFeatures || {}).reduce((newFeatures, [key, enabled]) => {
      const featureIndex = newFeatures.indexOf(key);
      const isFeatureEnabled = featureIndex > -1;

      if (enabled && !isFeatureEnabled) {
        newFeatures.push(key);
      } else if (!enabled && isFeatureEnabled) {
        newFeatures.splice(featureIndex, 1);
      }

      return newFeatures;
    }, features || []);
  }

  async load(): Promise<void> {
    try {
      const features = await firstValueFrom(this.getCurrentFeatures());
      this.set(features);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }

  getFeatures(options: FeatureToggleSettingsOptions = {}): Observable<FeatureToggleSettingsModel> {
    return this.adapter.getSettings(options);
  }

  getCurrentFeatures(options: FeatureToggleSettingsOptions = {}, applyOverwrite = false): Observable<string[]> {
    return this.getFeatures(options).pipe(
      map(features => features.filter(({selected}) => selected).map(({code}) => code)),
      map(enabledFeatures => {
        if (!applyOverwrite) {
          return enabledFeatures;
        }

        return this.applyOverwrite(enabledFeatures, this.overwriteFeatures);
      })
    );
  }

  init(routes: FeatureToggleStateDeclaration[]): void {
    const {router} = this;

    routes
      .filter(route => route.data && route.data.toggle)
      .forEach(route => {
        const fallbackUrl = router.stateService.get(route.data.toggle.fallbackState).url;

        const disabledFeatureMatch: UrlRuleMatchFn = url => {
          if (this.enabled(route.data.toggle.feature)) {
            return false;
          }
          return new RegExp(`(.*)${route.url}([?/].*|$)`).exec(url.path);
        };

        const disabledFallbackMatch: UrlRuleMatchFn = url => {
          if (!this.enabled(route.data.toggle.feature)) {
            return false;
          }
          return new RegExp(`(.*)${fallbackUrl}([?/].*|$)`).exec(url.path);
        };

        const redirectToFeature: UrlRuleHandlerFn = match => {
          return `${match[1]}${route.url}${match[2]}`;
        };

        const redirectToFallback: UrlRuleHandlerFn = match => {
          return `${match[1]}${fallbackUrl}${match[2]}`;
        };

        const featureRule = router.urlService.rules.urlRuleFactory.create(disabledFeatureMatch, redirectToFallback);
        const fallbackRule = router.urlService.rules.urlRuleFactory.create(disabledFallbackMatch, redirectToFeature);

        router.urlService.rules.rule(featureRule);
        router.urlService.rules.rule(fallbackRule);

        const getStateName = (routeName: string) => {
          if (routeName.slice(-3) === '.**') {
            return routeName.slice(0, -3);
          }
          return routeName;
        };

        router.transitionService.onBefore({to: route.name}, (transition: Transition) => {
          if (this.enabled(route.data.toggle.feature)) {
            return null;
          }

          const stateName = getStateName(route.name);
          const redirectTo = transition.$to().name.replace(stateName, route.data.toggle.fallbackState);
          return transition.router.stateService.target(redirectTo, transition.params());
        });

        router.transitionService.onBefore({to: route.data.toggle.fallbackState}, (transition: Transition) => {
          if (!this.enabled(route.data.toggle.feature)) {
            return null;
          }

          const stateName = getStateName(route.name);
          const redirectTo = transition.$to().name.replace(route.data.toggle.fallbackState, stateName);
          return transition.router.stateService.target(redirectTo, transition.params());
        });
      });
  }

  set(features: string[]): void {
    this.features = this.applyOverwrite(features, this.overwriteFeatures);
    this.initializeSubject$.next();
  }

  enabled(feature: string): boolean {
    return this.features.includes(feature);
  }

  enabledAll(features: string[]): boolean {
    return features.every((feature: string) => this.enabled(feature));
  }

  iifFeature(featureKey: string, callback: () => unknown, fallback: () => unknown): unknown {
    return this.enabled(featureKey) ? callback() : fallback();
  }
}
