/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Country } from '../models/country';
import { getCountries } from '../fn/geography-controller/get-countries';
import { GetCountries$Params } from '../fn/geography-controller/get-countries';
import { getCountry } from '../fn/geography-controller/get-country';
import { GetCountry$Params } from '../fn/geography-controller/get-country';
import { getState } from '../fn/geography-controller/get-state';
import { GetState$Params } from '../fn/geography-controller/get-state';
import { getStates } from '../fn/geography-controller/get-states';
import { GetStates$Params } from '../fn/geography-controller/get-states';
import { State } from '../models/state';

@Injectable({ providedIn: 'root' })
export class GeographyControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCountries()` */
  static readonly GetCountriesPath = '/geography/countries';

  /**
   * Retrieve list of available countries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Response(params?: GetCountries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Country>>> {
    return getCountries(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve list of available countries
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries(params?: GetCountries$Params, context?: HttpContext): Observable<Array<Country>> {
    return this.getCountries$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Country>>): Array<Country> => r.body)
    );
  }

  /** Path part for operation `getCountry()` */
  static readonly GetCountryPath = '/geography/countries/{countryCode}';

  /**
   * Get country by its two-letter code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountry$Response(params: GetCountry$Params, context?: HttpContext): Observable<StrictHttpResponse<Country>> {
    return getCountry(this.http, this.rootUrl, params, context);
  }

  /**
   * Get country by its two-letter code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountry(params: GetCountry$Params, context?: HttpContext): Observable<Country> {
    return this.getCountry$Response(params, context).pipe(
      map((r: StrictHttpResponse<Country>): Country => r.body)
    );
  }

  /** Path part for operation `getStates()` */
  static readonly GetStatesPath = '/geography/countries/{countryCode}/states';

  /**
   * Get country's states by its code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Response(params: GetStates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<State>>> {
    return getStates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get country's states by its code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates(params: GetStates$Params, context?: HttpContext): Observable<Array<State>> {
    return this.getStates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<State>>): Array<State> => r.body)
    );
  }

  /** Path part for operation `getState()` */
  static readonly GetStatePath = '/geography/countries/{countryCode}/states/{stateCode}';

  /**
   * Get country's state by its code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getState$Response(params: GetState$Params, context?: HttpContext): Observable<StrictHttpResponse<State>> {
    return getState(this.http, this.rootUrl, params, context);
  }

  /**
   * Get country's state by its code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getState(params: GetState$Params, context?: HttpContext): Observable<State> {
    return this.getState$Response(params, context).pipe(
      map((r: StrictHttpResponse<State>): State => r.body)
    );
  }

}
