/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteHistoryForTransferredPatient } from '../fn/history-controller/delete-history-for-transferred-patient';
import { DeleteHistoryForTransferredPatient$Params } from '../fn/history-controller/delete-history-for-transferred-patient';
import { get } from '../fn/history-controller/get';
import { Get$Params } from '../fn/history-controller/get';
import { PageRecentHistoryPage } from '../models/page-recent-history-page';
import { RecentHistoryPage } from '../models/recent-history-page';
import { updateOrCreateIfNotExist } from '../fn/history-controller/update-or-create-if-not-exist';
import { UpdateOrCreateIfNotExist$Params } from '../fn/history-controller/update-or-create-if-not-exist';

@Injectable({ providedIn: 'root' })
export class HistoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `get()` */
  static readonly GetPath = '/recent-history';

  /**
   * This method retrieves user's recent history
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<PageRecentHistoryPage>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves user's recent history
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: Get$Params, context?: HttpContext): Observable<PageRecentHistoryPage> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageRecentHistoryPage>): PageRecentHistoryPage => r.body)
    );
  }

  /** Path part for operation `updateOrCreateIfNotExist()` */
  static readonly UpdateOrCreateIfNotExistPath = '/recent-history';

  /**
   * This method updates recent history page for the user or create if not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrCreateIfNotExist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist$Response(params: UpdateOrCreateIfNotExist$Params, context?: HttpContext): Observable<StrictHttpResponse<RecentHistoryPage>> {
    return updateOrCreateIfNotExist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates recent history page for the user or create if not exist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrCreateIfNotExist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist(params: UpdateOrCreateIfNotExist$Params, context?: HttpContext): Observable<RecentHistoryPage> {
    return this.updateOrCreateIfNotExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecentHistoryPage>): RecentHistoryPage => r.body)
    );
  }

  /** Path part for operation `deleteHistoryForTransferredPatient()` */
  static readonly DeleteHistoryForTransferredPatientPath = '/recent-history/clear/transferred-patient/{patientId}/{fromBpGuid}';

  /**
   * This method deletes history for transferred patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHistoryForTransferredPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHistoryForTransferredPatient$Response(params: DeleteHistoryForTransferredPatient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteHistoryForTransferredPatient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes history for transferred patient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteHistoryForTransferredPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHistoryForTransferredPatient(params: DeleteHistoryForTransferredPatient$Params, context?: HttpContext): Observable<void> {
    return this.deleteHistoryForTransferredPatient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
