/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSettings } from '../fn/settings-controller/get-settings';
import { GetSettings$Params } from '../fn/settings-controller/get-settings';
import { getSettingsForBp } from '../fn/settings-controller/get-settings-for-bp';
import { GetSettingsForBp$Params } from '../fn/settings-controller/get-settings-for-bp';
import { getSettingsForSubject } from '../fn/settings-controller/get-settings-for-subject';
import { GetSettingsForSubject$Params } from '../fn/settings-controller/get-settings-for-subject';
import { SettingsDto } from '../models/settings-dto';
import { updateSettings } from '../fn/settings-controller/update-settings';
import { UpdateSettings$Params } from '../fn/settings-controller/update-settings';
import { updateSettingsForBp } from '../fn/settings-controller/update-settings-for-bp';
import { UpdateSettingsForBp$Params } from '../fn/settings-controller/update-settings-for-bp';
import { updateSettingsForSubject } from '../fn/settings-controller/update-settings-for-subject';
import { UpdateSettingsForSubject$Params } from '../fn/settings-controller/update-settings-for-subject';

@Injectable({ providedIn: 'root' })
export class SettingsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSettings()` */
  static readonly GetSettingsPath = '/settings/{type}';

  /**
   * Get settings of certain type for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettings$Response(params: GetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingsDto>> {
    return getSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get settings of certain type for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettings(params: GetSettings$Params, context?: HttpContext): Observable<SettingsDto> {
    return this.getSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingsDto>): SettingsDto => r.body)
    );
  }

  /** Path part for operation `updateSettings()` */
  static readonly UpdateSettingsPath = '/settings/{type}';

  /**
   * Update settings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSettings()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettings$Response(params: UpdateSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Update settings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSettings$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettings(params: UpdateSettings$Params, context?: HttpContext): Observable<void> {
    return this.updateSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSettingsForSubject()` */
  static readonly GetSettingsForSubjectPath = '/settings/{type}/subjects/{guid}';

  /**
   * Get settings for subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsForSubject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsForSubject$Response(params: GetSettingsForSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingsDto>> {
    return getSettingsForSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Get settings for subject
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingsForSubject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsForSubject(params: GetSettingsForSubject$Params, context?: HttpContext): Observable<SettingsDto> {
    return this.getSettingsForSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingsDto>): SettingsDto => r.body)
    );
  }

  /** Path part for operation `updateSettingsForSubject()` */
  static readonly UpdateSettingsForSubjectPath = '/settings/{type}/subjects/{guid}';

  /**
   * Update settings for subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSettingsForSubject()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettingsForSubject$Response(params: UpdateSettingsForSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSettingsForSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Update settings for subject
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSettingsForSubject$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettingsForSubject(params: UpdateSettingsForSubject$Params, context?: HttpContext): Observable<void> {
    return this.updateSettingsForSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSettingsForBp()` */
  static readonly GetSettingsForBpPath = '/settings/{type}/bp/{bpGuid}';

  /**
   * Get settings of certain type for user and BP
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsForBp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsForBp$Response(params: GetSettingsForBp$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingsDto>> {
    return getSettingsForBp(this.http, this.rootUrl, params, context);
  }

  /**
   * Get settings of certain type for user and BP
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingsForBp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsForBp(params: GetSettingsForBp$Params, context?: HttpContext): Observable<SettingsDto> {
    return this.getSettingsForBp$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingsDto>): SettingsDto => r.body)
    );
  }

  /** Path part for operation `updateSettingsForBp()` */
  static readonly UpdateSettingsForBpPath = '/settings/{type}/bp/{bpGuid}';

  /**
   * Update settings and BP
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSettingsForBp()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettingsForBp$Response(params: UpdateSettingsForBp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSettingsForBp(this.http, this.rootUrl, params, context);
  }

  /**
   * Update settings and BP
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSettingsForBp$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  updateSettingsForBp(params: UpdateSettingsForBp$Params, context?: HttpContext): Observable<void> {
    return this.updateSettingsForBp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
