/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkForDuplicates } from '../fn/duplicate-patient-controller/check-for-duplicates';
import { CheckForDuplicates$Params } from '../fn/duplicate-patient-controller/check-for-duplicates';
import { DuplicatePatientHistory } from '../models/duplicate-patient-history';
import { DuplicatePatientInfo } from '../models/duplicate-patient-info';
import { DuplicatePatientResponse } from '../models/duplicate-patient-response';
import { getAllUnresolved } from '../fn/duplicate-patient-controller/get-all-unresolved';
import { GetAllUnresolved$Params } from '../fn/duplicate-patient-controller/get-all-unresolved';
import { getDuplicateHistory } from '../fn/duplicate-patient-controller/get-duplicate-history';
import { GetDuplicateHistory$Params } from '../fn/duplicate-patient-controller/get-duplicate-history';
import { getDuplicateInfo } from '../fn/duplicate-patient-controller/get-duplicate-info';
import { GetDuplicateInfo$Params } from '../fn/duplicate-patient-controller/get-duplicate-info';
import { RecipientDuplicate } from '../models/recipient-duplicate';
import { resolveDuplicates } from '../fn/duplicate-patient-controller/resolve-duplicates';
import { ResolveDuplicates$Params } from '../fn/duplicate-patient-controller/resolve-duplicates';
import { resolveDuplicatesManually } from '../fn/duplicate-patient-controller/resolve-duplicates-manually';
import { ResolveDuplicatesManually$Params } from '../fn/duplicate-patient-controller/resolve-duplicates-manually';

@Injectable({ providedIn: 'root' })
export class DuplicatePatientControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `resolveDuplicates()` */
  static readonly ResolveDuplicatesPath = '/duplicate/patients/resolve/{guid}';

  /**
   * Resolve whether recipient is a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveDuplicates()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveDuplicates$Response(params: ResolveDuplicates$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resolveDuplicates(this.http, this.rootUrl, params, context);
  }

  /**
   * Resolve whether recipient is a duplicate or not
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveDuplicates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveDuplicates(params: ResolveDuplicates$Params, context?: HttpContext): Observable<void> {
    return this.resolveDuplicates$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resolveDuplicatesManually()` */
  static readonly ResolveDuplicatesManuallyPath = '/duplicate/patients/manual/resolve';

  /**
   * Manually resolve whether recipient is a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveDuplicatesManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resolveDuplicatesManually$Response(params: ResolveDuplicatesManually$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resolveDuplicatesManually(this.http, this.rootUrl, params, context);
  }

  /**
   * Manually resolve whether recipient is a duplicate or not
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveDuplicatesManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resolveDuplicatesManually(params: ResolveDuplicatesManually$Params, context?: HttpContext): Observable<void> {
    return this.resolveDuplicatesManually$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkForDuplicates()` */
  static readonly CheckForDuplicatesPath = '/duplicate/patients/check';

  /**
   * Check is patient a duplicate or not
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForDuplicates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkForDuplicates$Response(params: CheckForDuplicates$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipientDuplicate>> {
    return checkForDuplicates(this.http, this.rootUrl, params, context);
  }

  /**
   * Check is patient a duplicate or not
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkForDuplicates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkForDuplicates(params: CheckForDuplicates$Params, context?: HttpContext): Observable<RecipientDuplicate> {
    return this.checkForDuplicates$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipientDuplicate>): RecipientDuplicate => r.body)
    );
  }

  /** Path part for operation `getAllUnresolved()` */
  static readonly GetAllUnresolvedPath = '/duplicate/patients';

  /**
   * Get all unresolved duplicates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUnresolved()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUnresolved$Response(params?: GetAllUnresolved$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DuplicatePatientResponse>>> {
    return getAllUnresolved(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all unresolved duplicates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUnresolved$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUnresolved(params?: GetAllUnresolved$Params, context?: HttpContext): Observable<Array<DuplicatePatientResponse>> {
    return this.getAllUnresolved$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DuplicatePatientResponse>>): Array<DuplicatePatientResponse> => r.body)
    );
  }

  /** Path part for operation `getDuplicateInfo()` */
  static readonly GetDuplicateInfoPath = '/duplicate/patients/info/{guid}';

  /**
   * Get duplicate patient info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDuplicateInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateInfo$Response(params: GetDuplicateInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicatePatientInfo>> {
    return getDuplicateInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get duplicate patient info
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDuplicateInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateInfo(params: GetDuplicateInfo$Params, context?: HttpContext): Observable<DuplicatePatientInfo> {
    return this.getDuplicateInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicatePatientInfo>): DuplicatePatientInfo => r.body)
    );
  }

  /** Path part for operation `getDuplicateHistory()` */
  static readonly GetDuplicateHistoryPath = '/duplicate/patients/history/{guid}';

  /**
   * Get duplicate patient history
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDuplicateHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateHistory$Response(params: GetDuplicateHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DuplicatePatientHistory>>> {
    return getDuplicateHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get duplicate patient history
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDuplicateHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDuplicateHistory(params: GetDuplicateHistory$Params, context?: HttpContext): Observable<Array<DuplicatePatientHistory>> {
    return this.getDuplicateHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DuplicatePatientHistory>>): Array<DuplicatePatientHistory> => r.body)
    );
  }

}
