/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SampleInstructionTemplate } from '../../models/sample-instruction-template';

export interface GetTemplate$Params {
  type: 'DOMDOM' | 'INTLDOM' | 'DINTLINTL';
}

export function getTemplate(http: HttpClient, rootUrl: string, params: GetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleInstructionTemplate>> {
  const rb = new RequestBuilder(rootUrl, getTemplate.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SampleInstructionTemplate>;
    })
  );
}

getTemplate.PATH = '/sample/instruction/template';
