/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getOrderTrackingByIds } from '../fn/order-tracking-controller/get-order-tracking-by-ids';
import { GetOrderTrackingByIds$Params } from '../fn/order-tracking-controller/get-order-tracking-by-ids';
import { getOrderTrackingByIds1 } from '../fn/order-tracking-controller/get-order-tracking-by-ids-1';
import { GetOrderTrackingByIds1$Params } from '../fn/order-tracking-controller/get-order-tracking-by-ids-1';
import { getOrderTrackingByIdWithHistory } from '../fn/order-tracking-controller/get-order-tracking-by-id-with-history';
import { GetOrderTrackingByIdWithHistory$Params } from '../fn/order-tracking-controller/get-order-tracking-by-id-with-history';
import { OrderTracking } from '../models/order-tracking';

@Injectable({ providedIn: 'root' })
export class OrderTrackingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOrderTrackingByIds()` */
  static readonly GetOrderTrackingByIdsPath = '/order-trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds$Response(params: GetOrderTrackingByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>> {
    return getOrderTrackingByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds(params: GetOrderTrackingByIds$Params, context?: HttpContext): Observable<{
[key: string]: Array<OrderTracking>;
}> {
    return this.getOrderTrackingByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>): {
[key: string]: Array<OrderTracking>;
} => r.body)
    );
  }

  /** Path part for operation `getOrderTrackingByIds1()` */
  static readonly GetOrderTrackingByIds1Path = '/order-trackings/no-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIds1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds1$Response(params: GetOrderTrackingByIds1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderTracking>>> {
    return getOrderTrackingByIds1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIds1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds1(params: GetOrderTrackingByIds1$Params, context?: HttpContext): Observable<Array<OrderTracking>> {
    return this.getOrderTrackingByIds1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderTracking>>): Array<OrderTracking> => r.body)
    );
  }

  /** Path part for operation `getOrderTrackingByIdWithHistory()` */
  static readonly GetOrderTrackingByIdWithHistoryPath = '/order-trackings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIdWithHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderTrackingByIdWithHistory$Response(params: GetOrderTrackingByIdWithHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderTracking>>> {
    return getOrderTrackingByIdWithHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIdWithHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderTrackingByIdWithHistory(params: GetOrderTrackingByIdWithHistory$Params, context?: HttpContext): Observable<Array<OrderTracking>> {
    return this.getOrderTrackingByIdWithHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderTracking>>): Array<OrderTracking> => r.body)
    );
  }

}
