/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProgramResponse } from '../../models/program-response';

export interface GetSupplierProgram$Params {
  bpGuid: string;
  programName?: string;
}

export function getSupplierProgram(http: HttpClient, rootUrl: string, params: GetSupplierProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProgramResponse>>> {
  const rb = new RequestBuilder(rootUrl, getSupplierProgram.PATH, 'get');
  if (params) {
    rb.query('bpGuid', params.bpGuid, {});
    rb.query('programName', params.programName, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProgramResponse>>;
    })
  );
}

getSupplierProgram.PATH = '/ebs-programs/supplier';
