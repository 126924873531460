import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IncidentControllerService} from '@matchsource/api-generated/common';
import {IncidentCreationSerializerService} from './incident-creation.serializer';
import {IncidentCreationModel} from './incident-creation.model';
import {skipError} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class IncidentCreationApiService {
  constructor(
    private readonly incidentControllerService: IncidentControllerService,
    private readonly incidentCreationSerializerService: IncidentCreationSerializerService
  ) {}

  canCreateIncident(): Observable<boolean> {
    const context = skipError;
    return this.incidentControllerService.isAllowedToCreate(
      {
        type: 'TECH_DIFFICULTIES',
      },
      context()
    );
  }

  createIncident(incidentCreationModel: IncidentCreationModel): Observable<void> {
    const context = skipError;
    return this.incidentControllerService.create(
      {
        body: this.incidentCreationSerializerService.toDTO(incidentCreationModel),
      },
      context()
    );
  }
}
