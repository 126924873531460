/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressAutocompleteResponse } from '../models/address-autocomplete-response';
import { AddressValidationResponse } from '../models/address-validation-response';
import { autocompleteUsAddress } from '../fn/address-validation-controller/autocomplete-us-address';
import { AutocompleteUsAddress$Params } from '../fn/address-validation-controller/autocomplete-us-address';
import { validateUsAddress } from '../fn/address-validation-controller/validate-us-address';
import { ValidateUsAddress$Params } from '../fn/address-validation-controller/validate-us-address';

@Injectable({ providedIn: 'root' })
export class AddressValidationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `validateUsAddress()` */
  static readonly ValidateUsAddressPath = '/address-validation/validate-us';

  /**
   * Validate if the provided US address is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUsAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUsAddress$Response(params: ValidateUsAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressValidationResponse>> {
    return validateUsAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Validate if the provided US address is valid.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateUsAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUsAddress(params: ValidateUsAddress$Params, context?: HttpContext): Observable<AddressValidationResponse> {
    return this.validateUsAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressValidationResponse>): AddressValidationResponse => r.body)
    );
  }

  /** Path part for operation `autocompleteUsAddress()` */
  static readonly AutocompleteUsAddressPath = '/address-validation/autocomplete-us';

  /**
   * Returns US address suggestions based on the provided address inputs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `autocompleteUsAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autocompleteUsAddress$Response(params: AutocompleteUsAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressAutocompleteResponse>>> {
    return autocompleteUsAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns US address suggestions based on the provided address inputs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `autocompleteUsAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autocompleteUsAddress(params: AutocompleteUsAddress$Params, context?: HttpContext): Observable<Array<AddressAutocompleteResponse>> {
    return this.autocompleteUsAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressAutocompleteResponse>>): Array<AddressAutocompleteResponse> => r.body)
    );
  }

}
