/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BundleSearch } from '../models/bundle-search';
import { checkForDummySearch } from '../fn/search-controller/check-for-dummy-search';
import { CheckForDummySearch$Params } from '../fn/search-controller/check-for-dummy-search';
import { clearSearches } from '../fn/search-controller/clear-searches';
import { ClearSearches$Params } from '../fn/search-controller/clear-searches';
import { clearSearches1 } from '../fn/search-controller/clear-searches-1';
import { ClearSearches1$Params } from '../fn/search-controller/clear-searches-1';
import { getAvSearchSources } from '../fn/search-controller/get-av-search-sources';
import { GetAvSearchSources$Params } from '../fn/search-controller/get-av-search-sources';
import { getBundleSearch } from '../fn/search-controller/get-bundle-search';
import { GetBundleSearch$Params } from '../fn/search-controller/get-bundle-search';
import { getLatestSearch } from '../fn/search-controller/get-latest-search';
import { GetLatestSearch$Params } from '../fn/search-controller/get-latest-search';
import { getLatestSearchGuidFromBundleSearch } from '../fn/search-controller/get-latest-search-guid-from-bundle-search';
import { GetLatestSearchGuidFromBundleSearch$Params } from '../fn/search-controller/get-latest-search-guid-from-bundle-search';
import { getSourceTypeSearch } from '../fn/search-controller/get-source-type-search';
import { GetSourceTypeSearch$Params } from '../fn/search-controller/get-source-type-search';
import { reRunSearch } from '../fn/search-controller/re-run-search';
import { ReRunSearch$Params } from '../fn/search-controller/re-run-search';
import { retrieveSearches } from '../fn/search-controller/retrieve-searches';
import { RetrieveSearches$Params } from '../fn/search-controller/retrieve-searches';
import { runSearch } from '../fn/search-controller/run-search';
import { RunSearch$Params } from '../fn/search-controller/run-search';
import { runSourceTypeSearch } from '../fn/search-controller/run-source-type-search';
import { RunSourceTypeSearch$Params } from '../fn/search-controller/run-source-type-search';
import { Search } from '../models/search';
import { SourceTypeSearch } from '../models/source-type-search';
import { updateSearchMaintenance } from '../fn/search-controller/update-search-maintenance';
import { UpdateSearchMaintenance$Params } from '../fn/search-controller/update-search-maintenance';

@Injectable({ providedIn: 'root' })
export class SearchControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateSearchMaintenance()` */
  static readonly UpdateSearchMaintenancePath = '/searches/parameterized/maintenance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSearchMaintenance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSearchMaintenance$Response(params: UpdateSearchMaintenance$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSearchMaintenance(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSearchMaintenance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSearchMaintenance(params: UpdateSearchMaintenance$Params, context?: HttpContext): Observable<void> {
    return this.updateSearchMaintenance$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `retrieveSearches()` */
  static readonly RetrieveSearchesPath = '/searches/parameterized';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveSearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveSearches$Response(params: RetrieveSearches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BundleSearch>>> {
    return retrieveSearches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieveSearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveSearches(params: RetrieveSearches$Params, context?: HttpContext): Observable<Array<BundleSearch>> {
    return this.retrieveSearches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BundleSearch>>): Array<BundleSearch> => r.body)
    );
  }

  /** Path part for operation `runSearch()` */
  static readonly RunSearchPath = '/searches/parameterized';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch$Response(params: RunSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return runSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `runSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSearch(params: RunSearch$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.runSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `clearSearches()` */
  static readonly ClearSearchesPath = '/searches/parameterized/{searchGuid}/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearSearches()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearSearches$Response(params: ClearSearches$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clearSearches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearSearches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clearSearches(params: ClearSearches$Params, context?: HttpContext): Observable<void> {
    return this.clearSearches$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLatestSearch()` */
  static readonly GetLatestSearchPath = '/searches/parameterized/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearch$Response(params: GetLatestSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BundleSearch>>> {
    return getLatestSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearch(params: GetLatestSearch$Params, context?: HttpContext): Observable<Array<BundleSearch>> {
    return this.getLatestSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BundleSearch>>): Array<BundleSearch> => r.body)
    );
  }

  /** Path part for operation `reRunSearch()` */
  static readonly ReRunSearchPath = '/searches/parameterized/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reRunSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reRunSearch$Response(params: ReRunSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return reRunSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reRunSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reRunSearch(params: ReRunSearch$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.reRunSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `runSourceTypeSearch()` */
  static readonly RunSourceTypeSearchPath = '/searches/parameterized/latest/source-type-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runSourceTypeSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSourceTypeSearch$Response(params: RunSourceTypeSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return runSourceTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `runSourceTypeSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runSourceTypeSearch(params: RunSourceTypeSearch$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.runSourceTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `clearSearches1()` */
  static readonly ClearSearches1Path = '/searches/parameterized/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearSearches1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearSearches1$Response(params: ClearSearches1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clearSearches1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearSearches1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clearSearches1(params: ClearSearches1$Params, context?: HttpContext): Observable<void> {
    return this.clearSearches1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getBundleSearch()` */
  static readonly GetBundleSearchPath = '/searches/parameterized/{searchBundleGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundleSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundleSearch$Response(params: GetBundleSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<BundleSearch>> {
    return getBundleSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBundleSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundleSearch(params: GetBundleSearch$Params, context?: HttpContext): Observable<BundleSearch> {
    return this.getBundleSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<BundleSearch>): BundleSearch => r.body)
    );
  }

  /** Path part for operation `checkForDummySearch()` */
  static readonly CheckForDummySearchPath = '/searches/parameterized/status/{searchBundleGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForDummySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForDummySearch$Response(params: CheckForDummySearch$Params, context?: HttpContext): Observable<StrictHttpResponse<Search>> {
    return checkForDummySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkForDummySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForDummySearch(params: CheckForDummySearch$Params, context?: HttpContext): Observable<Search> {
    return this.checkForDummySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Search>): Search => r.body)
    );
  }

  /** Path part for operation `getAvSearchSources()` */
  static readonly GetAvSearchSourcesPath = '/searches/parameterized/sources/{searchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvSearchSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvSearchSources$Response(params: GetAvSearchSources$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getAvSearchSources(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvSearchSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvSearchSources(params: GetAvSearchSources$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getAvSearchSources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getSourceTypeSearch()` */
  static readonly GetSourceTypeSearchPath = '/searches/parameterized/source-type-search/{searchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSourceTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceTypeSearch$Response(params: GetSourceTypeSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<SourceTypeSearch>> {
    return getSourceTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSourceTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSourceTypeSearch(params: GetSourceTypeSearch$Params, context?: HttpContext): Observable<SourceTypeSearch> {
    return this.getSourceTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<SourceTypeSearch>): SourceTypeSearch => r.body)
    );
  }

  /** Path part for operation `getLatestSearchGuidFromBundleSearch()` */
  static readonly GetLatestSearchGuidFromBundleSearchPath = '/searches/parameterized/bundle-search/{bundleSearchGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestSearchGuidFromBundleSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearchGuidFromBundleSearch$Response(params: GetLatestSearchGuidFromBundleSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getLatestSearchGuidFromBundleSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestSearchGuidFromBundleSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSearchGuidFromBundleSearch(params: GetLatestSearchGuidFromBundleSearch$Params, context?: HttpContext): Observable<string> {
    return this.getLatestSearchGuidFromBundleSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
