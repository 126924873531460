/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CodeDescriptionDto } from '../models/code-description-dto';
import { donorMatchGrades } from '../fn/nomenclatures-controller/donor-match-grades';
import { DonorMatchGrades$Params } from '../fn/nomenclatures-controller/donor-match-grades';
import { hlaMismatches } from '../fn/nomenclatures-controller/hla-mismatches';
import { HlaMismatches$Params } from '../fn/nomenclatures-controller/hla-mismatches';
import { labResultReports } from '../fn/nomenclatures-controller/lab-result-reports';
import { LabResultReports$Params } from '../fn/nomenclatures-controller/lab-result-reports';
import { matchCategoryOptions } from '../fn/nomenclatures-controller/match-category-options';
import { MatchCategoryOptions$Params } from '../fn/nomenclatures-controller/match-category-options';
import { otherSourceTypes } from '../fn/nomenclatures-controller/other-source-types';
import { OtherSourceTypes$Params } from '../fn/nomenclatures-controller/other-source-types';
import { postponeCancelReasons } from '../fn/nomenclatures-controller/postpone-cancel-reasons';
import { PostponeCancelReasons$Params } from '../fn/nomenclatures-controller/postpone-cancel-reasons';
import { postponeCancelReasonsByType } from '../fn/nomenclatures-controller/postpone-cancel-reasons-by-type';
import { PostponeCancelReasonsByType$Params } from '../fn/nomenclatures-controller/postpone-cancel-reasons-by-type';
import { requestStatuses } from '../fn/nomenclatures-controller/request-statuses';
import { RequestStatuses$Params } from '../fn/nomenclatures-controller/request-statuses';
import { suggestedTypings } from '../fn/nomenclatures-controller/suggested-typings';
import { SuggestedTypings$Params } from '../fn/nomenclatures-controller/suggested-typings';
import { treatmentSecReasons } from '../fn/nomenclatures-controller/treatment-sec-reasons';
import { TreatmentSecReasons$Params } from '../fn/nomenclatures-controller/treatment-sec-reasons';
import { treatmentSubReasons } from '../fn/nomenclatures-controller/treatment-sub-reasons';
import { TreatmentSubReasons$Params } from '../fn/nomenclatures-controller/treatment-sub-reasons';
import { treatmentSubReasonsByType } from '../fn/nomenclatures-controller/treatment-sub-reasons-by-type';
import { TreatmentSubReasonsByType$Params } from '../fn/nomenclatures-controller/treatment-sub-reasons-by-type';

@Injectable({ providedIn: 'root' })
export class NomenclaturesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `treatmentSubReasons()` */
  static readonly TreatmentSubReasonsPath = '/nomenclatures/treatment-sub-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSubReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasons$Response(params?: TreatmentSubReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return treatmentSubReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `treatmentSubReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasons(params?: TreatmentSubReasons$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.treatmentSubReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `treatmentSubReasonsByType()` */
  static readonly TreatmentSubReasonsByTypePath = '/nomenclatures/treatment-sub-reasons/{sourceType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSubReasonsByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasonsByType$Response(params: TreatmentSubReasonsByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return treatmentSubReasonsByType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `treatmentSubReasonsByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasonsByType(params: TreatmentSubReasonsByType$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.treatmentSubReasonsByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `treatmentSecReasons()` */
  static readonly TreatmentSecReasonsPath = '/nomenclatures/treatment-sec-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSecReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSecReasons$Response(params?: TreatmentSecReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return treatmentSecReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `treatmentSecReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSecReasons(params?: TreatmentSecReasons$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.treatmentSecReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `suggestedTypings()` */
  static readonly SuggestedTypingsPath = '/nomenclatures/suggested-typings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestedTypings()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedTypings$Response(params?: SuggestedTypings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return suggestedTypings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suggestedTypings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedTypings(params?: SuggestedTypings$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.suggestedTypings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `requestStatuses()` */
  static readonly RequestStatusesPath = '/nomenclatures/request-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestStatuses$Response(params?: RequestStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return requestStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestStatuses(params?: RequestStatuses$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.requestStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `postponeCancelReasons()` */
  static readonly PostponeCancelReasonsPath = '/nomenclatures/postpone-cancel-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postponeCancelReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasons$Response(params?: PostponeCancelReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return postponeCancelReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postponeCancelReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasons(params?: PostponeCancelReasons$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.postponeCancelReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `postponeCancelReasonsByType()` */
  static readonly PostponeCancelReasonsByTypePath = '/nomenclatures/postpone-cancel-reasons/{sourceType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postponeCancelReasonsByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasonsByType$Response(params: PostponeCancelReasonsByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return postponeCancelReasonsByType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postponeCancelReasonsByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasonsByType(params: PostponeCancelReasonsByType$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.postponeCancelReasonsByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `otherSourceTypes()` */
  static readonly OtherSourceTypesPath = '/nomenclatures/other-sources-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otherSourceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  otherSourceTypes$Response(params?: OtherSourceTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return otherSourceTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otherSourceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otherSourceTypes(params?: OtherSourceTypes$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.otherSourceTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `matchCategoryOptions()` */
  static readonly MatchCategoryOptionsPath = '/nomenclatures/match-category-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchCategoryOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchCategoryOptions$Response(params?: MatchCategoryOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return matchCategoryOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchCategoryOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchCategoryOptions(params?: MatchCategoryOptions$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.matchCategoryOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `labResultReports()` */
  static readonly LabResultReportsPath = '/nomenclatures/lab-result-reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labResultReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  labResultReports$Response(params?: LabResultReports$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return labResultReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labResultReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labResultReports(params?: LabResultReports$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.labResultReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `hlaMismatches()` */
  static readonly HlaMismatchesPath = '/nomenclatures/hla-mismatches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hlaMismatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  hlaMismatches$Response(params?: HlaMismatches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return hlaMismatches(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hlaMismatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hlaMismatches(params?: HlaMismatches$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.hlaMismatches$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

  /** Path part for operation `donorMatchGrades()` */
  static readonly DonorMatchGradesPath = '/nomenclatures/donor-match-grades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `donorMatchGrades()` instead.
   *
   * This method doesn't expect any request body.
   */
  donorMatchGrades$Response(params?: DonorMatchGrades$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {
    return donorMatchGrades(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `donorMatchGrades$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  donorMatchGrades(params?: DonorMatchGrades$Params, context?: HttpContext): Observable<Array<CodeDescriptionDto>> {
    return this.donorMatchGrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>): Array<CodeDescriptionDto> => r.body)
    );
  }

}
