import {CordPotentialList} from '@matchsource/api-generated/search-match';
import {HlaHistoryLocusModel, HlaLocusModel, HlaModel, DonorMatching} from '@matchsource/models/hla';
import {DocumentDetails} from '@matchsource/api-generated/documents';
import {AltShippingAddress, ShareSearchSummaryReport, TypingResult} from '@matchsource/api-generated/subject';
import {Sex} from '@matchsource/models/sex';

export enum AdvancedPatientLookupFilterTypes {
  PatientStatus = 'PATIENT_STATUS',
  PrlmFrom = 'PRLM_DATE_FROM',
  PrlmTo = 'PRLM_DATE_TO',
  FrmlFrom = 'FRML_DATE_FROM',
  FrmlTo = 'FRML_DATE_TO',
  Tc = 'TC',
  TransferFrom = 'TRANSFER_COMPLETE_DTE_FROM',
  TransferTo = 'TRANSFER_COMPLETE_DTE_TO',
}

export const enum PatientStatusShortName {
  Preliminary = 'PRLM',
  Formal = 'FRML',
  Closed = 'CLOSED',
}

export const enum PatientStatus {
  InProgress = '9000',

  PreliminarySearch = '9002',

  FormalSearch = '9003',

  Closed = '9004',
}

export interface PatientStatusModel {
  code: PatientStatus;
  description: string;
}

export interface HlaTodayReportModel {
  uid: MsApp.Guid;
  generated?: boolean;
  searchGuid: MsApp.Guid;
  recipientGuid: MsApp.Guid;
  note: string;
  published: boolean;
  reportGeneratedDate: MsApp.DateTimeString;
  searchCompleteDate: MsApp.DateTimeString;
  noteUpdateDate: MsApp.DateTimeString;
  nmdpDonors?: {
    counts8of8?: number;
    counts7of8?: number;
    counts8of8Text: string;
    counts7of8Text: string;
  };
  wmdaDonors?: {
    counts8of8?: number;
    counts7of8?: number;
    counts8of8Text: string;
    counts7of8Text: string;
  };
}

export interface HlaTodayReportCountsModel {
  nmdpCords: NmdpCordsModel;
}

export interface NmdpCordsModel extends CordPotentialList {
  singleMatchCategoryText: string;
  doubleMatchCategoryText: string;
}

export type HlaTodayReportWithCountsModel = HlaTodayReportModel & HlaTodayReportCountsModel;

export interface HlaTodayReportModel {
  uid: MsApp.Guid;
  generated?: boolean;
  searchGuid: MsApp.Guid;
  recipientGuid: MsApp.Guid;
  note: string;
  reportGeneratedDate: MsApp.DateTimeString;
  searchCompleteDate: MsApp.DateTimeString;
  noteUpdateDate: MsApp.DateTimeString;
  sourceTypes: string[];
}

export interface PatientUniqueDataSet {
  firstName: string;
  lastName: string;
  birthDate?: MsApp.DateString;
  id?: MsApp.Guid;
  localId?: MsApp.Guid;
  refId?: MsApp.Guid;
  patientProcess?: string;
  tcId?: MsApp.Guid;
}

export interface TransferHistoryModel {
  fromTcBpGuid?: MsApp.Guid;
  toTcBpGuid?: MsApp.Guid;
  transferCompleteDate?: MsApp.DateTimeString;
  transferRequestDate?: MsApp.DateTimeString;
}

export interface IdentityHistoryModel {
  status?: string;
  confirmed?: boolean;
  confirmedDate?: MsApp.DateString;
  tcGuid?: MsApp.Guid;
  confirmedByFullName?: string;
}

export interface RecipientCloseModel {
  tccStatusReason?: string;
  tccStatusReasonDesc?: string;
  otherReason?: string;
  matchPreference?: string;
}

export type PatientStatusLiteral = `${PatientStatus}`;
export type DonorThresholdLiteral = `${DonorThreshold}`;
export type BdpThresholdLiteral = `${BdpThreshold}`;

export interface SearchCriteriaModel {
  donors: boolean;
  cords: boolean;
  bdps: boolean;
  donorsMatchThreshold: DonorThresholdLiteral | null;
  bdpsMatchThreshold?: BdpThresholdLiteral | null;
  deprioritizeSearch?: boolean;
}

export interface LigandRequestModel {
  gros: GroModel[];
}

export interface GroModel {
  groNames: string[];
  longLocusName: string;
}

export interface PatientModel {
  allogeneicCode: boolean;
  id?: MsApp.Guid;
  status?: PatientStatusLiteral;
  recipientClose?: RecipientCloseModel;
  tcId: MsApp.Guid;
  copySsrToBp?: MsApp.Guid;
  otherTcName?: string;
  otherTcEmail?: string;
  shareSsrHistory: ShareSearchSummaryReport[];
  patientProcess?: string;
  localId: string;
  secondaryCode?: string;
  coordinatorId?: number;
  tcCoordinatorName?: string;
  physician?: string;
  refPhysician?: string;
  physicianFirstName: string;
  physicianLastName: string;
  refPhysicianEmail: string;
  refPhysicianFirstName: string;
  refPhysicianLastName: string;
  sameAsPhysician: boolean;
  refId?: string;
  kitId?: string;
  createdById?: MsApp.Guid;
  firstName: string;
  middleInitialName?: string;
  lastName: string;
  sex: Sex;
  unbornPatient?: boolean;
  expectedDeliveryDate?: MsApp.DateString;
  birthDate?: MsApp.DateString;

  birthDateRaw?: MsApp.DateString;
  weight?: number;
  bloodTypeCode?: string;
  rhType?: string;
  cmvStatusCode?: string;
  raceCodes: string[];
  ethnicityCode: string;
  countryCode: string;
  languageCode?: string;
  otherLanguage?: string;
  primaryAddress?: string;
  secondaryAddress?: string;
  city?: string;
  state?: string;
  zipPostalCode?: string;
  phoneNum?: string;
  email?: string;
  careOf?: string;
  diagnosisCode: string;
  otherDisease?: string;
  secondaryDiagnosis?: string;
  diseaseStage?: string;
  remissionsCount?: number;
  diagnosisDate: MsApp.DateString;
  transplantTimelineCode: string;
  transplantTimelineUpdated?: boolean;
  preferredProductCode?: string;
  preliminarySearchEntryDate?: MsApp.DateString;
  formalizationDate?: MsApp.DateString;
  reactivationDate?: MsApp.DateString;
  caseManagerId?: number;
  caseManagerLastName?: string;
  caseManagerFirstName?: string;
  caseManagerEmail?: string;
  phenotypeCreationDates?: {
    [key: string]: string;
  };
  nmdpId?: MsApp.Guid;
  phenotype?: PatientHlaModel;
  phenotypeIds?: number[];
  isInProgress: boolean;
  isSubmitted: boolean;
  hasPhone: string;
  searchCriteria: SearchCriteriaModel;
  domesticSecondaryTc?: boolean;
  labTypingVerified?: boolean;
  labTypingReceived?: boolean;
  identityVerifiedInd?: boolean;
  crid?: string;
  readyForAutoSubmission?: boolean;
  hasSwabKit?: boolean;
  waitingForTyping: boolean;
  cpTransferred?: boolean;
  genderIdentityCode?: string;
  genderSelfIdentityText?: string;
  shipToPatientInd?: boolean;
  altShippingAddress?: AltShippingAddress;
  returnTrackingNumber?: string;
  chainOfIdentity: string;
  domesticDemographicsInvalid: boolean | null;
}

export interface CaseResourceInfo {
  emailId: string;
  firstName: string;
  lastName: string;
  roleCode?: string;
  id: number;
}

export interface CloseCaseModel {
  caseResourceInfo: CaseResourceInfo;
  closeReasonCde: string;
  matchPreferenceCde: string;
  otherReason: string;
}

export interface CloseCaseHistoryModel {
  closedDate?: MsApp.DateString;
  createdDate?: MsApp.DateString;
  reasonForClose?: string;
}

export interface PatientSearchParams {
  searchTerm?: string;
  page: number;
  size: number;
  status?: any;
  sortField: string;
  sortDir: 'asc' | 'desc';
  guids?: MsApp.Guid[];
  ignoreCoreFailed: boolean;
  tcId: MsApp.Guid;
  waitingForTyping: boolean;
  isHlaTodayOnly?: boolean;
}

export interface ManualResolvePayload {
  duplicate: boolean;
  duplicateRecipientGuid: string;
  originalRecipientGuid: string;
  transferPatient: boolean;
}

export interface PatientHlaLocusModel {
  name: string;
  type1: string;
  type1Formatted?: string;
  type2: string;
  type2Formatted?: string;
  glstringInd: boolean;
  glstring?: string;
}

export interface PatientHlaModel {
  index: number;
  loci: PatientHlaLocusModel[];
}

export interface PatientHlaLocusValidityModel {
  locus: string;
  typeNumber: number;
  typeValue: string;
  typing: string;
  validity: boolean;
}

export type AdvancedPatientFilterValueModel =
  | string
  | {localDate: MsApp.DateTimeString; timeZoneOffSetInMinutes: number};

export interface AdvancedPatientFilterModel {
  type: AdvancedPatientLookupFilterTypes;
  value: AdvancedPatientFilterValueModel;
}

export type AdvancedPatientFilterDTO = AdvancedPatientFilterModel;

export interface HlaTodayReportCountsDTO {
  nmdpDonors?: {
    counts8of8?: number;
    counts7of8?: number;
  };
  wmdaDonors?: {
    counts8of8?: number;
    counts7of8?: number;
  };
  nmdpCords?: CordPotentialList;
}

export interface SsaDefaultSettingsFormDTO {
  donorMatchInd: boolean;
  donorMatchCriteria: string[];
  donorNote: string;
  cordMatchInd: boolean;
  singleCordInd: boolean;
  singleCordTnc: number;
  singleCordCd34: number;
  doubleCordInd: boolean;
  doubleCordTnc: number;
  doubleCordCd34: number;
  cordNote: string;
  notifyCaseResourceIds: number[];
  includeNextBestMatch?: boolean;
}

export type SsaDefaultSettingsFormModel = SsaDefaultSettingsFormDTO;

export interface PatientUniqueDataSetDTO {
  firstName: string;
  lastName: string;
  dateOfBirth: MsApp.DateString;
  recipientGuid?: MsApp.Guid;
  localId?: MsApp.Guid;
  refId?: MsApp.Guid;
  processCode?: string;
  tcId?: MsApp.Guid;
}

export interface TransplantTimelineHistoryModel {
  code: string;
  date: MsApp.DateTimeString;
  updatedBy: string;
}

export interface PatientDuplicatesModel {
  duplicatesAmongFrmlOrClosed: number;
  duplicatesAmongHla: number;
  duplicatesAmongPrlm: number;
  duplicatesByLocalId: number;
  duplicatesByRefId: number;
}

export interface PatientTransferInfoModel {
  fromBpGuid: MsApp.Guid;
  toBpGuid: MsApp.Guid;
  formReceivedStatus: 'Y' | 'N';
  recipientNameChecked: boolean;
  coordinatorId?: number;
}

export interface SsaRequestDTO {
  requestGuid?: MsApp.Guid;
  labReportCode: string;
  donorMatchInd: boolean;
  donorMatchCriteria?: string[];
  donorNoteId?: number;
  cordMatchInd: boolean;
  singleCordInd?: boolean;
  singleCordTnc?: number;
  singleCordCd34?: number;
  doubleCordInd?: boolean;
  doubleCordTnc?: number;
  doubleCordCd34?: number;
  patientWeight?: number;
  cordNoteId?: number;
  reqNoteId?: number;
  donorNote?: string;
  cordNote?: string;
  requestNote?: string;
  requestedDate?: MsApp.DateString;
  status?: string;
  cancelDate?: MsApp.DateString;
  oplId?: MsApp.Guid;
  requestedBy?: string;
  editedBy: string;
  editedDate: MsApp.DateString;
  ssaDocuments: DocumentDetails[];
  idsToDelete?: (number | MsApp.Guid)[];
}

export interface PatientFormHlaModel {
  index: number;
  loci: HlaModel[];
}

export interface PatientDuplicateStatusInfoModel {
  nmdpId: string;
  status: string;
  tcId: string;
  identification: string;
  hlaLoci?: MsApp.Dictionary<HlaHistoryLocusModel>;
}

export interface PatientPartiallyUpdatedData {
  isVerified?: boolean;

  chainOfIdentity?: string;
}

export enum DonorThreshold {
  FourOfEight = 'FOUR_OF_EIGHT',

  SixOfEight = 'SIX_OF_EIGHT',

  SevenOfEight = 'SEVEN_OF_EIGHT',

  EightOfEight = 'EIGHT_OF_EIGHT',
}

export enum CordThreshold {
  FourOfSix = 'FOUR_OF_SIX',
}

export enum BdpThreshold {
  FourOfEight = 'FOUR_OF_EIGHT',
}

export interface DiagnosisModel {
  diagnosisCode: string | null;
  otherDiagnosis: string;
  secondaryDiagnosis: string;
  diseaseStage: string | null;
  numOfRemissions: number;
  diagnosisDate: string;
  transplantTimelineCode: string | null;
  transplantTimelineUpdated: boolean;
  preferredProductCode: string | null;
}
export interface TCInfoModel {
  tcId: string | null;
  localId: string;
  refId: string | number;
  secondaryCode: string;
  isDomesticSecondaryTc: boolean;
  coordinatorId: string | number;
  physician: string;
  refPhysician: string;
  sameAsPhysician: boolean;
  createdById: string;
  legacyCoordinator: string;
  patientProcess: string;
}

export interface PatientInfoModel {
  firstName: string;
  middleInitialName: string;
  lastName: string;
  fullNameAlt: string;
  sex: string | null;
  unborn: boolean;
  expectedDelivery: string;
  birthDate: string;
  birthDateFormatted: string;
  age: number | string;
  weight: number;
  bloodTypeCode: string | null;
  abo: string | null;
  rhType: string | null;
  cmvStatusCode: string | null;
  raceCodes: string[];
  ethnicityCode: string | null;
  transplantTimelineHistoryCode: string;
  transplantTimelineHistoryDate: string | null;
  genderIdentityCode: string;
  genderSelfIdentityText: string;
}

export enum RelatedFamilyMembersModelControlName {
  FirstName = 'firstName',
  LastName = 'lastName',
  RelationshipToPatient = 'relationshipToPatient',
  BirthDate = 'dateOfBirth',
  SwabKitRequested = 'needSwabKitShipped',
  RelativesPrimaryAddress = 'addressLine1',
  RelativesSecondaryAddress = 'addressLine2',
  RelativesCity = 'city',
  RelativesState = 'stateCode',
  RelativesZipPostalCode = 'zipCode',
  RelativesEmail = 'email',
  RelativesPhone = 'phoneNumber',
  KitStatus = 'kitStatus',
  DateLabReceivedKit = 'deliveredDate',
}

export enum Relationship {
  Parent = 'PARENT',
  Sibling = 'SIBLING',
  Child = 'CHILD',
  HalfSibling = 'HALF_SIBLING',
}

export enum KitStatus {
  Pending = 'PENDING',
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  Submitted = 'SUBMITTED',
  Shipped = 'SHIPPED',
  Closed = 'CLOSED',
  Delayed = 'DELAYED',
}

export interface RelatedFamilyMembersDTO {
  [RelatedFamilyMembersModelControlName.FirstName]?: string;
  [RelatedFamilyMembersModelControlName.LastName]?: string;
  [RelatedFamilyMembersModelControlName.RelationshipToPatient]?: Relationship;
  [RelatedFamilyMembersModelControlName.BirthDate]?: MsApp.DateString;
  [RelatedFamilyMembersModelControlName.SwabKitRequested]?: boolean;
  [RelatedFamilyMembersModelControlName.RelativesPrimaryAddress]?: string;
  [RelatedFamilyMembersModelControlName.RelativesSecondaryAddress]?: string;
  [RelatedFamilyMembersModelControlName.RelativesCity]?: string;
  [RelatedFamilyMembersModelControlName.RelativesState]?: string;
  [RelatedFamilyMembersModelControlName.RelativesZipPostalCode]?: string;
  [RelatedFamilyMembersModelControlName.RelativesEmail]?: string;
  [RelatedFamilyMembersModelControlName.RelativesPhone]?: string;
  [RelatedFamilyMembersModelControlName.KitStatus]?: KitStatus;
  [RelatedFamilyMembersModelControlName.DateLabReceivedKit]?: MsApp.DateString;
}

export interface ShippingAddressModel {
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  state: string;
  zipPostalCode: string;
  email: string;
  phone: string;
}

export interface RelatedFamilyDonorDTOModel {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  dateOfBirth: string;
  email?: string;
  familyMemberKitId?: string;
  firstName: string;
  lastName: string;
  needSwabKitShipped?: boolean;
  phoneNumber?: string;
  relationshipToPatient: Relationship;
  stateCode?: string;
  zipCode?: string;
}

export interface RelatedFamilyMemberDetailsModel {
  relatedFamilyMemberDetails: RelatedFamilyDonorDTOModel[];
}

export interface RelatedFamilyMembersModel {
  name: string;
  relationship: Relationship;
  birthDate: MsApp.DateString;
  swabKitRequested: boolean;
  shippingAddress?: ShippingAddressModel;
  kitStatus: KitStatus;
  deliveredDate: MsApp.DateString;
  id?: string;
}

export enum RelatedFamilyMembersTypingsModelControlName {
  Guid = 'donorGuid',
  FullName = 'fullName',
  RelationshipToPatient = 'relationship',
  Age = 'age',
  MCat = 'matCat',
  LikelyMatchLevel = 'likelyMatchLevel',
  TypingResults = 'typingResults',
  Comments = 'comments',
  DonorStatus = 'donorStatus',
}

export enum LikelyMatchLevel {
  HaploidenticalMatch = 'HAPLOIDENTICAL_MATCH',
  NoMatch = 'NO_MATCH',
  FullMatch = 'FULL_MATCH',
}

export interface LikelyMatchLevelListItem {
  code: LikelyMatchLevel | null;
  description: string;
}

export enum DonorStatus {
  New = 'New',
  PotentialDuplicate = 'Potential Duplicate',
  Available = 'Available',
}

export interface RelatedFamilyMembersTypingsDTO {
  [RelatedFamilyMembersTypingsModelControlName.Guid]?: MsApp.Guid;
  [RelatedFamilyMembersTypingsModelControlName.FullName]?: string;
  [RelatedFamilyMembersTypingsModelControlName.RelationshipToPatient]?: string;
  [RelatedFamilyMembersTypingsModelControlName.Age]?: number;
  [RelatedFamilyMembersTypingsModelControlName.MCat]?: string;
  [RelatedFamilyMembersTypingsModelControlName.LikelyMatchLevel]?: string;
  [RelatedFamilyMembersTypingsModelControlName.TypingResults]?: {
    [key: string]: TypingResult;
  };
  [RelatedFamilyMembersTypingsModelControlName.Comments]?: string;
  [RelatedFamilyMembersTypingsModelControlName.DonorStatus]?: string;
}

export interface RelatedFamilyMembersTypingModel {
  guid: MsApp.Guid;
  fullName: string;
  relationship: Relationship;
  age: number;
  mCat: string;
  likelyMatchLevel: LikelyMatchLevel;
  comments: string;
  typingResults: MsApp.Dictionary<HlaLocusModel>;
  matching?: DonorMatching;
  isAvailable: boolean;
}

export interface RFMTypingUpdateModel {
  formData: {[key: string]: string};
  updatedControls: string[];
}

export interface RFMTypingPartiallyModel {
  donorGuid: MsApp.Guid;
  donorMatchLevel: LikelyMatchLevel;
  comments: string;
}

export enum HlaTodayReportCountsMatchCategory {
  Zero = 'ZERO',
  OneToFive = 'ONETOFIVE',
  Five = 'FIVE',
  Ten = 'TEN',
  TwentyFive = 'TWENTYFIVE',
  Fifty = 'FIFTY',
}

export type CloseCaseSimpleModel = {
  closeReasonCde: string;
  matchPreferenceCde: string;
  otherReason: string;
};

export interface PhenotypeCreationDates {
  index: number;
  entryDate: MsApp.DateString;
}

export interface PatientDemographicsModel {
  tcId: MsApp.Guid;
  copySsrToBp: MsApp.Guid;
  otherTcName?: string;
  otherTcEmail?: string;
  patientProcess: string;
  coordinatorId: number;
  physician: string;
  refPhysicianEmail: string;
  refPhysician: string;
  expectedDelivery: string;
  raceCodes: string[];
  ethnicityCode: string;
  countryCode: string;
  preferredLanguage: string;
  otherLanguage: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  state: string;
  zipPostalCode: string;
  phone: string;
  email: string;
  parentOrGuardian: string;
  diagnosisCode: string;
  otherDiagnosis: string;
  diseaseStage: string;
  numOfRemissions: number;
  secondaryDiagnosis: string;
  diagnosisDate: MsApp.DateString;
  transplantTimelineCode: string;
  preferredProduct: string;
  unborn: boolean;
  refId: MsApp.Guid;
  crid?: string;
  genderIdentityCode?: string;
  genderSelfIdentityText?: string;
  allogeneicCode: boolean;
  chainOfIdentity: string;
}

export interface PatientCaseInfoModel {
  preliminarySearchEntryDate: MsApp.DateString;
  formalizationDate: MsApp.DateString;
  reactivationDate: MsApp.DateString;
  caseManagerId: number;
  caseManagerLastName: string;
  caseManagerFirstName: string;
  phenotypeCreationDates: PhenotypeCreationDates[];
  preferredProductCode: string;
  transplantTimelineCode: string;
  caseManager: string | number;
  status: string;
  tcId: MsApp.Guid;
  identification: string;
  id: MsApp.Guid;
}

export interface RemissionsNumListModel {
  name: string;
  value: number;
}
