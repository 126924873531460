import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AnnouncementModel} from './announcement.model';
import {AnnouncementSerializerService} from './announcement-serializer.service';
import {map} from 'rxjs/operators';
import {AnnouncementControllerService} from '@matchsource/api-generated/common';
import {HttpContext} from '@angular/common/http';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {AnnouncementTypes} from '@matchsource/models/announcement';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementApiService {
  constructor(
    private readonly announcementControllerService: AnnouncementControllerService,
    private readonly serializer: AnnouncementSerializerService
  ) {}

  private static getBasicCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingAnnouncements);
  }

  save(model: AnnouncementModel): Observable<number | void> {
    if (model.isNew) {
      return this.create(model);
    }

    return this.update(model);
  }

  create(model: AnnouncementModel): Observable<number> {
    return this.announcementControllerService.createAnnouncement({body: this.serializer.toDTO(model)});
  }

  get(id: MsApp.Guid): Observable<AnnouncementModel> {
    return this.announcementControllerService
      .getAnnouncement({
        id: Number.parseInt(id, 10),
      })
      .pipe(map(input => this.serializer.fromDTO(input)));
  }

  update(model: AnnouncementModel): Observable<void> {
    return this.announcementControllerService.updateAnnouncement({
      id: model.id ? Number.parseInt(model.id, 10) : undefined,
      body: this.serializer.toDTO(model),
    });
  }

  getAllActive(): Observable<AnnouncementModel[]> {
    return this.announcementControllerService
      .getAllActiveAnnouncements({
        type: AnnouncementTypes.BANNER,
      })
      .pipe(map(data => data.map(input => this.serializer.fromDTO(input))));
  }

  getLatest(): Observable<AnnouncementModel> {
    const context = AnnouncementApiService.getBasicCustomErrorHandling();

    return this.announcementControllerService
      .getLatest(
        {
          type: AnnouncementTypes.BANNER,
        },
        context()
      )
      .pipe(map(input => (input ? this.serializer.fromDTO(input) : null)));
  }
}
