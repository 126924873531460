/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DefaultWorkup } from '../models/default-workup';
import { getWorkupFormCurrentVersion } from '../fn/workup-controller/get-workup-form-current-version';
import { GetWorkupFormCurrentVersion$Params } from '../fn/workup-controller/get-workup-form-current-version';
import { loadDefaultWorkupAnswer } from '../fn/workup-controller/load-default-workup-answer';
import { LoadDefaultWorkupAnswer$Params } from '../fn/workup-controller/load-default-workup-answer';
import { loadWorkupAnswer } from '../fn/workup-controller/load-workup-answer';
import { LoadWorkupAnswer$Params } from '../fn/workup-controller/load-workup-answer';
import { loadWorkupAnswerInProgress } from '../fn/workup-controller/load-workup-answer-in-progress';
import { LoadWorkupAnswerInProgress$Params } from '../fn/workup-controller/load-workup-answer-in-progress';
import { loadWorkupAnswerInProgressById } from '../fn/workup-controller/load-workup-answer-in-progress-by-id';
import { LoadWorkupAnswerInProgressById$Params } from '../fn/workup-controller/load-workup-answer-in-progress-by-id';
import { loadWorkupFormStructure } from '../fn/workup-controller/load-workup-form-structure';
import { LoadWorkupFormStructure$Params } from '../fn/workup-controller/load-workup-form-structure';
import { loadWorkupFormStructure1 } from '../fn/workup-controller/load-workup-form-structure-1';
import { LoadWorkupFormStructure1$Params } from '../fn/workup-controller/load-workup-form-structure-1';
import { publishCreateRecipientPrescription } from '../fn/workup-controller/publish-create-recipient-prescription';
import { PublishCreateRecipientPrescription$Params } from '../fn/workup-controller/publish-create-recipient-prescription';
import { removeWorkupAnswerInProgress } from '../fn/workup-controller/remove-workup-answer-in-progress';
import { RemoveWorkupAnswerInProgress$Params } from '../fn/workup-controller/remove-workup-answer-in-progress';
import { saveDefaultWorkupAnswer } from '../fn/workup-controller/save-default-workup-answer';
import { SaveDefaultWorkupAnswer$Params } from '../fn/workup-controller/save-default-workup-answer';
import { saveWorkupAnswer } from '../fn/workup-controller/save-workup-answer';
import { SaveWorkupAnswer$Params } from '../fn/workup-controller/save-workup-answer';
import { saveWorkupAnswerInProgress } from '../fn/workup-controller/save-workup-answer-in-progress';
import { SaveWorkupAnswerInProgress$Params } from '../fn/workup-controller/save-workup-answer-in-progress';
import { updateWorkupAnswerInProgress } from '../fn/workup-controller/update-workup-answer-in-progress';
import { UpdateWorkupAnswerInProgress$Params } from '../fn/workup-controller/update-workup-answer-in-progress';
import { WorkupAnswer } from '../models/workup-answer';
import { WorkupForm } from '../models/workup-form';
import { WorkupInProgressInfo } from '../models/workup-in-progress-info';

@Injectable({ providedIn: 'root' })
export class WorkupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadWorkupAnswerInProgressById()` */
  static readonly LoadWorkupAnswerInProgressByIdPath = '/workup/in-progress/answers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswerInProgressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgressById$Response(params: LoadWorkupAnswerInProgressById$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupAnswer>> {
    return loadWorkupAnswerInProgressById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswerInProgressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgressById(params: LoadWorkupAnswerInProgressById$Params, context?: HttpContext): Observable<WorkupAnswer> {
    return this.loadWorkupAnswerInProgressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>): WorkupAnswer => r.body)
    );
  }

  /** Path part for operation `updateWorkupAnswerInProgress()` */
  static readonly UpdateWorkupAnswerInProgressPath = '/workup/in-progress/answers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkupAnswerInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupAnswerInProgress$Response(params: UpdateWorkupAnswerInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWorkupAnswerInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkupAnswerInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupAnswerInProgress(params: UpdateWorkupAnswerInProgress$Params, context?: HttpContext): Observable<void> {
    return this.updateWorkupAnswerInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveWorkupAnswerInProgress()` */
  static readonly SaveWorkupAnswerInProgressPath = '/workup/in-progress/answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupAnswerInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswerInProgress$Response(params: SaveWorkupAnswerInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupInProgressInfo>> {
    return saveWorkupAnswerInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWorkupAnswerInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswerInProgress(params: SaveWorkupAnswerInProgress$Params, context?: HttpContext): Observable<WorkupInProgressInfo> {
    return this.saveWorkupAnswerInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupInProgressInfo>): WorkupInProgressInfo => r.body)
    );
  }

  /** Path part for operation `loadDefaultWorkupAnswer()` */
  static readonly LoadDefaultWorkupAnswerPath = '/workup/default/{bpGuid}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadDefaultWorkupAnswer()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDefaultWorkupAnswer$Response(params: LoadDefaultWorkupAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<DefaultWorkup>> {
    return loadDefaultWorkupAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadDefaultWorkupAnswer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadDefaultWorkupAnswer(params: LoadDefaultWorkupAnswer$Params, context?: HttpContext): Observable<DefaultWorkup> {
    return this.loadDefaultWorkupAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<DefaultWorkup>): DefaultWorkup => r.body)
    );
  }

  /** Path part for operation `saveDefaultWorkupAnswer()` */
  static readonly SaveDefaultWorkupAnswerPath = '/workup/default/{bpGuid}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDefaultWorkupAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDefaultWorkupAnswer$Response(params: SaveDefaultWorkupAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveDefaultWorkupAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveDefaultWorkupAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDefaultWorkupAnswer(params: SaveDefaultWorkupAnswer$Params, context?: HttpContext): Observable<void> {
    return this.saveDefaultWorkupAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadWorkupAnswer()` */
  static readonly LoadWorkupAnswerPath = '/workup/answers/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswer()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswer$Response(params: LoadWorkupAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupAnswer>> {
    return loadWorkupAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswer(params: LoadWorkupAnswer$Params, context?: HttpContext): Observable<WorkupAnswer> {
    return this.loadWorkupAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupAnswer>): WorkupAnswer => r.body)
    );
  }

  /** Path part for operation `saveWorkupAnswer()` */
  static readonly SaveWorkupAnswerPath = '/workup/answers/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswer$Response(params: SaveWorkupAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return saveWorkupAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWorkupAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupAnswer(params: SaveWorkupAnswer$Params, context?: HttpContext): Observable<number> {
    return this.saveWorkupAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getWorkupFormCurrentVersion()` */
  static readonly GetWorkupFormCurrentVersionPath = '/workup/{inquiryCode}/current-version';

  /**
   * returns the current version of a form based on inquiry code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkupFormCurrentVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupFormCurrentVersion$Response(params: GetWorkupFormCurrentVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getWorkupFormCurrentVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * returns the current version of a form based on inquiry code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkupFormCurrentVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupFormCurrentVersion(params: GetWorkupFormCurrentVersion$Params, context?: HttpContext): Observable<string> {
    return this.getWorkupFormCurrentVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `loadWorkupFormStructure()` */
  static readonly LoadWorkupFormStructurePath = '/workup/structure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupFormStructure()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  loadWorkupFormStructure$Response(params?: LoadWorkupFormStructure$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupForm>> {
    return loadWorkupFormStructure(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupFormStructure$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  loadWorkupFormStructure(params?: LoadWorkupFormStructure$Params, context?: HttpContext): Observable<WorkupForm> {
    return this.loadWorkupFormStructure$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupForm>): WorkupForm => r.body)
    );
  }

  /** Path part for operation `loadWorkupFormStructure1()` */
  static readonly LoadWorkupFormStructure1Path = '/workup/structure/{inquiryCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupFormStructure1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupFormStructure1$Response(params: LoadWorkupFormStructure1$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupForm>> {
    return loadWorkupFormStructure1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupFormStructure1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupFormStructure1(params: LoadWorkupFormStructure1$Params, context?: HttpContext): Observable<WorkupForm> {
    return this.loadWorkupFormStructure1$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupForm>): WorkupForm => r.body)
    );
  }

  /** Path part for operation `publishCreateRecipientPrescription()` */
  static readonly PublishCreateRecipientPrescriptionPath = '/workup/publish/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishCreateRecipientPrescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription$Response(params: PublishCreateRecipientPrescription$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishCreateRecipientPrescription(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishCreateRecipientPrescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription(params: PublishCreateRecipientPrescription$Params, context?: HttpContext): Observable<void> {
    return this.publishCreateRecipientPrescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadWorkupAnswerInProgress()` */
  static readonly LoadWorkupAnswerInProgressPath = '/workup/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupAnswerInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgress$Response(params: LoadWorkupAnswerInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkupInProgressInfo>>> {
    return loadWorkupAnswerInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupAnswerInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupAnswerInProgress(params: LoadWorkupAnswerInProgress$Params, context?: HttpContext): Observable<Array<WorkupInProgressInfo>> {
    return this.loadWorkupAnswerInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkupInProgressInfo>>): Array<WorkupInProgressInfo> => r.body)
    );
  }

  /** Path part for operation `removeWorkupAnswerInProgress()` */
  static readonly RemoveWorkupAnswerInProgressPath = '/workup/in-progress/answers/{workupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeWorkupAnswerInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupAnswerInProgress$Response(params: RemoveWorkupAnswerInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeWorkupAnswerInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeWorkupAnswerInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupAnswerInProgress(params: RemoveWorkupAnswerInProgress$Params, context?: HttpContext): Observable<void> {
    return this.removeWorkupAnswerInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
