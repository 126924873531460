/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageFormDue } from '../../models/page-form-due';

export interface LoadFormDue$Params {
  bpGuid: string;
  secondaryCode?: string;
  pastDue?: boolean;
  startDte?: string;
  endDte?: string;
  closedDte?: string;
  skipPagination?: boolean;
  manuallyClosed?: boolean;
  productType?: 'NMDP' | 'COOP' | 'BMDW' | 'BMDWD' | 'MBR' | 'UNKNOWN';
  recipientGuid?: string;

/**
 * Zero-based page index (0..N)
 */
  page?: number;

/**
 * The size of the page to be returned
 */
  size?: number;

/**
 * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
  sort?: Array<string>;
}

export function loadFormDue(http: HttpClient, rootUrl: string, params: LoadFormDue$Params, context?: HttpContext): Observable<StrictHttpResponse<PageFormDue>> {
  const rb = new RequestBuilder(rootUrl, loadFormDue.PATH, 'get');
  if (params) {
    rb.query('bpGuid', params.bpGuid, {});
    rb.query('secondaryCode', params.secondaryCode, {});
    rb.query('pastDue', params.pastDue, {});
    rb.query('startDte', params.startDte, {});
    rb.query('endDte', params.endDte, {});
    rb.query('closedDte', params.closedDte, {});
    rb.query('skipPagination', params.skipPagination, {});
    rb.query('manuallyClosed', params.manuallyClosed, {});
    rb.query('productType', params.productType, {});
    rb.query('recipientGuid', params.recipientGuid, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageFormDue>;
    })
  );
}

loadFormDue.PATH = '/form-due';
