/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSummaryCounts } from '../fn/summary-counts-controller/get-summary-counts';
import { GetSummaryCounts$Params } from '../fn/summary-counts-controller/get-summary-counts';
import { SummaryCounts } from '../models/summary-counts';

@Injectable({ providedIn: 'root' })
export class SummaryCountsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSummaryCounts()` */
  static readonly GetSummaryCountsPath = '/summary-counts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSummaryCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSummaryCounts$Response(params: GetSummaryCounts$Params, context?: HttpContext): Observable<StrictHttpResponse<SummaryCounts>> {
    return getSummaryCounts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSummaryCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSummaryCounts(params: GetSummaryCounts$Params, context?: HttpContext): Observable<SummaryCounts> {
    return this.getSummaryCounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<SummaryCounts>): SummaryCounts => r.body)
    );
  }

}
