/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { State } from '../../models/state';

export interface GetStates$Params {
  countryCode: string;
}

export function getStates(http: HttpClient, rootUrl: string, params: GetStates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<State>>> {
  const rb = new RequestBuilder(rootUrl, getStates.PATH, 'get');
  if (params) {
    rb.path('countryCode', params.countryCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<State>>;
    })
  );
}

getStates.PATH = '/geography/countries/{countryCode}/states';
