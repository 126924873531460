/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BannerAnnouncement } from '../models/banner-announcement';
import { createAnnouncement } from '../fn/announcement-controller/create-announcement';
import { CreateAnnouncement$Params } from '../fn/announcement-controller/create-announcement';
import { deleteAnnouncement } from '../fn/announcement-controller/delete-announcement';
import { DeleteAnnouncement$Params } from '../fn/announcement-controller/delete-announcement';
import { FeatureAnnouncement } from '../models/feature-announcement';
import { getAllActiveAnnouncements } from '../fn/announcement-controller/get-all-active-announcements';
import { GetAllActiveAnnouncements$Params } from '../fn/announcement-controller/get-all-active-announcements';
import { getAllAnnouncements } from '../fn/announcement-controller/get-all-announcements';
import { GetAllAnnouncements$Params } from '../fn/announcement-controller/get-all-announcements';
import { getAnnouncement } from '../fn/announcement-controller/get-announcement';
import { GetAnnouncement$Params } from '../fn/announcement-controller/get-announcement';
import { getLatest } from '../fn/announcement-controller/get-latest';
import { GetLatest$Params } from '../fn/announcement-controller/get-latest';
import { HeadlineAnnouncement } from '../models/headline-announcement';
import { ImportantDatesAnnouncement } from '../models/important-dates-announcement';
import { updateAnnouncement } from '../fn/announcement-controller/update-announcement';
import { UpdateAnnouncement$Params } from '../fn/announcement-controller/update-announcement';

@Injectable({ providedIn: 'root' })
export class AnnouncementControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAnnouncement()` */
  static readonly GetAnnouncementPath = '/announcements/{id}';

  /**
   * This method retrieves an announcement by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnnouncement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncement$Response(params: GetAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>> {
    return getAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves an announcement by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnnouncement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncement(params: GetAnnouncement$Params, context?: HttpContext): Observable<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)> {
    return this.getAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>): (BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement) => r.body)
    );
  }

  /** Path part for operation `updateAnnouncement()` */
  static readonly UpdateAnnouncementPath = '/announcements/{id}';

  /**
   * This method updates an announcement with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnnouncement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnouncement$Response(params: UpdateAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates an announcement with a given ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnnouncement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnouncement(params: UpdateAnnouncement$Params, context?: HttpContext): Observable<void> {
    return this.updateAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAnnouncement()` */
  static readonly DeleteAnnouncementPath = '/announcements/{id}';

  /**
   * This method deletes an announcement with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAnnouncement()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAnnouncement$Response(params: DeleteAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes an announcement with a given ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAnnouncement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAnnouncement(params: DeleteAnnouncement$Params, context?: HttpContext): Observable<void> {
    return this.deleteAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllAnnouncements()` */
  static readonly GetAllAnnouncementsPath = '/announcements';

  /**
   * This method retrieves all announcements of a given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAnnouncements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAnnouncements$Response(params: GetAllAnnouncements$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>>> {
    return getAllAnnouncements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all announcements of a given type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAnnouncements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAnnouncements(params: GetAllAnnouncements$Params, context?: HttpContext): Observable<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>> {
    return this.getAllAnnouncements$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>>): Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)> => r.body)
    );
  }

  /** Path part for operation `createAnnouncement()` */
  static readonly CreateAnnouncementPath = '/announcements';

  /**
   * This method takes an announcement to be created
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAnnouncement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAnnouncement$Response(params: CreateAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method takes an announcement to be created
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAnnouncement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAnnouncement(params: CreateAnnouncement$Params, context?: HttpContext): Observable<number> {
    return this.createAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getLatest()` */
  static readonly GetLatestPath = '/announcements/latest';

  /**
   * This method gets the latest announcement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatest$Response(params: GetLatest$Params, context?: HttpContext): Observable<StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>> {
    return getLatest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method gets the latest announcement
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatest(params: GetLatest$Params, context?: HttpContext): Observable<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)> {
    return this.getLatest$Response(params, context).pipe(
      map((r: StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>): (BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement) => r.body)
    );
  }

  /** Path part for operation `getAllActiveAnnouncements()` */
  static readonly GetAllActiveAnnouncementsPath = '/announcements/active';

  /**
   * This method retrieves all active announcements of a given type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllActiveAnnouncements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllActiveAnnouncements$Response(params: GetAllActiveAnnouncements$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>>> {
    return getAllActiveAnnouncements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all active announcements of a given type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllActiveAnnouncements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllActiveAnnouncements(params: GetAllActiveAnnouncements$Params, context?: HttpContext): Observable<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>> {
    return this.getAllActiveAnnouncements$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>>): Array<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)> => r.body)
    );
  }

}
