import {ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {ButtonSize} from '../../internal';
import {Buttons} from './constants';

export type ButtonTypes =
  | Buttons.Action
  | Buttons.Secondary
  | Buttons.Warning
  | Buttons.Icon
  | Buttons.Link
  | Buttons.LinkInline
  | Buttons.Export
  | Buttons.TabLink
  | Buttons.SideTabLink
  | '';

type ButtonTypesLiteral = `${ButtonTypes}`;

type ButtonWidth = 'mini' | 'normal';

const BUTTON_TYPES_CLASS_MAPPING: {[key in ButtonTypes]?: string} = {
  [Buttons.Action]: 'action-button',

  [Buttons.Secondary]: 'secondary-button',

  [Buttons.Warning]: 'danger-button',

  [Buttons.Icon]: 'ico-button',

  [Buttons.Link]: 'link-button',

  [Buttons.LinkInline]: 'link-button--inline',

  [Buttons.Export]: 'ico-button',

  [Buttons.TabLink]: 'tab-link-button',

  [Buttons.SideTabLink]: 'side-tab-link-button',
};

const BUTTON_SIZES_CLASS_MAPPING: {[key in ButtonSize]?: string} = {
  large: 'functional',
};

const BUTTON_WIDTH_CLASS_MAPPING: {[key in ButtonWidth]?: string} = {
  mini: 'mini',
};

const resolveButtonTypeClass = (type: ButtonTypes) => BUTTON_TYPES_CLASS_MAPPING[type];

const resolveButtonSizeClass = (size: ButtonSize) => BUTTON_SIZES_CLASS_MAPPING[size];

const resolveButtonWidthClass = (width: ButtonWidth) => BUTTON_WIDTH_CLASS_MAPPING[width];

@Directive({
  selector: 'button[msButton]',
  exportAs: 'msButton',
  standalone: true,
})
export class ButtonDirective implements OnChanges {
  private _size: ButtonSize = 'normal';

  private _type: ButtonTypes;

  @Input()
  set msButton(type: ButtonTypes | ButtonTypesLiteral) {
    this.type = type as ButtonTypes;
  }

  get type() {
    return this._type;
  }

  set type(type: ButtonTypes) {
    this.replaceClass(resolveButtonTypeClass(this._type), resolveButtonTypeClass(type));

    this._type = type;
    this.cdRef.markForCheck();
  }

  @Input()
  set buttonType(type: ButtonTypes | ButtonTypesLiteral) {
    this.type = type as ButtonTypes;
  }

  @Input()
  set size(value: ButtonSize) {
    const prevSize = this._size;
    this._size = value;

    this.changeSize(value, prevSize);
  }

  get size() {
    return this._size;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('button-width')
  width: ButtonWidth = 'normal';

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.width) {
      const widthChanges = changes.width;

      this.replaceClass(
        resolveButtonWidthClass(widthChanges.previousValue),
        resolveButtonWidthClass(widthChanges.currentValue)
      );
    }
  }

  private addClass(className: string) {
    if (className) {
      this.renderer.addClass(this.el.nativeElement, className);
    }
  }

  private removeClass(className: string) {
    if (className) {
      this.renderer.removeClass(this.el.nativeElement, className);
    }
  }

  private replaceClass(oldClass: string, newClass: string) {
    this.removeClass(oldClass);
    this.addClass(newClass);
  }

  private changeSize(newSize: ButtonSize, oldSize: ButtonSize) {
    this.replaceClass(resolveButtonSizeClass(oldSize), resolveButtonSizeClass(newSize));
    this.cdRef.markForCheck();
  }
}
