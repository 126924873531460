/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WorkupForm } from '../../models/workup-form';

export interface LoadWorkupFormStructure1$Params {
  inquiryCode: 'DNRIDM' | 'MATID2' | 'MSQMS2' | 'FHQFH2' | 'CBUID2' | 'EWU' | 'SECEWU' | 'EIDMs' | 'CD34ST' | 'CD3ST' | 'CFU' | 'CFUST' | 'NRBCST' | 'TNCST' | 'VIAB' | 'VIABST' | 'NATHCV' | 'NATHIV' | 'EOR' | 'PROCES';
  version?: string;
  structureType?: 'EDIT' | 'VIEW';
}

export function loadWorkupFormStructure1(http: HttpClient, rootUrl: string, params: LoadWorkupFormStructure1$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupForm>> {
  const rb = new RequestBuilder(rootUrl, loadWorkupFormStructure1.PATH, 'get');
  if (params) {
    rb.path('inquiryCode', params.inquiryCode, {});
    rb.query('version', params.version, {});
    rb.query('structureType', params.structureType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<WorkupForm>;
    })
  );
}

loadWorkupFormStructure1.PATH = '/workup/structure/{inquiryCode}';
