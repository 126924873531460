export enum AdminToolsRouteNames {
  AdminTools = 'admin-tools',
  Announcements = 'announcement-settings',
  DashboardHeadlines = 'dashboard-headline-settings',
  ImportantDates = 'important-dates-settings',
  FeatureUpdates = 'feature-updates-settings',
  FeatureToggles = 'feature-toggle-settings',
}

export enum PatientsInProgressRouteNames {
  PatientsInProgress = 'patients-in-progress',
}

export enum PatientFormRouteNames {
  PatientForm = 'patient.patient-form',
  PatientFormCreate = 'patient.patient-form.create',
  PatientFormEdit = 'patient.patient-form.edit',
  PatientFormEditAdd = 'patient.patient-form.edit.add',
  PatientFormEditPhenotype = 'patient.patient-form.edit.phenotype',
}
