/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSuppliersByGuids } from '../fn/supplier-controller/get-suppliers-by-guids';
import { GetSuppliersByGuids$Params } from '../fn/supplier-controller/get-suppliers-by-guids';
import { Supplier } from '../models/supplier';

@Injectable({ providedIn: 'root' })
export class SupplierControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSuppliersByGuids()` */
  static readonly GetSuppliersByGuidsPath = '/suppliers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuppliersByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuppliersByGuids$Response(params: GetSuppliersByGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Supplier>>> {
    return getSuppliersByGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuppliersByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuppliersByGuids(params: GetSuppliersByGuids$Params, context?: HttpContext): Observable<Array<Supplier>> {
    return this.getSuppliersByGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Supplier>>): Array<Supplier> => r.body)
    );
  }

}
