/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addNewDocument } from '../fn/international-form-document-new-workup-controller/add-new-document';
import { AddNewDocument$Params } from '../fn/international-form-document-new-workup-controller/add-new-document';
import { DocumentDetails } from '../models/document-details';
import { getInternationalFormDetails } from '../fn/international-form-document-new-workup-controller/get-international-form-details';
import { GetInternationalFormDetails$Params } from '../fn/international-form-document-new-workup-controller/get-international-form-details';
import { reassignDocuments } from '../fn/international-form-document-new-workup-controller/reassign-documents';
import { ReassignDocuments$Params } from '../fn/international-form-document-new-workup-controller/reassign-documents';
import { removeDocuments } from '../fn/international-form-document-new-workup-controller/remove-documents';
import { RemoveDocuments$Params } from '../fn/international-form-document-new-workup-controller/remove-documents';

@Injectable({ providedIn: 'root' })
export class InternationalFormDocumentNewWorkupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reassignDocuments()` */
  static readonly ReassignDocumentsPath = '/int-forms-documents/enhanced/reassign_documents_workup';

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignDocuments$Response(params: ReassignDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reassignDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignDocuments(params: ReassignDocuments$Params, context?: HttpContext): Observable<void> {
    return this.reassignDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addNewDocument()` */
  static readonly AddNewDocumentPath = '/int-forms-documents/enhanced';

  /**
   * This method uploads international form document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  addNewDocument$Response(params: AddNewDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addNewDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method uploads international form document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNewDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addNewDocument(params: AddNewDocument$Params, context?: HttpContext): Observable<void> {
    return this.addNewDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeDocuments()` */
  static readonly RemoveDocumentsPath = '/int-forms-documents/enhanced';

  /**
   * This method deletes document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDocuments$Response(params: RemoveDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeDocuments(params: RemoveDocuments$Params, context?: HttpContext): Observable<void> {
    return this.removeDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getInternationalFormDetails()` */
  static readonly GetInternationalFormDetailsPath = '/int-forms-documents/enhanced/details';

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternationalFormDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternationalFormDetails$Response(params: GetInternationalFormDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDetails>>> {
    return getInternationalFormDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternationalFormDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternationalFormDetails(params: GetInternationalFormDetails$Params, context?: HttpContext): Observable<Array<DocumentDetails>> {
    return this.getInternationalFormDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>): Array<DocumentDetails> => r.body)
    );
  }

}
