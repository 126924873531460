/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addDocument } from '../fn/international-form-document-controller/add-document';
import { AddDocument$Params } from '../fn/international-form-document-controller/add-document';
import { deleteDocuments } from '../fn/international-form-document-controller/delete-documents';
import { DeleteDocuments$Params } from '../fn/international-form-document-controller/delete-documents';
import { DocumentDetails } from '../models/document-details';
import { getIntFormDetails } from '../fn/international-form-document-controller/get-int-form-details';
import { GetIntFormDetails$Params } from '../fn/international-form-document-controller/get-int-form-details';
import { reassignWorkupDocuments } from '../fn/international-form-document-controller/reassign-workup-documents';
import { ReassignWorkupDocuments$Params } from '../fn/international-form-document-controller/reassign-workup-documents';

@Injectable({ providedIn: 'root' })
export class InternationalFormDocumentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reassignWorkupDocuments()` */
  static readonly ReassignWorkupDocumentsPath = '/int-forms-documents/reassign_documents_workup';

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignWorkupDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignWorkupDocuments$Response(params: ReassignWorkupDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reassignWorkupDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This reassigns workup documents to newly created order workup
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignWorkupDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reassignWorkupDocuments(params: ReassignWorkupDocuments$Params, context?: HttpContext): Observable<void> {
    return this.reassignWorkupDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addDocument()` */
  static readonly AddDocumentPath = '/int-forms-documents';

  /**
   * This method uploads international form document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocument$Response(params: AddDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method uploads international form document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addDocument(params: AddDocument$Params, context?: HttpContext): Observable<void> {
    return this.addDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteDocuments()` */
  static readonly DeleteDocumentsPath = '/int-forms-documents';

  /**
   * This method deletes document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocuments$Response(params: DeleteDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocuments(params: DeleteDocuments$Params, context?: HttpContext): Observable<void> {
    return this.deleteDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getIntFormDetails()` */
  static readonly GetIntFormDetailsPath = '/int-forms-documents/details';

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntFormDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntFormDetails$Response(params: GetIntFormDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDetails>>> {
    return getIntFormDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method returns all documents details for a given international form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntFormDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntFormDetails(params: GetIntFormDetails$Params, context?: HttpContext): Observable<Array<DocumentDetails>> {
    return this.getIntFormDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>): Array<DocumentDetails> => r.body)
    );
  }

}
