/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BannerAnnouncement } from '../../models/banner-announcement';
import { FeatureAnnouncement } from '../../models/feature-announcement';
import { HeadlineAnnouncement } from '../../models/headline-announcement';
import { ImportantDatesAnnouncement } from '../../models/important-dates-announcement';

export interface GetLatest$Params {
  type: 'BANNER' | 'IMPDATES' | 'FEATURE' | 'HEADLINE';
}

export function getLatest(http: HttpClient, rootUrl: string, params: GetLatest$Params, context?: HttpContext): Observable<StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>> {
  const rb = new RequestBuilder(rootUrl, getLatest.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(BannerAnnouncement | FeatureAnnouncement | HeadlineAnnouncement | ImportantDatesAnnouncement)>;
    })
  );
}

getLatest.PATH = '/announcements/latest';
