import {ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ms-label',
  templateUrl: './label.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LabelComponent implements ControlValueAccessor {
  @Input()
  @HostBinding('class.marked')
  required = false;

  @HostBinding('class.ms-field-text')
  readonly hostClass = true;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnChange(_fn: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDisabledState(_isDisabled: boolean): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue(_obj: any): void {}
}
