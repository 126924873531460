/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveSearchResponse } from '../models/active-search-response';
import { addLiteRecipient } from '../fn/recipient-controller/add-lite-recipient';
import { AddLiteRecipient$Params } from '../fn/recipient-controller/add-lite-recipient';
import { addOrUpdateRecipientExternal } from '../fn/recipient-controller/add-or-update-recipient-external';
import { AddOrUpdateRecipientExternal$Params } from '../fn/recipient-controller/add-or-update-recipient-external';
import { addRecipient } from '../fn/recipient-controller/add-recipient';
import { AddRecipient$Params } from '../fn/recipient-controller/add-recipient';
import { closeRecipient } from '../fn/recipient-controller/close-recipient';
import { CloseRecipient$Params } from '../fn/recipient-controller/close-recipient';
import { deleteInProgressRecipient } from '../fn/recipient-controller/delete-in-progress-recipient';
import { DeleteInProgressRecipient$Params } from '../fn/recipient-controller/delete-in-progress-recipient';
import { getBpGuidByDocumentId } from '../fn/recipient-controller/get-bp-guid-by-document-id';
import { GetBpGuidByDocumentId$Params } from '../fn/recipient-controller/get-bp-guid-by-document-id';
import { getLatestTcPrlmPatients } from '../fn/recipient-controller/get-latest-tc-prlm-patients';
import { GetLatestTcPrlmPatients$Params } from '../fn/recipient-controller/get-latest-tc-prlm-patients';
import { getRecipient } from '../fn/recipient-controller/get-recipient';
import { GetRecipient$Params } from '../fn/recipient-controller/get-recipient';
import { getRecipientRaceEthnicityHistory } from '../fn/recipient-controller/get-recipient-race-ethnicity-history';
import { GetRecipientRaceEthnicityHistory$Params } from '../fn/recipient-controller/get-recipient-race-ethnicity-history';
import { getRecipientTcGuid } from '../fn/recipient-controller/get-recipient-tc-guid';
import { GetRecipientTcGuid$Params } from '../fn/recipient-controller/get-recipient-tc-guid';
import { getTransplantTimelineDates } from '../fn/recipient-controller/get-transplant-timeline-dates';
import { GetTransplantTimelineDates$Params } from '../fn/recipient-controller/get-transplant-timeline-dates';
import { importRecipient } from '../fn/recipient-controller/import-recipient';
import { ImportRecipient$Params } from '../fn/recipient-controller/import-recipient';
import { isDomesticDemographicsValid } from '../fn/recipient-controller/is-domestic-demographics-valid';
import { IsDomesticDemographicsValid$Params } from '../fn/recipient-controller/is-domestic-demographics-valid';
import { isRecipientClosed } from '../fn/recipient-controller/is-recipient-closed';
import { IsRecipientClosed$Params } from '../fn/recipient-controller/is-recipient-closed';
import { LiteRecipientResponse } from '../models/lite-recipient-response';
import { RaceEthnicityHistory } from '../models/race-ethnicity-history';
import { reactivateRecipient } from '../fn/recipient-controller/reactivate-recipient';
import { ReactivateRecipient$Params } from '../fn/recipient-controller/reactivate-recipient';
import { Recipient } from '../models/recipient';
import { rerunExternalIfNecessary } from '../fn/recipient-controller/rerun-external-if-necessary';
import { RerunExternalIfNecessary$Params } from '../fn/recipient-controller/rerun-external-if-necessary';
import { Result } from '../models/result';
import { setLatestShareHistoryPublishedFlagTrue } from '../fn/recipient-controller/set-latest-share-history-published-flag-true';
import { SetLatestShareHistoryPublishedFlagTrue$Params } from '../fn/recipient-controller/set-latest-share-history-published-flag-true';
import { transferRecipient } from '../fn/recipient-controller/transfer-recipient';
import { TransferRecipient$Params } from '../fn/recipient-controller/transfer-recipient';
import { TransplantTimelineDatesResponse } from '../models/transplant-timeline-dates-response';
import { updateImportedRecipient } from '../fn/recipient-controller/update-imported-recipient';
import { UpdateImportedRecipient$Params } from '../fn/recipient-controller/update-imported-recipient';
import { updateRecipient } from '../fn/recipient-controller/update-recipient';
import { UpdateRecipient$Params } from '../fn/recipient-controller/update-recipient';
import { updateRecipientPartially } from '../fn/recipient-controller/update-recipient-partially';
import { UpdateRecipientPartially$Params } from '../fn/recipient-controller/update-recipient-partially';
import { updateRecipientPartiallyBasedOnWorkupOrder } from '../fn/recipient-controller/update-recipient-partially-based-on-workup-order';
import { UpdateRecipientPartiallyBasedOnWorkupOrder$Params } from '../fn/recipient-controller/update-recipient-partially-based-on-workup-order';
import { updateRecipientStatus } from '../fn/recipient-controller/update-recipient-status';
import { UpdateRecipientStatus$Params } from '../fn/recipient-controller/update-recipient-status';
import { updateTransplantTimeline } from '../fn/recipient-controller/update-transplant-timeline';
import { UpdateTransplantTimeline$Params } from '../fn/recipient-controller/update-transplant-timeline';

@Injectable({ providedIn: 'root' })
export class RecipientControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRecipient()` */
  static readonly GetRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipient$Response(params: GetRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Recipient>> {
    return getRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipient(params: GetRecipient$Params, context?: HttpContext): Observable<Recipient> {
    return this.getRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Recipient>): Recipient => r.body)
    );
  }

  /** Path part for operation `updateRecipient()` */
  static readonly UpdateRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipient$Response(params: UpdateRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return updateRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipient(params: UpdateRecipient$Params, context?: HttpContext): Observable<Result> {
    return this.updateRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `deleteInProgressRecipient()` */
  static readonly DeleteInProgressRecipientPath = '/recipients/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInProgressRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInProgressRecipient$Response(params: DeleteInProgressRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return deleteInProgressRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInProgressRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInProgressRecipient(params: DeleteInProgressRecipient$Params, context?: HttpContext): Observable<{
}> {
    return this.deleteInProgressRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateTransplantTimeline()` */
  static readonly UpdateTransplantTimelinePath = '/recipients/{guid}/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTransplantTimeline()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransplantTimeline$Response(params: UpdateTransplantTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTransplantTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTransplantTimeline$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTransplantTimeline(params: UpdateTransplantTimeline$Params, context?: HttpContext): Observable<void> {
    return this.updateTransplantTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateRecipientStatus()` */
  static readonly UpdateRecipientStatusPath = '/recipients/{guid}/status/{statusCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientStatus$Response(params: UpdateRecipientStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRecipientStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRecipientStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientStatus(params: UpdateRecipientStatus$Params, context?: HttpContext): Observable<void> {
    return this.updateRecipientStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setLatestShareHistoryPublishedFlagTrue()` */
  static readonly SetLatestShareHistoryPublishedFlagTruePath = '/recipients/{guid}/set-share-history-published';

  /**
   * Set published flag true of the latest share history  after search summary report has been published for recipient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLatestShareHistoryPublishedFlagTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  setLatestShareHistoryPublishedFlagTrue$Response(params: SetLatestShareHistoryPublishedFlagTrue$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setLatestShareHistoryPublishedFlagTrue(this.http, this.rootUrl, params, context);
  }

  /**
   * Set published flag true of the latest share history  after search summary report has been published for recipient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setLatestShareHistoryPublishedFlagTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setLatestShareHistoryPublishedFlagTrue(params: SetLatestShareHistoryPublishedFlagTrue$Params, context?: HttpContext): Observable<void> {
    return this.setLatestShareHistoryPublishedFlagTrue$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reactivateRecipient()` */
  static readonly ReactivateRecipientPath = '/recipients/{guid}/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reactivateRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reactivateRecipient$Response(params: ReactivateRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reactivateRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reactivateRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reactivateRecipient(params: ReactivateRecipient$Params, context?: HttpContext): Observable<void> {
    return this.reactivateRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateRecipientPartially()` */
  static readonly UpdateRecipientPartiallyPath = '/recipients/{guid}/partially';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientPartially()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientPartially$Response(params: UpdateRecipientPartially$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return updateRecipientPartially(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRecipientPartially$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecipientPartially(params: UpdateRecipientPartially$Params, context?: HttpContext): Observable<Result> {
    return this.updateRecipientPartially$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `updateRecipientPartiallyBasedOnWorkupOrder()` */
  static readonly UpdateRecipientPartiallyBasedOnWorkupOrderPath = '/recipients/{guid}/partially/workup-order/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecipientPartiallyBasedOnWorkupOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRecipientPartiallyBasedOnWorkupOrder$Response(params: UpdateRecipientPartiallyBasedOnWorkupOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return updateRecipientPartiallyBasedOnWorkupOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRecipientPartiallyBasedOnWorkupOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRecipientPartiallyBasedOnWorkupOrder(params: UpdateRecipientPartiallyBasedOnWorkupOrder$Params, context?: HttpContext): Observable<Result> {
    return this.updateRecipientPartiallyBasedOnWorkupOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `updateImportedRecipient()` */
  static readonly UpdateImportedRecipientPath = '/recipients/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateImportedRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateImportedRecipient$Response(params: UpdateImportedRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return updateImportedRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateImportedRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateImportedRecipient(params: UpdateImportedRecipient$Params, context?: HttpContext): Observable<Result> {
    return this.updateImportedRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `importRecipient()` */
  static readonly ImportRecipientPath = '/recipients/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importRecipient$Response(params: ImportRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return importRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importRecipient(params: ImportRecipient$Params, context?: HttpContext): Observable<Result> {
    return this.importRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `addRecipient()` */
  static readonly AddRecipientPath = '/recipients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipient$Response(params: AddRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Result>> {
    return addRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipient(params: AddRecipient$Params, context?: HttpContext): Observable<Result> {
    return this.addRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Result>): Result => r.body)
    );
  }

  /** Path part for operation `transferRecipient()` */
  static readonly TransferRecipientPath = '/recipients/{guid}/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferRecipient$Response(params: TransferRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return transferRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transferRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferRecipient(params: TransferRecipient$Params, context?: HttpContext): Observable<void> {
    return this.transferRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `closeRecipient()` */
  static readonly CloseRecipientPath = '/recipients/{guid}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeRecipient$Response(params: CloseRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return closeRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeRecipient(params: CloseRecipient$Params, context?: HttpContext): Observable<void> {
    return this.closeRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addLiteRecipient()` */
  static readonly AddLiteRecipientPath = '/recipients/lite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLiteRecipient()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLiteRecipient$Response(params: AddLiteRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<LiteRecipientResponse>> {
    return addLiteRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addLiteRecipient$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLiteRecipient(params: AddLiteRecipient$Params, context?: HttpContext): Observable<LiteRecipientResponse> {
    return this.addLiteRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<LiteRecipientResponse>): LiteRecipientResponse => r.body)
    );
  }

  /** Path part for operation `addOrUpdateRecipientExternal()` */
  static readonly AddOrUpdateRecipientExternalPath = '/recipients/external';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrUpdateRecipientExternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateRecipientExternal$Response(params: AddOrUpdateRecipientExternal$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addOrUpdateRecipientExternal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOrUpdateRecipientExternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrUpdateRecipientExternal(params: AddOrUpdateRecipientExternal$Params, context?: HttpContext): Observable<void> {
    return this.addOrUpdateRecipientExternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rerunExternalIfNecessary()` */
  static readonly RerunExternalIfNecessaryPath = '/recipients/external/search/rerunIfNecessary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rerunExternalIfNecessary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunExternalIfNecessary$Response(params: RerunExternalIfNecessary$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveSearchResponse>> {
    return rerunExternalIfNecessary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rerunExternalIfNecessary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rerunExternalIfNecessary(params: RerunExternalIfNecessary$Params, context?: HttpContext): Observable<ActiveSearchResponse> {
    return this.rerunExternalIfNecessary$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveSearchResponse>): ActiveSearchResponse => r.body)
    );
  }

  /** Path part for operation `isRecipientClosed()` */
  static readonly IsRecipientClosedPath = '/recipients/{recipientGuid}/is-closed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isRecipientClosed()` instead.
   *
   * This method doesn't expect any request body.
   */
  isRecipientClosed$Response(params: IsRecipientClosed$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isRecipientClosed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isRecipientClosed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isRecipientClosed(params: IsRecipientClosed$Params, context?: HttpContext): Observable<boolean> {
    return this.isRecipientClosed$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getTransplantTimelineDates()` */
  static readonly GetTransplantTimelineDatesPath = '/recipients/{guid}/transplant-timeline-dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTransplantTimelineDates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransplantTimelineDates$Response(params: GetTransplantTimelineDates$Params, context?: HttpContext): Observable<StrictHttpResponse<TransplantTimelineDatesResponse>> {
    return getTransplantTimelineDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTransplantTimelineDates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTransplantTimelineDates(params: GetTransplantTimelineDates$Params, context?: HttpContext): Observable<TransplantTimelineDatesResponse> {
    return this.getTransplantTimelineDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransplantTimelineDatesResponse>): TransplantTimelineDatesResponse => r.body)
    );
  }

  /** Path part for operation `getRecipientTcGuid()` */
  static readonly GetRecipientTcGuidPath = '/recipients/{guid}/tc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTcGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTcGuid$Response(params: GetRecipientTcGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getRecipientTcGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientTcGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTcGuid(params: GetRecipientTcGuid$Params, context?: HttpContext): Observable<string> {
    return this.getRecipientTcGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `isDomesticDemographicsValid()` */
  static readonly IsDomesticDemographicsValidPath = '/recipients/{guid}/domesticDemographicsValid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isDomesticDemographicsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  isDomesticDemographicsValid$Response(params: IsDomesticDemographicsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isDomesticDemographicsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isDomesticDemographicsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isDomesticDemographicsValid(params: IsDomesticDemographicsValid$Params, context?: HttpContext): Observable<boolean> {
    return this.isDomesticDemographicsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `getRecipientRaceEthnicityHistory()` */
  static readonly GetRecipientRaceEthnicityHistoryPath = '/recipients/race-ethnicity-history/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRaceEthnicityHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRaceEthnicityHistory$Response(params: GetRecipientRaceEthnicityHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<RaceEthnicityHistory>> {
    return getRecipientRaceEthnicityHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientRaceEthnicityHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRaceEthnicityHistory(params: GetRecipientRaceEthnicityHistory$Params, context?: HttpContext): Observable<RaceEthnicityHistory> {
    return this.getRecipientRaceEthnicityHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<RaceEthnicityHistory>): RaceEthnicityHistory => r.body)
    );
  }

  /** Path part for operation `getLatestTcPrlmPatients()` */
  static readonly GetLatestTcPrlmPatientsPath = '/recipients/latest-prlm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestTcPrlmPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTcPrlmPatients$Response(params: GetLatestTcPrlmPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getLatestTcPrlmPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestTcPrlmPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTcPrlmPatients(params: GetLatestTcPrlmPatients$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getLatestTcPrlmPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getBpGuidByDocumentId()` */
  static readonly GetBpGuidByDocumentIdPath = '/recipients/documenttc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBpGuidByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBpGuidByDocumentId$Response(params: GetBpGuidByDocumentId$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getBpGuidByDocumentId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBpGuidByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBpGuidByDocumentId(params: GetBpGuidByDocumentId$Params, context?: HttpContext): Observable<string> {
    return this.getBpGuidByDocumentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
