<ul class="container">
  <li class="dialog-row" test-id="typing">
    {{ data.typing }}
  </li>
  <li class="dialog-row" *ngFor="let entry of dataSet">
    <ul class="container leader-type">
      <li class="leader-name" test-id="leader-name">{{ entry.leader }} ({{ entry.title | transloco }})</li>
      <li test-id="leader-value" class="text-wrap leader-loci">{{ entry.loci }}</li>
    </ul>
  </li>
</ul>
