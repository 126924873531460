/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Donor } from '../models/donor';
import { DonorEligibility } from '../models/donor-eligibility';
import { EthnicityRaceInfo } from '../models/ethnicity-race-info';
import { Form } from '../models/form';
import { getDonorByGuid } from '../fn/donor-controller/get-donor-by-guid';
import { GetDonorByGuid$Params } from '../fn/donor-controller/get-donor-by-guid';
import { getDonorEligibility } from '../fn/donor-controller/get-donor-eligibility';
import { GetDonorEligibility$Params } from '../fn/donor-controller/get-donor-eligibility';
import { getDonorEthnicityRace } from '../fn/donor-controller/get-donor-ethnicity-race';
import { GetDonorEthnicityRace$Params } from '../fn/donor-controller/get-donor-ethnicity-race';
import { getDonorEthnicityRaceInBulk } from '../fn/donor-controller/get-donor-ethnicity-race-in-bulk';
import { GetDonorEthnicityRaceInBulk$Params } from '../fn/donor-controller/get-donor-ethnicity-race-in-bulk';
import { getDonorIdms } from '../fn/donor-controller/get-donor-idms';
import { GetDonorIdms$Params } from '../fn/donor-controller/get-donor-idms';
import { getDonorsBaseByGuids } from '../fn/donor-controller/get-donors-base-by-guids';
import { GetDonorsBaseByGuids$Params } from '../fn/donor-controller/get-donors-base-by-guids';
import { getDonorsByGuids } from '../fn/donor-controller/get-donors-by-guids';
import { GetDonorsByGuids$Params } from '../fn/donor-controller/get-donors-by-guids';
import { getKir } from '../fn/donor-controller/get-kir';
import { GetKir$Params } from '../fn/donor-controller/get-kir';
import { Kir } from '../models/kir';
import { SourceBase } from '../models/source-base';

@Injectable({ providedIn: 'root' })
export class DonorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDonorsByGuids()` */
  static readonly GetDonorsByGuidsPath = '/donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorsByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsByGuids$Response(params: GetDonorsByGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Donor>>> {
    return getDonorsByGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorsByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsByGuids(params: GetDonorsByGuids$Params, context?: HttpContext): Observable<Array<Donor>> {
    return this.getDonorsByGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Donor>>): Array<Donor> => r.body)
    );
  }

  /** Path part for operation `getDonorEthnicityRaceInBulk()` */
  static readonly GetDonorEthnicityRaceInBulkPath = '/donors/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEthnicityRaceInBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorEthnicityRaceInBulk$Response(params: GetDonorEthnicityRaceInBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>> {
    return getDonorEthnicityRaceInBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorEthnicityRaceInBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorEthnicityRaceInBulk(params: GetDonorEthnicityRaceInBulk$Params, context?: HttpContext): Observable<{
[key: string]: EthnicityRaceInfo;
}> {
    return this.getDonorEthnicityRaceInBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>): {
[key: string]: EthnicityRaceInfo;
} => r.body)
    );
  }

  /** Path part for operation `getDonorsBaseByGuids()` */
  static readonly GetDonorsBaseByGuidsPath = '/donors/base';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorsBaseByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsBaseByGuids$Response(params: GetDonorsBaseByGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SourceBase>>> {
    return getDonorsBaseByGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorsBaseByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorsBaseByGuids(params: GetDonorsBaseByGuids$Params, context?: HttpContext): Observable<Array<SourceBase>> {
    return this.getDonorsBaseByGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SourceBase>>): Array<SourceBase> => r.body)
    );
  }

  /** Path part for operation `getDonorByGuid()` */
  static readonly GetDonorByGuidPath = '/donors/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorByGuid$Response(params: GetDonorByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Donor>> {
    return getDonorByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorByGuid(params: GetDonorByGuid$Params, context?: HttpContext): Observable<Donor> {
    return this.getDonorByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Donor>): Donor => r.body)
    );
  }

  /** Path part for operation `getKir()` */
  static readonly GetKirPath = '/donors/{guid}/kir';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKir()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir$Response(params: GetKir$Params, context?: HttpContext): Observable<StrictHttpResponse<Kir>> {
    return getKir(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKir$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir(params: GetKir$Params, context?: HttpContext): Observable<Kir> {
    return this.getKir$Response(params, context).pipe(
      map((r: StrictHttpResponse<Kir>): Kir => r.body)
    );
  }

  /** Path part for operation `getDonorIdms()` */
  static readonly GetDonorIdmsPath = '/donors/{guid}/idms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorIdms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorIdms$Response(params: GetDonorIdms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Form>>> {
    return getDonorIdms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorIdms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorIdms(params: GetDonorIdms$Params, context?: HttpContext): Observable<Array<Form>> {
    return this.getDonorIdms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Form>>): Array<Form> => r.body)
    );
  }

  /** Path part for operation `getDonorEthnicityRace()` */
  static readonly GetDonorEthnicityRacePath = '/donors/{guid}/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEthnicityRace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEthnicityRace$Response(params: GetDonorEthnicityRace$Params, context?: HttpContext): Observable<StrictHttpResponse<EthnicityRaceInfo>> {
    return getDonorEthnicityRace(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorEthnicityRace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEthnicityRace(params: GetDonorEthnicityRace$Params, context?: HttpContext): Observable<EthnicityRaceInfo> {
    return this.getDonorEthnicityRace$Response(params, context).pipe(
      map((r: StrictHttpResponse<EthnicityRaceInfo>): EthnicityRaceInfo => r.body)
    );
  }

  /** Path part for operation `getDonorEligibility()` */
  static readonly GetDonorEligibilityPath = '/donors/{guid}/eligibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorEligibility()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEligibility$Response(params: GetDonorEligibility$Params, context?: HttpContext): Observable<StrictHttpResponse<DonorEligibility>> {
    return getDonorEligibility(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorEligibility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorEligibility(params: GetDonorEligibility$Params, context?: HttpContext): Observable<DonorEligibility> {
    return this.getDonorEligibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<DonorEligibility>): DonorEligibility => r.body)
    );
  }

}
