import {HlaLocusModel, HlaMapModel, HlaModel, DonorMatching} from '@matchsource/models/hla';
import {Injectable} from '@angular/core';
import {DEFAULT_DONORS_CRITERIA, DPB1_MATCH_GRADE_VALUE_MAP} from '@matchsource/models/search-results-shared';
import {CcrModel} from '@matchsource/models/nomenclature';
import {isNumeric, range} from '@matchsource/utils';
import {formatProbability} from '@matchsource/api-utils';
import {CordMatch} from '@matchsource/models/cord';
import {hlaSerializer} from '@matchsource/api/hla';
import {BiobankMatch} from '@matchsource/models/biobank';

const mapAlleleLevelMProb8 = (data: string[] = []): [string, string] => {
  const firstCol = data.filter((item, index) => index < 3).join('\n');
  const secondCol = data.filter((item, index) => index > 2).join('\n');
  return [firstCol, secondCol];
};
const mapAlleleLevelMProb = (data: string[] = []) => {
  const firstCol = data.slice(0, 3).join('\n');
  const secondCol = data.slice(3).join('\n');

  return [firstCol, secondCol];
};

interface DonorMatchParams {
  dpb1TCEMatch: string;
  ccr5Genotype: string;
  computedRacePhase: string;
  matCatg8: number;
  matCatg10: number;
  pctOf6: number[];
  pctOf8: number[];
  pctOf10: number[];
  typingResults: MsApp.Dictionary<HlaLocusModel>;
}

interface BiobankMatchParams {
  dpb1TCEMatch: string;
  ccr5Genotype: string;
  computedRacePhase: string;
  cd34FcWeight: number;
  matCatg6: number;
  matCatg8: number;
  matCatg10: number;
  pctOf6: number[];
  pctOf8: number[];
  pctOf10: number[];
  tncWeight: number;
  typingResults: MsApp.Dictionary<HlaLocusModel>;
}

interface CordMatchParams {
  dpb1TCEMatch: string;
  ccr5Genotype: string;
  computedRacePhase: string;
  cd34FcWeight: number;
  matCatg6: number;
  matCatg8: number;
  matCatg10: number;
  pctOf6: number[];
  pctOf8: number[];
  pctOf10: number[];
  tncWeight: number;
  typingResults: MsApp.Dictionary<HlaLocusModel>;
}

const mapTypingResults = <T extends Partial<DonorMatchParams>>(data: T): {hlaMap: HlaMapModel; hla: HlaModel[]} => {
  const hlaMap = {} as HlaMapModel;
  const hla: HlaModel[] = [];

  if (data.typingResults) {
    Object.values(data.typingResults).forEach(locus => {
      const item = hlaSerializer.fromDTO(locus);
      hla.push(item);
      hlaMap[item.name] = item;
    });
  }

  return {hlaMap, hla};
};

@Injectable({
  providedIn: 'root',
})
export class SourceMatchFactoryService {
  createDonorMatch<T extends Partial<DonorMatchParams>>(
    data: T,
    matchingCategoryType = DEFAULT_DONORS_CRITERIA,
    ccrList: CcrModel[] = []
  ): DonorMatching {
    const alleleLevelMatchProbability10List = range(0, 2).map(idx => formatProbability(data.pctOf10, idx, 10));
    const alleleLevelMatchProbability8List = range(0, 2).map(idx => formatProbability(data.pctOf8, idx, 8));
    const alleleLevelMatchProbability10FullList = range(0, 5).map(idx => formatProbability(data.pctOf10, idx, 10));
    const alleleLevelMatchProbability8FullList = range(0, 5).map(idx => formatProbability(data.pctOf8, idx, 8));
    const matchingCategoryXf8 = isNumeric(data.matCatg8) ? `${data.matCatg8}/8` : '';
    const matchingCategoryXf10 = isNumeric(data.matCatg10) ? `${data.matCatg10}/10` : '';
    const matchingCategory =
      matchingCategoryType === DEFAULT_DONORS_CRITERIA ? matchingCategoryXf10 : matchingCategoryXf8;
    const {hla, hlaMap} = mapTypingResults(data);

    return {
      alleleLevelMatchProbability10List,
      alleleLevelMatchProbability8List,
      alleleLevelMatchProbability10: alleleLevelMatchProbability10List.join('\n'),
      alleleLevelMatchProbability8: alleleLevelMatchProbability8List.join('\n'),
      alleleLevelMatchProbability8Full: mapAlleleLevelMProb(alleleLevelMatchProbability8FullList),
      alleleLevelMatchProbability10Full: mapAlleleLevelMProb(alleleLevelMatchProbability10FullList),
      ccr5: data.ccr5Genotype || '',
      ccr5Code: data.ccr5Genotype && (ccrList.find(item => item.name === data.ccr5Genotype) || {}).code,
      dpb1TCEMatch: data.dpb1TCEMatch ? DPB1_MATCH_GRADE_VALUE_MAP[data.dpb1TCEMatch] : '',
      matchingCategory_Xf8: matchingCategoryXf8,
      matchingCategory_Xf10: matchingCategoryXf10,
      matCatg10: data.matCatg10,
      matchingCategory,
      hla,
      hlaMap,
      // MS-13142
      noTypingAvailable: (data.computedRacePhase || '').toUpperCase() === 'FAILED',
    };
  }

  createCordMatch<T extends Partial<CordMatchParams>>(data: T): CordMatch {
    let matchingCategory;
    if (isNumeric(data.matCatg10) && +data.matCatg10 > 0) {
      matchingCategory = `${data.matCatg10}/10`;
    } else if (isNumeric(data.matCatg8) && +data.matCatg8 > 0) {
      matchingCategory = `${data.matCatg8}/8`;
    } else if (isNumeric(data.matCatg6)) {
      matchingCategory = `${data.matCatg6}/6`;
    }

    const {hla, hlaMap} = mapTypingResults(data);

    const alleleLevelMatchProbability10List = range(0, 7).map(idx => formatProbability(data.pctOf10, idx, 10));
    const alleleLevelMatchProbability8List = range(0, 5).map(idx => formatProbability(data.pctOf8, idx, 8));
    const alleleLevelMatchProbability6List = range(0, 2).map(idx => formatProbability(data.pctOf6, idx, 6));

    return {
      alleleLevelMatchProbability8Values: data.pctOf8 || [],
      alleleLevelMatchProbability6Values: data.pctOf6 || [],
      alleleLevelMatchProbability10List,
      alleleLevelMatchProbability8List,
      alleleLevelMatchProbability6List,
      alleleLevelMatchProbability10: alleleLevelMatchProbability10List.join('\n'),
      alleleLevelMatchProbability8: mapAlleleLevelMProb8(alleleLevelMatchProbability8List),
      alleleLevelMatchProbability6: alleleLevelMatchProbability6List.join('\n'),
      ccr5: data.ccr5Genotype,
      dpb1TCEMatch: data.dpb1TCEMatch ? DPB1_MATCH_GRADE_VALUE_MAP[data.dpb1TCEMatch] : '',
      cd34FcWeight: data.cd34FcWeight || '',
      tncWeight: data.tncWeight || '',

      matchingCategory_Xf8: isNumeric(data.matCatg8) ? `${data.matCatg8}/8` : '',
      matchingCategory_Xf6: isNumeric(data.matCatg6) ? `${data.matCatg6}/6` : '',
      matchingCategory,
      hla,
      hlaMap,
      noTypingAvailable: (data.computedRacePhase || '').toUpperCase() === 'FAILED',
    };
  }

  createBiobankMatch<T extends Partial<BiobankMatchParams>>(data: T, ccrList: CcrModel[] = []): BiobankMatch {
    let matchingCategory;
    if (isNumeric(data.matCatg10) && +data.matCatg10 > 0) {
      matchingCategory = `${data.matCatg10}/10`;
    } else if (isNumeric(data.matCatg8) && +data.matCatg8 > 0) {
      matchingCategory = `${data.matCatg8}/8`;
    } else if (isNumeric(data.matCatg6)) {
      matchingCategory = `${data.matCatg6}/6`;
    }

    const {hla, hlaMap} = mapTypingResults(data);

    const alleleLevelMatchProbability10List = range(0, 5).map(idx => formatProbability(data.pctOf10, idx, 10));
    const alleleLevelMatchProbability8List = range(0, 5).map(idx => formatProbability(data.pctOf8, idx, 8));
    const alleleLevelMatchProbability6List = range(0, 2).map(idx => formatProbability(data.pctOf6, idx, 6));

    return {
      alleleLevelMatchProbability8Values: data.pctOf8 || [],
      alleleLevelMatchProbability6Values: data.pctOf6 || [],
      alleleLevelMatchProbability10List,
      alleleLevelMatchProbability8List,
      alleleLevelMatchProbability6List,
      alleleLevelMatchProbability10: alleleLevelMatchProbability10List.join('\n'),
      alleleLevelMatchProbability8: mapAlleleLevelMProb8(alleleLevelMatchProbability8List),
      alleleLevelMatchProbability6: alleleLevelMatchProbability6List.join('\n'),
      ccr5: data.ccr5Genotype,
      ccr5Code: data.ccr5Genotype && (ccrList.find(item => item.name === data.ccr5Genotype) || {}).code,
      dpb1TCEMatch: data.dpb1TCEMatch ? DPB1_MATCH_GRADE_VALUE_MAP[data.dpb1TCEMatch] : '',
      cd34FcWeight: data.cd34FcWeight || '',
      tncWeight: data.tncWeight || '',
      matchingCategory_Xf10: isNumeric(data.matCatg10) ? `${data.matCatg10}/10` : '',
      matchingCategory_Xf8: isNumeric(data.matCatg8) ? `${data.matCatg8}/8` : '',
      matchingCategory_Xf6: isNumeric(data.matCatg6) ? `${data.matCatg6}/6` : '',
      matchingCategory,
      hla,
      hlaMap,
      noTypingAvailable: (data.computedRacePhase || '').toUpperCase() === 'FAILED',
    };
  }
}
