/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Cord } from '../models/cord';
import { EthnicityRaceInfo } from '../models/ethnicity-race-info';
import { Form } from '../models/form';
import { getAdditionalTestForms } from '../fn/cord-controller/get-additional-test-forms';
import { GetAdditionalTestForms$Params } from '../fn/cord-controller/get-additional-test-forms';
import { getCordByGuid } from '../fn/cord-controller/get-cord-by-guid';
import { GetCordByGuid$Params } from '../fn/cord-controller/get-cord-by-guid';
import { getCordEthnicityRace } from '../fn/cord-controller/get-cord-ethnicity-race';
import { GetCordEthnicityRace$Params } from '../fn/cord-controller/get-cord-ethnicity-race';
import { getCordEthnicityRaceInBulk } from '../fn/cord-controller/get-cord-ethnicity-race-in-bulk';
import { GetCordEthnicityRaceInBulk$Params } from '../fn/cord-controller/get-cord-ethnicity-race-in-bulk';
import { getCordIdms } from '../fn/cord-controller/get-cord-idms';
import { GetCordIdms$Params } from '../fn/cord-controller/get-cord-idms';
import { getCordsByGuids } from '../fn/cord-controller/get-cords-by-guids';
import { GetCordsByGuids$Params } from '../fn/cord-controller/get-cords-by-guids';
import { getFullCordByGuid } from '../fn/cord-controller/get-full-cord-by-guid';
import { GetFullCordByGuid$Params } from '../fn/cord-controller/get-full-cord-by-guid';
import { getKir1 } from '../fn/cord-controller/get-kir-1';
import { GetKir1$Params } from '../fn/cord-controller/get-kir-1';
import { getProcessingProcedure } from '../fn/cord-controller/get-processing-procedure';
import { GetProcessingProcedure$Params } from '../fn/cord-controller/get-processing-procedure';
import { Kir } from '../models/kir';
import { ProcessingProcedure } from '../models/processing-procedure';

@Injectable({ providedIn: 'root' })
export class CordControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCordsByGuids()` */
  static readonly GetCordsByGuidsPath = '/cords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordsByGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordsByGuids$Response(params: GetCordsByGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Cord>>> {
    return getCordsByGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCordsByGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordsByGuids(params: GetCordsByGuids$Params, context?: HttpContext): Observable<Array<Cord>> {
    return this.getCordsByGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Cord>>): Array<Cord> => r.body)
    );
  }

  /** Path part for operation `getProcessingProcedure()` */
  static readonly GetProcessingProcedurePath = '/cords/processing-procedure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessingProcedure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProcessingProcedure$Response(params: GetProcessingProcedure$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: ProcessingProcedure;
}>> {
    return getProcessingProcedure(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProcessingProcedure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProcessingProcedure(params: GetProcessingProcedure$Params, context?: HttpContext): Observable<{
[key: string]: ProcessingProcedure;
}> {
    return this.getProcessingProcedure$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: ProcessingProcedure;
}>): {
[key: string]: ProcessingProcedure;
} => r.body)
    );
  }

  /** Path part for operation `getCordEthnicityRaceInBulk()` */
  static readonly GetCordEthnicityRaceInBulkPath = '/cords/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordEthnicityRaceInBulk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordEthnicityRaceInBulk$Response(params: GetCordEthnicityRaceInBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>> {
    return getCordEthnicityRaceInBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCordEthnicityRaceInBulk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCordEthnicityRaceInBulk(params: GetCordEthnicityRaceInBulk$Params, context?: HttpContext): Observable<{
[key: string]: EthnicityRaceInfo;
}> {
    return this.getCordEthnicityRaceInBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: EthnicityRaceInfo;
}>): {
[key: string]: EthnicityRaceInfo;
} => r.body)
    );
  }

  /** Path part for operation `getCordByGuid()` */
  static readonly GetCordByGuidPath = '/cords/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordByGuid$Response(params: GetCordByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Cord>> {
    return getCordByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCordByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordByGuid(params: GetCordByGuid$Params, context?: HttpContext): Observable<Cord> {
    return this.getCordByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cord>): Cord => r.body)
    );
  }

  /** Path part for operation `getKir1()` */
  static readonly GetKir1Path = '/cords/{guid}/kir';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKir1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir1$Response(params: GetKir1$Params, context?: HttpContext): Observable<StrictHttpResponse<Kir>> {
    return getKir1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKir1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKir1(params: GetKir1$Params, context?: HttpContext): Observable<Kir> {
    return this.getKir1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Kir>): Kir => r.body)
    );
  }

  /** Path part for operation `getCordIdms()` */
  static readonly GetCordIdmsPath = '/cords/{guid}/idms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordIdms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordIdms$Response(params: GetCordIdms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Form>>> {
    return getCordIdms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCordIdms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordIdms(params: GetCordIdms$Params, context?: HttpContext): Observable<Array<Form>> {
    return this.getCordIdms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Form>>): Array<Form> => r.body)
    );
  }

  /** Path part for operation `getFullCordByGuid()` */
  static readonly GetFullCordByGuidPath = '/cords/{guid}/full';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullCordByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullCordByGuid$Response(params: GetFullCordByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Cord>> {
    return getFullCordByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFullCordByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullCordByGuid(params: GetFullCordByGuid$Params, context?: HttpContext): Observable<Cord> {
    return this.getFullCordByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cord>): Cord => r.body)
    );
  }

  /** Path part for operation `getCordEthnicityRace()` */
  static readonly GetCordEthnicityRacePath = '/cords/{guid}/ethnicity-race';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCordEthnicityRace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordEthnicityRace$Response(params: GetCordEthnicityRace$Params, context?: HttpContext): Observable<StrictHttpResponse<EthnicityRaceInfo>> {
    return getCordEthnicityRace(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCordEthnicityRace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCordEthnicityRace(params: GetCordEthnicityRace$Params, context?: HttpContext): Observable<EthnicityRaceInfo> {
    return this.getCordEthnicityRace$Response(params, context).pipe(
      map((r: StrictHttpResponse<EthnicityRaceInfo>): EthnicityRaceInfo => r.body)
    );
  }

  /** Path part for operation `getAdditionalTestForms()` */
  static readonly GetAdditionalTestFormsPath = '/cords/{guid}/additional-tests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdditionalTestForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalTestForms$Response(params: GetAdditionalTestForms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Form>>> {
    return getAdditionalTestForms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAdditionalTestForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalTestForms(params: GetAdditionalTestForms$Params, context?: HttpContext): Observable<Array<Form>> {
    return this.getAdditionalTestForms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Form>>): Array<Form> => r.body)
    );
  }

}
