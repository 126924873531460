import {ChangeDetectionStrategy, Component, ContentChild, Directive, Input, Self, TemplateRef} from '@angular/core';
import {NgIf, NgTemplateOutlet} from '@angular/common';

@Directive({
  selector: '[msModalHeader]',
  standalone: true,
})
export class ModalHeaderDirective {
  constructor(@Self() public readonly template: TemplateRef<any>) {}
}

@Directive({
  selector: '[msModalBody]',
  standalone: true,
})
export class ModalBodyDirective {
  constructor(@Self() public readonly template: TemplateRef<any>) {}
}

@Directive({
  selector: '[msModalFooter]',
  standalone: true,
})
export class ModalFooterDirective {
  constructor(@Self() public readonly template: TemplateRef<any>) {}
}

@Component({
  selector: 'ms-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
})
export class ModalComponent {
  @Input()
  modalId!: string;

  @Input()
  caption: string;

  @ContentChild(ModalHeaderDirective, {static: true})
  modalHeaderTpl: ModalHeaderDirective;

  @ContentChild(ModalBodyDirective, {static: true})
  modalBodyTpl: ModalBodyDirective;

  @ContentChild(ModalFooterDirective, {static: true})
  modalFooterTpl: ModalFooterDirective;
}
