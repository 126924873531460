import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {shareReplay, takeUntil, tap, map, distinctUntilChanged} from 'rxjs/operators';
import {
  LoadRunningSearches,
  LoadSearches,
  LoadUnviewedSearches,
  MarkAsViewedSearches,
  MarkAsViewedSourceSearch,
} from './searches.action';
import {SearchesState, SearchesStateModel} from './searches.state';
import {toMap} from '@matchsource/utils';
import {toTimestamp} from '@matchsource/date';
import {isSearchStatusUnviwed} from './utils';
import {SourceType} from '@matchsource/models/source';
import {SearchStateModel} from '@matchsource/models/search-states';
import {SearchControllerService} from '@matchsource/api-generated/search-match';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {toParams} from '@matchsource/api/search';
import {RecipientSearchControllerService} from '@matchsource/api-generated/subject';
import {RunLatestSearchParams} from '@matchsource/models/search-results';
import {SearchCriteria, SearchNotificationType} from '@matchsource/models/search';
import {EventService} from '@matchsource/event';

export interface CompleteSearchEvent {
  searchGuid: MsApp.Guid;
  patientId: MsApp.Guid;
  autoRedirectToResults: boolean;
  sourceType: SourceType;
  isOnlyBdp: boolean;
}

const byId = (state: SearchesStateModel) => (id: MsApp.Guid) => state.entities[id];

const searchStatusAreChanged = (
  {searchStatuses: newSearchStatuses}: any,
  {searchStatuses: prevSearchStatuses}: any
): boolean =>
  Object.entries<{status: any}>(prevSearchStatuses).every(
    ([sourceType, {status}]) => status !== newSearchStatuses[sourceType].status
  );
@Injectable({
  providedIn: 'root',
})
export class SearchesService implements OnDestroy {
  public readonly data$: Observable<SearchesStateModel>;
  public readonly list$: Observable<SearchStateModel[]>;
  public readonly running$: Observable<SearchStateModel[]>;
  public readonly unviewed$: Observable<SearchStateModel[]>;
  public readonly unviewedCount$: Observable<number>;
  public readonly hasUnviewed$: Observable<boolean>;
  public readonly inProgress$: Observable<SearchStateModel[]>;
  public readonly inProgressCount$: Observable<number>;
  public readonly hasInProgress$: Observable<boolean>;

  public get list(): SearchStateModel[] {
    return Object.values(this.store.selectSnapshot<SearchesStateModel>(SearchesState).entities).map(
      search => new SearchStateModel(search)
    );
  }

  public get running(): SearchStateModel[] {
    return this.list.filter(search => search.sourceSearches.some(({status}) => !isSearchStatusUnviwed(status)));
  }

  public get unviewed(): SearchStateModel[] {
    return this.list.filter(search =>
      search.sourceSearches.some(({status, viewed}) => isSearchStatusUnviwed(status) && !viewed)
    );
  }

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly events: EventService,
    private readonly searchControllerService: SearchControllerService,
    private readonly recipientSearchControllerService: RecipientSearchControllerService,
  ) {
    this.data$ = this.store.select<SearchesStateModel>(SearchesState).pipe(
      distinctUntilChanged(),
      tap(() => {
        if (!this.store.selectSnapshot(SearchesState.inProgressOrLoaded)) {
          this.store.dispatch(new LoadSearches());
        }
      }),
      takeUntil(this.destroy$),
      shareReplay({refCount: true, bufferSize: 1})
    );

    this.list$ = this.data$.pipe(
      map((state: SearchesStateModel) => Object.values(state.entities).map(search => new SearchStateModel(search)))
    );

    this.inProgress$ = this.list$.pipe(map(searches => searches.filter(search => search.inProgress)));
    this.inProgressCount$ = this.inProgress$.pipe(map(inProgressList => inProgressList.length));
    this.hasInProgress$ = this.inProgressCount$.pipe(map(inProgressCount => inProgressCount > 0));
    this.running$ = this.list$.pipe(
      map(searches =>
        searches.filter(search => search.sourceSearches.some(({status}) => !isSearchStatusUnviwed(status)))
      )
    );
    this.unviewed$ = this.list$.pipe(
      map(searches =>
        searches.filter(search =>
          search.sourceSearches.every(({status, viewed}) => isSearchStatusUnviwed(status) && !viewed)
        )
      )
    );
    this.unviewedCount$ = this.unviewed$.pipe(map(unviewedList => unviewedList.length));
    this.hasUnviewed$ = this.unviewedCount$.pipe(map(unviewedCount => unviewedCount > 0));
  }

  getById(id: MsApp.Guid): Observable<SearchStateModel> {
    return combineLatest([of(id), this.data$]).pipe(
      map(([seachId, data]) => new SearchStateModel(byId(data)(seachId)))
    );
  }

  load() {
    return this.store.dispatch(new LoadSearches());
  }

  loadRunning() {
    return this.store.dispatch(new LoadRunningSearches());
  }

  loadUnviewed() {
    return this.store.dispatch(new LoadUnviewedSearches());
  }

  handleRunning(prevRunning: SearchStateModel[], running: SearchStateModel[]) {
    const newSearchesMap = toMap(running, 'guid');

    const changedSearches = prevRunning.filter(search => {
      const updatedSearch = newSearchesMap[search.guid];

      if (!updatedSearch) {
        return false;
      }

      return updatedSearch && searchStatusAreChanged(updatedSearch, search);
    });

    const becameCompletedDonor = prevRunning.filter(search => {
      const newSearch = newSearchesMap[search.guid];
      if (!newSearch) {
        return false;
      }
      return !search.isDonorSearchCompleted && newSearch.isDonorSearchCompleted;
    });
    const becameCompletedCord = prevRunning.filter(search => {
      const newSearch = newSearchesMap[search.guid];
      if (!newSearch) {
        return false;
      }
      return !search.isCordSearchCompleted && newSearch.isCordSearchCompleted;
    });
    const becameCompletedBdp = prevRunning.filter(search => {
      const newSearch = newSearchesMap[search.guid];
      if (!newSearch) {
        return false;
      }
      return !search.isBdpSearchCompleted && newSearch.isBdpSearchCompleted;
    });

    // If search becomes completed, it no longer comes in the data
    const becameCompleted = prevRunning.filter(({guid}) => !newSearchesMap[guid]);
    becameCompleted.forEach(search => {
      if (search.hasDonorSearch && !search.isDonorSearchCompleted) {
        becameCompletedDonor.push(search);
      }
      if (search.hasCordSearch && !search.isCordSearchCompleted) {
        becameCompletedCord.push(search);
      }
      if (search.hasBdpSearch && !search.isBdpSearchCompleted) {
        becameCompletedBdp.push(search);
      }
    });

    const becameCompletedSources = [
      ...becameCompletedDonor.map(search => ({
        search,
        sourceType: 'DONOR' as SourceType,
        completeDate: search.donorSearch.searchCompleteDate,
      })),
      ...becameCompletedCord.map(search => ({
        search,
        sourceType: 'CORD' as SourceType,
        completeDate: search.cordSearch.searchCompleteDate,
      })),
      ...becameCompletedBdp.map(search => ({
        search,
        sourceType: 'BDP' as SourceType,
        completeDate: search.bdpSearch.searchCompleteDate,
      })),
    ];

    // The only one search was running & search result is received - the user is navigated to the Search & Match page
    const allSearchesFinished = prevRunning.length === becameCompleted.length;

    if (becameCompleted.length > 0) {
      this.loadUnviewed();
    }

    // Send search complete notifications
    becameCompletedSources
      .sort((searchA, searchB) => toTimestamp(searchA.completeDate) - toTimestamp(searchB.completeDate))
      .forEach((item, idx) => {
        this.events.dispatch<CompleteSearchEvent>(SearchNotificationType.SearchCompleted, {
          searchGuid: item.search.guid,
          patientId: item.search.patientId,
          autoRedirectToResults: idx === becameCompletedSources.length - 1 && allSearchesFinished,
          sourceType: item.sourceType,
          isOnlyBdp: !item.search.searchCriteria.cords && !item.search.searchCriteria.donors,
        });
      });

    if (changedSearches.length || becameCompletedSources.length) {
      this.events.dispatch<CompleteSearchEvent>(SearchNotificationType.SearchStatusChanged);
    }
  }

  clearSourceSearch(ids: MsApp.Guid[], sourceType: SourceType) {
    if (ids.length) {
      this.store.dispatch(new MarkAsViewedSourceSearch(ids, sourceType));
    }
  }

  clearGrouped(ids: MsApp.Guid[]) {
    if (ids.length > 0) {
      this.store.dispatch(new MarkAsViewedSearches(ids));
    }
  }

  reRunSearch(params: RunLatestSearchParams): Observable<{[key: string]: string}> {
    const {abOnly, searchCriteria, phenotypeId, patientId: recipientGuid} = params;
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.RunningSearches);
    return this.searchControllerService.reRunSearch(
      {
        body: {
          abOnly,
          parameters: toParams(searchCriteria),
          phenotypeId,
          recipientGuid,
        },
      },
      context()
    );
  }

  runEmptySearch(
    guid: MsApp.Guid,
    phenotypeId: number,
    searchCriteria: SearchCriteria
  ): Observable<{[key: string]: string}> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.RunningSearches);
    return this.recipientSearchControllerService.runSearch(
      {
        guid,
        body: {
          parameters: toParams(searchCriteria),
          phenotypeId,
        },
      },
      context()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
