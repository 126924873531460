/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/alert-controller/create';
import { Create$Params } from '../fn/alert-controller/create';
import { create1 } from '../fn/alert-controller/create-1';
import { Create1$Params } from '../fn/alert-controller/create-1';
import { delete$ } from '../fn/alert-controller/delete';
import { Delete$Params } from '../fn/alert-controller/delete';
import { deleteAllForPatient } from '../fn/alert-controller/delete-all-for-patient';
import { DeleteAllForPatient$Params } from '../fn/alert-controller/delete-all-for-patient';
import { loadAllForBp } from '../fn/alert-controller/load-all-for-bp';
import { LoadAllForBp$Params } from '../fn/alert-controller/load-all-for-bp';
import { loadForBpAndFilter } from '../fn/alert-controller/load-for-bp-and-filter';
import { LoadForBpAndFilter$Params } from '../fn/alert-controller/load-for-bp-and-filter';
import { NotificationEvent } from '../models/notification-event';

@Injectable({ providedIn: 'root' })
export class AlertControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadAllForBp()` */
  static readonly LoadAllForBpPath = '/alerts';

  /**
   * This method loads all alerts for a business party defined by its GUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadAllForBp()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllForBp$Response(params: LoadAllForBp$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationEvent>>> {
    return loadAllForBp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads all alerts for a business party defined by its GUID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadAllForBp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllForBp(params: LoadAllForBp$Params, context?: HttpContext): Observable<Array<NotificationEvent>> {
    return this.loadAllForBp$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationEvent>>): Array<NotificationEvent> => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/alerts';

  /**
   * This method creates a notification event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method creates a notification event
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<number> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `deleteAllForPatient()` */
  static readonly DeleteAllForPatientPath = '/alerts';

  /**
   * This method deletes all notifications for a given patient (defined by the GUID)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllForPatient$Response(params: DeleteAllForPatient$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAllForPatient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes all notifications for a given patient (defined by the GUID)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllForPatient(params: DeleteAllForPatient$Params, context?: HttpContext): Observable<void> {
    return this.deleteAllForPatient$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadForBpAndFilter()` */
  static readonly LoadForBpAndFilterPath = '/alerts/filter';

  /**
   * This method loads all alerts by a bp GUID and filter
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadForBpAndFilter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadForBpAndFilter$Response(params: LoadForBpAndFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationEvent>>> {
    return loadForBpAndFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads all alerts by a bp GUID and filter
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadForBpAndFilter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadForBpAndFilter(params: LoadForBpAndFilter$Params, context?: HttpContext): Observable<Array<NotificationEvent>> {
    return this.loadForBpAndFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationEvent>>): Array<NotificationEvent> => r.body)
    );
  }

  /** Path part for operation `create1()` */
  static readonly Create1Path = '/alerts/batch';

  /**
   * This method creates batch of notification events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: Create1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return create1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method creates batch of notification events
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: Create1$Params, context?: HttpContext): Observable<void> {
    return this.create1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/alerts/list';

  /**
   * This method deletes notifications with given IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes notifications with given IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
