/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CtInstructionReport } from '../../models/ct-instruction-report';

export interface GetInstructionReport$Params {
  bpGuid: string;
  isDomestic: boolean;
  secCode?: string;
}

export function getInstructionReport(http: HttpClient, rootUrl: string, params: GetInstructionReport$Params, context?: HttpContext): Observable<StrictHttpResponse<CtInstructionReport>> {
  const rb = new RequestBuilder(rootUrl, getInstructionReport.PATH, 'get');
  if (params) {
    rb.query('bpGuid', params.bpGuid, {});
    rb.query('isDomestic', params.isDomestic, {});
    rb.query('secCode', params.secCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CtInstructionReport>;
    })
  );
}

getInstructionReport.PATH = '/sample/instruction';
