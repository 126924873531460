import {Transition} from '@uirouter/angular';
import {CartPatientService, PatientCartService} from '@matchsource/store/cart';
import {Provider} from '@angular/core';
import {ResolvableLiteral} from '@uirouter/core/lib/resolve/interface';

export function cartResolver(patientCartResolver: () => Promise<PatientCartService>) {
  return patientCartResolver();
}

export function cartPatientServiceFactory(transition: Transition) {
  return transition.injector().get<PatientCartService>('patientCart');
}

export const cartRouteResolve: ResolvableLiteral = {
  token: 'patientCart',
  deps: ['patientCartResolver'],
  resolveFn: cartResolver,
};

export const cartServiceProvider: Provider = {
  provide: CartPatientService,
  useFactory: cartPatientServiceFactory,
  deps: [Transition],
};
