/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CoopDonor } from '../models/coop-donor';
import { create1 } from '../fn/coop-donor-controller/create-1';
import { Create1$Params } from '../fn/coop-donor-controller/create-1';
import { CreateCoopSourceResult } from '../models/create-coop-source-result';
import { getCoopDonor } from '../fn/coop-donor-controller/get-coop-donor';
import { GetCoopDonor$Params } from '../fn/coop-donor-controller/get-coop-donor';
import { getCoopDonorsByPatientGuid } from '../fn/coop-donor-controller/get-coop-donors-by-patient-guid';
import { GetCoopDonorsByPatientGuid$Params } from '../fn/coop-donor-controller/get-coop-donors-by-patient-guid';
import { publishAddMatchedDonor } from '../fn/coop-donor-controller/publish-add-matched-donor';
import { PublishAddMatchedDonor$Params } from '../fn/coop-donor-controller/publish-add-matched-donor';
import { update1 } from '../fn/coop-donor-controller/update-1';
import { Update1$Params } from '../fn/coop-donor-controller/update-1';
import { UpdateCoopSourceResult } from '../models/update-coop-source-result';

@Injectable({ providedIn: 'root' })
export class CoopDonorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCoopDonor()` */
  static readonly GetCoopDonorPath = '/donors/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopDonor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonor$Response(params: GetCoopDonor$Params, context?: HttpContext): Observable<StrictHttpResponse<CoopDonor>> {
    return getCoopDonor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoopDonor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonor(params: GetCoopDonor$Params, context?: HttpContext): Observable<CoopDonor> {
    return this.getCoopDonor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoopDonor>): CoopDonor => r.body)
    );
  }

  /** Path part for operation `update1()` */
  static readonly Update1Path = '/donors/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: Update1$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCoopSourceResult>> {
    return update1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: Update1$Params, context?: HttpContext): Observable<UpdateCoopSourceResult> {
    return this.update1$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCoopSourceResult>): UpdateCoopSourceResult => r.body)
    );
  }

  /** Path part for operation `getCoopDonorsByPatientGuid()` */
  static readonly GetCoopDonorsByPatientGuidPath = '/donors/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopDonorsByPatientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonorsByPatientGuid$Response(params: GetCoopDonorsByPatientGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoopDonor>>> {
    return getCoopDonorsByPatientGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoopDonorsByPatientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopDonorsByPatientGuid(params: GetCoopDonorsByPatientGuid$Params, context?: HttpContext): Observable<Array<CoopDonor>> {
    return this.getCoopDonorsByPatientGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoopDonor>>): Array<CoopDonor> => r.body)
    );
  }

  /** Path part for operation `create1()` */
  static readonly Create1Path = '/donors/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: Create1$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCoopSourceResult>> {
    return create1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: Create1$Params, context?: HttpContext): Observable<CreateCoopSourceResult> {
    return this.create1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCoopSourceResult>): CreateCoopSourceResult => r.body)
    );
  }

  /** Path part for operation `publishAddMatchedDonor()` */
  static readonly PublishAddMatchedDonorPath = '/donors/coop/publish/matched/{donorGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishAddMatchedDonor()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedDonor$Response(params: PublishAddMatchedDonor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishAddMatchedDonor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishAddMatchedDonor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedDonor(params: PublishAddMatchedDonor$Params, context?: HttpContext): Observable<void> {
    return this.publishAddMatchedDonor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
