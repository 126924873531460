/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrderLineItemServiceCode } from '../../models/order-line-item-service-code';

export interface GetOrderTrackingServiceCodes$Params {
      body: Array<number>
}

export function getOrderTrackingServiceCodes(http: HttpClient, rootUrl: string, params: GetOrderTrackingServiceCodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderLineItemServiceCode>>> {
  const rb = new RequestBuilder(rootUrl, getOrderTrackingServiceCodes.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<OrderLineItemServiceCode>>;
    })
  );
}

getOrderTrackingServiceCodes.PATH = '/orders/order-line-items/service-codes';
