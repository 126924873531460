/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/secondary-code-controller/create';
import { Create$Params } from '../fn/secondary-code-controller/create';
import { getAllForTc } from '../fn/secondary-code-controller/get-all-for-tc';
import { GetAllForTc$Params } from '../fn/secondary-code-controller/get-all-for-tc';
import { getByGuid } from '../fn/secondary-code-controller/get-by-guid';
import { GetByGuid$Params } from '../fn/secondary-code-controller/get-by-guid';
import { getForTcAndSecondaryCode } from '../fn/secondary-code-controller/get-for-tc-and-secondary-code';
import { GetForTcAndSecondaryCode$Params } from '../fn/secondary-code-controller/get-for-tc-and-secondary-code';
import { SecondaryCode } from '../models/secondary-code';
import { update } from '../fn/secondary-code-controller/update';
import { Update$Params } from '../fn/secondary-code-controller/update';

@Injectable({ providedIn: 'root' })
export class SecondaryCodeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllForTc()` */
  static readonly GetAllForTcPath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllForTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForTc$Response(params: GetAllForTc$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SecondaryCode>>> {
    return getAllForTc(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllForTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllForTc(params: GetAllForTc$Params, context?: HttpContext): Observable<Array<SecondaryCode>> {
    return this.getAllForTc$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecondaryCode>>): Array<SecondaryCode> => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/secondary-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `getByGuid()` */
  static readonly GetByGuidPath = '/secondary-codes/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByGuid$Response(params: GetByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<SecondaryCode>> {
    return getByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByGuid(params: GetByGuid$Params, context?: HttpContext): Observable<SecondaryCode> {
    return this.getByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecondaryCode>): SecondaryCode => r.body)
    );
  }

  /** Path part for operation `getForTcAndSecondaryCode()` */
  static readonly GetForTcAndSecondaryCodePath = '/secondary-codes/find';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getForTcAndSecondaryCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForTcAndSecondaryCode$Response(params: GetForTcAndSecondaryCode$Params, context?: HttpContext): Observable<StrictHttpResponse<SecondaryCode>> {
    return getForTcAndSecondaryCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getForTcAndSecondaryCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getForTcAndSecondaryCode(params: GetForTcAndSecondaryCode$Params, context?: HttpContext): Observable<SecondaryCode> {
    return this.getForTcAndSecondaryCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecondaryCode>): SecondaryCode => r.body)
    );
  }

}
