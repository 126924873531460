import {Component} from '@angular/core';
import {Patient} from '@matchsource/models/search-status';
import {Navigate} from 'ngxs-ui-router';
import {Store} from '@ngxs/store';
import {BloodTypes} from '@matchsource/models/shared';
import {TranslocoDirective, TranslocoPipe} from '@jsverse/transloco';
import {ButtonDirective, ToastWrapperComponent} from '@matchsource/nmdp-ui';
import {NgIf} from '@angular/common';
import {PatientIdentificationComponent} from '../patient-identification/patient-identification.component';
import {SourceTypeSearchModel} from '@matchsource/models/search';
import {BloodSourceType} from '@matchsource/models/source';

@Component({
  selector: 'ms-search-completed',
  templateUrl: './search-completed.component.html',
  standalone: true,
  imports: [
    ToastWrapperComponent,
    PatientIdentificationComponent,
    NgIf,
    ButtonDirective,
    TranslocoDirective,
    TranslocoPipe,
  ],
})
export class SearchCompletedComponent {
  data: {
    sourceType: string;
    search: SourceTypeSearchModel;
    showSearchResultsLink: boolean;
    patient: Patient;
  };

  constructor(private readonly store: Store) {}

  openSearchResult(): void {
    const {sourceType, search, patient} = this.data;
    let bloodSourceType = null;
    if (sourceType === BloodTypes.Donor) {
      bloodSourceType = BloodSourceType.Donors;
    }
    if (sourceType === BloodTypes.Cord) {
      bloodSourceType = BloodSourceType.Cords;
    }
    if (sourceType === BloodTypes.AdultDonorCryopreservedProduct) {
      bloodSourceType = BloodSourceType.Biobanks;
    }

    this.store.dispatch(
      new Navigate(`patient-search.results.${bloodSourceType}`, {
        patientId: patient.id,
        phenotypeId: search.phenotype,
      })
    );
  }
}
