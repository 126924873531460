/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/incident-controller/create';
import { Create$Params } from '../fn/incident-controller/create';
import { isAllowedToCreate } from '../fn/incident-controller/is-allowed-to-create';
import { IsAllowedToCreate$Params } from '../fn/incident-controller/is-allowed-to-create';

@Injectable({ providedIn: 'root' })
export class IncidentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/incident/create';

  /**
   * Sends email to the Service Center mailbox with incident details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends email to the Service Center mailbox with incident details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<void> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `isAllowedToCreate()` */
  static readonly IsAllowedToCreatePath = '/incident/create/is-allowed';

  /**
   * Verifies if the context user is allowed to create an incident.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAllowedToCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAllowedToCreate$Response(params: IsAllowedToCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isAllowedToCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Verifies if the context user is allowed to create an incident.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isAllowedToCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAllowedToCreate(params: IsAllowedToCreate$Params, context?: HttpContext): Observable<boolean> {
    return this.isAllowedToCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
