/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addSourceToOpl } from '../fn/order-preview-list-controller/add-source-to-opl';
import { AddSourceToOpl$Params } from '../fn/order-preview-list-controller/add-source-to-opl';
import { changeSourceOrder } from '../fn/order-preview-list-controller/change-source-order';
import { ChangeSourceOrder$Params } from '../fn/order-preview-list-controller/change-source-order';
import { copySourcesToOpl } from '../fn/order-preview-list-controller/copy-sources-to-opl';
import { CopySourcesToOpl$Params } from '../fn/order-preview-list-controller/copy-sources-to-opl';
import { createOpl } from '../fn/order-preview-list-controller/create-opl';
import { CreateOpl$Params } from '../fn/order-preview-list-controller/create-opl';
import { deleteOpl } from '../fn/order-preview-list-controller/delete-opl';
import { DeleteOpl$Params } from '../fn/order-preview-list-controller/delete-opl';
import { DonorOpl } from '../models/donor-opl';
import { getDefaultOpl } from '../fn/order-preview-list-controller/get-default-opl';
import { GetDefaultOpl$Params } from '../fn/order-preview-list-controller/get-default-opl';
import { getDonorContactReport } from '../fn/order-preview-list-controller/get-donor-contact-report';
import { GetDonorContactReport$Params } from '../fn/order-preview-list-controller/get-donor-contact-report';
import { getDonorContactReportByReportIds } from '../fn/order-preview-list-controller/get-donor-contact-report-by-report-ids';
import { GetDonorContactReportByReportIds$Params } from '../fn/order-preview-list-controller/get-donor-contact-report-by-report-ids';
import { getOpl } from '../fn/order-preview-list-controller/get-opl';
import { GetOpl$Params } from '../fn/order-preview-list-controller/get-opl';
import { getOpls } from '../fn/order-preview-list-controller/get-opls';
import { GetOpls$Params } from '../fn/order-preview-list-controller/get-opls';
import { getSuggestedTypings } from '../fn/order-preview-list-controller/get-suggested-typings';
import { GetSuggestedTypings$Params } from '../fn/order-preview-list-controller/get-suggested-typings';
import { OplReportsDto } from '../models/opl-reports-dto';
import { OrderPreviewListDto } from '../models/order-preview-list-dto';
import { PageOplReportsDto } from '../models/page-opl-reports-dto';
import { removeSourceFromOpl } from '../fn/order-preview-list-controller/remove-source-from-opl';
import { RemoveSourceFromOpl$Params } from '../fn/order-preview-list-controller/remove-source-from-opl';
import { removeSourcesFromOpl } from '../fn/order-preview-list-controller/remove-sources-from-opl';
import { RemoveSourcesFromOpl$Params } from '../fn/order-preview-list-controller/remove-sources-from-opl';
import { saveOrUpdateDefaultOpl } from '../fn/order-preview-list-controller/save-or-update-default-opl';
import { SaveOrUpdateDefaultOpl$Params } from '../fn/order-preview-list-controller/save-or-update-default-opl';
import { updateOpl } from '../fn/order-preview-list-controller/update-opl';
import { UpdateOpl$Params } from '../fn/order-preview-list-controller/update-opl';
import { updateOplSource } from '../fn/order-preview-list-controller/update-opl-source';
import { UpdateOplSource$Params } from '../fn/order-preview-list-controller/update-opl-source';

@Injectable({ providedIn: 'root' })
export class OrderPreviewListControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getOpl()` */
  static readonly GetOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpl$Response(params: GetOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPreviewListDto>> {
    return getOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpl(params: GetOpl$Params, context?: HttpContext): Observable<OrderPreviewListDto> {
    return this.getOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderPreviewListDto>): OrderPreviewListDto => r.body)
    );
  }

  /** Path part for operation `updateOpl()` */
  static readonly UpdateOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOpl$Response(params: UpdateOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOpl(params: UpdateOpl$Params, context?: HttpContext): Observable<void> {
    return this.updateOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOpl()` */
  static readonly DeleteOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOpl$Response(params: DeleteOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOpl(params: DeleteOpl$Params, context?: HttpContext): Observable<void> {
    return this.deleteOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateOplSource()` */
  static readonly UpdateOplSourcePath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOplSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOplSource$Response(params: UpdateOplSource$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateOplSource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOplSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOplSource(params: UpdateOplSource$Params, context?: HttpContext): Observable<void> {
    return this.updateOplSource$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addSourceToOpl()` */
  static readonly AddSourceToOplPath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSourceToOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSourceToOpl$Response(params: AddSourceToOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addSourceToOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSourceToOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSourceToOpl(params: AddSourceToOpl$Params, context?: HttpContext): Observable<void> {
    return this.addSourceToOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeSourcesFromOpl()` */
  static readonly RemoveSourcesFromOplPath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSourcesFromOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourcesFromOpl$Response(params: RemoveSourcesFromOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeSourcesFromOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeSourcesFromOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourcesFromOpl(params: RemoveSourcesFromOpl$Params, context?: HttpContext): Observable<void> {
    return this.removeSourcesFromOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeSourceOrder()` */
  static readonly ChangeSourceOrderPath = '/opls/{id}/sources/{guid}/order/{position}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeSourceOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSourceOrder$Response(params: ChangeSourceOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeSourceOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeSourceOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSourceOrder(params: ChangeSourceOrder$Params, context?: HttpContext): Observable<void> {
    return this.changeSourceOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getOpls()` */
  static readonly GetOplsPath = '/opls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpls$Response(params: GetOpls$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderPreviewListDto>>> {
    return getOpls(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpls(params: GetOpls$Params, context?: HttpContext): Observable<Array<OrderPreviewListDto>> {
    return this.getOpls$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderPreviewListDto>>): Array<OrderPreviewListDto> => r.body)
    );
  }

  /** Path part for operation `createOpl()` */
  static readonly CreateOplPath = '/opls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOpl$Response(params: CreateOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {
    return createOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOpl(params: CreateOpl$Params, context?: HttpContext): Observable<{
[key: string]: number;
}> {
    return this.createOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>): {
[key: string]: number;
} => r.body)
    );
  }

  /** Path part for operation `getDonorContactReport()` */
  static readonly GetDonorContactReportPath = '/opls/{oplId}/donor-contact-report';

  /**
   * Fetch donor contact report by opl id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorContactReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorContactReport$Response(params: GetDonorContactReport$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOplReportsDto>> {
    return getDonorContactReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch donor contact report by opl id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorContactReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorContactReport(params: GetDonorContactReport$Params, context?: HttpContext): Observable<PageOplReportsDto> {
    return this.getDonorContactReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOplReportsDto>): PageOplReportsDto => r.body)
    );
  }

  /** Path part for operation `saveOrUpdateDefaultOpl()` */
  static readonly SaveOrUpdateDefaultOplPath = '/opls/{id}/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOrUpdateDefaultOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveOrUpdateDefaultOpl$Response(params: SaveOrUpdateDefaultOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveOrUpdateDefaultOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveOrUpdateDefaultOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveOrUpdateDefaultOpl(params: SaveOrUpdateDefaultOpl$Params, context?: HttpContext): Observable<void> {
    return this.saveOrUpdateDefaultOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSuggestedTypings()` */
  static readonly GetSuggestedTypingsPath = '/opls/suggested-typings/{guid}';

  /**
   * Get suggested typings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedTypings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTypings$Response(params: GetSuggestedTypings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DonorOpl>>> {
    return getSuggestedTypings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get suggested typings
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuggestedTypings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTypings(params: GetSuggestedTypings$Params, context?: HttpContext): Observable<Array<DonorOpl>> {
    return this.getSuggestedTypings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DonorOpl>>): Array<DonorOpl> => r.body)
    );
  }

  /** Path part for operation `getDonorContactReportByReportIds()` */
  static readonly GetDonorContactReportByReportIdsPath = '/opls/donor-contact-report-by-ids';

  /**
   * Fetch donor contact reports by report ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorContactReportByReportIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorContactReportByReportIds$Response(params: GetDonorContactReportByReportIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OplReportsDto>>> {
    return getDonorContactReportByReportIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch donor contact reports by report ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDonorContactReportByReportIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorContactReportByReportIds(params: GetDonorContactReportByReportIds$Params, context?: HttpContext): Observable<Array<OplReportsDto>> {
    return this.getDonorContactReportByReportIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OplReportsDto>>): Array<OplReportsDto> => r.body)
    );
  }

  /** Path part for operation `copySourcesToOpl()` */
  static readonly CopySourcesToOplPath = '/opls/copy/sources';

  /**
   * Copy sources to the opl
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copySourcesToOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copySourcesToOpl$Response(params: CopySourcesToOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return copySourcesToOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * Copy sources to the opl
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copySourcesToOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copySourcesToOpl(params: CopySourcesToOpl$Params, context?: HttpContext): Observable<void> {
    return this.copySourcesToOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDefaultOpl()` */
  static readonly GetDefaultOplPath = '/opls/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultOpl$Response(params: GetDefaultOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPreviewListDto>> {
    return getDefaultOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultOpl(params: GetDefaultOpl$Params, context?: HttpContext): Observable<OrderPreviewListDto> {
    return this.getDefaultOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderPreviewListDto>): OrderPreviewListDto => r.body)
    );
  }

  /** Path part for operation `removeSourceFromOpl()` */
  static readonly RemoveSourceFromOplPath = '/opls/{id}/sources/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSourceFromOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourceFromOpl$Response(params: RemoveSourceFromOpl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeSourceFromOpl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeSourceFromOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourceFromOpl(params: RemoveSourceFromOpl$Params, context?: HttpContext): Observable<void> {
    return this.removeSourceFromOpl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
