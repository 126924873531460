/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CaseResourceDto } from '../models/case-resource-dto';
import { CaseResourceExtendedDto } from '../models/case-resource-extended-dto';
import { getActiveRole } from '../fn/case-resource-controller/get-active-role';
import { GetActiveRole$Params } from '../fn/case-resource-controller/get-active-role';
import { getAllExtendedCaseResourcesByAdId } from '../fn/case-resource-controller/get-all-extended-case-resources-by-ad-id';
import { GetAllExtendedCaseResourcesByAdId$Params } from '../fn/case-resource-controller/get-all-extended-case-resources-by-ad-id';
import { getCaseResourceByAdId } from '../fn/case-resource-controller/get-case-resource-by-ad-id';
import { GetCaseResourceByAdId$Params } from '../fn/case-resource-controller/get-case-resource-by-ad-id';
import { getCaseResourceByAdIdForSsaRequest } from '../fn/case-resource-controller/get-case-resource-by-ad-id-for-ssa-request';
import { GetCaseResourceByAdIdForSsaRequest$Params } from '../fn/case-resource-controller/get-case-resource-by-ad-id-for-ssa-request';
import { getCaseResourceById } from '../fn/case-resource-controller/get-case-resource-by-id';
import { GetCaseResourceById$Params } from '../fn/case-resource-controller/get-case-resource-by-id';
import { getCaseResourceByRole } from '../fn/case-resource-controller/get-case-resource-by-role';
import { GetCaseResourceByRole$Params } from '../fn/case-resource-controller/get-case-resource-by-role';
import { getCaseResourceByRoleAndBp } from '../fn/case-resource-controller/get-case-resource-by-role-and-bp';
import { GetCaseResourceByRoleAndBp$Params } from '../fn/case-resource-controller/get-case-resource-by-role-and-bp';
import { getCaseResourceRolesByAdId } from '../fn/case-resource-controller/get-case-resource-roles-by-ad-id';
import { GetCaseResourceRolesByAdId$Params } from '../fn/case-resource-controller/get-case-resource-roles-by-ad-id';
import { getCaseResourcesByIds } from '../fn/case-resource-controller/get-case-resources-by-ids';
import { GetCaseResourcesByIds$Params } from '../fn/case-resource-controller/get-case-resources-by-ids';
import { getExtendedCaseResourceById } from '../fn/case-resource-controller/get-extended-case-resource-by-id';
import { GetExtendedCaseResourceById$Params } from '../fn/case-resource-controller/get-extended-case-resource-by-id';
import { getResourceRights } from '../fn/case-resource-controller/get-resource-rights';
import { GetResourceRights$Params } from '../fn/case-resource-controller/get-resource-rights';
import { getResourceRoles } from '../fn/case-resource-controller/get-resource-roles';
import { GetResourceRoles$Params } from '../fn/case-resource-controller/get-resource-roles';
import { getResourceRoles1 } from '../fn/case-resource-controller/get-resource-roles-1';
import { GetResourceRoles1$Params } from '../fn/case-resource-controller/get-resource-roles-1';
import { ResourceRightDto } from '../models/resource-right-dto';
import { ResourceRoleDto } from '../models/resource-role-dto';
import { updateDefaultTc } from '../fn/case-resource-controller/update-default-tc';
import { UpdateDefaultTc$Params } from '../fn/case-resource-controller/update-default-tc';

@Injectable({ providedIn: 'root' })
export class CaseResourceControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateDefaultTc()` */
  static readonly UpdateDefaultTcPath = '/case-resources/tcs/default';

  /**
   * This method updates a default tc for multi tc user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaultTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultTc$Response(params?: UpdateDefaultTc$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDefaultTc(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates a default tc for multi tc user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDefaultTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDefaultTc(params?: UpdateDefaultTc$Params, context?: HttpContext): Observable<void> {
    return this.updateDefaultTc$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getResourceRoles()` */
  static readonly GetResourceRolesPath = '/case-resources/roles';

  /**
   * This method retrieves resource roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceRoles$Response(params: GetResourceRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceRoleDto>>> {
    return getResourceRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves resource roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getResourceRoles(params: GetResourceRoles$Params, context?: HttpContext): Observable<Array<ResourceRoleDto>> {
    return this.getResourceRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceRoleDto>>): Array<ResourceRoleDto> => r.body)
    );
  }

  /** Path part for operation `getActiveRole()` */
  static readonly GetActiveRolePath = '/case-resources/role/active';

  /**
   * This method retrieves active role for multi role user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActiveRole$Response(params: GetActiveRole$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseResourceDto>> {
    return getActiveRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves active role for multi role user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getActiveRole(params: GetActiveRole$Params, context?: HttpContext): Observable<CaseResourceDto> {
    return this.getActiveRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>): CaseResourceDto => r.body)
    );
  }

  /** Path part for operation `getCaseResourcesByIds()` */
  static readonly GetCaseResourcesByIdsPath = '/case-resources/list';

  /**
   * This method retrieves case resources by a list of IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourcesByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCaseResourcesByIds$Response(params: GetCaseResourcesByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: CaseResourceDto;
}>> {
    return getCaseResourcesByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves case resources by a list of IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourcesByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getCaseResourcesByIds(params: GetCaseResourcesByIds$Params, context?: HttpContext): Observable<{
[key: string]: CaseResourceDto;
}> {
    return this.getCaseResourcesByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: CaseResourceDto;
}>): {
[key: string]: CaseResourceDto;
} => r.body)
    );
  }

  /** Path part for operation `getCaseResourceByRoleAndBp()` */
  static readonly GetCaseResourceByRoleAndBpPath = '/case-resources';

  /**
   * This method retrieves case resources by role code and bpGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByRoleAndBp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRoleAndBp$Response(params: GetCaseResourceByRoleAndBp$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CaseResourceDto>>> {
    return getCaseResourceByRoleAndBp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves case resources by role code and bpGuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByRoleAndBp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRoleAndBp(params: GetCaseResourceByRoleAndBp$Params, context?: HttpContext): Observable<Array<CaseResourceDto>> {
    return this.getCaseResourceByRoleAndBp$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceDto>>): Array<CaseResourceDto> => r.body)
    );
  }

  /** Path part for operation `getCaseResourceById()` */
  static readonly GetCaseResourceByIdPath = '/case-resources/{id}';

  /**
   * This method retrieves a case resource by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceById$Response(params: GetCaseResourceById$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseResourceDto>> {
    return getCaseResourceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a case resource by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceById(params: GetCaseResourceById$Params, context?: HttpContext): Observable<CaseResourceDto> {
    return this.getCaseResourceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>): CaseResourceDto => r.body)
    );
  }

  /** Path part for operation `getExtendedCaseResourceById()` */
  static readonly GetExtendedCaseResourceByIdPath = '/case-resources/{id}/extended';

  /**
   * This method retrieves an extended case resource by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtendedCaseResourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtendedCaseResourceById$Response(params: GetExtendedCaseResourceById$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseResourceExtendedDto>> {
    return getExtendedCaseResourceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves an extended case resource by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtendedCaseResourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtendedCaseResourceById(params: GetExtendedCaseResourceById$Params, context?: HttpContext): Observable<CaseResourceExtendedDto> {
    return this.getExtendedCaseResourceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseResourceExtendedDto>): CaseResourceExtendedDto => r.body)
    );
  }

  /** Path part for operation `getResourceRoles1()` */
  static readonly GetResourceRoles1Path = '/case-resources/user/roles';

  /**
   * This method retrieves all roles assigned user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRoles1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRoles1$Response(params?: GetResourceRoles1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceRoleDto>>> {
    return getResourceRoles1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all roles assigned user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceRoles1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRoles1(params?: GetResourceRoles1$Params, context?: HttpContext): Observable<Array<ResourceRoleDto>> {
    return this.getResourceRoles1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceRoleDto>>): Array<ResourceRoleDto> => r.body)
    );
  }

  /** Path part for operation `getCaseResourceByAdIdForSsaRequest()` */
  static readonly GetCaseResourceByAdIdForSsaRequestPath = '/case-resources/ssa/ad';

  /**
   * This method retrieves a case resource by AD ID for SSA request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByAdIdForSsaRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdIdForSsaRequest$Response(params: GetCaseResourceByAdIdForSsaRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseResourceDto>> {
    return getCaseResourceByAdIdForSsaRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a case resource by AD ID for SSA request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByAdIdForSsaRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdIdForSsaRequest(params: GetCaseResourceByAdIdForSsaRequest$Params, context?: HttpContext): Observable<CaseResourceDto> {
    return this.getCaseResourceByAdIdForSsaRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>): CaseResourceDto => r.body)
    );
  }

  /** Path part for operation `getCaseResourceByRole()` */
  static readonly GetCaseResourceByRolePath = '/case-resources/roles/{roleCode}';

  /**
   * This method retrieves case resources by role code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRole$Response(params: GetCaseResourceByRole$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CaseResourceDto>>> {
    return getCaseResourceByRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves case resources by role code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByRole(params: GetCaseResourceByRole$Params, context?: HttpContext): Observable<Array<CaseResourceDto>> {
    return this.getCaseResourceByRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceDto>>): Array<CaseResourceDto> => r.body)
    );
  }

  /** Path part for operation `getResourceRights()` */
  static readonly GetResourceRightsPath = '/case-resources/rights/{roleCode}';

  /**
   * This method retrieves resource rights by role code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getResourceRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRights$Response(params: GetResourceRights$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ResourceRightDto>>> {
    return getResourceRights(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves resource rights by role code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getResourceRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getResourceRights(params: GetResourceRights$Params, context?: HttpContext): Observable<Array<ResourceRightDto>> {
    return this.getResourceRights$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ResourceRightDto>>): Array<ResourceRightDto> => r.body)
    );
  }

  /** Path part for operation `getCaseResourceByAdId()` */
  static readonly GetCaseResourceByAdIdPath = '/case-resources/ad';

  /**
   * This method retrieves a case resource by AD ID if it exists
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdId$Response(params: GetCaseResourceByAdId$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseResourceDto>> {
    return getCaseResourceByAdId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a case resource by AD ID if it exists
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceByAdId(params: GetCaseResourceByAdId$Params, context?: HttpContext): Observable<CaseResourceDto> {
    return this.getCaseResourceByAdId$Response(params, context).pipe(
      map((r: StrictHttpResponse<CaseResourceDto>): CaseResourceDto => r.body)
    );
  }

  /** Path part for operation `getCaseResourceRolesByAdId()` */
  static readonly GetCaseResourceRolesByAdIdPath = '/case-resources/ad/roles';

  /**
   * This method retrieves case resource roles by AD ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCaseResourceRolesByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceRolesByAdId$Response(params: GetCaseResourceRolesByAdId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCaseResourceRolesByAdId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves case resource roles by AD ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCaseResourceRolesByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseResourceRolesByAdId(params: GetCaseResourceRolesByAdId$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCaseResourceRolesByAdId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getAllExtendedCaseResourcesByAdId()` */
  static readonly GetAllExtendedCaseResourcesByAdIdPath = '/case-resources/ad/extended/list';

  /**
   * This method retrieves all extended case resources by adId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExtendedCaseResourcesByAdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExtendedCaseResourcesByAdId$Response(params: GetAllExtendedCaseResourcesByAdId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CaseResourceExtendedDto>>> {
    return getAllExtendedCaseResourcesByAdId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all extended case resources by adId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllExtendedCaseResourcesByAdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExtendedCaseResourcesByAdId(params: GetAllExtendedCaseResourcesByAdId$Params, context?: HttpContext): Observable<Array<CaseResourceExtendedDto>> {
    return this.getAllExtendedCaseResourcesByAdId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CaseResourceExtendedDto>>): Array<CaseResourceExtendedDto> => r.body)
    );
  }

}
