/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addRecipientRelatedDonors } from '../fn/recipient-related-donor-controller/add-recipient-related-donors';
import { AddRecipientRelatedDonors$Params } from '../fn/recipient-related-donor-controller/add-recipient-related-donors';
import { CreatedRelatedFamilyMemberDetails } from '../models/created-related-family-member-details';
import { getRecipientRelatedDonorDetails } from '../fn/recipient-related-donor-controller/get-recipient-related-donor-details';
import { GetRecipientRelatedDonorDetails$Params } from '../fn/recipient-related-donor-controller/get-recipient-related-donor-details';
import { getRecipientRelatedDonorsDetails } from '../fn/recipient-related-donor-controller/get-recipient-related-donors-details';
import { GetRecipientRelatedDonorsDetails$Params } from '../fn/recipient-related-donor-controller/get-recipient-related-donors-details';
import { getRecipientRelatedDonorsGuids } from '../fn/recipient-related-donor-controller/get-recipient-related-donors-guids';
import { GetRecipientRelatedDonorsGuids$Params } from '../fn/recipient-related-donor-controller/get-recipient-related-donors-guids';
import { getTypingResults } from '../fn/recipient-related-donor-controller/get-typing-results';
import { GetTypingResults$Params } from '../fn/recipient-related-donor-controller/get-typing-results';
import { notifyDonorTypingReceived } from '../fn/recipient-related-donor-controller/notify-donor-typing-received';
import { NotifyDonorTypingReceived$Params } from '../fn/recipient-related-donor-controller/notify-donor-typing-received';
import { RelatedFamilyMemberTypingResults } from '../models/related-family-member-typing-results';
import { updateDonorsMatchLevels } from '../fn/recipient-related-donor-controller/update-donors-match-levels';
import { UpdateDonorsMatchLevels$Params } from '../fn/recipient-related-donor-controller/update-donors-match-levels';
import { updateRelatedDonorKit } from '../fn/recipient-related-donor-controller/update-related-donor-kit';
import { UpdateRelatedDonorKit$Params } from '../fn/recipient-related-donor-controller/update-related-donor-kit';
import { updateRelatedDonorStatusAndGuid } from '../fn/recipient-related-donor-controller/update-related-donor-status-and-guid';
import { UpdateRelatedDonorStatusAndGuid$Params } from '../fn/recipient-related-donor-controller/update-related-donor-status-and-guid';

@Injectable({ providedIn: 'root' })
export class RecipientRelatedDonorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateRelatedDonorStatusAndGuid()` */
  static readonly UpdateRelatedDonorStatusAndGuidPath = '/recipients/{recipientGuid}/related-donors/{temporaryDonorGuid}/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRelatedDonorStatusAndGuid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRelatedDonorStatusAndGuid$Response(params: UpdateRelatedDonorStatusAndGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRelatedDonorStatusAndGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRelatedDonorStatusAndGuid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRelatedDonorStatusAndGuid(params: UpdateRelatedDonorStatusAndGuid$Params, context?: HttpContext): Observable<void> {
    return this.updateRelatedDonorStatusAndGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateRelatedDonorKit()` */
  static readonly UpdateRelatedDonorKitPath = '/recipients/{recipientGuid}/related-donors/{donorGuid}/kit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRelatedDonorKit()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRelatedDonorKit$Response(params: UpdateRelatedDonorKit$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRelatedDonorKit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRelatedDonorKit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRelatedDonorKit(params: UpdateRelatedDonorKit$Params, context?: HttpContext): Observable<void> {
    return this.updateRelatedDonorKit$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateDonorsMatchLevels()` */
  static readonly UpdateDonorsMatchLevelsPath = '/recipients/{recipientGuid}/related-donors/match-level';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDonorsMatchLevels()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorsMatchLevels$Response(params: UpdateDonorsMatchLevels$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDonorsMatchLevels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDonorsMatchLevels$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDonorsMatchLevels(params: UpdateDonorsMatchLevels$Params, context?: HttpContext): Observable<void> {
    return this.updateDonorsMatchLevels$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getRecipientRelatedDonorsDetails()` */
  static readonly GetRecipientRelatedDonorsDetailsPath = '/recipients/{recipientGuid}/related-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRelatedDonorsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsDetails$Response(params: GetRecipientRelatedDonorsDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CreatedRelatedFamilyMemberDetails>>> {
    return getRecipientRelatedDonorsDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientRelatedDonorsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsDetails(params: GetRecipientRelatedDonorsDetails$Params, context?: HttpContext): Observable<Array<CreatedRelatedFamilyMemberDetails>> {
    return this.getRecipientRelatedDonorsDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreatedRelatedFamilyMemberDetails>>): Array<CreatedRelatedFamilyMemberDetails> => r.body)
    );
  }

  /** Path part for operation `addRecipientRelatedDonors()` */
  static readonly AddRecipientRelatedDonorsPath = '/recipients/{recipientGuid}/related-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRecipientRelatedDonors()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipientRelatedDonors$Response(params: AddRecipientRelatedDonors$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addRecipientRelatedDonors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRecipientRelatedDonors$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRecipientRelatedDonors(params: AddRecipientRelatedDonors$Params, context?: HttpContext): Observable<void> {
    return this.addRecipientRelatedDonors$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notifyDonorTypingReceived()` */
  static readonly NotifyDonorTypingReceivedPath = '/recipients/{recipientGuid}/related-donors/{donorGuid}/typing-received';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notifyDonorTypingReceived()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyDonorTypingReceived$Response(params: NotifyDonorTypingReceived$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notifyDonorTypingReceived(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notifyDonorTypingReceived$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notifyDonorTypingReceived(params: NotifyDonorTypingReceived$Params, context?: HttpContext): Observable<void> {
    return this.notifyDonorTypingReceived$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getRecipientRelatedDonorDetails()` */
  static readonly GetRecipientRelatedDonorDetailsPath = '/recipients/{recipientGuid}/related-donors/{donorGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRelatedDonorDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorDetails$Response(params: GetRecipientRelatedDonorDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedRelatedFamilyMemberDetails>> {
    return getRecipientRelatedDonorDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientRelatedDonorDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorDetails(params: GetRecipientRelatedDonorDetails$Params, context?: HttpContext): Observable<CreatedRelatedFamilyMemberDetails> {
    return this.getRecipientRelatedDonorDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedRelatedFamilyMemberDetails>): CreatedRelatedFamilyMemberDetails => r.body)
    );
  }

  /** Path part for operation `getTypingResults()` */
  static readonly GetTypingResultsPath = '/recipients/{recipientGuid}/related-donors/typing-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypingResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingResults$Response(params: GetTypingResults$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RelatedFamilyMemberTypingResults>>> {
    return getTypingResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTypingResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingResults(params: GetTypingResults$Params, context?: HttpContext): Observable<Array<RelatedFamilyMemberTypingResults>> {
    return this.getTypingResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RelatedFamilyMemberTypingResults>>): Array<RelatedFamilyMemberTypingResults> => r.body)
    );
  }

  /** Path part for operation `getRecipientRelatedDonorsGuids()` */
  static readonly GetRecipientRelatedDonorsGuidsPath = '/recipients/{recipientGuid}/related-donors/guids';

  /**
   * Get the related donor guids by recipient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientRelatedDonorsGuids()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsGuids$Response(params: GetRecipientRelatedDonorsGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getRecipientRelatedDonorsGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the related donor guids by recipient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientRelatedDonorsGuids$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientRelatedDonorsGuids(params: GetRecipientRelatedDonorsGuids$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getRecipientRelatedDonorsGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
