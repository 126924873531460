/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Cord } from '../../models/cord';

export interface GetCordsByGuids$Params {
  ignoreError?: boolean;
      body: Array<string>
}

export function getCordsByGuids(http: HttpClient, rootUrl: string, params: GetCordsByGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Cord>>> {
  const rb = new RequestBuilder(rootUrl, getCordsByGuids.PATH, 'post');
  if (params) {
    rb.query('ignoreError', params.ignoreError, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<Cord>>;
    })
  );
}

getCordsByGuids.PATH = '/cords';
