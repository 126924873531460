/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getLocusGenotypeProbability } from '../fn/genotype-probability-controller/get-locus-genotype-probability';
import { GetLocusGenotypeProbability$Params } from '../fn/genotype-probability-controller/get-locus-genotype-probability';
import { LocusGenotypeProbability } from '../models/locus-genotype-probability';

@Injectable({ providedIn: 'root' })
export class GenotypeProbabilityControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLocusGenotypeProbability()` */
  static readonly GetLocusGenotypeProbabilityPath = '/genotype-probabilities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocusGenotypeProbability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLocusGenotypeProbability$Response(params: GetLocusGenotypeProbability$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocusGenotypeProbability>>> {
    return getLocusGenotypeProbability(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocusGenotypeProbability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLocusGenotypeProbability(params: GetLocusGenotypeProbability$Params, context?: HttpContext): Observable<Array<LocusGenotypeProbability>> {
    return this.getLocusGenotypeProbability$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocusGenotypeProbability>>): Array<LocusGenotypeProbability> => r.body)
    );
  }

}
