import {Injectable} from '@angular/core';
import {SearchSummaryReportControllerService} from '@matchsource/api-generated/search-match';
import {
  AdvancedLookupControllerService,
  LookupControllerService,
  DuplicatePatientControllerService,
  RecipientDuplicate,
  Result,
  TransferRecipientRequest,
  CloseRequest,
  RecipientTransferHistory,
  RecipientClosedHistory,
  RecipientIdentityVerifyHistory,
  PageRecipientLookup,
  RecipientControllerService,
  HlaTypingControllerService,
  RaceEthnicityHistory,
  RecipientPtrControllerService,
  RecipientHistoryControllerService,
  RecipientTypingFrequencyControllerService,
  RecipientIdentityVerificationControllerService,
} from '@matchsource/api-generated/subject';
import {HttpContext, HttpResponse} from '@angular/common/http';
import findIndex from 'lodash-es/findIndex';
import {AdvancedPatientLookupResponse, PatientLookupResponse, PatientSaveWithTraceModel} from './declarations';
import {defaultIfEmpty, map, mergeMap, switchMap} from 'rxjs/operators';
import {firstValueFrom, forkJoin, iif, Observable, of} from 'rxjs';
import {
  AdvancedPatientLookupDataSetModel,
  PatientLookupDataSetModel,
  PatientSearchStatus,
  PatientWithSearchLookupDataSetModel,
  safeCleanPatientId,
  PatientUniqueDataSet,
  PatientModel,
  SearchCriteriaModel,
  PatientSearchParams,
  ManualResolvePayload,
  PatientHlaLocusModel,
  PatientHlaLocusValidityModel,
  AdvancedPatientFilterModel,
  TransplantTimelineHistoryModel,
  PatientDuplicatesModel,
  PatientPartiallyUpdatedData,
  AdvancedPatientLookupSearchParams,
} from '@matchsource/models/patient';
import {patientLookupSetSerializer} from './patient-lookup-set.serializer';
import {SearchApiService} from '@matchsource/api/search';
import {hasAccessibleSearch} from '@matchsource/api/search';
import {advancedPatientLookupSetSerializer} from './advanced-patient-lookup-set.serializer';
import {typingResultsSerializer} from '@matchsource/api/hla-history';
import {patientSerializer} from './patient.serializer';
import {TRACE_ID_HEADER_KEY} from '@matchsource/api-utils';
import {patientUniqueDataSetSerializer} from './patient-unique-data-set.serializer';
import {transplantTimelineHistorySerializer} from './transplant-timeline-history.serializer';
import {patientIdentitySerializer} from './patient-identity.serializer';
import {advancedPatientLookupFiltersSerializer} from './advanced-patient-lookup-filters.serializer';
import sizeLodash from 'lodash-es/size';
import {HlaMapModel, PreferredTestResultsModel} from '@matchsource/models/hla';
import {hlaSerializer} from '@matchsource/api/hla';
import {searchCriteriaSerializer} from '@matchsource/api/patient-shared';
import {typingFrequencySerializer} from './typing-frequency.serializer';
import flow from 'lodash-es/flow';
import {TypingFrequencyModel} from '@matchsource/models/frequency-data';
import {patientHlaTypingSerializer} from './patient-hla-typing.serializer';
import {patientHlaLocusValiditySerializer} from './patient-hla-locus-validity.serializer';
import {
  setSingleErrorCustomErrorHandlingContext,
  ClientErrorCode,
  ResponseBodyWithTraceInfo,
} from '@matchsource/error-handling/core';
import {mapPatientPartiallyModelToDto} from './patient-partially-update.serializer';
import {ptrLegacyDtoToModel} from './ptr-legacy.serializer';
import {SearchModel} from '@matchsource/models/search';
import {raceEthnicitySerializer} from './raceEthnicity.serializer';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';
import {rethrowTheOriginalError, skipError, skipResponseStatuses, skipSpinner} from '@matchsource/core';

const DEFAULT_SEARCH_IN_PROGRESS_PARAMS: Partial<PatientSearchParams> = {
  status: 9000,
  sortField: 'entryDate',
  sortDir: 'desc',
  ignoreCoreFailed: true,
};

const DEFAULT_LOOKUP_PARAMS: Partial<PatientSearchParams> = {
  searchTerm: '',
  sortField: 'rid',
  sortDir: 'asc',
  guids: [],
  ignoreCoreFailed: true,
};

const typingReceivedTabs = '9002';

const DEFAULT_PATIENT_SEARCH_STATUS: PatientSearchStatus = {
  hasAccessibleSearch: false,
  hasSearches: false,
};

const mapToResponseBodyWithTraceInfo = <TResponse>(
  httpResponse: HttpResponse<TResponse>,
  errorCode: ClientErrorCode
): ResponseBodyWithTraceInfo<TResponse> => {
  return {
    responseBody: httpResponse.body,
    traceInfo: {
      requestUrl: httpResponse.url,
      traceId: httpResponse.headers.get(TRACE_ID_HEADER_KEY),
      errorCode,
    },
  };
};

const mapPatientDuplicates = (duplicates: RecipientDuplicate): PatientDuplicatesModel => ({
  duplicatesAmongFrmlOrClosed: sizeLodash(duplicates.duplicatesAmongFrmlOrClosed),
  duplicatesAmongHla: sizeLodash(duplicates.duplicatesAmongHla),
  duplicatesAmongPrlm: sizeLodash(duplicates.duplicatesAmongPrlm),
  duplicatesByLocalId: sizeLodash(duplicates.duplicatesByLocalId),
  duplicatesByRefId: sizeLodash(duplicates.duplicatesByRefId),
});

@Injectable({
  providedIn: 'root',
})
export class PatientApiService {
  constructor(
    private readonly searchApi: SearchApiService,
    private readonly duplicatePatientControllerService: DuplicatePatientControllerService,
    private readonly recipientControllerService: RecipientControllerService,
    private readonly recipientPtrControllerService: RecipientPtrControllerService,
    private readonly recipientHistoryControllerService: RecipientHistoryControllerService,
    private readonly recipientIdentityVerificationControllerService: RecipientIdentityVerificationControllerService,
    private readonly recipientTypingFrequencyControllerService: RecipientTypingFrequencyControllerService,
    private readonly advancedLookupControllerService: AdvancedLookupControllerService,
    private readonly lookupControllerService: LookupControllerService,
    private readonly searchSummaryReportApi: SearchSummaryReportControllerService,
    private readonly hlaTypingControllerService: HlaTypingControllerService
  ) {}

  transfer(patientId: MsApp.Guid, transferInfo: TransferRecipientRequest): Observable<void> {
    return this.recipientControllerService.transferRecipient({
      guid: patientId,
      body: transferInfo,
    });
  }

  transferLegacy(patientId: MsApp.Guid, transferInfo: TransferRecipientRequest): Promise<void> {
    return firstValueFrom(this.transfer(patientId, transferInfo));
  }

  advancedSearch({
    searchTerm,
    page = 0,
    size = 0,
    status,
    sortField = 'entryDate',
    sortDir = 'desc',
    guids,
    ignoreCoreFailed = true,
    tcId,
    isHlaTodayOnly = false,
  }: Partial<PatientSearchParams> = {}): Observable<PatientLookupDataSetModel> {
    searchTerm = safeCleanPatientId(searchTerm);
    const sort = sortField ? [`${sortField},${sortDir}`] : [''];
    return this.lookupControllerService
      .lookupRecipientBy({
        sort,
        page,
        size,
        ignoreCoreFailed,
        body: {status, searchTerm, guids, tcId, isHlaTodayOnly},
      })
      .pipe(
        mergeMap((patients: PageRecipientLookup) => {
          return iif(
            () => status === typingReceivedTabs && !!patients?.content.length,
            this.getReportsForRecipients(patients),
            of(patients)
          );
        }),
        //unknown needed due to PatientLookupResponse being of type PaginatedDataSetResponse
        map(res => patientLookupSetSerializer.fromDTO(res as unknown as PatientLookupResponse))
      );
  }

  private getReportsForRecipients(patients: PageRecipientLookup): Observable<PageRecipientLookup> {
    return this.searchSummaryReportApi
      .getReportsForRecipients({body: patients.content.map(patient => patient.id)})
      .pipe(
        map(ids => {
          for (const id in ids) {
            const patientIndex = findIndex(patients.content, {id});
            // TODO NIKI TORBEV please check why we need to add them manually
            (patients.content[patientIndex] as any).published = ids[id].published;
            (patients.content[patientIndex] as any).generated = ids[id].generated;
          }
          return patients;
        })
      );
  }

  search(params: Partial<PatientSearchParams> = {}) {
    return this.advancedSearch({...DEFAULT_LOOKUP_PARAMS, ...params});
  }

  searchInProgress(params: Partial<PatientSearchParams> = {}) {
    return this.advancedSearch({...DEFAULT_SEARCH_IN_PROGRESS_PARAMS, ...params});
  }

  searchWithSearchStatus(params: Partial<PatientSearchParams> = {}): Observable<PatientWithSearchLookupDataSetModel> {
    return this.search(params).pipe(
      switchMap(patients => {
        return forkJoin(patients.data.map(patient => this.searchApi.getPatientSearches(patient.id))).pipe(
          defaultIfEmpty([] as SearchModel[][]),
          map(patientsSearches => {
            const patientsSearchesMap = patientsSearches.reduce<{[key: string]: PatientSearchStatus}>(
              (acc, patientSearches) => {
                if (patientSearches && patientSearches.length > 0) {
                  const [{patientId}] = patientSearches;

                  const actualSearch = patientSearches.find(item => item.phenotype === ACTUAL_PHENOTYPE_INDEX);

                  acc[patientId] = {
                    hasAccessibleSearch: !actualSearch || hasAccessibleSearch(actualSearch),
                    hasSearches: true,
                  };
                }

                return acc;
              },
              {}
            );

            return {
              ...patients,
              data: patients.data.map(patient => ({
                ...patient,
                ...(patientsSearchesMap[patient.id] || DEFAULT_PATIENT_SEARCH_STATUS),
              })),
            };
          })
        );
      })
    );
  }

  getSearchedPatients(
    filters: AdvancedPatientFilterModel[],
    {sortField, sortDir, page, size}: AdvancedPatientLookupSearchParams
  ): Observable<AdvancedPatientLookupDataSetModel> {
    const sort = sortField ? [`${sortField},${sortDir}`] : [''];
    const filtersDto = filters.map(filter => advancedPatientLookupFiltersSerializer.toDTO(filter));

    return this.advancedLookupControllerService.lookupRecipientsPageBy({body: filtersDto, sort, page, size}).pipe(
      //unknown needed due to AdvancedPatientLookupResponse being of type PaginatedDataSetResponse
      map(res => advancedPatientLookupSetSerializer.fromDTO(res as unknown as AdvancedPatientLookupResponse))
    );
  }

  getHlaMapModel(patientId: MsApp.Guid, index = ACTUAL_PHENOTYPE_INDEX): Observable<HlaMapModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);

    return this.recipientPtrControllerService
      .getRecipientPtr$Json(
        {
          guid: patientId,
          phenotypeId: index,
        },
        context()
      )
      .pipe(
        map(result => {
          return Object.entries(result || {}).reduce(
            (ptr, [locus, value]) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const item = hlaSerializer.fromDTO({locus, ...value});
              ptr[locus] = item;
              ptr.list.push(item);
              return ptr;
            },
            {index, list: []}
          );
        })
      );
  }

  deletePatient(patientId: MsApp.Guid): Observable<unknown> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.DeletingPatientInProgress);

    return this.recipientControllerService.deleteInProgressRecipient({guid: patientId}, context());
  }

  get(
    id: MsApp.Guid,
    {
      skipErrorNotification = false,
      suppressResponseStatuses,
      validateDemographics = true,
    }: {skipErrorNotification?: boolean; suppressResponseStatuses?: number[]; validateDemographics?: boolean} = {}
  ): Observable<PatientModel> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingPatientData);
    // rethrowTheOriginalError will allow the response interceptor to show techincal difficulties pop-up with the specified context error text
    // and will also throw the error if the caller needs to process it
    const firstFlowItem = skipErrorNotification ? skipError : rethrowTheOriginalError;
    type FlowItemType = (context?: HttpContext) => HttpContext;
    const flowItems: FlowItemType[] = [];
    flowItems.push(firstFlowItem);
    if (suppressResponseStatuses?.length > 0) {
      flowItems.push(skipResponseStatuses(suppressResponseStatuses));
    }
    flowItems.push(customErrorHandlingContext);
    const context = flow(flowItems);

    return this.recipientControllerService
      .getRecipient(
        {
          guid: id,
          validateDemographics,
        },
        context()
      )
      .pipe(map(res => patientSerializer.fromDTO(res)));
  }

  getPTRLegacy(patientId: MsApp.Guid, index = ACTUAL_PHENOTYPE_INDEX): Promise<unknown> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);

    return firstValueFrom(
      this.recipientPtrControllerService
        .getRecipientPtr$Json(
          {
            guid: patientId,
            phenotypeId: index,
          },
          context()
        )
        .pipe(map(ptr => ptrLegacyDtoToModel(ptr, index)))
    );
  }

  getPTR(patientId: MsApp.Guid, index = ACTUAL_PHENOTYPE_INDEX): Observable<PreferredTestResultsModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);
    if (!patientId.length) {
      return of(null);
    }

    return this.recipientPtrControllerService
      .getRecipientPtr$Json(
        {
          guid: patientId,
          phenotypeId: index,
        },
        context()
      )
      .pipe(map(ptr => typingResultsSerializer.fromDTO(ptr, index)));
  }

  save(patient: PatientModel): Observable<PatientSaveWithTraceModel> {
    return patient.id ? this.update(patient) : this.create(patient);
  }

  getHlaValidity(hla: PatientHlaLocusModel[]): Observable<PatientHlaLocusValidityModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ValidatingHlaTyping);

    return this.hlaTypingControllerService
      .validateHla({body: patientHlaTypingSerializer.toDTO(hla)}, context())
      .pipe(map(res => patientHlaLocusValiditySerializer.fromDTO(res)));
  }

  checkDuplicate(patient: PatientUniqueDataSet): Observable<PatientDuplicatesModel> {
    const customErrorHandlingContext = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.CheckingForDuplicates);
    const context = flow(skipSpinner, customErrorHandlingContext);

    return this.duplicatePatientControllerService
      .checkForDuplicates(
        {
          body: patientUniqueDataSetSerializer.toDTO(patient),
        },
        context()
      )
      .pipe(map(mapPatientDuplicates));
  }

  resolveDuplicates(id: MsApp.Guid): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.ResolvingDuplicates);

    return this.duplicatePatientControllerService.resolveDuplicates(
      {
        guid: id,
      },
      context()
    );
  }

  manualResolveDuplicates(manualResolvePayload: ManualResolvePayload): Observable<void> {
    return this.duplicatePatientControllerService.resolveDuplicatesManually({
      body: manualResolvePayload,
    });
  }

  closeCase(id: MsApp.Guid, closeCase: CloseRequest): Observable<void> {
    return this.recipientControllerService.closeRecipient({
      guid: id,
      body: closeCase,
    });
  }

  private create(patient: PatientModel): Observable<PatientSaveWithTraceModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.CreatingPatients);

    return this.recipientControllerService
      .addRecipient$Response(
        {
          body: patientSerializer.toDTO(patient),
        },
        context()
      )
      .pipe(map(result => mapToResponseBodyWithTraceInfo(result, ClientErrorCode.CreatingPatients)));
  }

  private update(patient: PatientModel): Observable<PatientSaveWithTraceModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.UpdatingPatient);

    return this.recipientControllerService
      .updateRecipient$Response(
        {
          guid: patient.id,
          body: patientSerializer.toDTO(patient),
        },
        context()
      )
      .pipe(map(result => mapToResponseBodyWithTraceInfo(result, ClientErrorCode.UpdatingPatient)));
  }

  getTransplantTimelineHistory(id: MsApp.Guid): Observable<TransplantTimelineHistoryModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingPatientData);

    return this.recipientHistoryControllerService
      .getRecipientTransplantTimelineHistory(
        {
          guid: id,
        },
        context()
      )
      .pipe(map(data => data.map(item => transplantTimelineHistorySerializer.fromDTO(item))));
  }

  getTransferHistory(id: MsApp.Guid): Observable<RecipientTransferHistory[]> {
    return this.recipientHistoryControllerService.getRecipientTransferHistory({
      guid: id,
    });
  }

  getCloseHistory(id: MsApp.Guid): Observable<RecipientClosedHistory[]> {
    return this.recipientHistoryControllerService.getRecipientClosedHistory({
      guid: id,
    });
  }

  getIdentityHistory(id: MsApp.Guid): Observable<RecipientIdentityVerifyHistory[]> {
    return this.recipientIdentityVerificationControllerService
      .getRecipientIdentityVerifyHistory({
        guid: id,
      })
      .pipe(map(data => data.map(item => patientIdentitySerializer.fromDTO(item))));
  }

  updatePatientPartially(patientId: MsApp.Guid, data: PatientPartiallyUpdatedData): Observable<Result> {
    return this.recipientControllerService.updateRecipientPartially({
      guid: patientId,
      body: mapPatientPartiallyModelToDto(data),
    });
  }

  reactivatePatient(patientId: MsApp.Guid, statusCode: string, searchCriteria: SearchCriteriaModel): Observable<void> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.SavingPatientStatus);

    return this.recipientControllerService.updateRecipientStatus(
      {
        guid: patientId,
        statusCode,
        body: searchCriteriaSerializer.toDTO(searchCriteria),
      },
      context()
    );
  }

  getPotentialTypings(params: {patientId: MsApp.Guid; phenotypeNum: number}): Observable<TypingFrequencyModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingHlaTypingData);

    return this.recipientTypingFrequencyControllerService
      .getTypingFrequency(
        {
          guid: params.patientId,
          phenotypeNum: params.phenotypeNum,
        },
        context()
      )
      .pipe(map(response => typingFrequencySerializer.fromDTO(response, params.phenotypeNum)));
  }

  checkHaplotypePairsExistence(params: {patientId: MsApp.Guid; phenotypeNum: number}): Observable<boolean> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingMatchingInfo);

    return this.recipientTypingFrequencyControllerService
      .isTypingFrequencyExist(
        {
          guid: params.patientId,
          phenotypeNum: params.phenotypeNum,
        },
        context()
      )
      .pipe(map(response => response?.typingFrequencyExists));
  }

  updateTransplantTimeline({
    patientId,
    transplantTimelineCode,
  }: {
    patientId: MsApp.Guid;
    transplantTimelineCode: string;
  }): Observable<void> {
    return this.recipientControllerService.updateTransplantTimeline({
      guid: patientId,
      body: transplantTimelineCode,
    });
  }

  loadRaceEthnicityHistory(id: number): Observable<RaceEthnicityHistory> {
    if (!id) {
      return of({});
    }
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadRaceEthnicityHistory);
    return this.recipientControllerService
      .getRecipientRaceEthnicityHistory({id}, context())
      .pipe(map(data => raceEthnicitySerializer.fromDTO(data)));
  }

  isDomesticDemographicsInvalid(recipientGuid: MsApp.Guid): Observable<boolean> {
    return this.recipientControllerService
      .isDomesticDemographicsValid({guid: recipientGuid})
      .pipe(map(isValid => !isValid));
  }
}
