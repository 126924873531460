/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptTc } from '../fn/login-controller/accept-tc';
import { AcceptTc$Params } from '../fn/login-controller/accept-tc';
import { changeRole } from '../fn/login-controller/change-role';
import { ChangeRole$Params } from '../fn/login-controller/change-role';
import { ChangeRoleResponse } from '../models/change-role-response';
import { login } from '../fn/login-controller/login';
import { Login$Params } from '../fn/login-controller/login';
import { logout } from '../fn/login-controller/logout';
import { Logout$Params } from '../fn/login-controller/logout';
import { UserDetails } from '../models/user-details';

@Injectable({ providedIn: 'root' })
export class LoginControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `acceptTc()` */
  static readonly AcceptTcPath = '/auth/tc';

  /**
   * Accept TC method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptTc$Response(params?: AcceptTc$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetails>> {
    return acceptTc(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept TC method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptTc(params?: AcceptTc$Params, context?: HttpContext): Observable<UserDetails> {
    return this.acceptTc$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetails>): UserDetails => r.body)
    );
  }

  /** Path part for operation `logout()` */
  static readonly LogoutPath = '/auth/logout';

  /**
   * Logout method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: Logout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logout(this.http, this.rootUrl, params, context);
  }

  /**
   * Logout method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: Logout$Params, context?: HttpContext): Observable<void> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `changeRole()` */
  static readonly ChangeRolePath = '/auth/switch/role/{role}';

  /**
   * Change user role for multi role user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeRole$Response(params: ChangeRole$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangeRoleResponse>> {
    return changeRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Change user role for multi role user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeRole(params: ChangeRole$Params, context?: HttpContext): Observable<ChangeRoleResponse> {
    return this.changeRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRoleResponse>): ChangeRoleResponse => r.body)
    );
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/auth/login';

  /**
   * Login method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method doesn't expect any request body.
   */
  login$Response(params?: Login$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetails>> {
    return login(this.http, this.rootUrl, params, context);
  }

  /**
   * Login method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  login(params?: Login$Params, context?: HttpContext): Observable<UserDetails> {
    return this.login$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetails>): UserDetails => r.body)
    );
  }

}
