/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CollectionStringFilter } from '../../models/collection-string-filter';
import { LocalDateAndTimeZoneFilter } from '../../models/local-date-and-time-zone-filter';
import { LocalDateFilter } from '../../models/local-date-filter';
import { PageRecipientAdvancedLookupResult } from '../../models/page-recipient-advanced-lookup-result';
import { PatientStatusFilter } from '../../models/patient-status-filter';
import { StringFilter } from '../../models/string-filter';

export interface LookupRecipientsPageBy$Params {

/**
 * Zero-based page index (0..N)
 */
  page?: number;

/**
 * The size of the page to be returned
 */
  size?: number;

/**
 * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
  sort?: Array<string>;
      body: Array<(CollectionStringFilter | LocalDateAndTimeZoneFilter | LocalDateFilter | PatientStatusFilter | StringFilter)>
}

export function lookupRecipientsPageBy(http: HttpClient, rootUrl: string, params: LookupRecipientsPageBy$Params, context?: HttpContext): Observable<StrictHttpResponse<PageRecipientAdvancedLookupResult>> {
  const rb = new RequestBuilder(rootUrl, lookupRecipientsPageBy.PATH, 'post');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageRecipientAdvancedLookupResult>;
    })
  );
}

lookupRecipientsPageBy.PATH = '/lookup/advanced/recipient';
