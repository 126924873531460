/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Contact } from '../models/contact';
import { createInstructions } from '../fn/sample-instruction-controller/create-instructions';
import { CreateInstructions$Params } from '../fn/sample-instruction-controller/create-instructions';
import { CtInstructionReport } from '../models/ct-instruction-report';
import { CustomerAccount } from '../models/customer-account';
import { deactivateTc } from '../fn/sample-instruction-controller/deactivate-tc';
import { DeactivateTc$Params } from '../fn/sample-instruction-controller/deactivate-tc';
import { getAvailableTemplates } from '../fn/sample-instruction-controller/get-available-templates';
import { GetAvailableTemplates$Params } from '../fn/sample-instruction-controller/get-available-templates';
import { getInstructionReport } from '../fn/sample-instruction-controller/get-instruction-report';
import { GetInstructionReport$Params } from '../fn/sample-instruction-controller/get-instruction-report';
import { getLabContacts } from '../fn/sample-instruction-controller/get-lab-contacts';
import { GetLabContacts$Params } from '../fn/sample-instruction-controller/get-lab-contacts';
import { getLabs } from '../fn/sample-instruction-controller/get-labs';
import { GetLabs$Params } from '../fn/sample-instruction-controller/get-labs';
import { getTemplate } from '../fn/sample-instruction-controller/get-template';
import { GetTemplate$Params } from '../fn/sample-instruction-controller/get-template';
import { publishMessage } from '../fn/sample-instruction-controller/publish-message';
import { PublishMessage$Params } from '../fn/sample-instruction-controller/publish-message';
import { SampleInstructionTemplate } from '../models/sample-instruction-template';
import { TemplateItem } from '../models/template-item';
import { updateInstructions } from '../fn/sample-instruction-controller/update-instructions';
import { UpdateInstructions$Params } from '../fn/sample-instruction-controller/update-instructions';
import { updateTemplate } from '../fn/sample-instruction-controller/update-template';
import { UpdateTemplate$Params } from '../fn/sample-instruction-controller/update-template';

@Injectable({ providedIn: 'root' })
export class SampleInstructionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getInstructionReport()` */
  static readonly GetInstructionReportPath = '/sample/instruction';

  /**
   * Get instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInstructionReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstructionReport$Response(params: GetInstructionReport$Params, context?: HttpContext): Observable<StrictHttpResponse<CtInstructionReport>> {
    return getInstructionReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Get instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInstructionReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstructionReport(params: GetInstructionReport$Params, context?: HttpContext): Observable<CtInstructionReport> {
    return this.getInstructionReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<CtInstructionReport>): CtInstructionReport => r.body)
    );
  }

  /** Path part for operation `updateInstructions()` */
  static readonly UpdateInstructionsPath = '/sample/instruction';

  /**
   * Update instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructions$Response(params: UpdateInstructions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateInstructions(this.http, this.rootUrl, params, context);
  }

  /**
   * Update instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructions(params: UpdateInstructions$Params, context?: HttpContext): Observable<void> {
    return this.updateInstructions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createInstructions()` */
  static readonly CreateInstructionsPath = '/sample/instruction';

  /**
   * Create instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInstructions$Response(params: CreateInstructions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createInstructions(this.http, this.rootUrl, params, context);
  }

  /**
   * Create instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInstructions(params: CreateInstructions$Params, context?: HttpContext): Observable<void> {
    return this.createInstructions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTemplate()` */
  static readonly GetTemplatePath = '/sample/instruction/template';

  /**
   * Get template with the specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate$Response(params: GetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<SampleInstructionTemplate>> {
    return getTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Get template with the specific type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate(params: GetTemplate$Params, context?: HttpContext): Observable<SampleInstructionTemplate> {
    return this.getTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SampleInstructionTemplate>): SampleInstructionTemplate => r.body)
    );
  }

  /** Path part for operation `updateTemplate()` */
  static readonly UpdateTemplatePath = '/sample/instruction/template';

  /**
   * Update template with the specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate$Response(params: UpdateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update template with the specific type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate(params: UpdateTemplate$Params, context?: HttpContext): Observable<void> {
    return this.updateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAvailableTemplates()` */
  static readonly GetAvailableTemplatesPath = '/sample/instruction/template/list';

  /**
   * Get all available templates (id + type)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableTemplates$Response(params?: GetAvailableTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TemplateItem>>> {
    return getAvailableTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all available templates (id + type)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableTemplates(params?: GetAvailableTemplates$Params, context?: HttpContext): Observable<Array<TemplateItem>> {
    return this.getAvailableTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateItem>>): Array<TemplateItem> => r.body)
    );
  }

  /** Path part for operation `publishMessage()` */
  static readonly PublishMessagePath = '/sample/instruction/publish-message';

  /**
   * Request to publish Update Transplant Center Lab message to ESB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishMessage$Response(params: PublishMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Request to publish Update Transplant Center Lab message to ESB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishMessage(params: PublishMessage$Params, context?: HttpContext): Observable<void> {
    return this.publishMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLabs()` */
  static readonly GetLabsPath = '/sample/instruction/labs/list';

  /**
   * Get all labs for the specific bpGuid and secondary code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabs$Response(params: GetLabs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAccount>>> {
    return getLabs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all labs for the specific bpGuid and secondary code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLabs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabs(params: GetLabs$Params, context?: HttpContext): Observable<Array<CustomerAccount>> {
    return this.getLabs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerAccount>>): Array<CustomerAccount> => r.body)
    );
  }

  /** Path part for operation `deactivateTc()` */
  static readonly DeactivateTcPath = '/sample/instruction/deactivate';

  /**
   * Request deactivate tc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTc$Response(params: DeactivateTc$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deactivateTc(this.http, this.rootUrl, params, context);
  }

  /**
   * Request deactivate tc
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTc(params: DeactivateTc$Params, context?: HttpContext): Observable<void> {
    return this.deactivateTc$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLabContacts()` */
  static readonly GetLabContactsPath = '/sample/instruction/contact/list';

  /**
   * Get all contacts for the specific lab
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabContacts$Response(params: GetLabContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Contact>>> {
    return getLabContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all contacts for the specific lab
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLabContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabContacts(params: GetLabContacts$Params, context?: HttpContext): Observable<Array<Contact>> {
    return this.getLabContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Contact>>): Array<Contact> => r.body)
    );
  }

}
