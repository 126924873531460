import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {map} from 'rxjs/operators';
import {fromDTO} from './blood-types.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {Nomenclature} from '@matchsource/models/nomenclature';

@Injectable({
  providedIn: 'root',
})
export class BloodTypesApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getAll(): Observable<Nomenclature[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingReferenceData);
    return this.nomenclaturesControllerService.listAllBloodTypes(null, context()).pipe(
      map(bloodTypes => {
        return bloodTypes.map(bloodType => fromDTO(bloodType));
      })
    );
  }
}
