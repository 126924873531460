/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSourceBaseInfo } from '../fn/source-base-info-controller/get-source-base-info';
import { GetSourceBaseInfo$Params } from '../fn/source-base-info-controller/get-source-base-info';
import { SourceBase } from '../models/source-base';

@Injectable({ providedIn: 'root' })
export class SourceBaseInfoControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSourceBaseInfo()` */
  static readonly GetSourceBaseInfoPath = '/source-base-info';

  /**
   * Get source base info for all types of sources (Donor, Cord, Bdp)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSourceBaseInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSourceBaseInfo$Response(params: GetSourceBaseInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SourceBase>>> {
    return getSourceBaseInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get source base info for all types of sources (Donor, Cord, Bdp)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSourceBaseInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSourceBaseInfo(params: GetSourceBaseInfo$Params, context?: HttpContext): Observable<Array<SourceBase>> {
    return this.getSourceBaseInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SourceBase>>): Array<SourceBase> => r.body)
    );
  }

}
