import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderControllerService} from '@matchsource/api-generated/orders';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {GetInventorySerialNumbersParams, INVENTORY_TYPE, LabRefferenceIdDropDownItem} from '@matchsource/models/order';
import {map} from 'rxjs/operators';
import {kitOrderSerializer} from './kit-order.serializer';

@Injectable({
  providedIn: 'root',
})
export class KitOrderApiService {
  private readonly orderControllerService: OrderControllerService;

  constructor(orderControllerService: OrderControllerService) {
    this.orderControllerService = orderControllerService;
  }

  getAllLabKitRefs(bpGuid: string): Observable<LabRefferenceIdDropDownItem[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingKitOrderDataLabRefIds)();
    const getInventorySerialNumbersParams: GetInventorySerialNumbersParams = {
      inventoryType: INVENTORY_TYPE,
      bpGuid,
      bulk: true,
      used: false,
      context,
    };

    return this.orderControllerService.getInventorySerialNumbers(getInventorySerialNumbersParams, context).pipe(
      map(kitSerialNumbers => {
        return kitSerialNumbers.map(kitSerialNumber => kitOrderSerializer.fromDTO(kitSerialNumber));
      })
    );
  }
}
