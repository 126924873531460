/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BdpLookup } from '../models/bdp-lookup';
import { CordLookup } from '../models/cord-lookup';
import { DonorLookup } from '../models/donor-lookup';
import { duplicatePatientLookup } from '../fn/lookup-controller/duplicate-patient-lookup';
import { DuplicatePatientLookup$Params } from '../fn/lookup-controller/duplicate-patient-lookup';
import { DuplicatesLookupResponse } from '../models/duplicates-lookup-response';
import { lookupBdpById } from '../fn/lookup-controller/lookup-bdp-by-id';
import { LookupBdpById$Params } from '../fn/lookup-controller/lookup-bdp-by-id';
import { lookupCordById } from '../fn/lookup-controller/lookup-cord-by-id';
import { LookupCordById$Params } from '../fn/lookup-controller/lookup-cord-by-id';
import { lookupDonorById } from '../fn/lookup-controller/lookup-donor-by-id';
import { LookupDonorById$Params } from '../fn/lookup-controller/lookup-donor-by-id';
import { lookupGuidByRid } from '../fn/lookup-controller/lookup-guid-by-rid';
import { LookupGuidByRid$Params } from '../fn/lookup-controller/lookup-guid-by-rid';
import { lookupInventoryOrdersBy } from '../fn/lookup-controller/lookup-inventory-orders-by';
import { LookupInventoryOrdersBy$Params } from '../fn/lookup-controller/lookup-inventory-orders-by';
import { lookupRecipientBy } from '../fn/lookup-controller/lookup-recipient-by';
import { LookupRecipientBy$Params } from '../fn/lookup-controller/lookup-recipient-by';
import { PageInventoryOrderLookup } from '../models/page-inventory-order-lookup';
import { PageRecipientLookup } from '../models/page-recipient-lookup';

@Injectable({ providedIn: 'root' })
export class LookupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lookupRecipientBy()` */
  static readonly LookupRecipientByPath = '/lookup/recipient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupRecipientBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientBy$Response(params: LookupRecipientBy$Params, context?: HttpContext): Observable<StrictHttpResponse<PageRecipientLookup>> {
    return lookupRecipientBy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupRecipientBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupRecipientBy(params: LookupRecipientBy$Params, context?: HttpContext): Observable<PageRecipientLookup> {
    return this.lookupRecipientBy$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageRecipientLookup>): PageRecipientLookup => r.body)
    );
  }

  /** Path part for operation `lookupInventoryOrdersBy()` */
  static readonly LookupInventoryOrdersByPath = '/lookup/inventory';

  /**
   * This method looks up inventory orders of specific type by bpGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupInventoryOrdersBy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupInventoryOrdersBy$Response(params: LookupInventoryOrdersBy$Params, context?: HttpContext): Observable<StrictHttpResponse<PageInventoryOrderLookup>> {
    return lookupInventoryOrdersBy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method looks up inventory orders of specific type by bpGuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupInventoryOrdersBy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lookupInventoryOrdersBy(params: LookupInventoryOrdersBy$Params, context?: HttpContext): Observable<PageInventoryOrderLookup> {
    return this.lookupInventoryOrdersBy$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageInventoryOrderLookup>): PageInventoryOrderLookup => r.body)
    );
  }

  /** Path part for operation `duplicatePatientLookup()` */
  static readonly DuplicatePatientLookupPath = '/lookup/duplicate-patient';

  /**
   * This endpoint is used for Patient Import functionality by Pathfinder application. It's main purpose is to find existing patients that could probably be a duplicate of a patient staged for import.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicatePatientLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicatePatientLookup$Response(params: DuplicatePatientLookup$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicatesLookupResponse>> {
    return duplicatePatientLookup(this.http, this.rootUrl, params, context);
  }

  /**
   * This endpoint is used for Patient Import functionality by Pathfinder application. It's main purpose is to find existing patients that could probably be a duplicate of a patient staged for import.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicatePatientLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicatePatientLookup(params: DuplicatePatientLookup$Params, context?: HttpContext): Observable<DuplicatesLookupResponse> {
    return this.duplicatePatientLookup$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicatesLookupResponse>): DuplicatesLookupResponse => r.body)
    );
  }

  /** Path part for operation `lookupGuidByRid()` */
  static readonly LookupGuidByRidPath = '/lookup/guid/{rid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGuidByRid()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGuidByRid$Response(params: LookupGuidByRid$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return lookupGuidByRid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGuidByRid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGuidByRid(params: LookupGuidByRid$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.lookupGuidByRid$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `lookupDonorById()` */
  static readonly LookupDonorByIdPath = '/lookup/donor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupDonorById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupDonorById$Response(params?: LookupDonorById$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DonorLookup>>> {
    return lookupDonorById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupDonorById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupDonorById(params?: LookupDonorById$Params, context?: HttpContext): Observable<Array<DonorLookup>> {
    return this.lookupDonorById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DonorLookup>>): Array<DonorLookup> => r.body)
    );
  }

  /** Path part for operation `lookupCordById()` */
  static readonly LookupCordByIdPath = '/lookup/cord';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupCordById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCordById$Response(params?: LookupCordById$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CordLookup>>> {
    return lookupCordById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupCordById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCordById(params?: LookupCordById$Params, context?: HttpContext): Observable<Array<CordLookup>> {
    return this.lookupCordById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CordLookup>>): Array<CordLookup> => r.body)
    );
  }

  /** Path part for operation `lookupBdpById()` */
  static readonly LookupBdpByIdPath = '/lookup/bdp';

  /**
   * This method retrieves BDP's by Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupBdpById()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupBdpById$Response(params?: LookupBdpById$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BdpLookup>>> {
    return lookupBdpById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves BDP's by Id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupBdpById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupBdpById(params?: LookupBdpById$Params, context?: HttpContext): Observable<Array<BdpLookup>> {
    return this.lookupBdpById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BdpLookup>>): Array<BdpLookup> => r.body)
    );
  }

}
