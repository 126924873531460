/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentDetails } from '../../models/document-details';

export interface GetAllDocuments$Params {
  entity: string;
  type: 'SSAOPLREQUEST' | 'INTERNATIONAL_FORMS' | 'POS_ANTIBODY_FORM' | 'SEARCH_SUM_REPORT' | 'DONOR_CONTACT_REPORT' | 'NEG_ANTIBODY_FORM';
}

export function getAllDocuments(http: HttpClient, rootUrl: string, params: GetAllDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDetails>>> {
  const rb = new RequestBuilder(rootUrl, getAllDocuments.PATH, 'get');
  if (params) {
    rb.query('entity', params.entity, {});
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DocumentDetails>>;
    })
  );
}

getAllDocuments.PATH = '/documents';
