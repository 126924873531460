/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkAvailability } from '../fn/order-type-controller/check-availability';
import { CheckAvailability$Params } from '../fn/order-type-controller/check-availability';
import { OrderTypeDto } from '../models/order-type-dto';

@Injectable({ providedIn: 'root' })
export class OrderTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkAvailability()` */
  static readonly CheckAvailabilityPath = '/order-types/available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAvailability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAvailability$Response(params: CheckAvailability$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderTypeDto>>> {
    return checkAvailability(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAvailability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAvailability(params: CheckAvailability$Params, context?: HttpContext): Observable<Array<OrderTypeDto>> {
    return this.checkAvailability$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderTypeDto>>): Array<OrderTypeDto> => r.body)
    );
  }

}
