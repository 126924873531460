import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BLeaderCode, BLeaderDetailedInfoModel} from '@matchsource/api/typings';
import {TranslocoPipe} from '@jsverse/transloco';
import {NgFor} from '@angular/common';

interface BLeaderDetailedInfoData {
  info: BLeaderDetailedInfoModel;
  leader: string;
  typing: string;
}

const getOrder = (leader: string, major: string) => {
  if (leader === major) {
    return -1;
  }

  if (leader === BLeaderCode.X) {
    return 1;
  }

  return 0;
};

const getResourceKey = (leader: string, patientLeader: string): string => {
  if (leader === BLeaderCode.M || leader === BLeaderCode.T) {
    if (leader === patientLeader) {
      return 'BLEADER.TYPE.MAJOR_LEADER';
    }

    return 'BLEADER.TYPE.MINOR_LEADER';
  }

  return 'BLEADER.TYPE.UNKNOWN_LEADER';
};

@Component({
  selector: 'ms-bleader-detailed-info',
  templateUrl: './bleader-detailed-info.component.html',
  styleUrls: ['./bleader-detailed-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, TranslocoPipe],
})
export class BleaderDetailedInfoComponent implements OnChanges {
  @Input()
  data: BLeaderDetailedInfoData;

  dataSet: any;

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      const patientLeader = this.data.leader;
      this.dataSet = Object.entries(this.data.info.bleaderDetails)
        .sort(([a], [b]) => getOrder(a, patientLeader) - getOrder(b, patientLeader))
        .map(([leader, value]) => ({leader, title: getResourceKey(leader, patientLeader), loci: value.join(', ')}));
    }
  }
}
