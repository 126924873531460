/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { extractSubjectInquiryAnswers } from '../fn/workup-enhanced-controller/extract-subject-inquiry-answers';
import { ExtractSubjectInquiryAnswers$Params } from '../fn/workup-enhanced-controller/extract-subject-inquiry-answers';
import { getLatestInProgressVerificationByGuid } from '../fn/workup-enhanced-controller/get-latest-in-progress-verification-by-guid';
import { GetLatestInProgressVerificationByGuid$Params } from '../fn/workup-enhanced-controller/get-latest-in-progress-verification-by-guid';
import { getWorkupByOrderGuid } from '../fn/workup-enhanced-controller/get-workup-by-order-guid';
import { GetWorkupByOrderGuid$Params } from '../fn/workup-enhanced-controller/get-workup-by-order-guid';
import { loadBusinessPartyDefaults } from '../fn/workup-enhanced-controller/load-business-party-defaults';
import { LoadBusinessPartyDefaults$Params } from '../fn/workup-enhanced-controller/load-business-party-defaults';
import { loadPatientDefaults } from '../fn/workup-enhanced-controller/load-patient-defaults';
import { LoadPatientDefaults$Params } from '../fn/workup-enhanced-controller/load-patient-defaults';
import { loadWorkupInProgress } from '../fn/workup-enhanced-controller/load-workup-in-progress';
import { LoadWorkupInProgress$Params } from '../fn/workup-enhanced-controller/load-workup-in-progress';
import { loadWorkupInProgressById } from '../fn/workup-enhanced-controller/load-workup-in-progress-by-id';
import { LoadWorkupInProgressById$Params } from '../fn/workup-enhanced-controller/load-workup-in-progress-by-id';
import { publishCreateRecipientPrescription1 } from '../fn/workup-enhanced-controller/publish-create-recipient-prescription-1';
import { PublishCreateRecipientPrescription1$Params } from '../fn/workup-enhanced-controller/publish-create-recipient-prescription-1';
import { removeWorkupInProgress } from '../fn/workup-enhanced-controller/remove-workup-in-progress';
import { RemoveWorkupInProgress$Params } from '../fn/workup-enhanced-controller/remove-workup-in-progress';
import { saveBusinessPartyDefaults } from '../fn/workup-enhanced-controller/save-business-party-defaults';
import { SaveBusinessPartyDefaults$Params } from '../fn/workup-enhanced-controller/save-business-party-defaults';
import { savePatientDefaults } from '../fn/workup-enhanced-controller/save-patient-defaults';
import { SavePatientDefaults$Params } from '../fn/workup-enhanced-controller/save-patient-defaults';
import { saveWorkup } from '../fn/workup-enhanced-controller/save-workup';
import { SaveWorkup$Params } from '../fn/workup-enhanced-controller/save-workup';
import { saveWorkupInProgress } from '../fn/workup-enhanced-controller/save-workup-in-progress';
import { SaveWorkupInProgress$Params } from '../fn/workup-enhanced-controller/save-workup-in-progress';
import { SubjectInquiryAnswers } from '../models/subject-inquiry-answers';
import { updateWorkingWorkup } from '../fn/workup-enhanced-controller/update-working-workup';
import { UpdateWorkingWorkup$Params } from '../fn/workup-enhanced-controller/update-working-workup';
import { updateWorkupInProgress } from '../fn/workup-enhanced-controller/update-workup-in-progress';
import { UpdateWorkupInProgress$Params } from '../fn/workup-enhanced-controller/update-workup-in-progress';
import { Workup } from '../models/workup';
import { WorkupId } from '../models/workup-id';
import { WorkupInProgressInfo } from '../models/workup-in-progress-info';
import { WuDefaultWorkup } from '../models/wu-default-workup';

@Injectable({ providedIn: 'root' })
export class WorkupEnhancedControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateWorkingWorkup()` */
  static readonly UpdateWorkingWorkupPath = '/workup/enhanced/working-workup/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkingWorkup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingWorkup$Response(params: UpdateWorkingWorkup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWorkingWorkup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkingWorkup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkingWorkup(params: UpdateWorkingWorkup$Params, context?: HttpContext): Observable<void> {
    return this.updateWorkingWorkup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadWorkupInProgress()` */
  static readonly LoadWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgress$Response(params: LoadWorkupInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkupInProgressInfo>>> {
    return loadWorkupInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgress(params: LoadWorkupInProgress$Params, context?: HttpContext): Observable<Array<WorkupInProgressInfo>> {
    return this.loadWorkupInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkupInProgressInfo>>): Array<WorkupInProgressInfo> => r.body)
    );
  }

  /** Path part for operation `updateWorkupInProgress()` */
  static readonly UpdateWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkupInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupInProgress$Response(params: UpdateWorkupInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWorkupInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkupInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkupInProgress(params: UpdateWorkupInProgress$Params, context?: HttpContext): Observable<void> {
    return this.updateWorkupInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveWorkupInProgress()` */
  static readonly SaveWorkupInProgressPath = '/workup/enhanced/in-progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkupInProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupInProgress$Response(params: SaveWorkupInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupInProgressInfo>> {
    return saveWorkupInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWorkupInProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkupInProgress(params: SaveWorkupInProgress$Params, context?: HttpContext): Observable<WorkupInProgressInfo> {
    return this.saveWorkupInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupInProgressInfo>): WorkupInProgressInfo => r.body)
    );
  }

  /** Path part for operation `getWorkupByOrderGuid()` */
  static readonly GetWorkupByOrderGuidPath = '/workup/enhanced/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkupByOrderGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupByOrderGuid$Response(params: GetWorkupByOrderGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Workup>> {
    return getWorkupByOrderGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkupByOrderGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkupByOrderGuid(params: GetWorkupByOrderGuid$Params, context?: HttpContext): Observable<Workup> {
    return this.getWorkupByOrderGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Workup>): Workup => r.body)
    );
  }

  /** Path part for operation `saveWorkup()` */
  static readonly SaveWorkupPath = '/workup/enhanced/{orderGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveWorkup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkup$Response(params: SaveWorkup$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkupId>> {
    return saveWorkup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveWorkup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveWorkup(params: SaveWorkup$Params, context?: HttpContext): Observable<WorkupId> {
    return this.saveWorkup$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkupId>): WorkupId => r.body)
    );
  }

  /** Path part for operation `extractSubjectInquiryAnswers()` */
  static readonly ExtractSubjectInquiryAnswersPath = '/workup/enhanced/workup-answers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractSubjectInquiryAnswers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extractSubjectInquiryAnswers$Response(params: ExtractSubjectInquiryAnswers$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectInquiryAnswers>> {
    return extractSubjectInquiryAnswers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extractSubjectInquiryAnswers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extractSubjectInquiryAnswers(params: ExtractSubjectInquiryAnswers$Params, context?: HttpContext): Observable<SubjectInquiryAnswers> {
    return this.extractSubjectInquiryAnswers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectInquiryAnswers>): SubjectInquiryAnswers => r.body)
    );
  }

  /** Path part for operation `savePatientDefaults()` */
  static readonly SavePatientDefaultsPath = '/workup/enhanced/patient-default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePatientDefaults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePatientDefaults$Response(params: SavePatientDefaults$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return savePatientDefaults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `savePatientDefaults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePatientDefaults(params: SavePatientDefaults$Params, context?: HttpContext): Observable<void> {
    return this.savePatientDefaults$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveBusinessPartyDefaults()` */
  static readonly SaveBusinessPartyDefaultsPath = '/workup/enhanced/bp-default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveBusinessPartyDefaults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBusinessPartyDefaults$Response(params: SaveBusinessPartyDefaults$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveBusinessPartyDefaults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveBusinessPartyDefaults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveBusinessPartyDefaults(params: SaveBusinessPartyDefaults$Params, context?: HttpContext): Observable<void> {
    return this.saveBusinessPartyDefaults$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `publishCreateRecipientPrescription1()` */
  static readonly PublishCreateRecipientPrescription1Path = '/workup/enhanced/publish/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishCreateRecipientPrescription1()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription1$Response(params: PublishCreateRecipientPrescription1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishCreateRecipientPrescription1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishCreateRecipientPrescription1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishCreateRecipientPrescription1(params: PublishCreateRecipientPrescription1$Params, context?: HttpContext): Observable<void> {
    return this.publishCreateRecipientPrescription1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadPatientDefaults()` */
  static readonly LoadPatientDefaultsPath = '/workup/enhanced/patient-default/{bpGuid}/{subjectGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPatientDefaults()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPatientDefaults$Response(params: LoadPatientDefaults$Params, context?: HttpContext): Observable<StrictHttpResponse<WuDefaultWorkup>> {
    return loadPatientDefaults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadPatientDefaults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPatientDefaults(params: LoadPatientDefaults$Params, context?: HttpContext): Observable<WuDefaultWorkup> {
    return this.loadPatientDefaults$Response(params, context).pipe(
      map((r: StrictHttpResponse<WuDefaultWorkup>): WuDefaultWorkup => r.body)
    );
  }

  /** Path part for operation `loadWorkupInProgressById()` */
  static readonly LoadWorkupInProgressByIdPath = '/workup/enhanced/in-progress/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadWorkupInProgressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgressById$Response(params: LoadWorkupInProgressById$Params, context?: HttpContext): Observable<StrictHttpResponse<Workup>> {
    return loadWorkupInProgressById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadWorkupInProgressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadWorkupInProgressById(params: LoadWorkupInProgressById$Params, context?: HttpContext): Observable<Workup> {
    return this.loadWorkupInProgressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Workup>): Workup => r.body)
    );
  }

  /** Path part for operation `removeWorkupInProgress()` */
  static readonly RemoveWorkupInProgressPath = '/workup/enhanced/in-progress/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeWorkupInProgress()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupInProgress$Response(params: RemoveWorkupInProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeWorkupInProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeWorkupInProgress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeWorkupInProgress(params: RemoveWorkupInProgress$Params, context?: HttpContext): Observable<void> {
    return this.removeWorkupInProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLatestInProgressVerificationByGuid()` */
  static readonly GetLatestInProgressVerificationByGuidPath = '/workup/enhanced/in-progress/latest/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestInProgressVerificationByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInProgressVerificationByGuid$Response(params: GetLatestInProgressVerificationByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return getLatestInProgressVerificationByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestInProgressVerificationByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestInProgressVerificationByGuid(params: GetLatestInProgressVerificationByGuid$Params, context?: HttpContext): Observable<boolean> {
    return this.getLatestInProgressVerificationByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `loadBusinessPartyDefaults()` */
  static readonly LoadBusinessPartyDefaultsPath = '/workup/enhanced/bp-default/{bpGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadBusinessPartyDefaults()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadBusinessPartyDefaults$Response(params: LoadBusinessPartyDefaults$Params, context?: HttpContext): Observable<StrictHttpResponse<WuDefaultWorkup>> {
    return loadBusinessPartyDefaults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadBusinessPartyDefaults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadBusinessPartyDefaults(params: LoadBusinessPartyDefaults$Params, context?: HttpContext): Observable<WuDefaultWorkup> {
    return this.loadBusinessPartyDefaults$Response(params, context).pipe(
      map((r: StrictHttpResponse<WuDefaultWorkup>): WuDefaultWorkup => r.body)
    );
  }

}
