/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BusinessParty } from '../../models/business-party';

export interface ByBpType$Params {
  searchMethods?: Array<'MANUAL' | 'ELECTRONIC' | 'EMDIS' | 'UNKNOWN'>;
  suppliedSources?: Array<'DONOR' | 'CORD' | 'BOTH'>;
  contractStatuses?: Array<'CONTRACTED' | 'NON_CONTRACTED'>;
  types?: Array<'AC' | 'IC' | 'MFG' | 'BIO' | 'REG' | 'DC' | 'LP' | 'TC' | 'CBB' | 'CDS' | 'CCS' | 'BMC' | 'BMD' | 'CP' | 'BB'>;
}

export function byBpType(http: HttpClient, rootUrl: string, params?: ByBpType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessParty>>> {
  const rb = new RequestBuilder(rootUrl, byBpType.PATH, 'get');
  if (params) {
    rb.query('searchMethods', params.searchMethods, {});
    rb.query('suppliedSources', params.suppliedSources, {});
    rb.query('contractStatuses', params.contractStatuses, {});
    rb.query('types', params.types, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BusinessParty>>;
    })
  );
}

byBpType.PATH = '/business-parties';
