import {Serializer} from '@matchsource/api-utils';
import {PatientModel, PatientStatusLiteral, PatientStatus} from '@matchsource/models/patient';
import {isEmpty, getFullName, parseFullName} from '@matchsource/utils';
import {fromDateToISO, fromISOToDate, fromISOToMonthYear, fromMonthYearDateToISO} from '@matchsource/date';
import {Recipient} from '@matchsource/api-generated/subject';
import {getMostRecentShareSearchSummaryReport} from './utils';
import {HasPhone} from '@matchsource/models/patient-shared';
import {searchCriteriaSerializer} from '@matchsource/api/patient-shared';
import {Sex} from '@matchsource/models/sex';

export const patientSerializer: Serializer<PatientModel, Recipient> = {
  fromDTO(input: Recipient): PatientModel {
    const physicianNameTokens = input.physician ? parseFullName(input.physician) : {firstName: null, lastName: null};
    const refPhysician = input.refPhysician ? parseFullName(input.refPhysician) : {firstName: null, lastName: null};
    const sameAsPhysician = input.physician && input.physician === input.refPhysician;
    const mostRecentShareReport = getMostRecentShareSearchSummaryReport(input.shareSsrHistory);
    const domesticDemographicsInvalid = 'domesticDemographicsValid' in input ? !input.domesticDemographicsValid : null;

    return {
      ...input,
      localId: input.localId,
      status: input.status as PatientStatusLiteral,
      sex: input.sex as Sex,
      sameAsPhysician,
      physicianFirstName: physicianNameTokens.firstName,
      physicianLastName: physicianNameTokens.lastName,
      refPhysicianEmail: input.refPhysicianEmail,
      refPhysicianFirstName: sameAsPhysician ? physicianNameTokens.firstName : refPhysician.firstName,
      refPhysicianLastName: sameAsPhysician ? physicianNameTokens.lastName : refPhysician.lastName,
      isInProgress: input.status === PatientStatus.InProgress,
      isSubmitted:
        input.status === PatientStatus.PreliminarySearch ||
        input.status === PatientStatus.FormalSearch ||
        input.status === PatientStatus.Closed,
      hasPhone: input.phoneNum ? HasPhone.Yes : HasPhone.No,
      searchCriteria: searchCriteriaSerializer.fromDTO(input.searchParameters),
      phenotype: {
        index: input.phenotype?.num,
        loci: input.phenotype?.hla.map(item => ({
          name: item.locus,
          type1: item.type1,
          type2: item.type2,
          glstringInd: item.glstringInd,
        })),
      },
      birthDateRaw: input.birthDate,
      birthDate: fromISOToDate(input.birthDate),
      expectedDeliveryDate: fromISOToMonthYear(input.expectedDeliveryDate),
      diagnosisDate: fromISOToMonthYear(input.diagnosisDate),
      copySsrToBp: mostRecentShareReport.bpGuid,
      otherTcEmail: mostRecentShareReport.bpEmail,
      otherTcName: mostRecentShareReport.bpName,
      shareSsrHistory: input.shareSsrHistory,
      readyForAutoSubmission: input.readyForAutoSubmission,
      hasSwabKit: input.hasSwabKit,
      // The flag waitingForTyping is used only for "CP" patients
      waitingForTyping: input.status === PatientStatus.InProgress,
      cpTransferred: input.cpTransferred,
      genderIdentityCode: input.genderIdentityCode,
      genderSelfIdentityText: input.genderSelfIdentityText,
      allogeneicCode: input.previousAlloHtcInd,
      returnTrackingNumber: input.returnTrackingNumber,
      chainOfIdentity: input.chainOfIdentity,
      countryCode: input.countryCode,
      domesticDemographicsInvalid,
    };
  },

  toDTO(input: PatientModel): Recipient {
    const physician = getFullName(input.physicianFirstName, input.physicianLastName);
    const refPhysician = input.sameAsPhysician
      ? physician
      : getFullName(input.refPhysicianFirstName, input.refPhysicianLastName);

    const result: Recipient = {
      birthDate: fromDateToISO(input.birthDate),
      expectedDeliveryDate: fromMonthYearDateToISO(input.expectedDeliveryDate),
      diagnosisDate: fromMonthYearDateToISO(input.diagnosisDate),
      bloodTypeCode: input.bloodTypeCode,
      careOf: input.careOf,
      city: input.city,
      cmvStatusCode: input.cmvStatusCode,
      coordinatorId: input.coordinatorId,
      countryCode: input.countryCode,
      diagnosisCode: input.diagnosisCode,
      diseaseStage: input.diseaseStage,
      email: input.email,
      ethnicityCode: input.ethnicityCode,
      firstName: input.firstName,
      id: input.id,
      languageCode: input.languageCode,
      lastName: input.lastName,
      localId: input.localId,
      middleInitialName: input.middleInitialName,
      otherDisease: input.otherDisease,
      otherLanguage: input.otherLanguage,
      patientProcess: input.patientProcess,
      phenotypeIds: input.phenotypeIds,
      phoneNum: input.phoneNum,
      physician,
      refPhysician,
      preferredProductCode: input.preferredProductCode,
      primaryAddress: input.primaryAddress,
      raceCodes: input.raceCodes,
      refId: input.refId,
      refPhysicianEmail: input.refPhysicianEmail,
      remissionsCount: input.remissionsCount,
      rhType: input.rhType,
      secondaryAddress: input.secondaryAddress,
      secondaryCode: input.secondaryCode,
      secondaryDiagnosis: input.secondaryDiagnosis,
      sex: input.sex,
      state: input.state,
      status: input.status,
      tcId: input.tcId,
      transplantTimelineCode: input.transplantTimelineCode,
      transplantTimelineUpdated: input.transplantTimelineUpdated,
      unbornPatient: input.unbornPatient,
      weight: input.weight,
      zipPostalCode: input.zipPostalCode,
      searchParameters: input.waitingForTyping === false ? null : searchCriteriaSerializer.toDTO(input.searchCriteria),
      identityVerifiedInd: input.identityVerifiedInd,
      shareSsrHistory: input.shareSsrHistory,
      readyForAutoSubmission: input.readyForAutoSubmission,
      hasSwabKit: input.hasSwabKit,
      genderIdentityCode: input.genderIdentityCode,
      genderSelfIdentityText: input.genderSelfIdentityText,
      previousAlloHtcInd: input.allogeneicCode,
      chainOfIdentity: input.chainOfIdentity,
    };

    if (input.labTypingReceived !== undefined) {
      result.labTypingReceived = input.labTypingReceived;
    }

    if (input.labTypingVerified !== undefined) {
      result.labTypingVerified = input.labTypingVerified;
    }

    if (input.phenotype !== undefined) {
      result.phenotype = {
        num: input.phenotype.index,
        hla: input.phenotype.loci.map(item => ({
          locus: item.name,
          type1: item.type1 || '',
          type2: isEmpty(item.type1) ? '' : item.type2,
          glstringInd: item.glstringInd,
        })),
      };
    }

    if (input.shipToPatientInd !== undefined) {
      result.shipToPatientInd = input.shipToPatientInd;
    }

    if (input.altShippingAddress !== undefined) {
      result.altShippingAddress = input.altShippingAddress;
    }
    return result;
  },
};
