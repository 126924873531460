/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkIfLabRefOrLocalIdExist } from '../fn/recipient-identifier-controller/check-if-lab-ref-or-local-id-exist';
import { CheckIfLabRefOrLocalIdExist$Params } from '../fn/recipient-identifier-controller/check-if-lab-ref-or-local-id-exist';
import { generateId } from '../fn/recipient-identifier-controller/generate-id';
import { GenerateId$Params } from '../fn/recipient-identifier-controller/generate-id';
import { getRecipientIdentifiers } from '../fn/recipient-identifier-controller/get-recipient-identifiers';
import { GetRecipientIdentifiers$Params } from '../fn/recipient-identifier-controller/get-recipient-identifiers';
import { SubjectIdentifier } from '../models/subject-identifier';

@Injectable({ providedIn: 'root' })
export class RecipientIdentifierControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `generateId()` */
  static readonly GenerateIdPath = '/recipients/generateId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateId$Response(params?: GenerateId$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return generateId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateId(params?: GenerateId$Params, context?: HttpContext): Observable<number> {
    return this.generateId$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getRecipientIdentifiers()` */
  static readonly GetRecipientIdentifiersPath = '/recipients/{guid}/identifiers';

  /**
   * Retrieves all identifier of patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientIdentifiers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentifiers$Response(params: GetRecipientIdentifiers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectIdentifier>>> {
    return getRecipientIdentifiers(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves all identifier of patient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientIdentifiers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientIdentifiers(params: GetRecipientIdentifiers$Params, context?: HttpContext): Observable<Array<SubjectIdentifier>> {
    return this.getRecipientIdentifiers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectIdentifier>>): Array<SubjectIdentifier> => r.body)
    );
  }

  /** Path part for operation `checkIfLabRefOrLocalIdExist()` */
  static readonly CheckIfLabRefOrLocalIdExistPath = '/recipients/identifiers/exist/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfLabRefOrLocalIdExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfLabRefOrLocalIdExist$Response(params: CheckIfLabRefOrLocalIdExist$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return checkIfLabRefOrLocalIdExist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfLabRefOrLocalIdExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfLabRefOrLocalIdExist(params: CheckIfLabRefOrLocalIdExist$Params, context?: HttpContext): Observable<boolean> {
    return this.checkIfLabRefOrLocalIdExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
