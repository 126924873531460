/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { detectStatusAnomaly } from '../fn/anomaly-detection-controller/detect-status-anomaly';
import { DetectStatusAnomaly$Params } from '../fn/anomaly-detection-controller/detect-status-anomaly';
import { getDetectedStatusAnomalies } from '../fn/anomaly-detection-controller/get-detected-status-anomalies';
import { GetDetectedStatusAnomalies$Params } from '../fn/anomaly-detection-controller/get-detected-status-anomalies';
import { StatusAnomaly } from '../models/status-anomaly';
import { StatusAnomalyDetectionResponse } from '../models/status-anomaly-detection-response';

@Injectable({ providedIn: 'root' })
export class AnomalyDetectionControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `detectStatusAnomaly()` */
  static readonly DetectStatusAnomalyPath = '/anomalyDetection';

  /**
   * Use known information where possible from the client to detect status anomalies across backend systems.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detectStatusAnomaly()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  detectStatusAnomaly$Response(params: DetectStatusAnomaly$Params, context?: HttpContext): Observable<StrictHttpResponse<StatusAnomalyDetectionResponse>> {
    return detectStatusAnomaly(this.http, this.rootUrl, params, context);
  }

  /**
   * Use known information where possible from the client to detect status anomalies across backend systems.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detectStatusAnomaly$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  detectStatusAnomaly(params: DetectStatusAnomaly$Params, context?: HttpContext): Observable<StatusAnomalyDetectionResponse> {
    return this.detectStatusAnomaly$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusAnomalyDetectionResponse>): StatusAnomalyDetectionResponse => r.body)
    );
  }

  /** Path part for operation `getDetectedStatusAnomalies()` */
  static readonly GetDetectedStatusAnomaliesPath = '/anomalyDetection/get';

  /**
   * Checks if the requested sources have anomalies already detected.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetectedStatusAnomalies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDetectedStatusAnomalies$Response(params: GetDetectedStatusAnomalies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StatusAnomaly>>> {
    return getDetectedStatusAnomalies(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks if the requested sources have anomalies already detected.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetectedStatusAnomalies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDetectedStatusAnomalies(params: GetDetectedStatusAnomalies$Params, context?: HttpContext): Observable<Array<StatusAnomaly>> {
    return this.getDetectedStatusAnomalies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatusAnomaly>>): Array<StatusAnomaly> => r.body)
    );
  }

}
