/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { report } from '../fn/unit-report-controller/report';
import { Report$Params } from '../fn/unit-report-controller/report';

@Injectable({ providedIn: 'root' })
export class UnitReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `report()` */
  static readonly ReportPath = '/unit-report/cords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `report()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  report$Response(params: Report$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return report(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `report$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  report(params: Report$Params, context?: HttpContext): Observable<void> {
    return this.report$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
