/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRecipientClosedHistory } from '../fn/recipient-history-controller/get-recipient-closed-history';
import { GetRecipientClosedHistory$Params } from '../fn/recipient-history-controller/get-recipient-closed-history';
import { getRecipientTransferHistory } from '../fn/recipient-history-controller/get-recipient-transfer-history';
import { GetRecipientTransferHistory$Params } from '../fn/recipient-history-controller/get-recipient-transfer-history';
import { getRecipientTransplantTimelineHistory } from '../fn/recipient-history-controller/get-recipient-transplant-timeline-history';
import { GetRecipientTransplantTimelineHistory$Params } from '../fn/recipient-history-controller/get-recipient-transplant-timeline-history';
import { RecipientClosedHistory } from '../models/recipient-closed-history';
import { RecipientTransferHistory } from '../models/recipient-transfer-history';
import { TransplantTimelineHistory } from '../models/transplant-timeline-history';

@Injectable({ providedIn: 'root' })
export class RecipientHistoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRecipientTransplantTimelineHistory()` */
  static readonly GetRecipientTransplantTimelineHistoryPath = '/recipients/{guid}/history/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTransplantTimelineHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransplantTimelineHistory$Response(params: GetRecipientTransplantTimelineHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransplantTimelineHistory>>> {
    return getRecipientTransplantTimelineHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientTransplantTimelineHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransplantTimelineHistory(params: GetRecipientTransplantTimelineHistory$Params, context?: HttpContext): Observable<Array<TransplantTimelineHistory>> {
    return this.getRecipientTransplantTimelineHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransplantTimelineHistory>>): Array<TransplantTimelineHistory> => r.body)
    );
  }

  /** Path part for operation `getRecipientTransferHistory()` */
  static readonly GetRecipientTransferHistoryPath = '/recipients/{guid}/history/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientTransferHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransferHistory$Response(params: GetRecipientTransferHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipientTransferHistory>>> {
    return getRecipientTransferHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientTransferHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientTransferHistory(params: GetRecipientTransferHistory$Params, context?: HttpContext): Observable<Array<RecipientTransferHistory>> {
    return this.getRecipientTransferHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipientTransferHistory>>): Array<RecipientTransferHistory> => r.body)
    );
  }

  /** Path part for operation `getRecipientClosedHistory()` */
  static readonly GetRecipientClosedHistoryPath = '/recipients/{guid}/history/closed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientClosedHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientClosedHistory$Response(params: GetRecipientClosedHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipientClosedHistory>>> {
    return getRecipientClosedHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientClosedHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientClosedHistory(params: GetRecipientClosedHistory$Params, context?: HttpContext): Observable<Array<RecipientClosedHistory>> {
    return this.getRecipientClosedHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipientClosedHistory>>): Array<RecipientClosedHistory> => r.body)
    );
  }

}
