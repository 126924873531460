import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetNavigationHistory, SaveNavigationItem, UpdateNavigationItem, ClearNavigationHistory} from './navigation-history.actions';
import {NavigationHistoryApiService} from '@matchsource/api/navigation-history';
import {catchError, finalize, tap} from 'rxjs/operators';
import {NavigationItemModel} from '@matchsource/models/navigation-history';
import orderBy from 'lodash-es/orderBy';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {compose, patch} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {HISTORY_RECORDS_LIMIT} from '../declarations';

export type NavigationHistoryStateModel = EntitiesStateModel<NavigationItemModel> & {
  lastSavedId: number | null;
};

@State<NavigationHistoryStateModel>({
  name: 'navigationHistory',
  defaults: {
    ...defaultEntitiesState<NavigationItemModel>(),
    lastSavedId: null,
  },
})
@Injectable()
export class NavigationHistoryState {
  constructor(private readonly navigationHistoryApiService: NavigationHistoryApiService) {}

  @Selector([NavigationHistoryState])
  static entities({entities}: NavigationHistoryStateModel): MsApp.Dictionary<NavigationItemModel> {
    return entities;
  }

  @Selector([NavigationHistoryState.entities])
  static history(entities: MsApp.Dictionary<NavigationItemModel>): NavigationItemModel[] {
    const sortedHistory = orderBy(Object.values(entities), 'createTimestamp', 'desc');

    return sortedHistory.slice(0, HISTORY_RECORDS_LIMIT);
  }

  @Selector([NavigationHistoryState])
  static lastSavedId({lastSavedId}: NavigationHistoryStateModel): number | null {
    return lastSavedId;
  }

  @Selector([NavigationHistoryState.lastSavedId, NavigationHistoryState.entities])
  static lastSavedItem(
    lastSavedId: number | null,
    entities: MsApp.Dictionary<NavigationItemModel>
  ): NavigationItemModel | null {
    return lastSavedId ? entities[lastSavedId] : null;
  }

  @Action(GetNavigationHistory)
  getNavigationHistory(ctx: StateContext<NavigationHistoryStateModel>) {
    const {loaded, loading} = ctx.getState();

    if (loaded || loading) {
      return;
    }

    ctx.setState(compose(loadingEntities(true)));

    return this.navigationHistoryApiService.loadHistory(HISTORY_RECORDS_LIMIT).pipe(
      catchError(error => {
        ctx.setState(setError(error));
        return of([]);
      }),
      tap(history => {
        ctx.setState(
          compose(
            addOrReplaceEntities<NavigationItemModel, NavigationHistoryStateModel>('id', history),
            loadedEntities(true)
          )
        );
      }),
      finalize(() => ctx.setState(loadingEntities(false)))
    );
  }

  @Action(SaveNavigationItem)
  saveNavigationItem(ctx: StateContext<NavigationHistoryStateModel>, {navigationItem}: SaveNavigationItem) {
    return this.navigationHistoryApiService.saveNavigationItem(navigationItem).pipe(
      tap(fetchedNavigationItem => {
        ctx.setState(
          compose(
            addOrReplaceEntities<NavigationItemModel, NavigationHistoryStateModel>('id', fetchedNavigationItem),
            patch({
              lastSavedId: fetchedNavigationItem.id,
            })
          )
        );
      })
    );
  }

  @Action(UpdateNavigationItem)
  updateNavigationItem(ctx: StateContext<NavigationHistoryStateModel>, {navigationItem}: SaveNavigationItem) {
    return this.navigationHistoryApiService.updateNavigationItem(navigationItem).pipe(
      tap(fetchedNavigationItem => {
        ctx.setState(
          compose(
            addOrReplaceEntities<NavigationItemModel, NavigationHistoryStateModel>('id', fetchedNavigationItem),
            patch({
              lastSavedId: fetchedNavigationItem.id,
            })
          )
        );
      })
    );
  }

  @Action(ClearNavigationHistory)
  clearNavigationHistory(ctx: StateContext<NavigationHistoryStateModel>) {
    ctx.setState({...defaultEntitiesState<NavigationItemModel>(), lastSavedId: null,});
  }
}
