/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LabReport } from '../../models/lab-report';

export interface GetLabReports$Params {
  subjectGuid: string;
  includeRelatedDonors?: boolean;
}

export function getLabReports(http: HttpClient, rootUrl: string, params: GetLabReports$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LabReport>>> {
  const rb = new RequestBuilder(rootUrl, getLabReports.PATH, 'get');
  if (params) {
    rb.query('subjectGuid', params.subjectGuid, {});
    rb.query('includeRelatedDonors', params.includeRelatedDonors, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<LabReport>>;
    })
  );
}

getLabReports.PATH = '/lab-reports';
