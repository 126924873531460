/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeDocumentFeature } from '../fn/document-controller/change-document-feature';
import { ChangeDocumentFeature$Params } from '../fn/document-controller/change-document-feature';
import { DocumentDetails } from '../models/document-details';
import { DocumentResult } from '../models/document-result';
import { downloadByOwcId } from '../fn/document-controller/download-by-owc-id';
import { DownloadByOwcId$Params } from '../fn/document-controller/download-by-owc-id';
import { downloadDocument } from '../fn/document-controller/download-document';
import { DownloadDocument$Params } from '../fn/document-controller/download-document';
import { getAllDocuments } from '../fn/document-controller/get-all-documents';
import { GetAllDocuments$Params } from '../fn/document-controller/get-all-documents';
import { getDocumentDetails } from '../fn/document-controller/get-document-details';
import { GetDocumentDetails$Params } from '../fn/document-controller/get-document-details';
import { getDocumentFormats } from '../fn/document-controller/get-document-formats';
import { GetDocumentFormats$Params } from '../fn/document-controller/get-document-formats';
import { getDocumentsDetails } from '../fn/document-controller/get-documents-details';
import { GetDocumentsDetails$Params } from '../fn/document-controller/get-documents-details';
import { getInfoByOwcId } from '../fn/document-controller/get-info-by-owc-id';
import { GetInfoByOwcId$Params } from '../fn/document-controller/get-info-by-owc-id';
import { getLabReportsByRecipient } from '../fn/document-controller/get-lab-reports-by-recipient';
import { GetLabReportsByRecipient$Params } from '../fn/document-controller/get-lab-reports-by-recipient';
import { LabReport } from '../models/lab-report';
import { softDelete } from '../fn/document-controller/soft-delete';
import { SoftDelete$Params } from '../fn/document-controller/soft-delete';
import { uploadDocument } from '../fn/document-controller/upload-document';
import { UploadDocument$Params } from '../fn/document-controller/upload-document';

@Injectable({ providedIn: 'root' })
export class DocumentControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changeDocumentFeature()` */
  static readonly ChangeDocumentFeaturePath = '/documents/feature';

  /**
   * This method changes a document featureGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDocumentFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDocumentFeature$Response(params: ChangeDocumentFeature$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDetails>> {
    return changeDocumentFeature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method changes a document featureGuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeDocumentFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDocumentFeature(params: ChangeDocumentFeature$Params, context?: HttpContext): Observable<DocumentDetails> {
    return this.changeDocumentFeature$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDetails>): DocumentDetails => r.body)
    );
  }

  /** Path part for operation `getAllDocuments()` */
  static readonly GetAllDocumentsPath = '/documents';

  /**
   * This method gets all documents for entity with given GUID and type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocuments$Response(params: GetAllDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDetails>>> {
    return getAllDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method gets all documents for entity with given GUID and type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDocuments(params: GetAllDocuments$Params, context?: HttpContext): Observable<Array<DocumentDetails>> {
    return this.getAllDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>): Array<DocumentDetails> => r.body)
    );
  }

  /** Path part for operation `uploadDocument()` */
  static readonly UploadDocumentPath = '/documents';

  /**
   * This method uploads a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadDocument$Response(params?: UploadDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentResult>> {
    return uploadDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method uploads a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadDocument(params?: UploadDocument$Params, context?: HttpContext): Observable<DocumentResult> {
    return this.uploadDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentResult>): DocumentResult => r.body)
    );
  }

  /** Path part for operation `getDocumentsDetails()` */
  static readonly GetDocumentsDetailsPath = '/documents/details';

  /**
   * This method returns documents details by IDs in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDocumentsDetails$Response(params: GetDocumentsDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDetails>>> {
    return getDocumentsDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method returns documents details by IDs in MatchSource DB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentsDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDocumentsDetails(params: GetDocumentsDetails$Params, context?: HttpContext): Observable<Array<DocumentDetails>> {
    return this.getDocumentsDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDetails>>): Array<DocumentDetails> => r.body)
    );
  }

  /** Path part for operation `downloadDocument()` */
  static readonly DownloadDocumentPath = '/documents/{id}';

  /**
   * This method downloads a document by its ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument$Response(params: DownloadDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method downloads a document by its ID in MatchSource DB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocument(params: DownloadDocument$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `softDelete()` */
  static readonly SoftDeletePath = '/documents/{id}';

  /**
   * This method deletes a document by ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDelete$Response(params: SoftDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return softDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes a document by ID in MatchSource DB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDelete(params: SoftDelete$Params, context?: HttpContext): Observable<void> {
    return this.softDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDocumentDetails()` */
  static readonly GetDocumentDetailsPath = '/documents/{documentId}/details';

  /**
   * This method returns document details by its ID in MatchSource DB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentDetails$Response(params: GetDocumentDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDetails>> {
    return getDocumentDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method returns document details by its ID in MatchSource DB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentDetails(params: GetDocumentDetails$Params, context?: HttpContext): Observable<DocumentDetails> {
    return this.getDocumentDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDetails>): DocumentDetails => r.body)
    );
  }

  /** Path part for operation `getInfoByOwcId()` */
  static readonly GetInfoByOwcIdPath = '/documents/info/{owcId}/recipients/{rcpGuid}';

  /**
   * This method gets a document's information by OWC ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInfoByOwcId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoByOwcId$Response(params: GetInfoByOwcId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return getInfoByOwcId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method gets a document's information by OWC ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInfoByOwcId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInfoByOwcId(params: GetInfoByOwcId$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.getInfoByOwcId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `getLabReportsByRecipient()` */
  static readonly GetLabReportsByRecipientPath = '/documents/info/recipients/{recipientGuid}';

  /**
   * This method gets all Lab Reports related to the provided recipientGuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabReportsByRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabReportsByRecipient$Response(params: GetLabReportsByRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LabReport>>> {
    return getLabReportsByRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method gets all Lab Reports related to the provided recipientGuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLabReportsByRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabReportsByRecipient(params: GetLabReportsByRecipient$Params, context?: HttpContext): Observable<Array<LabReport>> {
    return this.getLabReportsByRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LabReport>>): Array<LabReport> => r.body)
    );
  }

  /** Path part for operation `getDocumentFormats()` */
  static readonly GetDocumentFormatsPath = '/documents/extensions';

  /**
   * This method lists all accepted document formats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentFormats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentFormats$Response(params: GetDocumentFormats$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getDocumentFormats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method lists all accepted document formats
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentFormats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentFormats(params: GetDocumentFormats$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getDocumentFormats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `downloadByOwcId()` */
  static readonly DownloadByOwcIdPath = '/documents/download/{owcId}/recipients/{rcpGuid}';

  /**
   * This method downloads document specified with OWC ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadByOwcId()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadByOwcId$Response(params: DownloadByOwcId$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadByOwcId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method downloads document specified with OWC ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadByOwcId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadByOwcId(params: DownloadByOwcId$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadByOwcId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
