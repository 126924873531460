/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRecipientPtr$Json } from '../fn/recipient-ptr-controller/get-recipient-ptr-json';
import { GetRecipientPtr$Json$Params } from '../fn/recipient-ptr-controller/get-recipient-ptr-json';
import { getRecipientPtr$Plain } from '../fn/recipient-ptr-controller/get-recipient-ptr-plain';
import { GetRecipientPtr$Plain$Params } from '../fn/recipient-ptr-controller/get-recipient-ptr-plain';
import { getSelfAntibodies } from '../fn/recipient-ptr-controller/get-self-antibodies';
import { GetSelfAntibodies$Params } from '../fn/recipient-ptr-controller/get-self-antibodies';
import { SelfAntibodyResult } from '../models/self-antibody-result';

@Injectable({ providedIn: 'root' })
export class RecipientPtrControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSelfAntibodies()` */
  static readonly GetSelfAntibodiesPath = '/recipients/{guid}/self-antibodies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelfAntibodies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfAntibodies$Response(params: GetSelfAntibodies$Params, context?: HttpContext): Observable<StrictHttpResponse<SelfAntibodyResult>> {
    return getSelfAntibodies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSelfAntibodies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelfAntibodies(params: GetSelfAntibodies$Params, context?: HttpContext): Observable<SelfAntibodyResult> {
    return this.getSelfAntibodies$Response(params, context).pipe(
      map((r: StrictHttpResponse<SelfAntibodyResult>): SelfAntibodyResult => r.body)
    );
  }

  /** Path part for operation `getRecipientPtr()` */
  static readonly GetRecipientPtrPath = '/recipients/{guid}/ptr';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientPtr$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Plain$Response(params: GetRecipientPtr$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getRecipientPtr$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientPtr$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Plain(params: GetRecipientPtr$Plain$Params, context?: HttpContext): Observable<{
}> {
    return this.getRecipientPtr$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecipientPtr$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Json$Response(params: GetRecipientPtr$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getRecipientPtr$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecipientPtr$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecipientPtr$Json(params: GetRecipientPtr$Json$Params, context?: HttpContext): Observable<{
}> {
    return this.getRecipientPtr$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
