/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addProfile } from '../fn/antibody-profile-controller/add-profile';
import { AddProfile$Params } from '../fn/antibody-profile-controller/add-profile';
import { AntibodyProfile } from '../models/antibody-profile';
import { BaseAntibodyProfile } from '../models/base-antibody-profile';
import { getBasicProfiles } from '../fn/antibody-profile-controller/get-basic-profiles';
import { GetBasicProfiles$Params } from '../fn/antibody-profile-controller/get-basic-profiles';
import { getProfile } from '../fn/antibody-profile-controller/get-profile';
import { GetProfile$Params } from '../fn/antibody-profile-controller/get-profile';
import { getProfiles } from '../fn/antibody-profile-controller/get-profiles';
import { GetProfiles$Params } from '../fn/antibody-profile-controller/get-profiles';
import { getProfiles1 } from '../fn/antibody-profile-controller/get-profiles-1';
import { GetProfiles1$Params } from '../fn/antibody-profile-controller/get-profiles-1';

@Injectable({ providedIn: 'root' })
export class AntibodyProfileControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProfiles1()` */
  static readonly GetProfiles1Path = '/antibody-profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfiles1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfiles1$Response(params: GetProfiles1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AntibodyProfile>>> {
    return getProfiles1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfiles1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfiles1(params: GetProfiles1$Params, context?: HttpContext): Observable<Array<AntibodyProfile>> {
    return this.getProfiles1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AntibodyProfile>>): Array<AntibodyProfile> => r.body)
    );
  }

  /** Path part for operation `addProfile()` */
  static readonly AddProfilePath = '/antibody-profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProfile$Response(params: AddProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return addProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProfile(params: AddProfile$Params, context?: HttpContext): Observable<number> {
    return this.addProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getProfiles()` */
  static readonly GetProfilesPath = '/antibody-profiles/by-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProfiles$Response(params: GetProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AntibodyProfile>>> {
    return getProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getProfiles(params: GetProfiles$Params, context?: HttpContext): Observable<Array<AntibodyProfile>> {
    return this.getProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AntibodyProfile>>): Array<AntibodyProfile> => r.body)
    );
  }

  /** Path part for operation `getProfile()` */
  static readonly GetProfilePath = '/antibody-profiles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Response(params: GetProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<AntibodyProfile>> {
    return getProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile(params: GetProfile$Params, context?: HttpContext): Observable<AntibodyProfile> {
    return this.getProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<AntibodyProfile>): AntibodyProfile => r.body)
    );
  }

  /** Path part for operation `getBasicProfiles()` */
  static readonly GetBasicProfilesPath = '/antibody-profiles/base';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBasicProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBasicProfiles$Response(params: GetBasicProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BaseAntibodyProfile>>> {
    return getBasicProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBasicProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBasicProfiles(params: GetBasicProfiles$Params, context?: HttpContext): Observable<Array<BaseAntibodyProfile>> {
    return this.getBasicProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseAntibodyProfile>>): Array<BaseAntibodyProfile> => r.body)
    );
  }

}
