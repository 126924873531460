/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AntibodyProfileFeature } from '../../models/antibody-profile-feature';
import { DocumentResult } from '../../models/document-result';
import { DonorContactReportRequestFeature } from '../../models/donor-contact-report-request-feature';
import { InternationalFormFeature } from '../../models/international-form-feature';
import { SearchSumReportFeature } from '../../models/search-sum-report-feature';
import { SsaRequestFeature } from '../../models/ssa-request-feature';

export interface UploadDocument$Params {
      body?: {
'feature': (AntibodyProfileFeature | DonorContactReportRequestFeature | InternationalFormFeature | SearchSumReportFeature | SsaRequestFeature);
'file': Blob;
}
}

export function uploadDocument(http: HttpClient, rootUrl: string, params?: UploadDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentResult>> {
  const rb = new RequestBuilder(rootUrl, uploadDocument.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentResult>;
    })
  );
}

uploadDocument.PATH = '/documents';
