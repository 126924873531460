/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTestResultsBySubjectGuid } from '../fn/test-result-controller/get-test-results-by-subject-guid';
import { GetTestResultsBySubjectGuid$Params } from '../fn/test-result-controller/get-test-results-by-subject-guid';
import { getTestResultsBySubjectGuid1 } from '../fn/test-result-controller/get-test-results-by-subject-guid-1';
import { GetTestResultsBySubjectGuid1$Params } from '../fn/test-result-controller/get-test-results-by-subject-guid-1';
import { TestResultDto } from '../models/test-result-dto';

@Injectable({ providedIn: 'root' })
export class TestResultControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTestResultsBySubjectGuid()` */
  static readonly GetTestResultsBySubjectGuidPath = '/test-results/source/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTestResultsBySubjectGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTestResultsBySubjectGuid$Response(params: GetTestResultsBySubjectGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TestResultDto>>> {
    return getTestResultsBySubjectGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTestResultsBySubjectGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTestResultsBySubjectGuid(params: GetTestResultsBySubjectGuid$Params, context?: HttpContext): Observable<Array<TestResultDto>> {
    return this.getTestResultsBySubjectGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TestResultDto>>): Array<TestResultDto> => r.body)
    );
  }

  /** Path part for operation `getTestResultsBySubjectGuid1()` */
  static readonly GetTestResultsBySubjectGuid1Path = '/test-results/recipient/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTestResultsBySubjectGuid1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTestResultsBySubjectGuid1$Response(params: GetTestResultsBySubjectGuid1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TestResultDto>>> {
    return getTestResultsBySubjectGuid1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTestResultsBySubjectGuid1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTestResultsBySubjectGuid1(params: GetTestResultsBySubjectGuid1$Params, context?: HttpContext): Observable<Array<TestResultDto>> {
    return this.getTestResultsBySubjectGuid1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TestResultDto>>): Array<TestResultDto> => r.body)
    );
  }

}
