/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Allele } from '../models/allele';
import { BloodType } from '../models/blood-type';
import { CcrType } from '../models/ccr-type';
import { ClinicalRationale } from '../models/clinical-rationale';
import { CmvStatus } from '../models/cmv-status';
import { Disease } from '../models/disease';
import { DiseaseStage } from '../models/disease-stage';
import { Dsa } from '../models/dsa';
import { EthnicityDto } from '../models/ethnicity-dto';
import { Genders } from '../models/genders';
import { getAntigenAndAlleles } from '../fn/nomenclatures-controller/get-antigen-and-alleles';
import { GetAntigenAndAlleles$Params } from '../fn/nomenclatures-controller/get-antigen-and-alleles';
import { getGenders } from '../fn/nomenclatures-controller/get-genders';
import { GetGenders$Params } from '../fn/nomenclatures-controller/get-genders';
import { getLightAlleles } from '../fn/nomenclatures-controller/get-light-alleles';
import { GetLightAlleles$Params } from '../fn/nomenclatures-controller/get-light-alleles';
import { getShortAlleles } from '../fn/nomenclatures-controller/get-short-alleles';
import { GetShortAlleles$Params } from '../fn/nomenclatures-controller/get-short-alleles';
import { getSubsequentRequestReasons } from '../fn/nomenclatures-controller/get-subsequent-request-reasons';
import { GetSubsequentRequestReasons$Params } from '../fn/nomenclatures-controller/get-subsequent-request-reasons';
import { Language } from '../models/language';
import { listAllBloodTypes } from '../fn/nomenclatures-controller/list-all-blood-types';
import { ListAllBloodTypes$Params } from '../fn/nomenclatures-controller/list-all-blood-types';
import { listAllClinicalRationales } from '../fn/nomenclatures-controller/list-all-clinical-rationales';
import { ListAllClinicalRationales$Params } from '../fn/nomenclatures-controller/list-all-clinical-rationales';
import { listAllCloseReasons } from '../fn/nomenclatures-controller/list-all-close-reasons';
import { ListAllCloseReasons$Params } from '../fn/nomenclatures-controller/list-all-close-reasons';
import { listAllCmvStatuses } from '../fn/nomenclatures-controller/list-all-cmv-statuses';
import { ListAllCmvStatuses$Params } from '../fn/nomenclatures-controller/list-all-cmv-statuses';
import { listAllDisease } from '../fn/nomenclatures-controller/list-all-disease';
import { ListAllDisease$Params } from '../fn/nomenclatures-controller/list-all-disease';
import { listAllDiseaseStages } from '../fn/nomenclatures-controller/list-all-disease-stages';
import { ListAllDiseaseStages$Params } from '../fn/nomenclatures-controller/list-all-disease-stages';
import { listAllEthnicities } from '../fn/nomenclatures-controller/list-all-ethnicities';
import { ListAllEthnicities$Params } from '../fn/nomenclatures-controller/list-all-ethnicities';
import { listAllLanguages } from '../fn/nomenclatures-controller/list-all-languages';
import { ListAllLanguages$Params } from '../fn/nomenclatures-controller/list-all-languages';
import { listAllMatchPrefernces } from '../fn/nomenclatures-controller/list-all-match-prefernces';
import { ListAllMatchPrefernces$Params } from '../fn/nomenclatures-controller/list-all-match-prefernces';
import { listAllNotReferredReasons } from '../fn/nomenclatures-controller/list-all-not-referred-reasons';
import { ListAllNotReferredReasons$Params } from '../fn/nomenclatures-controller/list-all-not-referred-reasons';
import { listAllStatuses } from '../fn/nomenclatures-controller/list-all-statuses';
import { ListAllStatuses$Params } from '../fn/nomenclatures-controller/list-all-statuses';
import { listAllStatusReasons } from '../fn/nomenclatures-controller/list-all-status-reasons';
import { ListAllStatusReasons$Params } from '../fn/nomenclatures-controller/list-all-status-reasons';
import { listRaces } from '../fn/nomenclatures-controller/list-races';
import { ListRaces$Params } from '../fn/nomenclatures-controller/list-races';
import { loadCcr } from '../fn/nomenclatures-controller/load-ccr';
import { LoadCcr$Params } from '../fn/nomenclatures-controller/load-ccr';
import { loadCoopSubmitResult } from '../fn/nomenclatures-controller/load-coop-submit-result';
import { LoadCoopSubmitResult$Params } from '../fn/nomenclatures-controller/load-coop-submit-result';
import { loadLoci } from '../fn/nomenclatures-controller/load-loci';
import { LoadLoci$Params } from '../fn/nomenclatures-controller/load-loci';
import { loadPreferredProducts } from '../fn/nomenclatures-controller/load-preferred-products';
import { LoadPreferredProducts$Params } from '../fn/nomenclatures-controller/load-preferred-products';
import { loadRhs } from '../fn/nomenclatures-controller/load-rhs';
import { LoadRhs$Params } from '../fn/nomenclatures-controller/load-rhs';
import { loadSexes } from '../fn/nomenclatures-controller/load-sexes';
import { LoadSexes$Params } from '../fn/nomenclatures-controller/load-sexes';
import { loadTransplantTimelines } from '../fn/nomenclatures-controller/load-transplant-timelines';
import { LoadTransplantTimelines$Params } from '../fn/nomenclatures-controller/load-transplant-timelines';
import { MatchPreference } from '../models/match-preference';
import { NotReferredReason } from '../models/not-referred-reason';
import { PreferredProduct } from '../models/preferred-product';
import { Race } from '../models/race';
import { RhType } from '../models/rh-type';
import { Sex } from '../models/sex';
import { Status } from '../models/status';
import { StatusReason } from '../models/status-reason';
import { SubsequentRequestReason } from '../models/subsequent-request-reason';
import { TransplantTimeline } from '../models/transplant-timeline';

@Injectable({ providedIn: 'root' })
export class NomenclaturesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadTransplantTimelines()` */
  static readonly LoadTransplantTimelinesPath = '/nomenclatures/transplant-timelines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadTransplantTimelines()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadTransplantTimelines$Response(params?: LoadTransplantTimelines$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransplantTimeline>>> {
    return loadTransplantTimelines(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadTransplantTimelines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadTransplantTimelines(params?: LoadTransplantTimelines$Params, context?: HttpContext): Observable<Array<TransplantTimeline>> {
    return this.loadTransplantTimelines$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransplantTimeline>>): Array<TransplantTimeline> => r.body)
    );
  }

  /** Path part for operation `getSubsequentRequestReasons()` */
  static readonly GetSubsequentRequestReasonsPath = '/nomenclatures/subsequent-requests-reasons';

  /**
   * Provides a list of subsequent request reason
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubsequentRequestReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsequentRequestReasons$Response(params?: GetSubsequentRequestReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubsequentRequestReason>>> {
    return getSubsequentRequestReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides a list of subsequent request reason
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubsequentRequestReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubsequentRequestReasons(params?: GetSubsequentRequestReasons$Params, context?: HttpContext): Observable<Array<SubsequentRequestReason>> {
    return this.getSubsequentRequestReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubsequentRequestReason>>): Array<SubsequentRequestReason> => r.body)
    );
  }

  /** Path part for operation `listAllStatuses()` */
  static readonly ListAllStatusesPath = '/nomenclatures/statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatuses$Response(params?: ListAllStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Status>>> {
    return listAllStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatuses(params?: ListAllStatuses$Params, context?: HttpContext): Observable<Array<Status>> {
    return this.listAllStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Status>>): Array<Status> => r.body)
    );
  }

  /** Path part for operation `listAllStatusReasons()` */
  static readonly ListAllStatusReasonsPath = '/nomenclatures/status/reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllStatusReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatusReasons$Response(params?: ListAllStatusReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StatusReason>>> {
    return listAllStatusReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllStatusReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllStatusReasons(params?: ListAllStatusReasons$Params, context?: HttpContext): Observable<Array<StatusReason>> {
    return this.listAllStatusReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatusReason>>): Array<StatusReason> => r.body)
    );
  }

  /** Path part for operation `getShortAlleles()` */
  static readonly GetShortAllelesPath = '/nomenclatures/short-alleles/{longLocusName}/{groName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShortAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortAlleles$Response(params: GetShortAlleles$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {
    return getShortAlleles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShortAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortAlleles(params: GetShortAlleles$Params, context?: HttpContext): Observable<{
[key: string]: {
};
}> {
    return this.getShortAlleles$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>): {
[key: string]: {
};
} => r.body)
    );
  }

  /** Path part for operation `loadSexes()` */
  static readonly LoadSexesPath = '/nomenclatures/sexes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadSexes()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadSexes$Response(params?: LoadSexes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Sex>>> {
    return loadSexes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadSexes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadSexes(params?: LoadSexes$Params, context?: HttpContext): Observable<Array<Sex>> {
    return this.loadSexes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Sex>>): Array<Sex> => r.body)
    );
  }

  /** Path part for operation `loadRhs()` */
  static readonly LoadRhsPath = '/nomenclatures/rhs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadRhs()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadRhs$Response(params?: LoadRhs$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RhType>>> {
    return loadRhs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadRhs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadRhs(params?: LoadRhs$Params, context?: HttpContext): Observable<Array<RhType>> {
    return this.loadRhs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RhType>>): Array<RhType> => r.body)
    );
  }

  /** Path part for operation `listRaces()` */
  static readonly ListRacesPath = '/nomenclatures/races';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRaces$Response(params?: ListRaces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Race>>> {
    return listRaces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRaces(params?: ListRaces$Params, context?: HttpContext): Observable<Array<Race>> {
    return this.listRaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Race>>): Array<Race> => r.body)
    );
  }

  /** Path part for operation `loadPreferredProducts()` */
  static readonly LoadPreferredProductsPath = '/nomenclatures/preferred-products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPreferredProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPreferredProducts$Response(params?: LoadPreferredProducts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PreferredProduct>>> {
    return loadPreferredProducts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadPreferredProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPreferredProducts(params?: LoadPreferredProducts$Params, context?: HttpContext): Observable<Array<PreferredProduct>> {
    return this.loadPreferredProducts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PreferredProduct>>): Array<PreferredProduct> => r.body)
    );
  }

  /** Path part for operation `listAllNotReferredReasons()` */
  static readonly ListAllNotReferredReasonsPath = '/nomenclatures/not-referred-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllNotReferredReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllNotReferredReasons$Response(params?: ListAllNotReferredReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotReferredReason>>> {
    return listAllNotReferredReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllNotReferredReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllNotReferredReasons(params?: ListAllNotReferredReasons$Params, context?: HttpContext): Observable<Array<NotReferredReason>> {
    return this.listAllNotReferredReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotReferredReason>>): Array<NotReferredReason> => r.body)
    );
  }

  /** Path part for operation `listAllMatchPrefernces()` */
  static readonly ListAllMatchPreferncesPath = '/nomenclatures/match-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMatchPrefernces()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMatchPrefernces$Response(params?: ListAllMatchPrefernces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MatchPreference>>> {
    return listAllMatchPrefernces(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllMatchPrefernces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMatchPrefernces(params?: ListAllMatchPrefernces$Params, context?: HttpContext): Observable<Array<MatchPreference>> {
    return this.listAllMatchPrefernces$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MatchPreference>>): Array<MatchPreference> => r.body)
    );
  }

  /** Path part for operation `loadLoci()` */
  static readonly LoadLociPath = '/nomenclatures/loci';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadLoci()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLoci$Response(params?: LoadLoci$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return loadLoci(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadLoci$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadLoci(params?: LoadLoci$Params, context?: HttpContext): Observable<Array<string>> {
    return this.loadLoci$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getLightAlleles()` */
  static readonly GetLightAllelesPath = '/nomenclatures/light-alleles/{longLocusName}/{groName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLightAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLightAlleles$Response(params: GetLightAlleles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Allele>>> {
    return getLightAlleles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLightAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLightAlleles(params: GetLightAlleles$Params, context?: HttpContext): Observable<Array<Allele>> {
    return this.getLightAlleles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Allele>>): Array<Allele> => r.body)
    );
  }

  /** Path part for operation `listAllLanguages()` */
  static readonly ListAllLanguagesPath = '/nomenclatures/languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllLanguages$Response(params?: ListAllLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Language>>> {
    return listAllLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllLanguages(params?: ListAllLanguages$Params, context?: HttpContext): Observable<Array<Language>> {
    return this.listAllLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Language>>): Array<Language> => r.body)
    );
  }

  /** Path part for operation `getGenders()` */
  static readonly GetGendersPath = '/nomenclatures/genders';

  /**
   * Provides a list of gender options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGenders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenders$Response(params?: GetGenders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Genders>>> {
    return getGenders(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides a list of gender options
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGenders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenders(params?: GetGenders$Params, context?: HttpContext): Observable<Array<Genders>> {
    return this.getGenders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Genders>>): Array<Genders> => r.body)
    );
  }

  /** Path part for operation `listAllEthnicities()` */
  static readonly ListAllEthnicitiesPath = '/nomenclatures/ethnicities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllEthnicities()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllEthnicities$Response(params?: ListAllEthnicities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EthnicityDto>>> {
    return listAllEthnicities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllEthnicities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllEthnicities(params?: ListAllEthnicities$Params, context?: HttpContext): Observable<Array<EthnicityDto>> {
    return this.listAllEthnicities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EthnicityDto>>): Array<EthnicityDto> => r.body)
    );
  }

  /** Path part for operation `getAntigenAndAlleles()` */
  static readonly GetAntigenAndAllelesPath = '/nomenclatures/dsa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAntigenAndAlleles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAntigenAndAlleles$Response(params?: GetAntigenAndAlleles$Params, context?: HttpContext): Observable<StrictHttpResponse<Dsa>> {
    return getAntigenAndAlleles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAntigenAndAlleles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAntigenAndAlleles(params?: GetAntigenAndAlleles$Params, context?: HttpContext): Observable<Dsa> {
    return this.getAntigenAndAlleles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Dsa>): Dsa => r.body)
    );
  }

  /** Path part for operation `listAllDisease()` */
  static readonly ListAllDiseasePath = '/nomenclatures/diseases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllDisease()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDisease$Response(params?: ListAllDisease$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Disease>>> {
    return listAllDisease(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllDisease$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDisease(params?: ListAllDisease$Params, context?: HttpContext): Observable<Array<Disease>> {
    return this.listAllDisease$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Disease>>): Array<Disease> => r.body)
    );
  }

  /** Path part for operation `listAllDiseaseStages()` */
  static readonly ListAllDiseaseStagesPath = '/nomenclatures/diseases/{diseaseCode}/disease-stages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllDiseaseStages()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDiseaseStages$Response(params: ListAllDiseaseStages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DiseaseStage>>> {
    return listAllDiseaseStages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllDiseaseStages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllDiseaseStages(params: ListAllDiseaseStages$Params, context?: HttpContext): Observable<Array<DiseaseStage>> {
    return this.listAllDiseaseStages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DiseaseStage>>): Array<DiseaseStage> => r.body)
    );
  }

  /** Path part for operation `loadCoopSubmitResult()` */
  static readonly LoadCoopSubmitResultPath = '/nomenclatures/coop/submit-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCoopSubmitResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopSubmitResult$Response(params?: LoadCoopSubmitResult$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return loadCoopSubmitResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadCoopSubmitResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCoopSubmitResult(params?: LoadCoopSubmitResult$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.loadCoopSubmitResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `listAllCmvStatuses()` */
  static readonly ListAllCmvStatusesPath = '/nomenclatures/cmv-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllCmvStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCmvStatuses$Response(params?: ListAllCmvStatuses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CmvStatus>>> {
    return listAllCmvStatuses(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllCmvStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCmvStatuses(params?: ListAllCmvStatuses$Params, context?: HttpContext): Observable<Array<CmvStatus>> {
    return this.listAllCmvStatuses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CmvStatus>>): Array<CmvStatus> => r.body)
    );
  }

  /** Path part for operation `listAllCloseReasons()` */
  static readonly ListAllCloseReasonsPath = '/nomenclatures/closereasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllCloseReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCloseReasons$Response(params?: ListAllCloseReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StatusReason>>> {
    return listAllCloseReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllCloseReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllCloseReasons(params?: ListAllCloseReasons$Params, context?: HttpContext): Observable<Array<StatusReason>> {
    return this.listAllCloseReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatusReason>>): Array<StatusReason> => r.body)
    );
  }

  /** Path part for operation `listAllClinicalRationales()` */
  static readonly ListAllClinicalRationalesPath = '/nomenclatures/clinical-rationales';

  /**
   * Provides a list of clinical rationales
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllClinicalRationales()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllClinicalRationales$Response(params?: ListAllClinicalRationales$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ClinicalRationale>>> {
    return listAllClinicalRationales(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides a list of clinical rationales
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllClinicalRationales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllClinicalRationales(params?: ListAllClinicalRationales$Params, context?: HttpContext): Observable<Array<ClinicalRationale>> {
    return this.listAllClinicalRationales$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ClinicalRationale>>): Array<ClinicalRationale> => r.body)
    );
  }

  /** Path part for operation `loadCcr()` */
  static readonly LoadCcrPath = '/nomenclatures/ccr-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadCcr()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCcr$Response(params?: LoadCcr$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CcrType>>> {
    return loadCcr(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadCcr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadCcr(params?: LoadCcr$Params, context?: HttpContext): Observable<Array<CcrType>> {
    return this.loadCcr$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrType>>): Array<CcrType> => r.body)
    );
  }

  /** Path part for operation `listAllBloodTypes()` */
  static readonly ListAllBloodTypesPath = '/nomenclatures/blood-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllBloodTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllBloodTypes$Response(params?: ListAllBloodTypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BloodType>>> {
    return listAllBloodTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllBloodTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllBloodTypes(params?: ListAllBloodTypes$Params, context?: HttpContext): Observable<Array<BloodType>> {
    return this.listAllBloodTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BloodType>>): Array<BloodType> => r.body)
    );
  }

}
