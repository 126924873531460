/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getComputedMatchResult } from '../fn/match-result-controller/get-computed-match-result';
import { GetComputedMatchResult$Params } from '../fn/match-result-controller/get-computed-match-result';
import { getEpsPotentialSources } from '../fn/match-result-controller/get-eps-potential-sources';
import { GetEpsPotentialSources$Params } from '../fn/match-result-controller/get-eps-potential-sources';
import { getMatchResults } from '../fn/match-result-controller/get-match-results';
import { GetMatchResults$Params } from '../fn/match-result-controller/get-match-results';
import { MatchResultDto } from '../models/match-result-dto';
import { PageMatchResultDto } from '../models/page-match-result-dto';
import { PotentialSource } from '../models/potential-source';

@Injectable({ providedIn: 'root' })
export class MatchResultControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMatchResults()` */
  static readonly GetMatchResultsPath = '/match-results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMatchResults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMatchResults$Response(params: GetMatchResults$Params, context?: HttpContext): Observable<StrictHttpResponse<PageMatchResultDto>> {
    return getMatchResults(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMatchResults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getMatchResults(params: GetMatchResults$Params, context?: HttpContext): Observable<PageMatchResultDto> {
    return this.getMatchResults$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageMatchResultDto>): PageMatchResultDto => r.body)
    );
  }

  /** Path part for operation `getComputedMatchResult()` */
  static readonly GetComputedMatchResultPath = '/match-results/computed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComputedMatchResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComputedMatchResult$Response(params: GetComputedMatchResult$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: MatchResultDto;
}>> {
    return getComputedMatchResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getComputedMatchResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getComputedMatchResult(params: GetComputedMatchResult$Params, context?: HttpContext): Observable<{
[key: string]: MatchResultDto;
}> {
    return this.getComputedMatchResult$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: MatchResultDto;
}>): {
[key: string]: MatchResultDto;
} => r.body)
    );
  }

  /** Path part for operation `getEpsPotentialSources()` */
  static readonly GetEpsPotentialSourcesPath = '/match-results/potential-sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEpsPotentialSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEpsPotentialSources$Response(params: GetEpsPotentialSources$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PotentialSource>>> {
    return getEpsPotentialSources(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEpsPotentialSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEpsPotentialSources(params: GetEpsPotentialSources$Params, context?: HttpContext): Observable<Array<PotentialSource>> {
    return this.getEpsPotentialSources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PotentialSource>>): Array<PotentialSource> => r.body)
    );
  }

}
