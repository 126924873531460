/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNote } from '../fn/note-controller/create-note';
import { CreateNote$Params } from '../fn/note-controller/create-note';
import { deleteNote } from '../fn/note-controller/delete-note';
import { DeleteNote$Params } from '../fn/note-controller/delete-note';
import { getNote } from '../fn/note-controller/get-note';
import { GetNote$Params } from '../fn/note-controller/get-note';
import { getNotes } from '../fn/note-controller/get-notes';
import { GetNotes$Params } from '../fn/note-controller/get-notes';
import { NoteDto } from '../models/note-dto';
import { updateNote } from '../fn/note-controller/update-note';
import { UpdateNote$Params } from '../fn/note-controller/update-note';

@Injectable({ providedIn: 'root' })
export class NoteControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNote()` */
  static readonly GetNotePath = '/notes/{id}';

  /**
   * This method retrieves a note by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNote$Response(params: GetNote$Params, context?: HttpContext): Observable<StrictHttpResponse<NoteDto>> {
    return getNote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a note by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNote(params: GetNote$Params, context?: HttpContext): Observable<NoteDto> {
    return this.getNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoteDto>): NoteDto => r.body)
    );
  }

  /** Path part for operation `updateNote()` */
  static readonly UpdateNotePath = '/notes/{id}';

  /**
   * This method updates a note with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNote$Response(params: UpdateNote$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateNote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates a note with a given ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNote(params: UpdateNote$Params, context?: HttpContext): Observable<void> {
    return this.updateNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteNote()` */
  static readonly DeleteNotePath = '/notes/{id}';

  /**
   * This method deletes a note with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNote$Response(params: DeleteNote$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteNote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes a note with a given ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNote(params: DeleteNote$Params, context?: HttpContext): Observable<void> {
    return this.deleteNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createNote()` */
  static readonly CreateNotePath = '/notes';

  /**
   * This method creates a note and returns its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNote$Response(params: CreateNote$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createNote(this.http, this.rootUrl, params, context);
  }

  /**
   * This method creates a note and returns its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNote(params: CreateNote$Params, context?: HttpContext): Observable<number> {
    return this.createNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getNotes()` */
  static readonly GetNotesPath = '/notes/all';

  /**
   * This method retrieves notes by their IDs specified in a list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNotes$Response(params: GetNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NoteDto>>> {
    return getNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves notes by their IDs specified in a list
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNotes(params: GetNotes$Params, context?: HttpContext): Observable<Array<NoteDto>> {
    return this.getNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NoteDto>>): Array<NoteDto> => r.body)
    );
  }

}
