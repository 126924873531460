import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {AnalyticsApiService} from '@matchsource/api/analytics';
import {NEW_SESSION, NmdpWidget, SESSION_CLOSED} from '@nmdp/nmdp-login';
import {filter, takeUntil} from 'rxjs/operators';
import {AnalyticItemCommonModel, AnalyticsCommonModel, AnalyticsModel} from '@matchsource/models/analytics';
import {ensureArray, uniqId} from '@matchsource/utils';
import {MsDate} from '@matchsource/date';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  private _commonData: MsApp.Dictionary<unknown> = {};

  private readonly destroy$ = new Subject<void>();

  private initialized = false;

  get commonData(): MsApp.Dictionary<unknown> {
    return this._commonData;
  }

  get sessionGuid(): string {
    return this.commonData.sessionGuid as string;
  }

  constructor(
    private readonly api: AnalyticsApiService,
    private readonly nmdpWidget: NmdpWidget
  ) {}

  init(): void {
    if (this.initialized) {
      return;
    }

    this.nmdpWidget.onEvent
      .pipe(
        filter(({type}) => type === NEW_SESSION),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.setNewUserSession();
      });

    this.nmdpWidget.onEvent
      .pipe(
        filter(({type}) => type === SESSION_CLOSED),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.clearUserSession();
      });

    this.initialized = true;
  }

  log(
    items: AnalyticItemCommonModel[] | AnalyticItemCommonModel,
    metadata: Partial<AnalyticsCommonModel> = {}
  ): Promise<void> {
    if (!this.initialized) {
      throw new Error('Call init() method to initialize service properly.');
    }

    items = ensureArray(items);

    //The SEL does not always fire the event for NEW_SESSION on page refreshes,
    //so set the sessionGuid if it is undefined
    if (this.nmdpWidget.isLoggedIn && !this.sessionGuid) {
      this.setNewUserSession();
    }

    const logData = this.prepareData(items, metadata);

    return this.api.log(logData);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTimeStamp(): string {
    return new MsDate().toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.S'Z'");
  }

  private clearUserSession(): void {
    this.setCommonData({
      sessionGuid: null,
    });
  }

  private setNewUserSession(): void {
    this.setCommonData({
      sessionGuid: uniqId(''),
    });
  }

  private setCommonData(commonData: Partial<AnalyticsCommonModel>): void {
    this._commonData = commonData;
  }

  private prepareData(items: AnalyticItemCommonModel[], metadata: Partial<AnalyticsCommonModel>): AnalyticsModel {
    const ts = this.getTimeStamp();

    return {
      sessionGuid: this.sessionGuid,
      ...metadata,
      ...this.commonData,
      eventTimestamp: ts,
      items,
    };
  }
}
