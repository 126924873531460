/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AutoSaveDto } from '../models/auto-save-dto';
import { delete$ } from '../fn/auto-save-controller/delete';
import { Delete$Params } from '../fn/auto-save-controller/delete';
import { get1 } from '../fn/auto-save-controller/get-1';
import { Get1$Params } from '../fn/auto-save-controller/get-1';
import { updateOrCreateIfNotExist1 } from '../fn/auto-save-controller/update-or-create-if-not-exist-1';
import { UpdateOrCreateIfNotExist1$Params } from '../fn/auto-save-controller/update-or-create-if-not-exist-1';

@Injectable({ providedIn: 'root' })
export class AutoSaveControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateOrCreateIfNotExist1()` */
  static readonly UpdateOrCreateIfNotExist1Path = '/autosaves';

  /**
   * This method updates auto saved user form or create if not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrCreateIfNotExist1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist1$Response(params: UpdateOrCreateIfNotExist1$Params, context?: HttpContext): Observable<StrictHttpResponse<AutoSaveDto>> {
    return updateOrCreateIfNotExist1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates auto saved user form or create if not exist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrCreateIfNotExist1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist1(params: UpdateOrCreateIfNotExist1$Params, context?: HttpContext): Observable<AutoSaveDto> {
    return this.updateOrCreateIfNotExist1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutoSaveDto>): AutoSaveDto => r.body)
    );
  }

  /** Path part for operation `get1()` */
  static readonly Get1Path = '/autosaves/{typeCode}';

  /**
   * This method retrieves auto saved user form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get1()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1$Response(params: Get1$Params, context?: HttpContext): Observable<StrictHttpResponse<AutoSaveDto>> {
    return get1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves auto saved user form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1(params: Get1$Params, context?: HttpContext): Observable<AutoSaveDto> {
    return this.get1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutoSaveDto>): AutoSaveDto => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/autosaves/{typeCode}';

  /**
   * This method deletes auto saved user form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method deletes auto saved user form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
