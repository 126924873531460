/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addReferralPlan } from '../fn/recipient-referral-plan-controller/add-referral-plan';
import { AddReferralPlan$Params } from '../fn/recipient-referral-plan-controller/add-referral-plan';
import { deleteReferralPlan } from '../fn/recipient-referral-plan-controller/delete-referral-plan';
import { DeleteReferralPlan$Params } from '../fn/recipient-referral-plan-controller/delete-referral-plan';
import { getReferralPlan } from '../fn/recipient-referral-plan-controller/get-referral-plan';
import { GetReferralPlan$Params } from '../fn/recipient-referral-plan-controller/get-referral-plan';
import { getReferralPlansByRecipientGuid } from '../fn/recipient-referral-plan-controller/get-referral-plans-by-recipient-guid';
import { GetReferralPlansByRecipientGuid$Params } from '../fn/recipient-referral-plan-controller/get-referral-plans-by-recipient-guid';
import { ReferralPlan } from '../models/referral-plan';
import { updateReferralPlan } from '../fn/recipient-referral-plan-controller/update-referral-plan';
import { UpdateReferralPlan$Params } from '../fn/recipient-referral-plan-controller/update-referral-plan';

@Injectable({ providedIn: 'root' })
export class RecipientReferralPlanControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateReferralPlan()` */
  static readonly UpdateReferralPlanPath = '/recipients/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReferralPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferralPlan$Response(params: UpdateReferralPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateReferralPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReferralPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReferralPlan(params: UpdateReferralPlan$Params, context?: HttpContext): Observable<void> {
    return this.updateReferralPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getReferralPlansByRecipientGuid()` */
  static readonly GetReferralPlansByRecipientGuidPath = '/recipients/{guid}/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralPlansByRecipientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlansByRecipientGuid$Response(params: GetReferralPlansByRecipientGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReferralPlan>>> {
    return getReferralPlansByRecipientGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReferralPlansByRecipientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlansByRecipientGuid(params: GetReferralPlansByRecipientGuid$Params, context?: HttpContext): Observable<Array<ReferralPlan>> {
    return this.getReferralPlansByRecipientGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReferralPlan>>): Array<ReferralPlan> => r.body)
    );
  }

  /** Path part for operation `addReferralPlan()` */
  static readonly AddReferralPlanPath = '/recipients/{guid}/referral/plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addReferralPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReferralPlan$Response(params: AddReferralPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferralPlan>> {
    return addReferralPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addReferralPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addReferralPlan(params: AddReferralPlan$Params, context?: HttpContext): Observable<ReferralPlan> {
    return this.addReferralPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferralPlan>): ReferralPlan => r.body)
    );
  }

  /** Path part for operation `getReferralPlan()` */
  static readonly GetReferralPlanPath = '/recipients/referral/plans/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReferralPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlan$Response(params: GetReferralPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferralPlan>> {
    return getReferralPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReferralPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReferralPlan(params: GetReferralPlan$Params, context?: HttpContext): Observable<ReferralPlan> {
    return this.getReferralPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferralPlan>): ReferralPlan => r.body)
    );
  }

  /** Path part for operation `deleteReferralPlan()` */
  static readonly DeleteReferralPlanPath = '/recipients/referral/plans/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReferralPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReferralPlan$Response(params: DeleteReferralPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteReferralPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteReferralPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReferralPlan(params: DeleteReferralPlan$Params, context?: HttpContext): Observable<void> {
    return this.deleteReferralPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
