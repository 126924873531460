/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTypingFrequency } from '../fn/recipient-typing-frequency-controller/get-typing-frequency';
import { GetTypingFrequency$Params } from '../fn/recipient-typing-frequency-controller/get-typing-frequency';
import { isTypingFrequencyExist } from '../fn/recipient-typing-frequency-controller/is-typing-frequency-exist';
import { IsTypingFrequencyExist$Params } from '../fn/recipient-typing-frequency-controller/is-typing-frequency-exist';
import { TypingFrequenciesExistResponse } from '../models/typing-frequencies-exist-response';
import { TypingFrequency } from '../models/typing-frequency';

@Injectable({ providedIn: 'root' })
export class RecipientTypingFrequencyControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTypingFrequency()` */
  static readonly GetTypingFrequencyPath = '/recipients/{guid}/typing-frequency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTypingFrequency()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingFrequency$Response(params: GetTypingFrequency$Params, context?: HttpContext): Observable<StrictHttpResponse<TypingFrequency>> {
    return getTypingFrequency(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTypingFrequency$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTypingFrequency(params: GetTypingFrequency$Params, context?: HttpContext): Observable<TypingFrequency> {
    return this.getTypingFrequency$Response(params, context).pipe(
      map((r: StrictHttpResponse<TypingFrequency>): TypingFrequency => r.body)
    );
  }

  /** Path part for operation `isTypingFrequencyExist()` */
  static readonly IsTypingFrequencyExistPath = '/recipients/{guid}/typing-frequency/exist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isTypingFrequencyExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  isTypingFrequencyExist$Response(params: IsTypingFrequencyExist$Params, context?: HttpContext): Observable<StrictHttpResponse<TypingFrequenciesExistResponse>> {
    return isTypingFrequencyExist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isTypingFrequencyExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isTypingFrequencyExist(params: IsTypingFrequencyExist$Params, context?: HttpContext): Observable<TypingFrequenciesExistResponse> {
    return this.isTypingFrequencyExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<TypingFrequenciesExistResponse>): TypingFrequenciesExistResponse => r.body)
    );
  }

}
