/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OplExportConfig } from '../../models/opl-export-config';

export interface ExportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params {
      body: OplExportConfig
}

export function exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet(http: HttpClient, rootUrl: string, params: ExportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

exportOpl$VndOpenxmlformatsOfficedocumentSpreadsheetmlSheet.PATH = '/exports/opl';
