/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BpContact } from '../../models/bp-contact';

export interface FindBpContact$Params {
  customerAccountId: number;
  addressId: number;
  contactId: number;
}

export function findBpContact(http: HttpClient, rootUrl: string, params: FindBpContact$Params, context?: HttpContext): Observable<StrictHttpResponse<BpContact>> {
  const rb = new RequestBuilder(rootUrl, findBpContact.PATH, 'get');
  if (params) {
    rb.path('customerAccountId', params.customerAccountId, {});
    rb.path('addressId', params.addressId, {});
    rb.path('contactId', params.contactId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BpContact>;
    })
  );
}

findBpContact.PATH = '/business-parties/contacts/{customerAccountId}/{addressId}/{contactId}';
