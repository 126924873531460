<ms-dynamic-dialog
  customClass="b-leader-modal"
>
  <ng-template let-data>
    <ms-modal [caption]="'BLEADER.INFO.SIMPLE_HEADER' | transloco">
      <ng-template msModalBody>
        <ms-bleader-detailed-info [data]="data"></ms-bleader-detailed-info>
      </ng-template>
    </ms-modal>
  </ng-template>
</ms-dynamic-dialog>
