/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkIfFormDueExists } from '../fn/form-due-controller/check-if-form-due-exists';
import { CheckIfFormDueExists$Params } from '../fn/form-due-controller/check-if-form-due-exists';
import { createFormDue } from '../fn/form-due-controller/create-form-due';
import { CreateFormDue$Params } from '../fn/form-due-controller/create-form-due';
import { FormDue } from '../models/form-due';
import { FormDueExistResponse } from '../models/form-due-exist-response';
import { loadFormDue } from '../fn/form-due-controller/load-form-due';
import { LoadFormDue$Params } from '../fn/form-due-controller/load-form-due';
import { loadFormsDueByDonorId } from '../fn/form-due-controller/load-forms-due-by-donor-id';
import { LoadFormsDueByDonorId$Params } from '../fn/form-due-controller/load-forms-due-by-donor-id';
import { PageFormDue } from '../models/page-form-due';
import { requestResolution } from '../fn/form-due-controller/request-resolution';
import { RequestResolution$Params } from '../fn/form-due-controller/request-resolution';
import { updateFormDue } from '../fn/form-due-controller/update-form-due';
import { UpdateFormDue$Params } from '../fn/form-due-controller/update-form-due';

@Injectable({ providedIn: 'root' })
export class FormDueControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `loadFormDue()` */
  static readonly LoadFormDuePath = '/form-due';

  /**
   * This method loads all Forms 22 Due for provided criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadFormDue()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadFormDue$Response(params: LoadFormDue$Params, context?: HttpContext): Observable<StrictHttpResponse<PageFormDue>> {
    return loadFormDue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads all Forms 22 Due for provided criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadFormDue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadFormDue(params: LoadFormDue$Params, context?: HttpContext): Observable<PageFormDue> {
    return this.loadFormDue$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFormDue>): PageFormDue => r.body)
    );
  }

  /** Path part for operation `updateFormDue()` */
  static readonly UpdateFormDuePath = '/form-due';

  /**
   * This method updates Form 22 Due.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormDue$Response(params: UpdateFormDue$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateFormDue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method updates Form 22 Due.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormDue(params: UpdateFormDue$Params, context?: HttpContext): Observable<void> {
    return this.updateFormDue$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createFormDue()` */
  static readonly CreateFormDuePath = '/form-due';

  /**
   * This method creates Form 22 Due.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFormDue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormDue$Response(params: CreateFormDue$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createFormDue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method creates Form 22 Due.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFormDue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormDue(params: CreateFormDue$Params, context?: HttpContext): Observable<void> {
    return this.createFormDue$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loadFormsDueByDonorId()` */
  static readonly LoadFormsDueByDonorIdPath = '/form-due/search';

  /**
   * This method loads forms filtered by given donor's ID (e.g. GRID)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadFormsDueByDonorId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadFormsDueByDonorId$Response(params: LoadFormsDueByDonorId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FormDue>>> {
    return loadFormsDueByDonorId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method loads forms filtered by given donor's ID (e.g. GRID)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loadFormsDueByDonorId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadFormsDueByDonorId(params: LoadFormsDueByDonorId$Params, context?: HttpContext): Observable<Array<FormDue>> {
    return this.loadFormsDueByDonorId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FormDue>>): Array<FormDue> => r.body)
    );
  }

  /** Path part for operation `requestResolution()` */
  static readonly RequestResolutionPath = '/form-due/resolution/request';

  /**
   * Request a resolution for a row in the Form 22 Due
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestResolution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestResolution$Response(params?: RequestResolution$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestResolution(this.http, this.rootUrl, params, context);
  }

  /**
   * Request a resolution for a row in the Form 22 Due
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestResolution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestResolution(params?: RequestResolution$Params, context?: HttpContext): Observable<void> {
    return this.requestResolution$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkIfFormDueExists()` */
  static readonly CheckIfFormDueExistsPath = '/form-due/exist';

  /**
   * Checks if open Form Due records exists for provided criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfFormDueExists()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfFormDueExists$Response(params: CheckIfFormDueExists$Params, context?: HttpContext): Observable<StrictHttpResponse<FormDueExistResponse>> {
    return checkIfFormDueExists(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks if open Form Due records exists for provided criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfFormDueExists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfFormDueExists(params: CheckIfFormDueExists$Params, context?: HttpContext): Observable<FormDueExistResponse> {
    return this.checkIfFormDueExists$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormDueExistResponse>): FormDueExistResponse => r.body)
    );
  }

}
