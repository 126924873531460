/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CoopCord } from '../models/coop-cord';
import { createCoopCord } from '../fn/coop-cord-controller/create-coop-cord';
import { CreateCoopCord$Params } from '../fn/coop-cord-controller/create-coop-cord';
import { CreateCoopSourceResult } from '../models/create-coop-source-result';
import { getCoopCord } from '../fn/coop-cord-controller/get-coop-cord';
import { GetCoopCord$Params } from '../fn/coop-cord-controller/get-coop-cord';
import { getCoopCords } from '../fn/coop-cord-controller/get-coop-cords';
import { GetCoopCords$Params } from '../fn/coop-cord-controller/get-coop-cords';
import { publishAddMatchedCord } from '../fn/coop-cord-controller/publish-add-matched-cord';
import { PublishAddMatchedCord$Params } from '../fn/coop-cord-controller/publish-add-matched-cord';
import { updateCoopCord } from '../fn/coop-cord-controller/update-coop-cord';
import { UpdateCoopCord$Params } from '../fn/coop-cord-controller/update-coop-cord';
import { UpdateCoopSourceResult } from '../models/update-coop-source-result';

@Injectable({ providedIn: 'root' })
export class CoopCordControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCoopCord()` */
  static readonly GetCoopCordPath = '/cords/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopCord()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCord$Response(params: GetCoopCord$Params, context?: HttpContext): Observable<StrictHttpResponse<CoopCord>> {
    return getCoopCord(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoopCord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCord(params: GetCoopCord$Params, context?: HttpContext): Observable<CoopCord> {
    return this.getCoopCord$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoopCord>): CoopCord => r.body)
    );
  }

  /** Path part for operation `updateCoopCord()` */
  static readonly UpdateCoopCordPath = '/cords/coop/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoopCord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCoopCord$Response(params: UpdateCoopCord$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateCoopSourceResult>> {
    return updateCoopCord(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCoopCord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCoopCord(params: UpdateCoopCord$Params, context?: HttpContext): Observable<UpdateCoopSourceResult> {
    return this.updateCoopCord$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateCoopSourceResult>): UpdateCoopSourceResult => r.body)
    );
  }

  /** Path part for operation `getCoopCords()` */
  static readonly GetCoopCordsPath = '/cords/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCoopCords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCords$Response(params: GetCoopCords$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoopCord>>> {
    return getCoopCords(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCoopCords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCoopCords(params: GetCoopCords$Params, context?: HttpContext): Observable<Array<CoopCord>> {
    return this.getCoopCords$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoopCord>>): Array<CoopCord> => r.body)
    );
  }

  /** Path part for operation `createCoopCord()` */
  static readonly CreateCoopCordPath = '/cords/coop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCoopCord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCoopCord$Response(params: CreateCoopCord$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateCoopSourceResult>> {
    return createCoopCord(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCoopCord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCoopCord(params: CreateCoopCord$Params, context?: HttpContext): Observable<CreateCoopSourceResult> {
    return this.createCoopCord$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateCoopSourceResult>): CreateCoopSourceResult => r.body)
    );
  }

  /** Path part for operation `publishAddMatchedCord()` */
  static readonly PublishAddMatchedCordPath = '/cords/coop/publish/matched/{cordGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishAddMatchedCord()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedCord$Response(params: PublishAddMatchedCord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishAddMatchedCord(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishAddMatchedCord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAddMatchedCord(params: PublishAddMatchedCord$Params, context?: HttpContext): Observable<void> {
    return this.publishAddMatchedCord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
