/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BLeader } from '../models/b-leader';
import { DetailedBLeader } from '../models/detailed-b-leader';
import { DetailedDpb1Expression } from '../models/detailed-dpb-1-expression';
import { getBLeaderInfo } from '../fn/hla-typing-controller/get-b-leader-info';
import { GetBLeaderInfo$Params } from '../fn/hla-typing-controller/get-b-leader-info';
import { getBLeaders } from '../fn/hla-typing-controller/get-b-leaders';
import { GetBLeaders$Params } from '../fn/hla-typing-controller/get-b-leaders';
import { getDetailedBLeaderInfo } from '../fn/hla-typing-controller/get-detailed-b-leader-info';
import { GetDetailedBLeaderInfo$Params } from '../fn/hla-typing-controller/get-detailed-b-leader-info';
import { getDetailedDpb1Expression } from '../fn/hla-typing-controller/get-detailed-dpb-1-expression';
import { GetDetailedDpb1Expression$Params } from '../fn/hla-typing-controller/get-detailed-dpb-1-expression';
import { getPatientLigandType } from '../fn/hla-typing-controller/get-patient-ligand-type';
import { GetPatientLigandType$Params } from '../fn/hla-typing-controller/get-patient-ligand-type';
import { Ligand } from '../models/ligand';
import { ValidatedTyping } from '../models/validated-typing';
import { validateHla } from '../fn/hla-typing-controller/validate-hla';
import { ValidateHla$Params } from '../fn/hla-typing-controller/validate-hla';

@Injectable({ providedIn: 'root' })
export class HlaTypingControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `validateHla()` */
  static readonly ValidateHlaPath = '/typings/validity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateHla()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateHla$Response(params: ValidateHla$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ValidatedTyping>>> {
    return validateHla(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateHla$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateHla(params: ValidateHla$Params, context?: HttpContext): Observable<Array<ValidatedTyping>> {
    return this.validateHla$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ValidatedTyping>>): Array<ValidatedTyping> => r.body)
    );
  }

  /** Path part for operation `getPatientLigandType()` */
  static readonly GetPatientLigandTypePath = '/typings/ligand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPatientLigandType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPatientLigandType$Response(params: GetPatientLigandType$Params, context?: HttpContext): Observable<StrictHttpResponse<Ligand>> {
    return getPatientLigandType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPatientLigandType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPatientLigandType(params: GetPatientLigandType$Params, context?: HttpContext): Observable<Ligand> {
    return this.getPatientLigandType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Ligand>): Ligand => r.body)
    );
  }

  /** Path part for operation `getBLeaderInfo()` */
  static readonly GetBLeaderInfoPath = '/typings/bleader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBLeaderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBLeaderInfo$Response(params: GetBLeaderInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<BLeader>> {
    return getBLeaderInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBLeaderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBLeaderInfo(params: GetBLeaderInfo$Params, context?: HttpContext): Observable<BLeader> {
    return this.getBLeaderInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<BLeader>): BLeader => r.body)
    );
  }

  /** Path part for operation `getBLeaders()` */
  static readonly GetBLeadersPath = '/typings/bleader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBLeaders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBLeaders$Response(params: GetBLeaders$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: BLeader;
}>> {
    return getBLeaders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBLeaders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBLeaders(params: GetBLeaders$Params, context?: HttpContext): Observable<{
[key: string]: BLeader;
}> {
    return this.getBLeaders$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: BLeader;
}>): {
[key: string]: BLeader;
} => r.body)
    );
  }

  /** Path part for operation `getDetailedDpb1Expression()` */
  static readonly GetDetailedDpb1ExpressionPath = '/typings/dpb1-expression/detailed-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedDpb1Expression()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedDpb1Expression$Response(params: GetDetailedDpb1Expression$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedDpb1Expression>> {
    return getDetailedDpb1Expression(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetailedDpb1Expression$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedDpb1Expression(params: GetDetailedDpb1Expression$Params, context?: HttpContext): Observable<DetailedDpb1Expression> {
    return this.getDetailedDpb1Expression$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedDpb1Expression>): DetailedDpb1Expression => r.body)
    );
  }

  /** Path part for operation `getDetailedBLeaderInfo()` */
  static readonly GetDetailedBLeaderInfoPath = '/typings/bleader/detailed-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedBLeaderInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedBLeaderInfo$Response(params: GetDetailedBLeaderInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedBLeader>> {
    return getDetailedBLeaderInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetailedBLeaderInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedBLeaderInfo(params: GetDetailedBLeaderInfo$Params, context?: HttpContext): Observable<DetailedBLeader> {
    return this.getDetailedBLeaderInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedBLeader>): DetailedBLeader => r.body)
    );
  }

}
