import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {ClearPatients, LoadPatients} from './searches-patients.action';
import {PatientApiService} from '@matchsource/api/patient';
import {catchError, map, tap} from 'rxjs/operators';
import {PatientLookupDataSetModel, PatientLookupModel, formatPatientId} from '@matchsource/models/patient';
import {patch} from '@ngxs/store/operators';
import {isEmptyObject, toMap} from '@matchsource/utils';
import {Patient} from '@matchsource/models/search-status';

import {of} from 'rxjs';

type PatientSearchData = Pick<PatientLookupDataSetModel, 'data'>;

interface EntityMetadata {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export interface SearchesStatusPatientsStateModel {
  entities: MsApp.Dictionary<Patient>;
  metadata: MsApp.Dictionary<EntityMetadata>;
}

const getDefaultState = (): SearchesStatusPatientsStateModel => ({
  entities: {},
  metadata: {},
});

const getDefaultEntityMetadata = (override: Partial<EntityMetadata> = {}): EntityMetadata => ({
  loading: false,
  loaded: false,
  error: null,
  ...override,
});

@State<SearchesStatusPatientsStateModel>({
  name: 'searchesStatusPatients',
  defaults: getDefaultState(),
})
@Injectable()
export class SearchesStatusPatientsState {
  @Selector([SearchesStatusPatientsState])
  static entities({entities}: SearchesStatusPatientsStateModel) {
    return entities;
  }

  @Selector([SearchesStatusPatientsState.entities])
  static patients(entities: MsApp.Dictionary<Patient>): MsApp.Dictionary<Patient> {
    return entities;
  }

  @Selector([SearchesStatusPatientsState])
  static metadata({metadata}: SearchesStatusPatientsStateModel) {
    return metadata;
  }

  static patientMetadata(patientId: MsApp.Guid) {
    return createSelector([this.metadata], (metadata: MsApp.Dictionary<EntityMetadata>) =>
      patientId in metadata ? metadata[patientId] : null
    );
  }

  static patientLoadedOrInProgress(patientId: MsApp.Guid) {
    return createSelector(
      [this.patientMetadata(patientId)],
      (entityMetadata: EntityMetadata) => entityMetadata && (entityMetadata.loaded || entityMetadata.loading)
    );
  }

  constructor(
    private readonly patientApi: PatientApiService,
    private readonly store: Store
  ) {}

  @Action(LoadPatients)
  loadPatients(ctx: StateContext<SearchesStatusPatientsStateModel>, {patientIds, tcId}: LoadPatients) {
    patientIds = patientIds.filter(
      patientId => !this.store.selectSnapshot(SearchesStatusPatientsState.patientLoadedOrInProgress(patientId))
    );

    if (patientIds.length === 0) {
      return;
    }

    ctx.setState(
      patch({
        metadata: patch(
          patientIds.reduce((acc, patientId) => {
            acc[patientId] = getDefaultEntityMetadata({loading: true});

            return acc;
          }, {})
        ),
      })
    );

    return this.patientApi
      .search({
        guids: patientIds,
        ignoreCoreFailed: false,
        tcId,
      })
      .pipe(
        catchError(() => of({data: []})),
        map(({data: patients}: PatientSearchData) => patients),
        tap((patients: PatientLookupModel[]) => {
          const {entities: currentPatients} = ctx.getState();

          let newPatients: MsApp.Dictionary<Patient> = toMap(
            patients.map(patient => ({
              ...patient,
              patientIdFormatted: formatPatientId(patient.rid),
              removed: false,
            })),
            'id'
          );

          const removedPatients = Object.entries(currentPatients)
            .filter(([patientId]) => !(patientId in newPatients))
            .reduce<MsApp.Dictionary<Patient>>((acc, [patientId, patient]) => {
              acc[patientId] = {
                ...patient,
                removed: true,
              };

              return acc;
            }, {});

          if (!isEmptyObject(removedPatients)) {
            newPatients = {
              ...newPatients,
              ...removedPatients,
            };
          }

          ctx.setState(
            patch({
              entities: patch(newPatients),
              metadata: patch(
                patients.reduce((acc, {id}) => {
                  acc[id] = patch<EntityMetadata>({
                    loading: false,
                    loaded: true,
                  });

                  return acc;
                }, {})
              ),
            })
          );
        })
      );
  }

  @Action(ClearPatients)
  clear(ctx: StateContext<SearchesStatusPatientsStateModel>) {
    ctx.setState(getDefaultState());
  }
}
