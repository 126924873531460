import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BLeaderCode, BLeaderTyping} from '@matchsource/api/typings';
import {DetailedBLeader} from '@matchsource/api-generated/subject';
import {TranslocoPipe} from '@jsverse/transloco';
import {BleaderDetailedInfoComponent} from '@matchsource/shared/components';
import {DynamicDialogComponent} from '@matchsource/nmdp-ui';
import {ModalBodyDirective, ModalComponent} from '@matchsource/shared/modal';

export interface BleaderDetailedInfoModalData {
  info: DetailedBLeader;
  typing: BLeaderTyping;
  leader: BLeaderCode;
}

@Component({
  selector: 'ms-bleader-detailed-info-modal',
  templateUrl: './bleader-detailed-info-modal.component.html',
  styleUrls: ['./bleader-detailed-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicDialogComponent, ModalComponent, ModalBodyDirective, BleaderDetailedInfoComponent, TranslocoPipe],
})
export class BleaderDetailedInfoModalComponent {}
