import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  DuplicatePatientHistoryModel,
  PotentialDuplicateInfoModel,
  PotentialDuplicateModel,
} from '@matchsource/models/potential-duplicates';
import {DuplicatePatientControllerService} from '@matchsource/api-generated/subject';
import {duplicatesSerializer} from './potential-duplicates.serializer';
import {duplicateInfoSerializer} from './potential-duplicate-info.serializer';
import {duplicateHistorySerializer} from './potential-duplicate-history.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class PotentialDuplicatesApiService {
  constructor(private readonly duplicatePatientControllerService: DuplicatePatientControllerService) {}

  getDuplicates(): Observable<PotentialDuplicateModel[]> {
    return this.duplicatePatientControllerService
      .getAllUnresolved()
      .pipe(map(data => data.map(input => duplicatesSerializer.fromDTO(input))));
  }

  getDuplicateInfo(patientId: MsApp.Guid): Observable<PotentialDuplicateInfoModel> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingPatientData);

    return this.duplicatePatientControllerService
      .getDuplicateInfo({guid: patientId}, context())
      .pipe(map(data => duplicateInfoSerializer.fromDTO(data)));
  }

  getHistory(patientId: MsApp.Guid): Observable<DuplicatePatientHistoryModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDuplicatePatientHistoryData);

    return this.duplicatePatientControllerService
      .getDuplicateHistory({guid: patientId}, context())
      .pipe(
        map(historyEntries => historyEntries.map(historyEntry => duplicateHistorySerializer.fromDTO(historyEntry)))
      );
  }
}
