/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BpAddress } from '../models/bp-address';
import { BpContact } from '../models/bp-contact';
import { BusinessParty } from '../models/business-party';
import { BusinessPartyExtended } from '../models/business-party-extended';
import { byBpGuid } from '../fn/business-party-controller/by-bp-guid';
import { ByBpGuid$Params } from '../fn/business-party-controller/by-bp-guid';
import { byBpGuidExtended } from '../fn/business-party-controller/by-bp-guid-extended';
import { ByBpGuidExtended$Params } from '../fn/business-party-controller/by-bp-guid-extended';
import { byBpGuids } from '../fn/business-party-controller/by-bp-guids';
import { ByBpGuids$Params } from '../fn/business-party-controller/by-bp-guids';
import { byBpType } from '../fn/business-party-controller/by-bp-type';
import { ByBpType$Params } from '../fn/business-party-controller/by-bp-type';
import { findBpContact } from '../fn/business-party-controller/find-bp-contact';
import { FindBpContact$Params } from '../fn/business-party-controller/find-bp-contact';
import { findBpContacts } from '../fn/business-party-controller/find-bp-contacts';
import { FindBpContacts$Params } from '../fn/business-party-controller/find-bp-contacts';
import { findBusinessPartyOfUser } from '../fn/business-party-controller/find-business-party-of-user';
import { FindBusinessPartyOfUser$Params } from '../fn/business-party-controller/find-business-party-of-user';
import { findCpBpAddress } from '../fn/business-party-controller/find-cp-bp-address';
import { FindCpBpAddress$Params } from '../fn/business-party-controller/find-cp-bp-address';
import { findCpBpAddressesByGuid } from '../fn/business-party-controller/find-cp-bp-addresses-by-guid';
import { FindCpBpAddressesByGuid$Params } from '../fn/business-party-controller/find-cp-bp-addresses-by-guid';

@Injectable({ providedIn: 'root' })
export class BusinessPartyControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `byBpType()` */
  static readonly ByBpTypePath = '/business-parties';

  /**
   * This method retrieves a list of business parties by their types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpType()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpType$Response(params?: ByBpType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessParty>>> {
    return byBpType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a list of business parties by their types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `byBpType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpType(params?: ByBpType$Params, context?: HttpContext): Observable<Array<BusinessParty>> {
    return this.byBpType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>): Array<BusinessParty> => r.body)
    );
  }

  /** Path part for operation `byBpGuids()` */
  static readonly ByBpGuidsPath = '/business-parties';

  /**
   * This method retrieves a list of business parties by their IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  byBpGuids$Response(params: ByBpGuids$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessParty>>> {
    return byBpGuids(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a list of business parties by their IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `byBpGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  byBpGuids(params: ByBpGuids$Params, context?: HttpContext): Observable<Array<BusinessParty>> {
    return this.byBpGuids$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>): Array<BusinessParty> => r.body)
    );
  }

  /** Path part for operation `byBpGuid()` */
  static readonly ByBpGuidPath = '/business-parties/{bpGuid}';

  /**
   * This method retrieves a business party by its ID and search method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuid$Response(params: ByBpGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessParty>> {
    return byBpGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a business party by its ID and search method
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `byBpGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuid(params: ByBpGuid$Params, context?: HttpContext): Observable<BusinessParty> {
    return this.byBpGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessParty>): BusinessParty => r.body)
    );
  }

  /** Path part for operation `byBpGuidExtended()` */
  static readonly ByBpGuidExtendedPath = '/business-parties/{bpGuid}/extended';

  /**
   * This method retrieves an extended business party by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuidExtended()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuidExtended$Response(params: ByBpGuidExtended$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessPartyExtended>> {
    return byBpGuidExtended(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves an extended business party by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `byBpGuidExtended$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuidExtended(params: ByBpGuidExtended$Params, context?: HttpContext): Observable<BusinessPartyExtended> {
    return this.byBpGuidExtended$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessPartyExtended>): BusinessPartyExtended => r.body)
    );
  }

  /** Path part for operation `findBusinessPartyOfUser()` */
  static readonly FindBusinessPartyOfUserPath = '/business-parties/of-user';

  /**
   * This method retrieves a list of business parties for a given user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBusinessPartyOfUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBusinessPartyOfUser$Response(params?: FindBusinessPartyOfUser$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessParty>>> {
    return findBusinessPartyOfUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a list of business parties for a given user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findBusinessPartyOfUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBusinessPartyOfUser(params?: FindBusinessPartyOfUser$Params, context?: HttpContext): Observable<Array<BusinessParty>> {
    return this.findBusinessPartyOfUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>): Array<BusinessParty> => r.body)
    );
  }

  /** Path part for operation `findCpBpAddress()` */
  static readonly FindCpBpAddressPath = '/business-parties/cp/addresses/{customerAccountId}/{addressId}';

  /**
   * This method retrieves a business party address by its customer account ID and address ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCpBpAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddress$Response(params: FindCpBpAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<BpAddress>> {
    return findCpBpAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a business party address by its customer account ID and address ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findCpBpAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddress(params: FindCpBpAddress$Params, context?: HttpContext): Observable<BpAddress> {
    return this.findCpBpAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<BpAddress>): BpAddress => r.body)
    );
  }

  /** Path part for operation `findCpBpAddressesByGuid()` */
  static readonly FindCpBpAddressesByGuidPath = '/business-parties/cp/addresses/{bpGuid}';

  /**
   * This method retrieves a list of business party addresses by its guid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCpBpAddressesByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddressesByGuid$Response(params: FindCpBpAddressesByGuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BpAddress>>> {
    return findCpBpAddressesByGuid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a list of business party addresses by its guid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findCpBpAddressesByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddressesByGuid(params: FindCpBpAddressesByGuid$Params, context?: HttpContext): Observable<Array<BpAddress>> {
    return this.findCpBpAddressesByGuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BpAddress>>): Array<BpAddress> => r.body)
    );
  }

  /** Path part for operation `findBpContacts()` */
  static readonly FindBpContactsPath = '/business-parties/contacts/{customerAccountId}/{addressId}';

  /**
   * This method retrieves a list of business party address contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBpContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContacts$Response(params: FindBpContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BpContact>>> {
    return findBpContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves a list of business party address contacts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findBpContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContacts(params: FindBpContacts$Params, context?: HttpContext): Observable<Array<BpContact>> {
    return this.findBpContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BpContact>>): Array<BpContact> => r.body)
    );
  }

  /** Path part for operation `findBpContact()` */
  static readonly FindBpContactPath = '/business-parties/contacts/{customerAccountId}/{addressId}/{contactId}';

  /**
   * This method retrieves business party address contact by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBpContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContact$Response(params: FindBpContact$Params, context?: HttpContext): Observable<StrictHttpResponse<BpContact>> {
    return findBpContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves business party address contact by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findBpContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContact(params: FindBpContact$Params, context?: HttpContext): Observable<BpContact> {
    return this.findBpContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<BpContact>): BpContact => r.body)
    );
  }

}
