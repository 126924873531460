import {ApplicationRef, Component, ContentChild, TemplateRef} from '@angular/core';
import {ToastNoAnimation, ToastPackage, ToastrService} from 'ngx-toastr';
import {NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ms-toast-wrapper]',
  templateUrl: './toast-wrapper.component.html',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
})
export class ToastWrapperComponent extends ToastNoAnimation {
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  actions = {
    remove: () => {
      this.remove();
    },
  };

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    protected override appRef: ApplicationRef
  ) {
    super(toastrService, toastPackage, appRef);
  }
}
