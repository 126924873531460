/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelRequest } from '../fn/ssa-request-controller/cancel-request';
import { CancelRequest$Params } from '../fn/ssa-request-controller/cancel-request';
import { createRequest } from '../fn/ssa-request-controller/create-request';
import { CreateRequest$Params } from '../fn/ssa-request-controller/create-request';
import { getRequest } from '../fn/ssa-request-controller/get-request';
import { GetRequest$Params } from '../fn/ssa-request-controller/get-request';
import { getRequestsForPatient } from '../fn/ssa-request-controller/get-requests-for-patient';
import { GetRequestsForPatient$Params } from '../fn/ssa-request-controller/get-requests-for-patient';
import { SsaRequestDto } from '../models/ssa-request-dto';
import { updateRequest } from '../fn/ssa-request-controller/update-request';
import { UpdateRequest$Params } from '../fn/ssa-request-controller/update-request';

@Injectable({ providedIn: 'root' })
export class SsaRequestControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRequestsForPatient()` */
  static readonly GetRequestsForPatientPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestsForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestsForPatient$Response(params: GetRequestsForPatient$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SsaRequestDto>>> {
    return getRequestsForPatient(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequestsForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestsForPatient(params: GetRequestsForPatient$Params, context?: HttpContext): Observable<Array<SsaRequestDto>> {
    return this.getRequestsForPatient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SsaRequestDto>>): Array<SsaRequestDto> => r.body)
    );
  }

  /** Path part for operation `updateRequest()` */
  static readonly UpdateRequestPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRequest$Response(params: UpdateRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRequest(params: UpdateRequest$Params, context?: HttpContext): Observable<void> {
    return this.updateRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createRequest()` */
  static readonly CreateRequestPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest$Response(params: CreateRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest(params: CreateRequest$Params, context?: HttpContext): Observable<void> {
    return this.createRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getRequest()` */
  static readonly GetRequestPath = '/ssa-requests/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequest$Response(params: GetRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<SsaRequestDto>> {
    return getRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequest(params: GetRequest$Params, context?: HttpContext): Observable<SsaRequestDto> {
    return this.getRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<SsaRequestDto>): SsaRequestDto => r.body)
    );
  }

  /** Path part for operation `cancelRequest()` */
  static readonly CancelRequestPath = '/ssa-requests/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelRequest$Response(params: CancelRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelRequest(params: CancelRequest$Params, context?: HttpContext): Observable<void> {
    return this.cancelRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
