/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface DownloadByOwcId$Params {
  owcId: string;
  rcpGuid: string;
  docOwnerGuid: string;
}

export function downloadByOwcId(http: HttpClient, rootUrl: string, params: DownloadByOwcId$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, downloadByOwcId.PATH, 'get');
  if (params) {
    rb.path('owcId', params.owcId, {});
    rb.path('rcpGuid', params.rcpGuid, {});
    rb.query('docOwnerGuid', params.docOwnerGuid, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

downloadByOwcId.PATH = '/documents/download/{owcId}/recipients/{rcpGuid}';
