/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DuplicatePatientLookupRequest } from '../../models/duplicate-patient-lookup-request';
import { DuplicatesLookupResponse } from '../../models/duplicates-lookup-response';

export interface DuplicatePatientLookup$Params {
      body: DuplicatePatientLookupRequest
}

export function duplicatePatientLookup(http: HttpClient, rootUrl: string, params: DuplicatePatientLookup$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicatesLookupResponse>> {
  const rb = new RequestBuilder(rootUrl, duplicatePatientLookup.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DuplicatesLookupResponse>;
    })
  );
}

duplicatePatientLookup.PATH = '/lookup/duplicate-patient';
