/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllCustomersProgram } from '../fn/program-controller/get-all-customers-program';
import { GetAllCustomersProgram$Params } from '../fn/program-controller/get-all-customers-program';
import { getAllSuppliersProgram } from '../fn/program-controller/get-all-suppliers-program';
import { GetAllSuppliersProgram$Params } from '../fn/program-controller/get-all-suppliers-program';
import { getCustomerProgram } from '../fn/program-controller/get-customer-program';
import { GetCustomerProgram$Params } from '../fn/program-controller/get-customer-program';
import { getSupplierProgram } from '../fn/program-controller/get-supplier-program';
import { GetSupplierProgram$Params } from '../fn/program-controller/get-supplier-program';
import { ProgramResponse } from '../models/program-response';

@Injectable({ providedIn: 'root' })
export class ProgramControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSupplierProgram()` */
  static readonly GetSupplierProgramPath = '/ebs-programs/supplier';

  /**
   * This method retrieves the info for CBB (Cord Blood Bank) if it participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplierProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierProgram$Response(params: GetSupplierProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProgramResponse>>> {
    return getSupplierProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves the info for CBB (Cord Blood Bank) if it participates in a given program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupplierProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierProgram(params: GetSupplierProgram$Params, context?: HttpContext): Observable<Array<ProgramResponse>> {
    return this.getSupplierProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>): Array<ProgramResponse> => r.body)
    );
  }

  /** Path part for operation `getCustomerProgram()` */
  static readonly GetCustomerProgramPath = '/ebs-programs/customer';

  /**
   * This method retrieves the info for TC if it participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProgram$Response(params: GetCustomerProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProgramResponse>>> {
    return getCustomerProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves the info for TC if it participates in a given program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProgram(params: GetCustomerProgram$Params, context?: HttpContext): Observable<Array<ProgramResponse>> {
    return this.getCustomerProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>): Array<ProgramResponse> => r.body)
    );
  }

  /** Path part for operation `getAllSuppliersProgram()` */
  static readonly GetAllSuppliersProgramPath = '/ebs-programs/all-suppliers';

  /**
   * This method retrieves all suppliers CBB's(Cord Blood Bank) for a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSuppliersProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSuppliersProgram$Response(params: GetAllSuppliersProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProgramResponse>>> {
    return getAllSuppliersProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all suppliers CBB's(Cord Blood Bank) for a given program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSuppliersProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSuppliersProgram(params: GetAllSuppliersProgram$Params, context?: HttpContext): Observable<Array<ProgramResponse>> {
    return this.getAllSuppliersProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>): Array<ProgramResponse> => r.body)
    );
  }

  /** Path part for operation `getAllCustomersProgram()` */
  static readonly GetAllCustomersProgramPath = '/ebs-programs/all-customers';

  /**
   * This method retrieves all TC's participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomersProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomersProgram$Response(params: GetAllCustomersProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProgramResponse>>> {
    return getAllCustomersProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method retrieves all TC's participates in a given program
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCustomersProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomersProgram(params: GetAllCustomersProgram$Params, context?: HttpContext): Observable<Array<ProgramResponse>> {
    return this.getAllCustomersProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>): Array<ProgramResponse> => r.body)
    );
  }

}
