<div class="simple-component-wrapper">
  <div class="modal-header">
    <ng-container *ngIf="modalHeaderTpl else defaultHeaderTpl">
      <ng-container *ngTemplateOutlet="modalHeaderTpl.template"></ng-container>
    </ng-container>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="modalBodyTpl?.template"></ng-container>
  </div>
  <div class="modal-footer">
    <ng-container *ngTemplateOutlet="modalFooterTpl?.template"></ng-container>
  </div>
</div>
<ng-template #defaultHeaderTpl>
  <h4 class="modal-title">{{ caption }}</h4>
</ng-template>
