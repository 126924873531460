/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BundleSearch } from '../../models/bundle-search';

export interface RetrieveSearches$Params {
  status: Array<'RUNNING' | 'COMPLETED' | 'FAILED' | 'HALTED' | 'PENDING' | 'INQUEUE' | 'INPROGRESS' | 'DEFERRED'>;
  viewedInd?: boolean;
  recipientGuid?: string;
}

export function retrieveSearches(http: HttpClient, rootUrl: string, params: RetrieveSearches$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BundleSearch>>> {
  const rb = new RequestBuilder(rootUrl, retrieveSearches.PATH, 'get');
  if (params) {
    rb.query('status', params.status, {});
    rb.query('viewedInd', params.viewedInd, {});
    rb.query('recipientGuid', params.recipientGuid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BundleSearch>>;
    })
  );
}

retrieveSearches.PATH = '/searches/parameterized';
