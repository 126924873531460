/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllReportsForRecipient } from '../fn/search-summary-report-controller/get-all-reports-for-recipient';
import { GetAllReportsForRecipient$Params } from '../fn/search-summary-report-controller/get-all-reports-for-recipient';
import { getCounts } from '../fn/search-summary-report-controller/get-counts';
import { GetCounts$Params } from '../fn/search-summary-report-controller/get-counts';
import { getReport } from '../fn/search-summary-report-controller/get-report';
import { GetReport$Params } from '../fn/search-summary-report-controller/get-report';
import { getReportsForRecipients } from '../fn/search-summary-report-controller/get-reports-for-recipients';
import { GetReportsForRecipients$Params } from '../fn/search-summary-report-controller/get-reports-for-recipients';
import { requestReport } from '../fn/search-summary-report-controller/request-report';
import { RequestReport$Params } from '../fn/search-summary-report-controller/request-report';
import { SearchSummaryCounts } from '../models/search-summary-counts';
import { SearchSummaryReport } from '../models/search-summary-report';
import { update } from '../fn/search-summary-report-controller/update';
import { Update$Params } from '../fn/search-summary-report-controller/update';

@Injectable({ providedIn: 'root' })
export class SearchSummaryReportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getReport()` */
  static readonly GetReportPath = '/search/summary/report';

  /**
   * Get HLA search summary report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReport$Response(params: GetReport$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchSummaryReport>> {
    return getReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Get HLA search summary report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReport(params: GetReport$Params, context?: HttpContext): Observable<SearchSummaryReport> {
    return this.getReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchSummaryReport>): SearchSummaryReport => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/search/summary/report';

  /**
   * Update HLA search summary report (with an option to publish it)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update HLA search summary report (with an option to publish it)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `requestReport()` */
  static readonly RequestReportPath = '/search/summary/report';

  /**
   * Request HLA search summary report
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestReport$Response(params: RequestReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Request HLA search summary report
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestReport(params: RequestReport$Params, context?: HttpContext): Observable<void> {
    return this.requestReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getReportsForRecipients()` */
  static readonly GetReportsForRecipientsPath = '/search/summary/report/by-guids';

  /**
   * Get HLA search summary reports for several patients
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportsForRecipients()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportsForRecipients$Response(params: GetReportsForRecipients$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: SearchSummaryReport;
}>> {
    return getReportsForRecipients(this.http, this.rootUrl, params, context);
  }

  /**
   * Get HLA search summary reports for several patients
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportsForRecipients$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getReportsForRecipients(params: GetReportsForRecipients$Params, context?: HttpContext): Observable<{
[key: string]: SearchSummaryReport;
}> {
    return this.getReportsForRecipients$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: SearchSummaryReport;
}>): {
[key: string]: SearchSummaryReport;
} => r.body)
    );
  }

  /** Path part for operation `getCounts()` */
  static readonly GetCountsPath = '/search/summary/report/counts';

  /**
   * Get HLA search summary report counts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounts$Response(params: GetCounts$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchSummaryCounts>> {
    return getCounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get HLA search summary report counts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounts(params: GetCounts$Params, context?: HttpContext): Observable<SearchSummaryCounts> {
    return this.getCounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchSummaryCounts>): SearchSummaryCounts => r.body)
    );
  }

  /** Path part for operation `getAllReportsForRecipient()` */
  static readonly GetAllReportsForRecipientPath = '/search/summary/report/all';

  /**
   * Get all HLA search summary reports for a patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReportsForRecipient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReportsForRecipient$Response(params: GetAllReportsForRecipient$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchSummaryReport>>> {
    return getAllReportsForRecipient(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all HLA search summary reports for a patient
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllReportsForRecipient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReportsForRecipient(params: GetAllReportsForRecipient$Params, context?: HttpContext): Observable<Array<SearchSummaryReport>> {
    return this.getAllReportsForRecipient$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchSummaryReport>>): Array<SearchSummaryReport> => r.body)
    );
  }

}
